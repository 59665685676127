const meActions = {
  GET_ME_REQUEST: 'GET_ME_REQUEST',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_FAILURE: 'GET_ME_FAILURE',

  UPDATE_ME_REQUEST: 'UPDATE_ME_REQUEST',
  UPDATE_ME_SUCCESS: 'UPDATE_ME_SUCCESS',
  UPDATE_ME_FAILURE: 'UPDATE_ME_FAILURE',

  UPLOAD_ME_AVATAR_REQUEST: 'UPLOAD_ME_AVATAR_REQUEST',
  UPLOAD_ME_AVATAR_SUCCESS: 'UPLOAD_ME_AVATAR_SUCCESS',
  UPLOAD_ME_AVATAR_FAILURE: 'UPLOAD_ME_AVATAR_FAILURE',

  GET_AGREEMENT_REQUEST: 'GET_AGREEMENT_REQUEST',
  GET_AGREEMENT_SUCCESS: 'GET_AGREEMENT_SUCCESS',
  GET_AGREEMENT_FAILURE: 'GET_AGREEMENT_FAILURE',

  SET_USER_SECTIONS: 'SET_USER_SECTIONS',

  GET_ME_FILTERS_REQUEST: 'GET_ME_FILTERS_REQUEST',
  GET_ME_FILTERS_SUCCESS: 'GET_ME_FILTERS_SUCCESS',
  GET_ME_FILTERS_FAILURE: 'GET_ME_FILTERS_FAILURE',

  UPDATE_ME_FILTERS_REQUEST: 'UPDATE_ME_FILTERS_REQUEST',
  UPDATE_ME_FILTERS_SUCCESS: 'UPDATE_ME_FILTERS_SUCCESS',
  UPDATE_ME_FILTERS_FAILURE: 'UPDATE_ME_FILTERS_FAILURE',

  UPDATE_ME_CUSTOM_FIELDS_SUCCESS: 'UPDATE_ME_CUSTOM_FIELDS_SUCCESS',

  SET_UPLOAD_FILE_NOTIFICATION_REQUEST: 'SET_UPLOAD_FILE_NOTIFICATION_REQUEST',
  SET_UPLOAD_FILE_NOTIFICATION_SUCCESS: 'SET_UPLOAD_FILE_NOTIFICATION_SUCCESS',
  SET_UPLOAD_FILE_NOTIFICATION_FAILURE: 'SET_UPLOAD_FILE_NOTIFICATION_FAILURE',

  setUploadFileNotificationRequest: payload => ({
    type: meActions.SET_UPLOAD_FILE_NOTIFICATION_REQUEST,
    payload,
  }),
  setUploadFileNotificationSuccess: () => ({
    type: meActions.SET_UPLOAD_FILE_NOTIFICATION_SUCCESS,
  }),
  setUploadFileNotificationFailure: payload => ({
    type: meActions.SET_UPLOAD_FILE_NOTIFICATION_FAILURE,
    payload,
  }),

  getMeRequest: () => ({
    type: meActions.GET_ME_REQUEST,
  }),
  getMeSuccess: user => ({
    type: meActions.GET_ME_SUCCESS,
    payload: user,
  }),
  getMeFailure: error => ({
    type: meActions.GET_ME_FAILURE,
    payload: error,
  }),

  updateMeRequest: data => ({
    type: meActions.UPDATE_ME_REQUEST,
    data,
  }),
  updateMeSuccess: user => ({
    type: meActions.UPDATE_ME_SUCCESS,
    payload: user,
  }),
  updateMeFailure: error => ({
    type: meActions.UPDATE_ME_FAILURE,
    payload: error,
  }),

  uploadAvatarRequest: data => ({
    type: meActions.UPLOAD_ME_AVATAR_REQUEST,
    data,
  }),
  uploadAvatarSuccess: src => ({
    type: meActions.UPLOAD_ME_AVATAR_SUCCESS,
    payload: src,
  }),
  uploadAvatarFailure: error => ({
    type: meActions.UPLOAD_ME_AVATAR_FAILURE,
    payload: error,
  }),

  getAgreementRequest: () => ({ type: meActions.GET_AGREEMENT_REQUEST }),
  getAgreementSuccess: agreement => ({
    type: meActions.GET_AGREEMENT_SUCCESS,
    payload: agreement,
  }),
  getAgreementFailure: error => ({
    type: meActions.GET_AGREEMENT_FAILURE,
    payload: error,
  }),

  setUserSections: data => ({
    type: meActions.SET_USER_SECTIONS,
    payload: data,
  }),

  getMeFilters: () => ({ type: meActions.GET_ME_FILTERS_REQUEST }),

  getMeFiltersSuccess: agreement => ({
    type: meActions.GET_ME_FILTERS_SUCCESS,
    payload: agreement,
  }),

  getMeFiltersFailure: error => ({
    type: meActions.GET_ME_FILTERS_FAILURE,
    payload: error,
  }),

  updateMeFilters: data => ({
    type: meActions.UPDATE_ME_FILTERS_REQUEST,
    data,
  }),

  updateMeFiltersSuccess: agreement => ({
    type: meActions.UPDATE_ME_FILTERS_SUCCESS,
    payload: agreement,
  }),

  updateMeFiltersFailure: error => ({
    type: meActions.UPDATE_ME_FILTERS_FAILURE,
    payload: error,
  }),
  // Adding custom fields to the filter.
  updateMeCustomFieldsSuccess: payload => ({
    type: meActions.UPDATE_ME_CUSTOM_FIELDS_SUCCESS,
    payload,
  }),
};

export default meActions;
