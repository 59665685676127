import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
import databind from './helpers/redux';
import actions from '@iso/redux/companies/actions';
import companiesActions from '@iso/redux/companies/actions';

import { getUrlParams } from './helpers/utils';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() =>
      import('@iso/containers/Pages/AuthForms_V2/SignIn/SignIn')
    ),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() =>
      import('@iso/containers/Pages/AuthForms_V2/SignIn/SignIn/')
    ),
  },
  {
    path: PUBLIC_ROUTE.INVITE_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/Invite/InvitePasswordForm')
    ),
  },
  {
    path: PUBLIC_ROUTE.INVITE_BY_LINK,
    component: lazy(() =>
      import('@iso/containers/Pages/Invite/InviteByLinkForm')
    ),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() =>
      import('@iso/containers/Pages/ResetPassword/ResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/AuthForms_V2/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/AuthForms_V2/ResetPassword/ResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import('@iso/containers/Authentication/Auth0/Auth0Callback')
    ),
  },
  {
    path: PUBLIC_ROUTE.DOWNLOAD_RESOURCE,
    component: lazy(() =>
      import('./containers/pages/Resources/DownloadResource')
    ),
  },
  {
    path: PUBLIC_ROUTE.SESSION_END,
    exact: true,
    component: lazy(() => import('./containers/pages/SessionEnd/index')),
  },
  {
    path: [PUBLIC_ROUTE.SIGN_UP_FREE, PUBLIC_ROUTE.VERIFY],
    exact: true,
    component: lazy(() =>
      import('@iso/containers/Pages/AuthForms_V2/SignUp/SignUp')
    ),
  },
];

// component: lazy(() => import('@iso/containers/Pages/AuthForms_V2/Signup/SignUp')),

// function PrivateRoute({ children, ...rest }) {
//   let location = useLocation();
//   const isLoggedIn = useSelector(state => state.Auth.idToken);
//   if (isLoggedIn) return children;
//   return (
//     <Redirect
//       to={{
//         pathname: '/signin',
//         state: { from: location },
//       }}
//     />
//   );
// }

function RedirectRoute() {
  let location = useLocation();
  const { pathname, search } = location;

  const getCodeParam = uri => {
    const decodedUri = decodeURIComponent(uri);
    return getUrlParams(decodedUri).get('code');
  };

  const getCalendarType = pathname => {
    return pathname.includes('google-calendar') ? 'google' : 'outlook';
  };

  if (pathname && search) {
    if (pathname.includes('/dropbox/oauth')) {
      localStorage.setItem('dropboxCode', getCodeParam(search));
    }

    if (pathname.includes('/google-drive/oauth')) {
      localStorage.setItem('googleDriveCode', getCodeParam(search));
    }

    if (
      pathname.includes('/google-calendar/oauth') ||
      pathname.includes('/outlook-calendar/oauth')
    ) {
      localStorage.setItem('calendarCode', getCodeParam(search));
      localStorage.setItem('calendarType', getCalendarType(pathname));
    }
  }

  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

class Routes extends React.Component {
  static databind = state => ({
    isLoggedIn: state.Auth.idToken,
    loading: state.Auth.loading,
    user: state.me.user,
    companies: state.companies.data,
    selectedCompany: state.companies.selectedCompany,
  });

  static actions = {
    selectCompany: actions.selectCompany,
    getCompanyUsers: companiesActions.getCompanyUsersRequest,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { companies } = this.props;

    if (
      !this.props.selectedCompany &&
      !nextProps.selectedCompany &&
      !companies.length &&
      nextProps.companies.length
    ) {
      const selectedCompanyId = localStorage.getItem('selectedCompany');

      const hasCompanyAccess = selectedCompanyId
        ? !!nextProps.companies.find(
            company => company.id === selectedCompanyId
          )
        : false;

      const id =
        hasCompanyAccess && selectedCompanyId && selectedCompanyId !== 'null'
          ? selectedCompanyId
          : nextProps.companies[0].id;

      this.props.selectCompany({
        id,
        needUpdateToken: true,
      });
    }

    if (
      this.props.selectedCompany !== nextProps.selectedCompany &&
      nextProps.selectedCompany
    ) {
      this.props.getCompanyUsers(nextProps.selectedCompany);
    }
  }

  render() {
    const { isLoggedIn, loading } = this.props;

    return (
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Router>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))}

              {!isLoggedIn && !loading && <RedirectRoute />}

              {isLoggedIn && (
                <React.Fragment>
                  <Dashboard />
                </React.Fragment>
              )}
            </Switch>
          </Router>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export default databind(Routes);
