import { all, takeEvery, put, apply } from 'redux-saga/effects';
import _ from 'lodash';

import businessesActions from './actions';
import meActions from './../me/actions';
import anamiService from '../../services/anamiService';
import notifications from '@iso/components/Feedback/Notification';
import Geocode from '@iso/config/geocode.config';
import usersActions from '@iso/redux/users/actions';

import analytics, { events } from 'services/analytics';

const excludedFields = ['id', 'customFields'];

function* fetchData({ data }) {
  try {
    const response = yield anamiService.getBusinessList(data.queryParams);
    // If projects have custom fields, add fields with value to businesses column filter
    const customFields =
      response?.data?.businesses[0]?.additionalInformation?.customFields || [];
    if (customFields.length) {
      // set
      yield put(
        meActions.updateMeCustomFieldsSuccess(customFields)
        // meActions.updateMeCustomFieldsSuccess(
        //   customFields.map(field => ({
        //     name: field.name,
        //     active: false,
        //     key: getGetCustomSectionKeyFromName(field.name),
        //     isCustomField: true,
        //   }))
        // )
      );
    }

    if (data?.isFetchBusinessAvatars) {
      const avatarIds = response.data.businesses.reduce((acc, business) => {
        if (business.avatarId) {
          acc.push({
            businessId: business.id,
            avatarId: business.avatarId,
          });
        }

        return acc;
      }, []);

      if (!!avatarIds.length) {
        yield put(businessesActions.getBusinessesAvatarUrlRequest(avatarIds));
      }
    }

    yield put(businessesActions.fetchBusinessDataSuccess(response.data));
  } catch (error) {
    yield put(businessesActions.fetchBusinessDataFailure(error));
  }
}
// ANCHOR
function* businessCreate({ data }) {
  try {
    const { businessData, customFields } = data;
    const {
      files,
      file = null,
      fileUuid = null,
      projectData = null,
      privacy = 'private',
      ...rest
    } = businessData;

    if (projectData) {
      const project = yield anamiService.createProject(projectData);

      yield put(businessesActions.createProjectSuccess(project.data.project));

      rest.additionalCommunityBenefits = project.data.project.id;
    }

    rest.coordinates = yield Geocode.getCoordinatesFromAddress(rest);

    const response = yield anamiService.createBusiness(rest);

    sessionStorage.removeItem('business');
    yield put(businessesActions.businessCreateSuccess(response));

    if (file) {
      yield put(
        businessesActions.createBusinessAvatarReqeust({
          businessId: response.data.business.id,
          file,
          privacy,
        })
      );
    }

    if (fileUuid) {
      yield put(
        businessesActions.createBusinessAvatarReqeust({
          businessId: response.data.business.id,
          fileUuid,
          privacy,
        })
      );
    }

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        yield put(
          businessesActions.createBusinessFileRequest({
            businessId: response.data.business.id,
            ...files[i],
          })
        );
      }
    }

    if (customFields && customFields.length) {
      const updatedBusiness = yield anamiService.updateBusiness({
        id: response.data.business.id,
        customFields,
      });

      yield put(
        businessesActions.businessUpdateSuccess(updatedBusiness.data.business)
      );
    }
  } catch (error) {
    yield put(businessesActions.businessCreateFailure(error));
  } finally {
    yield put(businessesActions.stopBusinessLoading());
  }
}

function* businessGet({ id }) {
  try {
    if (id) {
      const response = yield anamiService.getBusiness(id);

      if (response.data?.business?.connectedTeams?.length) {
        const connectedTeams = response.data?.business?.connectedTeams;
        let teamUsers = [];

        for (let index = 0; index < connectedTeams.length; index++) {
          const teamUsersResponse = yield anamiService.fetchTeamUsers(
            connectedTeams[index].id
          );

          teamUsers = [
            ...teamUsers,
            ...(teamUsersResponse.data.users || []).map(user => ({
              ...user,
              teamMember: true,
            })),
          ];
        }

        response.data.business.connectedTeamUsers = _.uniqBy(teamUsers, 'id');
      }

      yield put(businessesActions.businessGetSuccess(response.data.business));
    } else {
      yield put(businessesActions.businessGetSuccess({}));
    }
  } catch (error) {
    yield put(businessesActions.businessGetFailure(error));
  }
}

function* businessUpdate({ business }) {
  try {
    let { projectData = null, ...data } = business;

    if (projectData) {
      const project = yield anamiService.createProject(projectData);

      yield put(businessesActions.createProjectSuccess(project.data.project));

      data.additionalCommunityBenefits = project.data.project.id;
    }

    try {
      data.coordinates = yield Geocode.getCoordinatesFromAddress(data);
    } catch (error) {
      console.error(error);
    }

    const response = yield anamiService.updateBusiness(data);

    yield apply(analytics, analytics.trackEvent, [
      events.EDIT_LOCATION,
      {
        businessId: response.data.business.id,
        updatedFields: Object.entries(data)
          .map(([field, value]) =>
            !!value && !excludedFields.includes(field) ? field : null
          )
          .filter(field => field),
      },
    ]);

    if (business.fileUuid) {
      yield put(
        businessesActions.businessUpdateSuccess({
          ...response.data.business,
          avatarId: business.fileUuid,
        })
      );
    } else {
      yield put(
        businessesActions.businessUpdateSuccess(response.data.business)
      );
    }

    if (business.fileUuid) {
      yield put(
        businessesActions.createBusinessAvatarReqeust({
          businessId: business.id,
          fileUuid: business.fileUuid,
        })
      );
    }

    yield put(businessesActions.stopBusinessLoading());
    // yield put(
    //   businessesActions.stopBusinessLoading({
    //     isBusinessCustomSectionAdded:
    //       businessCustomSectionNames.length >
    //       prevBusinessCustomSectionNamess.length,
    //   })
    // );
  } catch (error) {
    yield put(businessesActions.businessUpdateFailure(error));
  }
}

function* businessDelete({ id }) {
  try {
    const data = yield anamiService.removeBusiness(id);
    yield put(businessesActions.businessRemoveSuccess(id));
  } catch (error) {
    yield put(businessesActions.businessRemoveFailure(error));
  }
}

function* createBusinessAttachment({ data }) {
  try {
    const { businessId, file } = data;
    const response = yield anamiService.createBusinessAttachment({
      businessId,
      file,
    });
    const { attachment } = response.data;

    yield put(
      businessesActions.createBusinessAttachmentSuccess({
        ...attachment,
        businessId: data.businessId,
      })
    );
  } catch (error) {
    yield put(businessesActions.createBusinessAttachmentFailure(error));
  }
}

function* deleteAttachment({ data }) {
  try {
    const { businessId, attachmentId, ...rest } = data;
    yield anamiService.deleteBusinessAttachment({
      businessId,
      attachmentId,
    });

    yield put(
      businessesActions.deleteBusinessAttachmentSuccess({
        businessId,
        attachmentId,
      })
    );
  } catch (error) {
    yield put(businessesActions.deleteBusinessAttachmentFailure(error));
  }
}

function* createBusinessAvatar({ data }) {
  try {
    const { businessId, ...rest } = data;

    const response = yield anamiService.createBusinessAvatar({
      businessId,
      ...rest,
    });
    const { avatarId, avatarUrl } = response.data;

    const responseUrl = yield anamiService.getResourceUrl({ id: avatarId });

    yield put(
      businessesActions.createBusinessAvatarSuccess({
        avatarId,
        avatarUrl,
        avatar: responseUrl.data.url,
        businessId: data.businessId,
      })
    );
  } catch (error) {
    yield put(businessesActions.createBusinessAvatarFailure(error));
  }
}

function* updateMetrcKey({ data }) {
  try {
    const response = yield anamiService.updateMetrcKey(data);

    yield put(businessesActions.updateMetrcKeySuccess(response.data.business));
  } catch (error) {
    yield put(businessesActions.updateMetrcKeyFailure(error));
  }
}

function* fetchUsers(data) {
  try {
    const response = yield anamiService.fetchBusinessUsers(data);

    yield put(businessesActions.fetchBusinessUsersSuccess(response.data.users));
  } catch (error) {
    yield put(businessesActions.fetchBusinessUsersFailure(error));
  }
}

function* createUser({ data }) {
  try {
    const response = yield anamiService.createBusinessUser(data);

    yield put(
      businessesActions.createBusinessUserSuccess(response.data.businesses)
    );
  } catch (error) {
    yield put(businessesActions.createBusinessUserFailure(error));
  }
}

function* deleteUser({ data }) {
  try {
    const response = yield anamiService.deleteBusinessUser(data);

    yield put(
      businessesActions.deleteBusinessUserSuccess(response.data.businesses)
    );
    yield put(businessesActions.businessDeleteMainContact());
  } catch (error) {
    yield put(businessesActions.deleteBusinessUserFailure(error));
  }
}

function* fetchLicenses() {
  try {
    const response = yield anamiService.fetchBusinessLicenses();

    yield put(
      businessesActions.fetchBusinessLicensesSuccess(response.data.licenses)
    );
  } catch (error) {
    yield put(businessesActions.fetchBusinessLicensesFailure(error));
  }
}

function* fetchAssignedLicenses({ data }) {
  try {
    const response = yield anamiService.fetchBusinessLicenses(data);

    yield put(
      businessesActions.fetchBusinessAssignedLicensesSuccess(
        response.data.licenses
      )
    );
  } catch (error) {
    yield put(businessesActions.fetchBusinessAssignedLicensesFailure(error));
  }
}

function* assignLicense({ data }) {
  try {
    const { license, ...rest } = data;

    yield anamiService.setLicensesBusiness({
      licenseId: license.id,
      ...rest,
    });

    yield put(businessesActions.assignBusinessLicenseSuccess(license));
  } catch (error) {
    yield put(businessesActions.assignBusinessLicenseFailure(error));
  }
}

function* removeAssignedLicense({ data }) {
  try {
    const { license, ...rest } = data;

    yield anamiService.setLicensesBusiness({
      licenseId: license.id,
      ...rest,
    });

    yield put(businessesActions.removeAssignedLicenseSuccess(license));
  } catch (error) {
    yield put(businessesActions.removeAssignedLicenseFailure(error));
  }
}

function* exportSCV({ data }) {
  try {
    const response = yield anamiService.fetchBusinessesCSV(data);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'businesses.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    yield put(businessesActions.exportSCVSuccess());
  } catch (error) {
    yield put(businessesActions.exportSCVFailure(error));
  }
}

function* createBusinessFile({ data }) {
  try {
    const response = yield anamiService.createBusinessFile(data);
    const { attachment } = response.data;

    yield put(
      businessesActions.createBusinessFileSuccess({
        ...attachment,
        businessId: data.businessId,
      })
    );
  } catch (error) {
    yield put(businessesActions.createBusinessFileFailure(error));
  }
}

function* fetchArchivedBusinesses({ payload }) {
  try {
    const {
      data: { businesses },
    } = yield anamiService.fetchArchivedBusinesses(payload.queryParams);

    yield put(businessesActions.fetchArchivedBusinessesSuccess(businesses));
  } catch (error) {
    yield put(businessesActions.fetchBusinessAssignedLicensesFailure(error));
  }
}

function* archiveBusiness({ payload }) {
  try {
    yield anamiService.archiveBusiness(payload);

    notifications.info({
      message: 'Business archived successfully.',
    });

    yield put(businessesActions.archiveBusinessSuccess(payload));
  } catch (error) {
    yield put(businessesActions.archiveBusinessFailure(error));
  }
}

function* unzipBusiness({ payload }) {
  try {
    yield anamiService.unzipBusiness(payload);

    yield put(businessesActions.unzipBusinessSuccess(payload));
  } catch (error) {
    yield put(businessesActions.unzipBusinessFailure(error));
  }
}

function* fetchBusinessStructure({ data }) {
  try {
    const response = yield anamiService.getCompanyInfo(data);

    yield put(
      businessesActions.fetchBusinessStructureSuccess(
        response.data.businessStructure
      )
    );
  } catch (error) {
    yield put(businessesActions.fetchBusinessStructureFailure(error));
  }
}

function* fetchBusinessComments({ data }) {
  try {
    const response = yield anamiService.fetchBusinessComments(data);

    yield put(
      businessesActions.fetchBusinessCommentsSuccess(response.data.comments)
    );
  } catch (error) {
    yield put(businessesActions.fetchBusinessCommentsFailure(error));
  }
}

function* createBusinessComment({ data }) {
  try {
    const response = yield anamiService.createBusinessComment(data);

    yield apply(analytics, analytics.trackEvent, [
      events.COMMENT_ON_LOCATION,
      {
        businessId: data.businessId,
      },
    ]);

    yield put(
      businessesActions.createBusinessCommentSuccess(response.data.comment)
    );
  } catch (error) {
    yield put(businessesActions.createBusinessCommentFailure(error));
  }
}

function* updateBusinessComment({ data }) {
  try {
    const response = yield anamiService.updateBusinessComment(data);

    yield put(
      businessesActions.updateBusinessCommentSuccess(response.data.comment)
    );
  } catch (error) {
    yield put(businessesActions.updateBusinessCommentFailure(error));
  }
}

function* removeBusinessComment({ data }) {
  try {
    yield anamiService.deleteBusinessComment(data);

    yield put(businessesActions.removeBusinessCommentSuccess(data.commentId));
  } catch (error) {
    yield put(businessesActions.removeBusinessCommentFailure(error));
  }
}

function* fetchBusinessAuditLogs({ data }) {
  try {
    const response = yield anamiService.fetchFullAuditLogs(data);

    yield put(
      businessesActions.fetchBusinessAuditLogsSuccess(response.data.auditLogs)
    );
  } catch (error) {
    yield put(businessesActions.fetchBusinessAuditLogsFailure(error));
  }
}

function* readBusinessComments({ data }) {
  try {
    yield anamiService.readBusinessComments(data);

    yield put(businessesActions.readBusinessCommentsSuccess(data.commentIds));
  } catch (error) {
    yield put(businessesActions.readBusinessCommentsFailure(error));
  }
}

function* getBusinessAvatarUrl({ data }) {
  try {
    const response = yield anamiService.getResourceUrl(data);

    yield put(businessesActions.getBusinessAvatarUrlSuccess(response.data.url));
  } catch (error) {
    yield put(businessesActions.getBusinessAvatarUrlFailure(error));
  }
}

function* getBusinessesAvatarUrl({ data }) {
  try {
    for (let i = 0; i < data.length; i++) {
      const response = yield anamiService.getResourceUrl({
        id: data[i].avatarId,
      });
      // TODO remove after updating businesse logic
      yield put(
        businessesActions.getBusineseAvatarPreviewSuccess({
          businessId: data[i].businessId,
          avatarUrl: response.data.url,
        })
      );
    }

    yield put(businessesActions.getBusinessesAvatarUrlSuccess([]));
  } catch (error) {
    yield put(businessesActions.getBusinessesAvatarUrlFailure(error));
  }
}

function* addToFavorites({ data }) {
  try {
    yield anamiService.addBusinessToFavorites(data);

    yield put(businessesActions.addToFavoritesSuccess(data));
  } catch (error) {
    yield put(businessesActions.addToFavoritesFailure(error));
  }
}

function* removeFromFavorites({ data }) {
  try {
    yield anamiService.removeBusinessFromFavorites(data);

    yield put(businessesActions.removeFromFavoritesSuccess(data));
  } catch (error) {
    yield put(businessesActions.removeFromFavoritesFailure(error));
  }
}

function* assignTeam({ data }) {
  try {
    const { teamId } = data;

    const response = yield anamiService.setLocation(data);
    const teamUsersResponse = yield anamiService.fetchTeamUsers(teamId);

    yield put(
      businessesActions.assignTeamSuccess({
        team: response.data.team,
        teamUsers: teamUsersResponse.data.users,
      })
    );
  } catch (error) {
    yield put(businessesActions.assignTeamFailure(error));
  }
}

function* dismissTeam({ data }) {
  try {
    const { teamId } = data;

    const response = yield anamiService.unsetLocation(data);
    const teamUsersResponse = yield anamiService.fetchTeamUsers(teamId);

    yield put(
      businessesActions.dismissTeamSuccess({
        team: response.data.team,
        dismissedTeamUsers: teamUsersResponse.data.users,
      })
    );
  } catch (error) {
    yield put(businessesActions.dismissTeamFailure(error));
  }
}

function* connectUser({ data }) {
  try {
    const response = yield anamiService.connectUserToBusiness(data);
    const {
      userId,
      avatar,
      firstName,
      lastName,
      email,
    } = response.data.connectedUser;

    if (data?.forUser) {
      yield put(usersActions.getUserTeamsRequest());
    }

    yield put(
      businessesActions.connectUserSuccess({
        userId,
        avatar: avatar || '',
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
      })
    );
  } catch (error) {
    yield put(businessesActions.connectUserFailure(error));
  }
}

function* disconnectUser({ data }) {
  try {
    yield anamiService.disconnectUserFromBusiness(data);

    if (data?.forUser) {
      yield put(usersActions.getUserTeamsRequest());
    }

    yield put(businessesActions.disconnectUserSuccess(data));
  } catch (error) {
    yield put(businessesActions.disconnectUserFailure(error));
  }
}

function* getSimpleBusinessesData() {
  try {
    const response = yield anamiService.getAllBusinessesSmallObject();
    yield put(businessesActions.getSimpleBusinessesDataSuccess(response.data));
  } catch (error) {
    yield put(businessesActions.getSimpleBusinessesDataFailure(error));
  }
}

function* revokeInviteUserFromBusiness({ payload }) {
  // payload exist user id
  try {
    yield anamiService.revokeInviteUserFromBusiness(payload);
    yield put(businessesActions.revokeInviteUserFromBusinessSuccess(payload));
  } catch (error) {
    yield put(businessesActions.revokeInviteUserFromBusinessFailure(error));
  }
}

function* saveBusinessesFilter({ payload }) {
  // payload exist user id
  try {
    yield anamiService.revokeInviteUserFromBusiness(payload);
    yield put(businessesActions.revokeInviteUserFromBusinessSuccess(payload));
  } catch (error) {
    yield put(businessesActions.revokeInviteUserFromBusinessFailure(error));
  }
}

function* getBusinessFilterInitData() {
  try {
    const response = yield anamiService.getBusinessFilterInitData();
    yield put(
      businessesActions.getBusinessFilterInitDataSuccess(response.data)
    );
  } catch (error) {
    yield put(businessesActions.getBusinessFilterInitDataFailure(error));
  }
}
// TODO - remove after update business table logic
// function* fetchBusinessAvatarUrls({ data }) {
//   try {
//     let avatars = {};

//     if (!!data.length) {
//       for (let i = 0; i < data.length; i++) {
//         if (data[i].avatarId) {
//           const response = yield anamiService.getResourceUrl({
//             id: data[i].avatarId,
//           });

//           avatars = {
//             ...avatars,
//             [data[i].id]: response.data.url,
//           };
//         }
//       }
//     }

//     yield put(businessesActions.fetchBusinessAvatarUrlsSuccess(avatars));
//   } catch (error) {
//     yield put(businessesActions.fetchBusinessAvatarUrlsFailure(error));
//   }
// }

export default function* businessesSaga() {
  yield all([
    takeEvery(businessesActions.FETCH_BUSINESSES_DATA_REQUEST, fetchData),
  ]);
  yield all([
    takeEvery(businessesActions.BUSINESS_CREATE_REQUEST, businessCreate),
  ]);
  yield all([takeEvery(businessesActions.BUSINESS_GET_REQUEST, businessGet)]);
  yield all([
    takeEvery(businessesActions.BUSINESS_UPDATE_REQUEST, businessUpdate),
  ]);
  yield all([
    takeEvery(businessesActions.BUSINESS_REMOVE_REQUEST, businessDelete),
  ]);
  yield all([
    takeEvery(
      businessesActions.CREATE_BUSINESS_ATTACHMENT_REQUEST,
      createBusinessAttachment
    ),
    takeEvery(
      businessesActions.DELETE_BUSINESS_ATTACHMENT_REQUEST,
      deleteAttachment
    ),
  ]);
  yield all([
    takeEvery(
      businessesActions.CREATE_BUSINESS_AVATAR_REQUEST,
      createBusinessAvatar
    ),
  ]);
  yield all([
    takeEvery(businessesActions.UPDATE_METRC_KEY_REQUEST, updateMetrcKey),
  ]);
  yield takeEvery(businessesActions.FETCH_BUSINESS_USERS_REQUEST, fetchUsers);
  yield takeEvery(businessesActions.CREATE_BUSINESS_USER_REQUEST, createUser);
  yield takeEvery(businessesActions.DELETE_BUSINESS_USER_REQUEST, deleteUser);
  yield takeEvery(
    businessesActions.FETCH_BUSINESS_LICENSES_REQUEST,
    fetchLicenses
  );
  yield takeEvery(
    businessesActions.FETCH_BUSINESS_ASSIGNED_LICENSES_REQUEST,
    fetchAssignedLicenses
  );
  yield takeEvery(
    businessesActions.ASSIGN_BUSINESS_LICENSE_REQUEST,
    assignLicense
  );
  yield takeEvery(
    businessesActions.REMOVE_ASSIGNED_LICENSE_REQUEST,
    removeAssignedLicense
  );
  yield takeEvery(businessesActions.EXPORT_BUSINESSES_SCV_REQUEST, exportSCV);
  yield takeEvery(
    businessesActions.CREATE_BUSINESS_FILE_REQUEST,
    createBusinessFile
  );
  yield takeEvery(
    businessesActions.FETCH_ARCHIVED_BUSINESSES_REQUEST,
    fetchArchivedBusinesses
  );
  yield takeEvery(businessesActions.ARCHIVE_BUSINESS_REQUEST, archiveBusiness);
  yield takeEvery(businessesActions.UNZIP_BUSINESS_REQUEST, unzipBusiness);
  yield takeEvery(
    businessesActions.FETCH_BUSINESS_STRUCTURE_REQUEST,
    fetchBusinessStructure
  );
  yield takeEvery(
    businessesActions.FETCH_BUSINESS_COMMENTS_REQUEST,
    fetchBusinessComments
  );
  yield takeEvery(
    businessesActions.CREATE_BUSINESS_COMMENT_REQUEST,
    createBusinessComment
  );
  yield takeEvery(
    businessesActions.UPDATE_BUSINESS_COMMENT_REQUEST,
    updateBusinessComment
  );
  yield takeEvery(
    businessesActions.REMOVE_BUSINESS_COMMENT_REQUEST,
    removeBusinessComment
  );
  yield takeEvery(
    businessesActions.FETCH_BUSINESS_AUDIT_LOGS_REQUEST,
    fetchBusinessAuditLogs
  );
  yield takeEvery(
    businessesActions.READ_BUSINESS_COMMENTS_REQUEST,
    readBusinessComments
  );
  yield takeEvery(
    businessesActions.GET_BUSINESS_AVATAR_URL_REQUEST,
    getBusinessAvatarUrl
  );
  yield takeEvery(
    businessesActions.GET_BUSINESSES_AVATAR_URL_REQUEST,
    getBusinessesAvatarUrl
  );
  yield takeEvery(businessesActions.ADD_TO_FAVORITES_REQUEST, addToFavorites);
  yield takeEvery(
    businessesActions.REMOVE_FROM_FAVORITES_REQUEST,
    removeFromFavorites
  );
  // yield takeEvery(
  //   businessesActions.FETCH_BUSINESS_AVATAR_URLS_REQUEST,
  //   fetchBusinessAvatarUrls
  // );
  yield takeEvery(
    businessesActions.ASSIGN_TEAM_TO_BUSINESS_REQUEST,
    assignTeam
  );
  yield takeEvery(
    businessesActions.DISMISS_TEAM_FROM_BUSINESS_REQUEST,
    dismissTeam
  );
  yield takeEvery(
    businessesActions.CONNECT_USER_TO_BUSINESS_REQUEST,
    connectUser
  );
  yield takeEvery(
    businessesActions.DISCONNECT_USER_FROM_BUSINESS_REQUEST,
    disconnectUser
  );
  yield takeEvery(
    businessesActions.GET_SIMPLE_BUSINESSES_DATA_REQUEST,
    getSimpleBusinessesData
  );
  yield takeEvery(
    businessesActions.REVOKE_INVITE_USER_FROM_BUSINESS,
    revokeInviteUserFromBusiness
  );
  yield takeEvery(
    businessesActions.SAVE_BUSINESSES_FILTER_REQUEST,
    saveBusinessesFilter
  );
  yield takeEvery(
    businessesActions.GET_BUSINESSES_FILTERS_INIT_DATA_REQUEST,
    getBusinessFilterInitData
  );
}
