import { filterObject } from 'helpers/utils';
import businessesActions from './actions';

const defaultBusinessesQueryParams = {
  orderBy: 'name',
  order: 'descend',
  page: 1,
  page_size: 10,
};

// TODO remove after redesign
const getBusinessData = () => {
  const business = {
    name: '',
    dba: '',
    status: '—',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    email: '',
    county: '',
    licenseType: '',
    files: [],
    avatar: null,
    preview: null,
    modeType: '',
    location: '',
    stateLicenseNumber: '',
    localLicenseNumber: '',
    // licenseExpiration: null,
    // openingDate: null,
    owners: null,
    apn: '',
    zoning: '',
    taxRate: null,
    population: '',
    communityBenefits: true,
    communityBenefitFee: null,
    squareFootage: '',
    business: '',
    // notes: '',
    contactName: '',
    phoneNumber: '',
    additionalCommunityBenefits: '',
    stateLicense: [],
    localLicense: [],
    businessLicense: [],
    leasePurchaseAgreements: [],
    occupancyCertificates: [],
    generalContractor: null,
    repairsAndMaintenance: null,
  };

  const businessData = sessionStorage.getItem('business');

  if (businessData) {
    const parsedData = JSON.parse(businessData);

    if (Object.keys(parsedData).length === 0) {
      return business;
    }

    return parsedData;
  } else {
    return business;
  }
};
// remove after redesign
const initialState = {
  data: [],
  loading: false,
  businessListLoading: false,
  attachmentsLoading: false,
  firstLoad: true,
  error: null,
  isOpenAddView: false,
  dataForNewBusiness: getBusinessData(),
  activeBusiness: {},
  filesForUpload: [],
  selectedBusiness: { id: '7d58970f-e320-408a-aad2-90a16ec82863' },
  users: [],
  licenses: [],
  assignedLicenses: [],
  isOpenExportCSV: false,
  downloadingCSV: false,
  pendingUpdating: false,
  businessStructure: [],
  auditLogsLoading: false,
  commentsLoading: false,
  businessMode: 'view',
  hasNewCustomSection: false,
  usersTeamsLoading: false,
  sorterData: { ...defaultBusinessesQueryParams },
  avatarUrlLoading: false,
  businessFilters: {},
  simpleBusinessesData: [],
  simpleBusinessesLoading: false,
  tempFilterData: {},
  activeFilter: null,
  specifications: {},
  businessesTotalNumber: 0,
};

export default function businesses(state = initialState, action) {
  switch (action.type) {
    // request
    case businessesActions.FETCH_BUSINESSES_DATA_REQUEST:
    case businessesActions.FETCH_ARCHIVED_BUSINESSES_REQUEST:
      return {
        ...state,
        loading: true,
        businessListLoading: true,
        error: null,
      };

    case businessesActions.BUSINESS_CREATE_REQUEST:
    case businessesActions.BUSINESS_REMOVE_REQUEST:
    case businessesActions.CREATE_BUSINESS_AVATAR_REQUEST:
    case businessesActions.FETCH_BUSINESS_USERS_REQUEST:
    case businessesActions.CREATE_BUSINESS_USER_REQUEST:
    case businessesActions.DELETE_BUSINESS_USER_REQUEST:
      return { ...state, loading: true, error: null };

    case businessesActions.GET_BUSINESSES_AVATAR_URL_REQUEST:
      return { ...state, loading: true, avatarUrlLoading: true, error: null };

    case businessesActions.CREATE_BUSINESS_ATTACHMENT_REQUEST:
    case businessesActions.DELETE_BUSINESS_ATTACHMENT_REQUEST:
      return { ...state, attachmentsLoading: true, error: null };

    // failure
    case businessesActions.FETCH_BUSINESSES_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        businessListLoading: false,
        error: action.payload,
      };

    case businessesActions.CREATE_BUSINESS_AVATAR_FAILURE:
    case businessesActions.BUSINESS_CREATE_FAILURE:
    case businessesActions.BUSINESS_GET_FAILURE:
    case businessesActions.BUSINESS_REMOVE_FAILURE:
    case businessesActions.FETCH_BUSINESS_USERS_FAILURE:
    case businessesActions.CREATE_BUSINESS_USER_FAILURE:
    case businessesActions.DELETE_BUSINESS_USER_FAILURE:
    case businessesActions.CREATE_BUSINESS_COMMENT_FAILURE:
    case businessesActions.UPDATE_BUSINESS_COMMENT_FAILURE:
    case businessesActions.GET_BUSINESSES_AVATAR_URL_FAILURE:
    case businessesActions.REVOKE_INVITE_USER_FROM_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        avatarUrlLoading: false,
        error: action.payload,
      };

    case businessesActions.CREATE_BUSINESS_ATTACHMENT_FAILURE:
    case businessesActions.DELETE_BUSINESS_ATTACHMENT_FAILURE:
      return { ...state, attachmentsLoading: false, error: null };

    // success
    case businessesActions.FETCH_BUSINESS_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case businessesActions.CREATE_BUSINESS_USER_SUCCESS:
    case businessesActions.DELETE_BUSINESS_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        activeBusiness: action.payload,
        data: state.data.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    // added a condition for viewing the last functionality when the flag is set to true
    case businessesActions.FETCH_BUSINESSES_DATA_SUCCESS:
      if (process.env.REACT_APP_SHOW_LATEST_FUNCTIONAL === 'false') {
        if (
          !state.activeBusiness ||
          !action.payload.businesses.find(x => x.id === state.activeBusiness.id)
        ) {
          state.activeBusiness = action.payload.businesses[0];
        }
      }

      return {
        ...state,
        data: action.payload.businesses,
        loading: false,
        error: null,
        businessListLoading: false,
        businessesTotalNumber: action.payload.totalNumber,
      };

    case businessesActions.FILTER_BUSINESSES:
      return {
        ...state,
        filterStatus: action.filter,
      };

    case businessesActions.BUSINESS_SHOW_CREATE_SECTION:
      return {
        ...state,
        isOpenAddView: action.payload,
      };
    case businessesActions.BUSINESS_HIDE_CREATE_SECTION:
      return {
        ...state,
        isOpenAddView: false,
      };

    case businessesActions.BUSINESS_ADD_NEW:
      return {
        ...state,
        loading: false,
        dataForNewBusiness: action.data,
      };

    case businessesActions.BUSINESS_CREATE_SUCCESS:
      const updatedData = [action.payload.data.business, ...state.data];
      return {
        ...state,
        activeBusiness: action.payload.data.business,
        // isOpenAddView: false,
        // loading: false,
        data: updatedData,
        dataForNewBusiness: getBusinessData(),
      };

    case businessesActions.STOP_BUSINESS_LOADING:
      return {
        ...state,
        isOpenAddView: false,
        loading: false,
        // hasNewCustomSection: action.payload?.isBusinessCustomSectionAdded,
      };
    // added a condition for viewing the last functionality when the flag is set to true
    case businessesActions.BUSINESS_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        ...(process.env.REACT_APP_SHOW_LATEST_FUNCTIONAL === 'true' && {
          activeBusiness: {},
        }),
      };

    case businessesActions.BUSINESS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        activeBusiness: action.payload,
        data: [],
      };

    case businessesActions.BUSINESS_UPDATE_REQUEST:
      return {
        ...state,
        pendingUpdating: true,
      };

    case businessesActions.BUSINESS_UPDATE_FAILURE:
      return {
        ...state,
        pendingUpdating: false,
        error: action.payload,
      };

    case businessesActions.BUSINESS_UPDATE_SUCCESS: {
      let updatedBusiness = { ...state.activeBusiness };

      if (!updatedBusiness.avatar) {
        updatedBusiness = action.payload;
      } else {
        updatedBusiness = {
          ...action.payload,
          avatar: updatedBusiness.avatar,
        };
      }

      return {
        ...state,
        pendingUpdating: false,
        activeBusiness: updatedBusiness,
        data: state.data.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    }

    // added a condition for viewing the last functionality when the flag is set to true
    case businessesActions.BUSINESS_REMOVE_SUCCESS:
      const nData = state.data.filter(item => item.id !== action.payload);

      return {
        ...state,
        ...(process.env.REACT_APP_SHOW_LATEST_FUNCTIONAL === 'false' && {
          activeBusiness: nData[0],
        }),
        data: nData,
        loading: false,
      };

    case businessesActions.CREATE_BUSINESS_ATTACHMENT_SUCCESS:
    case businessesActions.CREATE_BUSINESS_FILE_SUCCESS:
      let activeItem = {};
      const updData = state.data.map(item => {
        if (item.id === action.payload.businessId) {
          item.files.push(action.payload);
          activeItem = item;
        }
        return item;
      });
      return {
        ...state,
        attachmentsLoading: false,
        activeBusiness: activeItem,
        data: updData,
      };

    case businessesActions.CREATE_BUSINESS_AVATAR_SUCCESS: {
      const { businessId, ...rest } = action.payload;

      return {
        ...state,
        activeBusiness: {
          ...state.activeBusiness,
          ...rest,
        },
        data: state.data.map(item => {
          if (item.id === businessId) {
            return {
              ...item,
              ...rest,
            };
          }
          return item;
        }),
        loading: false,
        error: null,
      };
    }

    case businessesActions.DELETE_BUSINESS_ATTACHMENT_SUCCESS:
      let activeI = {};
      let updDataD = [];

      if (
        process.env.REACT_APP_SHOW_LATEST_FUNCTIONAL === 'true' &&
        process.env.REACT_APP_LOCATIONS_PAGE_TABLE_VIEW === 'true'
      ) {
        activeI = {
          ...state.activeBusiness,
          files: state.activeBusiness.files.filter(
            file => file.id !== action.payload.attachmentId
          ),
        };

        updDataD = state.data;
      } else {
        updDataD = state.data.map(item => {
          if (item.id === action.payload.businessId) {
            item.files = item.files.filter(
              f => f.id !== action.payload.attachmentId
            );
            activeI = item;
          }
          return item;
        });
      }

      return {
        ...state,
        attachmentsLoading: false,
        activeBusiness: activeI,
        data: updDataD,
      };

    case businessesActions.UPDATE_METRC_KEY_SUCCESS:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };

    case businessesActions.REMOVE_DEFAULT_BUSINESS_DATA:
      return {
        ...state,
        dataForNewBusiness: getBusinessData(),
      };

    case businessesActions.FETCH_BUSINESS_ASSIGNED_LICENSES_FAILURE:
    case businessesActions.FETCH_BUSINESS_LICENSES_FAILURE:
    case businessesActions.ASSIGN_BUSINESS_LICENSE_FAILURE:
    case businessesActions.REMOVE_ASSIGNED_LICENSE_FAILURE:
    case businessesActions.FETCH_BUSINESS_STRUCTURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case businessesActions.FETCH_BUSINESS_LICENSES_SUCCESS:
      return {
        ...state,
        licenses: action.payload,
      };

    case businessesActions.FETCH_BUSINESS_ASSIGNED_LICENSES_SUCCESS:
      return {
        ...state,
        assignedLicenses: action.payload,
      };

    case businessesActions.ASSIGN_BUSINESS_LICENSE_SUCCESS:
      return {
        ...state,
        assignedLicenses: [...state.assignedLicenses, action.payload],
        licenses: state.licenses.filter(
          license => license.id !== action.payload.id
        ),
      };

    case businessesActions.REMOVE_ASSIGNED_LICENSE_SUCCESS:
      return {
        ...state,
        assignedLicenses: state.assignedLicenses.filter(
          assignedLicense => assignedLicense.id !== action.payload.id
        ),
        licenses: [...state.licenses, action.payload],
      };

    case businessesActions.TOGGLE_BUSINESS_EXPORT_CSV_MODAL_STATE:
      return {
        ...state,
        isOpenExportCSV: action.payload,
      };

    case businessesActions.EXPORT_BUSINESSES_SCV_REQUEST:
      return {
        ...state,
        downloadingCSV: true,
      };

    case businessesActions.EXPORT_BUSINESSES_SCV_SUCCESS:
    case businessesActions.EXPORT_BUSINESSES_SCV_FAILURE:
      return {
        ...state,
        downloadingCSV: false,
      };

    case businessesActions.CREATE_CUSTOM_BUSINESS_FIELD_SUCCESS:
      return {
        ...state,
        activeBusiness: action.payload.business,
        data: state.data.map(item => {
          if (item.id === action.payload.business.id) {
            return {
              ...item,
              ...action.payload.business,
            };
          }
          return item;
        }),
      };

    case businessesActions.FETCH_ARCHIVED_BUSINESSES_SUCCESS:
      // added a condition for viewing the last functionality when the flag is set to true
      return {
        ...state,
        data: [...action.payload],
        loading: false,
        businessListLoading: false,
        ...(process.env.REACT_APP_SHOW_LATEST_FUNCTIONAL === 'false' && {
          activeBusiness: action.payload[0],
        }),
      };

    case businessesActions.FETCH_BUSINESS_STRUCTURE_SUCCESS:
      return {
        ...state,
        businessStructure: action.payload,
      };

    case businessesActions.CLEAR_BUSINESS_STRUCTURE:
      return {
        ...state,
        businessStructure: [],
      };

    case businessesActions.FETCH_BUSINESS_COMMENTS_REQUEST:
    case businessesActions.UPDATE_BUSINESS_COMMENT_REQUEST:
    case businessesActions.REMOVE_BUSINESS_COMMENT_REQUEST:
      return {
        ...state,
        commentsLoading: true,
      };

    case businessesActions.FETCH_BUSINESS_COMMENTS_FAILURE:
    case businessesActions.UPDATE_BUSINESS_COMMENT_FAILURE:
    case businessesActions.REMOVE_BUSINESS_COMMENT_FAILURE:
      return {
        ...state,
        commentsLoading: false,
        error: action.payload,
      };

    case businessesActions.FETCH_BUSINESS_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsLoading: false,
        activeBusiness: {
          ...state.activeBusiness,
          comments: action.payload,
        },
      };

    case businessesActions.CREATE_BUSINESS_COMMENT_SUCCESS:
      return {
        ...state,
        activeBusiness: {
          ...state.activeBusiness,
          comments: [...state.activeBusiness.comments, action.payload],
        },
      };

    case businessesActions.UPDATE_BUSINESS_COMMENT_SUCCESS:
      return {
        ...state,
        commentsLoading: false,
        activeBusiness: {
          ...state.activeBusiness,
          comments: state.activeBusiness.comments.map(comment => {
            if (comment.id === action.payload.id) {
              return action.payload;
            }

            return comment;
          }),
        },
      };

    case businessesActions.REMOVE_BUSINESS_COMMENT_SUCCESS:
      return {
        ...state,
        commentsLoading: false,
        activeBusiness: {
          ...state.activeBusiness,
          comments: state.activeBusiness.comments.filter(
            comment => comment.id !== action.payload
          ),
        },
      };

    case businessesActions.READ_BUSINESS_COMMENTS_SUCCESS:
      return {
        ...state,
        activeBusiness: {
          ...state.activeBusiness,
          comments: state.activeBusiness.comments.map(comment => {
            if (action.payload.includes(comment.id)) {
              return {
                ...comment,
                read: true,
              };
            }

            return comment;
          }),
        },
      };

    case businessesActions.SET_ACTIVE_BUSINESS_TO_STORE:
      return {
        ...state,
        activeBusiness: action.payload,
      };

    case businessesActions.ARCHIVE_BUSINESS_SUCCESS:
    case businessesActions.UNZIP_BUSINESS_REQUEST:
      const filtredBusinesses = state.data.filter(
        business => business.id !== action.payload
      );
      // added a condition for viewing the last functionality when the flag is set to true
      return {
        ...state,
        data: filtredBusinesses,
        ...(process.env.REACT_APP_SHOW_LATEST_FUNCTIONAL === 'false' && {
          activeBusiness: filtredBusinesses.length ? filtredBusinesses[0] : {},
        }),
      };

    case businessesActions.FETCH_BUSINESS_AUDIT_LOGS_REQUEST:
      return {
        ...state,
        auditLogsLoading: true,
      };

    case businessesActions.FETCH_BUSINESS_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        auditLogsLoading: false,
        activeBusiness: {
          ...state.activeBusiness,
          auditLogs: action.payload,
        },
      };

    case businessesActions.FETCH_BUSINESS_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        auditLogsLoading: false,
        error: action.payload,
      };

    case businessesActions.SET_BUSINESS_MODE:
      return {
        ...state,
        businessMode: action.payload,
      };

    case businessesActions.GET_BUSINESS_AVATAR_URL_SUCCESS:
      return {
        ...state,
        activeBusiness: {
          ...state.activeBusiness,
          avatar: action.payload,
        },
      };
    // TODO remove after updating businesse logic
    case businessesActions.GET_BUSINESSES_AVATAR_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        avatarUrlLoading: false,
        data: state.data.map(item => {
          const { id } = item || {};

          if (_.has(action.payload, id)) {
            return {
              ...item,
              avatar: action.payload[id],
            };
          }

          return item;
        }),
      };
    // TODO remove after updating businesse logic
    case businessesActions.GET_BUSINESS_AVATAR_PREVIEW_SUCCESS:
      return {
        ...state,
        data: state.data.map(item => {
          if (action.payload.businessId === item.id) {
            return {
              ...item,
              avatar: action.payload.avatarUrl,
            };
          }

          return item;
        }),
      };

    case businessesActions.ADD_TO_FAVORITES_SUCCESS:
    case businessesActions.REMOVE_FROM_FAVORITES_SUCCESS:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id === action.payload.businessId) {
            return {
              ...item,
              isFavorite: !item.isFavorite,
            };
          }

          return item;
        }),
        ...(state.activeBusiness.id && {
          activeBusiness: {
            ...state.activeBusiness,
            isFavorite: !state.activeBusiness.isFavorite,
          },
        }),
      };

    case businessesActions.SET_BUSINESS_HAS_NEW_SECTION_FLAG:
      return {
        ...state,
        hasNewCustomSection: action.payload,
      };

    case businessesActions.ASSIGN_TEAM_TO_BUSINESS_REQUEST:
    case businessesActions.DISMISS_TEAM_FROM_BUSINESS_REQUEST:
    case businessesActions.CONNECT_USER_TO_BUSINESS_REQUEST:
    case businessesActions.DISCONNECT_USER_FROM_BUSINESS_REQUEST:
      return {
        ...state,
        usersTeamsLoading: true,
      };

    case businessesActions.ASSIGN_TEAM_TO_BUSINESS_FAILURE:
    case businessesActions.DISMISS_TEAM_FROM_BUSINESS_FAILURE:
    case businessesActions.CONNECT_USER_TO_BUSINESS_FAILURE:
    case businessesActions.DISCONNECT_USER_FROM_BUSINESS_FAILURE:
      return {
        ...state,
        usersTeamsLoading: false,
        error: action.payload,
      };

    case businessesActions.ASSIGN_TEAM_TO_BUSINESS_SUCCESS:
      return {
        ...state,
        usersTeamsLoading: false,
        activeBusiness: {
          ...state.activeBusiness,
          connectedTeamUsers: [
            ...(state.activeBusiness.connectedTeamUsers
              ? state.activeBusiness.connectedTeamUsers
              : []),
            ...(action.payload.teamUsers
              ? action.payload.teamUsers.map(user => ({
                  ...user,
                  teamMember: true,
                }))
              : []),
          ],
          connectedTeams: [
            ...(state.activeBusiness.connectedTeams
              ? state.activeBusiness.connectedTeams
              : []),
            { id: action.payload.team.id, title: action.payload.team.title },
          ],
        },
      };

    case businessesActions.DISMISS_TEAM_FROM_BUSINESS_SUCCESS:
      return {
        ...state,
        usersTeamsLoading: false,
        activeBusiness: {
          ...state.activeBusiness,
          connectedTeams: state.activeBusiness.connectedTeams.filter(
            item => item.id !== action.payload.team.id
          ),
          ...(state.activeBusiness.connectedTeamUsers && {
            connectedTeamUsers: state.activeBusiness.connectedTeamUsers.filter(
              teamUser =>
                !action.payload.dismissedTeamUsers.find(
                  dismissedTeamUser => dismissedTeamUser.id === teamUser.id
                )
            ),
          }),
        },
      };

    case businessesActions.CONNECT_USER_TO_BUSINESS_SUCCESS:
      return {
        ...state,
        usersTeamsLoading: false,
        activeBusiness: {
          ...state.activeBusiness,
          connectedUsers: [
            ...(state.activeBusiness.connectedUsers
              ? state.activeBusiness.connectedUsers
              : []),
            action.payload,
          ],
        },
      };

    case businessesActions.DISCONNECT_USER_FROM_BUSINESS_SUCCESS:
      return {
        ...state,
        usersTeamsLoading: false,
        activeBusiness: {
          ...state.activeBusiness,
          connectedUsers: state.activeBusiness.connectedUsers.filter(
            item => item.userId !== action.payload.userId
          ),
        },
      };

    case businessesActions.SET_BUSINESS_SORTER_DATA:
      return {
        ...state,
        sorterData: { ...action.payload },
      };

    case businessesActions.SET_BUSINESS_FILTERS:
      return {
        ...state,
        businessFilters: action.payload,
      };

    case businessesActions.RESET_FILTERS_AND_QUERY_PARAMS:
      return {
        ...state,
        sorterData: { ...defaultBusinessesQueryParams },
        tempFilterData: {},
      };

    case businessesActions.GET_SIMPLE_BUSINESSES_DATA_REQUEST:
      return {
        ...state,
        simpleBusinessesLoading: true,
      };
    case businessesActions.GET_SIMPLE_BUSINESSES_DATA_SUCCESS:
      return {
        ...state,
        simpleBusinessesLoading: false,
        simpleBusinessesData: action.payload,
      };
    case businessesActions.GET_SIMPLE_BUSINESSES_DATA_FAILURE:
      return {
        ...state,
        simpleBusinessesLoading: false,
        error: action.payload,
      };

    case businessesActions.REVOKE_INVITE_USER_FROM_BUSINESS_SUCCESS:
      return {
        ...state,
        activeBusiness: {
          ...state.activeBusiness,
          connectedUsers: [
            ...state.activeBusiness.connectedUsers.filter(
              user => user.userId !== action,
              payload
            ),
          ],
        },
      };

    case businessesActions.ADD_INVITE_USER_TO_STATE:
      return {
        ...state,
        activeBusiness: {
          ...state.activeBusiness,
          connectedUsers: [
            ...state.activeBusiness.connectedUsers,
            action.payload.user,
          ],
        },
      };

    case businessesActions.SET_BUSINESSES_FILTER:
      const { fieldName, value } = action.payload;

      //if field name is null, clear all filters
      if (!fieldName)
        return { ...state, tempFilterData: {}, activeFilter: null };

      //if filter value is empty, remove field from tempFilterData
      const payload = value ? { [fieldName]: value } : {};

      const tempFilterDataWithoutEmptyFields = value
        ? state.tempFilterData
        : Object.fromEntries(
            Object.entries(state.tempFilterData).filter(
              ([key, value]) => !(key === fieldName)
            )
          );

      return {
        ...state,
        tempFilterData: {
          ...tempFilterDataWithoutEmptyFields,
          ...payload,
        },
      };

    case businessesActions.GET_BUSINESSES_FILTERS_INIT_DATA_SUCCESS:
      return {
        ...state,
        specifications: {
          ...state.specifications,
          filterInitData: action.payload,
        },
      };

    case businessesActions.SET_ACTIVE_FILTER:
      const { filterName, filterData } = action.payload;
      const isDeselectFilter = filterName === state.activeFilter;
      return {
        ...state,
        activeFilter: isDeselectFilter ? '' : filterName,
        tempFilterData: isDeselectFilter ? {} : filterData,
      };

    // case businessesActions.SET_QUERY_PARAMS:
    //   return {
    //     ...state,
    //     queryParams: {...action.payload}
    //   };

    case businessesActions.SET_SEARCH_VALUE:
      return {
        ...state,
        sorterData: filterObject(
          {
            ...state.sorterData,
            search: action.payload,
          },
          ([key, value]) => value
        ),
      };

    case businessesActions.SET_DEFAULT_ERROR_STATE:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
