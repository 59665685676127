import actionTypes from './actionTypes';

export default {
  getSopsRequest: () => ({
    type: actionTypes.GET_SOPS_REQUEST,
  }),
  getSopsSuccess: data => ({
    type: actionTypes.GET_SOPS_SUCCESS,
    payload: data,
  }),
  getSopsFailure: error => ({
    type: actionTypes.GET_SOPS_FAILURE,
    payload: error,
  }),

  createSopRequest: data => ({
    type: actionTypes.CREATE_SOP_REQUEST,
    data,
  }),
  createSopSuccess: checklist => ({
    type: actionTypes.CREATE_SOP_SUCCESS,
    payload: checklist,
  }),
  createSopFailure: error => ({
    type: actionTypes.CREATE_SOP_FAILURE,
    payload: error,
  }),

  assigneeSopRequest: data => ({
    type: actionTypes.ASSIGNEE_SOP_REQUEST,
    data,
  }),
  assigneeSopSuccess: checklist => ({
    type: actionTypes.ASSIGNEE_SOP_SUCCESS,
    payload: checklist,
  }),
  assigneeSopFailure: error => ({
    type: actionTypes.ASSIGNEE_SOP_FAILURE,
    payload: error,
  }),

  setSopDueDateRequest: data => ({
    type: actionTypes.SET_SOP_DUE_DATE_REQUEST,
    data,
  }),
  setSopDueDateSuccess: checklist => ({
    type: actionTypes.SET_SOP_DUE_DATE_SUCCESS,
    payload: checklist,
  }),
  setSopDueDateFailure: error => ({
    type: actionTypes.SET_SOP_DUE_DATE_FAILURE,
    payload: error,
  }),

  setSopEffectiveDateRequest: data => ({
    type: actionTypes.SET_SOP_EFFECTIVE_DATE_REQUEST,
    data,
  }),
  setSopEffectiveDateSuccess: checklist => ({
    type: actionTypes.SET_SOP_EFFECTIVE_DATE_SUCCESS,
    payload: checklist,
  }),
  setSopEffectiveDateFailure: error => ({
    type: actionTypes.SET_SOP_EFFECTIVE_DATE_FAILURE,
    payload: error,
  }),
};
