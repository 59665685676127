import moment from 'moment';

const getFullProject = (state, id) => {
  let project = null;

  if (state.selectedProject && id === state.selectedProject.id) {
    project = state.selectedProject;
  } else {
    project = state.data.find(x => x.id === id);
  }

  return project;
};

const updateProjectList = (state, project) => {
  return state.data.map(item => {
    if (item.id === project.id) {
      return project;
    }
    return item;
  });
};

// REMOVE AFTER TEST
// const recursiveUpdatingTaskDates = (sections, targetTask) => {
//   try {
//     let updatedTask = null;

//     sections = sections.map(section => {
//       return {
//         ...section,
//         tasks: section.tasks.map(task => {
//           let calculatedDueDate = null;
//           let calculatedStartDate = null;

//           const targetDateDetails = targetTask?.dateDetails;
//           const targetDate = targetTask?.dueDate;

//           if (task.startDateDetails?.target?.id === targetDateDetails?.id) {
//             calculatedStartDate = task.startDateDetails?.isAfter
//               ? moment(targetDate)
//                   .add(
//                     task.startDateDetails?.duration,
//                     task.startDateDetails?.metric
//                   )
//                   .format()
//               : moment(targetDate)
//                   .subtract(
//                     task.startDateDetails?.duration,
//                     task.startDateDetails?.metric
//                   )
//                   .format();
//           }

//           if (task.dateDetails?.target?.id === targetDateDetails?.id) {
//             calculatedDueDate = task.dateDetails?.isAfter
//               ? moment(targetDate)
//                   .add(task.dateDetails?.duration, task.dateDetails?.metric)
//                   .format()
//               : moment(targetDate)
//                   .subtract(
//                     task.dateDetails?.duration,
//                     task.dateDetails?.metric
//                   )
//                   .format();
//           }

//           if (calculatedDueDate || calculatedStartDate) {
//             updatedTask = {
//               ...task,
//               ...(calculatedDueDate && {
//                 dueDate: calculatedDueDate,
//                 dateDetails: {
//                   ...task.dateDetails,
//                   date: calculatedDueDate,
//                   target: {
//                     ...task.dateDetails.target,
//                     date: targetDateDetails.date,
//                   },
//                 },
//               }),
//               ...(calculatedStartDate && {
//                 startDate: calculatedStartDate,
//                 startDateDetails: {
//                   ...task.startDateDetails,
//                   date: calculatedStartDate,
//                   target: {
//                     ...task.startDateDetails.target,
//                     date: targetDateDetails.date,
//                   },
//                 },
//               }),
//             };

//             return {
//               ...updatedTask,
//             };
//           }

//           return task;
//         }),
//       };
//     });

//     if (updatedTask) {
//       const copyUpdatedTask = { ...updatedTask };
//       updatedTask = null;

//       return recursiveUpdatingTaskDates(sections, copyUpdatedTask);
//     }

//     return sections;
//   } catch (error) {
//     console.error(error);
//     return sections;
//   }
// };

export default (state, action) => ({
  updateSectionTaskDates: () => {
    let project = getFullProject(state, action.payload.projectId);

    return {
      ...state,
      selectedProject: {
        ...project,
        section: action.payload.sections,
      },
      loading: false,
      error: null,
    };
  },
  createSection: () => {
    let project = getFullProject(state, action.payload.projectId);

    project = {
      ...project,
      section: [...project.section, action.payload],
    };

    return {
      ...state,
      // data: updateProjectList(state, project),
      selectedProject: project,
      loading: false,
      error: null,
    };
  },

  updateSection: () => {
    let project = getFullProject(state, action.payload.projectId);

    project = {
      ...project,
      section: project.section.map(section => {
        if (section.id === action.payload.id) {
          return { ...action.payload, tasks: section.tasks };
        }
        return section;
      }),
    };

    return {
      ...state,
      // data: updateProjectList(state, project),
      selectedProject: project,
      loading: false,
      error: null,
    };
  },

  removeSection: () => {
    let project = getFullProject(state, action.payload.projectId);

    project = {
      ...project,
      section: project.section.filter(
        section => section.id !== action.payload.sectionId
      ),
    };

    return {
      ...state,
      // data: updateProjectList(state, project),
      selectedProject: project,
      loading: false,
      error: null,
    };
  },

  hideSection: (state, action) => {
    return {
      ...state,
      selectedProject: {
        ...state.selectedProject,
        settings: {
          ...state.selectedProject.settings,
          hiddenSections: action.payload,
        },
      },
    };
  },
});
