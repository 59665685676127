const googleDriveActions = {
  GET_GOOGLE_DRIVE_AUTH_URL_REQUEST: 'GET_GOOGLE_DRIVE_AUTH_URL_REQUEST',
  GET_GOOGLE_DRIVE_AUTH_URL_SUCCESS: 'GET_GOOGLE_DRIVE_AUTH_URL_SUCCESS',
  GET_GOOGLE_DRIVE_AUTH_URL_FAILURE: 'GET_GOOGLE_DRIVE_AUTH_URL_FAILURE',

  GET_GOOGLE_DRIVE_TOKEN_REQUEST: 'GET_GOOGLE_DRIVE_TOKEN_REQUEST',
  GET_GOOGLE_DRIVE_TOKEN_SUCCESS: 'GET_GOOGLE_DRIVE_TOKEN_SUCCESS',
  GET_GOOGLE_DRIVE_TOKEN_FAILURE: 'GET_GOOGLE_DRIVE_TOKEN_FAILURE',

  CHECK_GOOGLE_DRIVE_TOKEN_REQUEST: 'CHECK_GOOGLE_DRIVE_TOKEN_REQUEST',
  CHECK_GOOGLE_DRIVE_TOKEN_SUCCESS: 'CHECK_GOOGLE_DRIVE_TOKEN_SUCCESS',
  CHECK_GOOGLE_DRIVE_TOKEN_FAILURE: 'CHECK_GOOGLE_DRIVE_TOKEN_FAILURE',

  INIT_GOOGLE_DRIVE_SYNC_REQUEST: 'INIT_GOOGLE_DRIVE_SYNC_REQUEST',
  INIT_GOOGLE_DRIVE_SYNC_SUCCESS: 'INIT_GOOGLE_DRIVE_SYNC_SUCCESS',
  INIT_GOOGLE_DRIVE_SYNC_FAILURE: 'INIT_GOOGLE_DRIVE_SYNC_FAILURE',

  CHECK_GOOGLE_DRIVE_SYNC_REQUEST: 'CHECK_GOOGLE_DRIVE_SYNC_REQUEST',
  CHECK_GOOGLE_DRIVE_SYNC_SUCCESS: 'CHECK_GOOGLE_DRIVE_SYNC_SUCCESS',
  CHECK_GOOGLE_DRIVE_SYNC_FAILURE: 'CHECK_GOOGLE_DRIVE_SYNC_FAILURE',

  DISCONNECT_GOOGLE_DRIVE_REQUEST: 'DISCONNECT_GOOGLE_DRIVE_REQUEST',
  DISCONNECT_GOOGLE_DRIVE_SUCCESS: 'DISCONNECT_GOOGLE_DRIVE_SUCCESS',
  DISCONNECT_GOOGLE_DRIVE_FAILURE: 'DISCONNECT_GOOGLE_DRIVE_FAILURE',

  CLEAR_GOOGLE_DRIVE_INFO: 'CLEAR_GOOGLE_DRIVE_INFO',

  GET_GOOGLE_DRIVE_STORAGE_REQUEST: 'GET_GOOGLE_DRIVE_STORAGE_REQUEST',
  GET_GOOGLE_DRIVE_STORAGE_SUCCESS: 'GET_GOOGLE_DRIVE_STORAGE_SUCCESS',
  GET_GOOGLE_DRIVE_STORAGE_FAILURE: 'GET_GOOGLE_DRIVE_STORAGE_FAILURE',

  getGoogleDriveAuthUrlRequest: () => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_AUTH_URL_REQUEST,
  }),
  getGoogleDriveAuthUrlSuccess: data => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_AUTH_URL_SUCCESS,
    payload: data,
  }),
  getGoogleDriveAuthUrlFailure: error => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_AUTH_URL_FAILURE,
    payload: error,
  }),

  getGoogleDriveTokenRequest: data => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_TOKEN_REQUEST,
    data,
  }),
  getGoogleDriveTokenSuccess: data => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_TOKEN_SUCCESS,
    payload: data,
  }),
  getGoogleDriveTokenFailure: error => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_TOKEN_FAILURE,
    payload: error,
  }),

  checkGoogleDriveTokenRequest: () => ({
    type: googleDriveActions.CHECK_GOOGLE_DRIVE_TOKEN_REQUEST,
  }),
  checkGoogleDriveTokenSuccess: data => ({
    type: googleDriveActions.CHECK_GOOGLE_DRIVE_TOKEN_SUCCESS,
    payload: data,
  }),
  checkGoogleDriveTokenFailure: error => ({
    type: googleDriveActions.CHECK_GOOGLE_DRIVE_TOKEN_FAILURE,
    payload: error,
  }),

  initGoogleDriveSyncRequest: data => ({
    type: googleDriveActions.INIT_GOOGLE_DRIVE_SYNC_REQUEST,
    data,
  }),
  initGoogleDriveSyncSuccess: data => ({
    type: googleDriveActions.INIT_GOOGLE_DRIVE_SYNC_SUCCESS,
    payload: data,
  }),
  initGoogleDriveSyncFailure: error => ({
    type: googleDriveActions.INIT_GOOGLE_DRIVE_SYNC_FAILURE,
    payload: error,
  }),

  disconnectGoogleDriveRequest: data => ({
    type: googleDriveActions.DISCONNECT_GOOGLE_DRIVE_REQUEST,
    data,
  }),
  disconnectGoogleDriveSuccess: () => ({
    type: googleDriveActions.DISCONNECT_GOOGLE_DRIVE_SUCCESS,
  }),
  disconnectGoogleDriveFailure: error => ({
    type: googleDriveActions.DISCONNECT_GOOGLE_DRIVE_FAILURE,
    payload: error,
  }),

  checkGoogleDriveSyncRequest: data => ({
    type: googleDriveActions.CHECK_GOOGLE_DRIVE_SYNC_REQUEST,
    data,
  }),
  checkGoogleDriveSyncSuccess: data => ({
    type: googleDriveActions.CHECK_GOOGLE_DRIVE_SYNC_SUCCESS,
    payload: data,
  }),
  checkGoogleDriveSyncFailure: error => ({
    type: googleDriveActions.CHECK_GOOGLE_DRIVE_SYNC_FAILURE,
    payload: error,
  }),

  clearGoogleDriveInfo: () => ({
    type: googleDriveActions.CLEAR_GOOGLE_DRIVE_INFO,
  }),

  getGoogleDriveStorageRequest: () => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_STORAGE_REQUEST,
  }),
  getGoogleDriveStorageSuccess: data => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_STORAGE_SUCCESS,
    payload: data,
  }),
  getGoogleDriveStorageFailure: error => ({
    type: googleDriveActions.GET_GOOGLE_DRIVE_STORAGE_FAILURE,
    payload: error,
  }),
};

export default googleDriveActions;
