export const events = {
  SESSION_START: 'SESSION START',
  SESSION_END: 'SESSION END',
  USER_COMPANY_INFO: `USER'S COMPANY INFO`,
  CREATE_TASK: 'CREATE TASK',
  CREATE_SUBTASK: 'CREATE SUBTASK',
  CLICK_ON_TASK_TITLE: 'CLICK ON TASK TITLE',
  CLICK_ON_VIEW_TASK: 'CLICK ON VIEW TASK',
  CLICK_ON_TASK_ASSIGNEE: 'CLICK ON TASK ASSIGNEE',
  CLICK_ON_TASK_PRIORITY: 'CLICK ON TASK PRIORITY',
  CLICK_ON_TASK_STATUS: 'CLICK ON TASK STATUS',
  CLICK_ON_TASK_TAG: 'CLICK ON TASK TAG/LABEL',
  CLICK_ON_TASK_FILES: 'CLICK ON TASK FILES',
  CLICK_ON_TASK_START_DATE: 'CLICK ON TASK START DATE',
  CLICK_ON_TASK_DUE_DATE: 'CLICK ON TASK DUE DATE',
  CLICK_ON_TASK_DESCRIPTION: 'CLICK ON TASK DESCRIPTION',
  CLICK_ON_TASK_REPEAT: 'CLICK ON TASK REPEAT',
  CLICK_ON_DELETE_TASK_FILE: 'CLICK ON DELETE TASK FILE',
  CLICK_ON_VIEW_TASK_FILE: 'CLICK ON VIEW TASK FILE',
  CLICK_ON_TASK_ADD_COMMENT: 'CLICK ON TASK ADD COMMENT',
  CLICK_ON_TASK_EDIT_COMMENT: 'CLICK ON TASK EDIT COMMENT',
  CLICK_ON_TASK_DELETE_COMMENT: 'CLICK ON TASK DELETE COMMENT',
  CLICK_ON_FILE: 'CLICK ON FILE',
  CLICK_ON_FOLDER: 'CLICK ON FOLDER',
  RIGHT_CLICK_ON_FOLDER: 'RIGHT CLICK ON FOLDER',
  RIGHT_CLICK_ON_FILE: 'RIGHT CLICK ON FILE',
  CLICK_ON_FOLDER_DELETE: 'CLICK ON FOLDER DELETE',
  CLICK_ON_FILE_DELETE: 'CLICK ON FILE DELETE',
  CLICK_ON_FOLDER_DOWNLOAD: 'CLICK ON FOLDER DOWNLOAD',
  CLICK_ON_FILE_DOWNLOAD: 'CLICK ON FILE DOWNLOAD',
  CLICK_ON_FILE_EXPIRATION: 'CLICK ON FILE EXPIRATION',
  CLICK_ON_FILE_REMOVE_EXPIRATION: 'CLICK ON FILE REMOVE EXPIRATION',
  CLICK_ON_FILE_NOTIFY: 'CLICK ON FILE NOTIFY',
  CLICK_ON_FOLDER_RENAME: 'CLICK ON FOLDER RENAME',
  CLICK_ON_FILE_RENAME: 'CLICK ON FILE RENAME',
  CLICK_ON_FOLDER_COPY: 'CLICK ON FOLDER COPY',
  CLICK_ON_FILE_COPY: 'CLICK ON FILE COPY',
  CLICK_ON_FOLDER_RESTRICT_USER_ACCESS: 'CLICK ON FOLDER RESTRICT USER ACCESS',
  CLICK_ON_FOLDER_RESTRICT_TEAM_ACCESS: 'CLICK ON FOLDER RESTRICT TEAM ACCESS',
  CLICK_ON_FOLDER_SHARE: 'CLICK ON FOLDER SHARE',
  CLICK_ON_FILE_SHARE: 'CLICK ON FILE SHARE',
  SIGN_UP_PAGE_IS_VISITED: 'SIGN UP PAGE IS VISITED',
  SIGN_IN_PAGE_IS_VISITED: 'SIGN IN PAGE IS VISITED',
  RESET_PASSWORD_PAGE_IS_VISITED: 'RESET PASSWORD PAGE IS VISITED',
  CREATE_AN_ACCOUNT_BUTTON_IS_PRESSED: 'CREATE AN ACCOUNT BUTTON IS PRESSED',
  WENT_TO_PAGE: 'IS VISITED',
  FILE_IS_OPENED: 'FILE IS OPENED',
  SET_FILE_EXPIRATION_DATE: 'SET FILE EXPIRATION DATE',
  CHANGE_LOCATION_PAGE_VIEW: `CHANGED THE VIEW ON THE LOCATION'S PAGE`,
  CLICK_ON_ADD_LOCATION: 'CLICK ON ADD LOCATION',
  CLICK_ON_ADD_PROJECT: 'CLICK ON ADD PROJECT',
  COMMENT_ON_LOCATION: 'COMMENT ON LOCATION',
  ARCHIVE_PROJECT: 'ARCHIVE PROJECT',
  DELETE_PROJECT: 'DELETE PROJECT',
  SEARCH_PROJECTS: 'SEARCH PROJECTS',
  SORT_PROJECTS: 'SORT PROJECTS',
  SEARCH_LOCATIONS: 'SEARCH LOCATIONS',
  SORT_LOCATIONS: 'SORT LOCATIONS',
  EDIT_LOCATION: 'EDIT LOCATION',
  INVITE_USER_TO_COMPANY: 'INVITE USER TO COMPANY',
  CREATE_TEAM: 'CREATE TEAM',
};

export const routes = {
  '/projects': 'PROJECTS PAGE',
  '/project': 'PROJECT PAGE',
  '/dashboard': 'DASHBOARD PAGE',
  '/locations': 'LOCATIONS PAGE',
  '/location': 'LOCATION PAGE',
  '/file-library': 'FILING-CABINET PAGE',
  '/revenues': 'REVENUES PAGE',
  '/metrc': 'METRC PAGE',
  '/help': 'HELP PAGE',
  '/applications': 'PIPELINES PAGE',
  '/profile/users': 'TEAMS AND USERS PAGE',
  '/profile/workspaces': 'WORKSPACES PAGE',
  '/profile/tools': 'TOOLS PAGE',
  business: 'LOCATION BUSINESS PAGE',
  license: 'LOCATION LICENSE PAGE',
  property: 'LOCATION PROPERTY PAGE',
  taxes: 'LOCATION TAXES AND FEES PAGE',
  contacts: 'LOCATION CONTACTS PAGE',
  custom: 'LOCATION CUSTOM PAGE',
};

export const ANALYTIC_ROUTES = {
  LOCATION_PAGE: '/location',
  PROJECT_PAGE: '/project',
};

export const DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss';
