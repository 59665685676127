import { takeEvery } from 'redux-saga/effects';

import watcherActions from './actions';
import anamiService from '../../services/anamiService';
import auth from '../../containers/Authentication/Auth0/Auth0';

function* startWatchTokenWorker() {
  const delay = 1000 * 60;

  const intervalId = setInterval(async () => {
    try {
      localStorage.setItem('session_check', true);

      setTimeout(() => {
        localStorage.removeItem('session_check');
      }, 1000);
      await anamiService.checkAuthorization();
    } catch (err) {
      clearInterval(intervalId);
      setTimeout(() => {
        auth.endSession();
        sessionStorage.removeItem('tokenIntervalId', intervalId);
      }, 1000);
    }
  }, delay);

  sessionStorage.setItem('tokenIntervalId', intervalId);
}

function* stopWatchTokenWorker() {
  const intervalId = sessionStorage.getItem('tokenIntervalId');

  clearInterval(intervalId);
  sessionStorage.removeItem('tokenIntervalId', intervalId);
}

export default function* watcherSaga() {
  yield takeEvery(watcherActions.START_WATCH_TOKEN, startWatchTokenWorker);
  yield takeEvery(watcherActions.STOP_WATCH_TOKEN, stopWatchTokenWorker);
}
