import actions from './actions';

const initialState = {
  status: 'UNAUTHORIZED',
  isConnect: { google: false, outlook: false },
  authUrl: '',
  loading: false,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.GET_CALENDAR_AUTH_URL_REQUEST:
    case actions.CHECK_CALENDAR_STATUS_REQUEST:
    case actions.DISCONNECT_CALENDAR_REQUEST:
    case actions.SET_CALENDAR_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_CALENDAR_AUTH_URL_FAILURE:
    case actions.CHECK_CALENDAR_STATUS_FAILURE:
    case actions.DISCONNECT_CALENDAR_FAILURE:
    case actions.SET_CALENDAR_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case actions.CHECK_CALENDAR_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload,
        loading: false,
      };

    case actions.CHECK_CALENDAR_STATUSES_SUCCESS:
      return {
        ...state,
        isConnect: {
          ...state.isConnect,
          [action.data.type]: action.data.value,
        },
        loading: false,
      };

    case actions.GET_CALENDAR_AUTH_URL_SUCCESS:
      return {
        ...state,
        authUrl: action.payload,
        loading: false,
      };

    case actions.SET_CALENDAR_TOKEN_SUCCESS:
      return {
        ...state,
        status: 'AUTHORIZED',
        loading: false,
      };

    case actions.DISCONNECT_CALENDAR_SUCCESS:
      return {
        ...state,
        status: 'UNAUTHORIZED',
        loading: false,
      };

    case actions.SET_STATUS_TO_INITIAL_STATE:
      return {
        ...state,
        status: 'UNAUTHORIZED',
      };

    default:
      return state;
  }
}
