import { all, takeEvery, put, select, apply } from 'redux-saga/effects';
import { notification } from 'antd';

import companiesActions from './actions';
import authActions from '@iso/redux/auth/actions';
import anamiService from '../../services/anamiService';

import segment, { events } from 'services/analytics';

function* getCompanyList() {
  try {
    const response = yield anamiService.getCompanyList();

    yield put(companiesActions.getCompanyListSuccess(response.data.companies));
  } catch (error) {
    yield put(companiesActions.getCompanyListFailure(error));
  }
}

function* createCompany({ data }) {
  try {
    const response = yield anamiService.createCompany(data);

    yield put(companiesActions.createCompanySuccess(response.data.company));
    notification.success({
      message: 'A new company has been added',
      duration: 5,
    });
  } catch (error) {
    yield put(companiesActions.createCompanyFailure(error));
  }
}

function* updateCompany({ data }) {
  try {
    const response = yield anamiService.updateCompany(data);

    yield put(companiesActions.updateCompanySuccess(response.data.company));
  } catch (error) {
    yield put(companiesActions.updateCompanyFailure(error));
  }
}

function* removeCompany({ id }) {
  try {
    yield anamiService.removeCompany(id);

    yield put(companiesActions.removeCompanySuccess(id));
  } catch (error) {
    yield put(companiesActions.removeCompanyFailure(error));
  }
}

function* getCompanyUsers({ id }) {
  try {
    const response = yield anamiService.getCompanyUsers(id);

    const user = yield select(state => state.me.user);
    const isTabDuplicate = yield select(state => state.App.isTabDuplicate);
    const companies = yield select(state => state.companies.data);

    const usersExcludingSuperAdmins = response.data.users.filter(
      user => !user.isSuperAdmin
    );

    if (!isTabDuplicate) {
      yield apply(segment, segment.identify, [
        user.id,
        {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          role:
            user.roles && user.roles.length > 0
              ? user.roles[0].role.role
              : 'Unknown',
          companyName:
            companies && companies.length > 0 ? companies[0].title : 'Unknown',
          companyId:
            companies && companies.length > 0 ? companies[0].id : 'Unknown',
          sections: user.sections,
          companyUsers: usersExcludingSuperAdmins.length,
        },
        isTabDuplicate,
      ]);

      yield apply(segment, segment.trackEvent, [
        events.USER_COMPANY_INFO,
        {
          userId: user.id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          role:
            user.roles && user.roles.length > 0
              ? user.roles[0].role.role
              : 'Unknown',
          companyName:
            companies && companies.length > 0 ? companies[0].title : 'Unknown',
          companyId:
            companies && companies.length > 0 ? companies[0].id : 'Unknown',
          sections: user.sections,
          companyUsers: usersExcludingSuperAdmins.length,
        },
      ]);
    }

    yield put(companiesActions.getCompanyUsersSuccess(response.data.users));
  } catch (error) {
    yield put(companiesActions.getCompanyUsersFailure(error));
  }
}

function* selectCompany({ data }) {
  try {
    yield put({ type: authActions.UPDATE_TOKEN, payload: data });
  } catch (error) {
    console.log(error);
  }
}

function* upgradeCompanyPayment({ data }) {
  try {
    yield anamiService.upgradeCompany(data);

    yield put(companiesActions.upgradeCompanyPaymentSuccess());

    notification.success({
      message: 'The representative will follow up shortly!',
      duration: 5,
    });
  } catch (error) {
    yield put(companiesActions.upgradeCompanyPaymentFailure(error));
  }
}

function* checkCompanyTrialNotificationSaga({ data }) {
  try {
    const reaponse = yield anamiService.checkTrial(data);

    yield put(
      companiesActions.checkCompanyTrialNotificationSuccess(reaponse.data)
    );
  } catch (error) {
    yield put(companiesActions.checkCompanyTrialNotificationFailure(error));
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(companiesActions.GET_COMPANY_LIST_REQUEST, getCompanyList),
  ]);
  yield all([
    takeEvery(companiesActions.CREATE_COMPANY_REQUEST, createCompany),
  ]);
  yield all([
    takeEvery(companiesActions.UPDATE_COMPANY_REQUEST, updateCompany),
  ]);
  yield all([
    takeEvery(companiesActions.REMOVE_COMPANY_REQUEST, removeCompany),
  ]);
  yield all([
    takeEvery(companiesActions.GET_COMPANY_USERS_REQUEST, getCompanyUsers),
  ]);
  yield all([takeEvery(companiesActions.SELECT_COMPANY, selectCompany)]);
  yield takeEvery(
    companiesActions.UPGRADE_COMPANY_PAYMENT_REQUEST,
    upgradeCompanyPayment
  );
  yield takeEvery(
    companiesActions.CHECK_COMPANY_TRIAL_NOTIFICATION_REQUEST,
    checkCompanyTrialNotificationSaga
  );
}
