const usersActions = {
  GET_USER_LIST_REQUEST: 'FETCH_USERS_DATA',
  GET_USER_LIST_SUCCESS: 'FETCH_USERS_DATA_SUCCESS',
  GET_USER_LIST_FAILURE: 'FETCH_USERS_DATA_FAILURE',
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  ADD_USER: 'ADD_USER',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  REMOVE_USER_REQUEST: 'REMOVE_USER_REQUEST',
  REMOVE_USER_SUCCESS: 'REMOVE_USER_SUCCESS',
  REMOVE_USER_FAILURE: 'REMOVE_USER_FAILURE',

  UPLOAD_AVATAR_REQUEST: 'UPLOAD_AVATAR_REQUEST',
  UPLOAD_AVATAR_SUCCESS: 'UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_FAILURE: 'UPLOAD_AVATAR_FAILURE',

  GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
  GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
  GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',

  SET_USER_ROLE_REQUEST: 'SET_USER_ROLE_REQUEST',
  SET_USER_ROLE_SUCCESS: 'SET_USER_ROLE_SUCCESS',
  SET_USER_ROLE_FAILURE: 'SET_USER_ROLE_FAILURE',

  UNSET_USER_ROLE_REQUEST: 'UNSET_USER_ROLE_REQUEST',
  UNSET_USER_ROLE_SUCCESS: 'UNSET_USER_ROLE_SUCCESS',
  UNSET_USER_ROLE_FAILURE: 'UNSET_USER_ROLE_FAILURE',

  UPDATE_SECTIONS_REQUEST: 'UPDATE_SECTIONS_REQUEST',
  UPDATE_SECTIONS_SUCCESS: 'UPDATE_SECTIONS_SUCCESS',
  UPDATE_SECTIONS_FAILURE: 'UPDATE_SECTIONS_FAILURE',

  ADD_NEW_TEAM: 'ADD_NEW_TEAM',

  CREATE_NEW_TEAM_REQUEST: 'CREATE_NEW_TEAM_REQUEST',
  CREATE_NEW_TEAM_SUCCESS: 'CREATE_NEW_TEAM_SUCCESS',
  CREATE_NEW_TEAM_FAILURE: 'CREATE_NEW_TEAM_FAILURE',

  FETCH_TEAMS_REQUEST: 'FETCH_TEAMS_REQUEST',
  FETCH_TEAMS_SUCCESS: 'FETCH_TEAMS_SUCCESS',
  FETCH_TEAMS_FAILURE: 'FETCH_TEAMS_FAILURE',

  REMOVE_TEAM_REQUEST: 'REMOVE_TEAM_REQUEST',
  REMOVE_TEAM_SUCCESS: 'REMOVE_TEAM_SUCCESS',
  REMOVE_TEAM_FAILURE: 'REMOVE_TEAM_FAILURE',

  UPDATE_TEAM_SECTIONS_REQUEST: 'UPDATE_TEAM_SECTIONS_REQUEST',
  UPDATE_TEAM_SECTIONS_SUCCESS: 'UPDATE_TEAM_SECTIONS_SUCCESS',
  UPDATE_TEAM_SECTIONS_FAILURE: 'UPDATE_TEAM_SECTIONS_FAILURE',

  ADD_USER_TO_TEAM_REQUEST: 'ADD_USER_TO_TEAM_REQUEST',
  ADD_USER_TO_TEAM_SUCCESS: 'ADD_USER_TO_TEAM_SUCCESS',
  ADD_USER_TO_TEAM_FAILURE: 'ADD_USER_TO_TEAM_FAILURE',

  UPDATE_TEAM_TITLE_REQUEST: 'UPDATE_TEAM_TITLE_REQUEST',
  UPDATE_TEAM_TITLE_SUCCESS: 'UPDATE_TEAM_TITLE_SUCCESS',
  UPDATE_TEAM_TITLE_FAILURE: 'UPDATE_TEAM_TITLE_FAILURE',

  SET_PROJECT_TO_TEAM_REQUEST: 'SET_PROJECT_TO_TEAM_REQUEST',
  SET_PROJECT_TO_TEAM_SUCCESS: 'SET_PROJECT_TO_TEAM_SUCCESS',
  SET_PROJECT_TO_TEAM_FAILURE: 'SET_PROJECT_TO_TEAM_FAILURE',

  UNSET_PROJECT_FROM_TEAM_REQUEST: 'UNSET_PROJECT_FROM_TEAM_REQUEST',
  UNSET_PROJECT_FROM_TEAM_SUCCESS: 'UNSET_PROJECT_FROM_TEAM_SUCCESS',
  UNSET_PROJECT_FROM_TEAM_FAILURE: 'UNSET_PROJECT_FROM_TEAM_FAILURE',

  SET_LOCATION_TO_TEAM_REQUEST: 'SET_LOCATION_TO_TEAM_REQUEST',
  SET_LOCATION_TO_TEAM_SUCCESS: 'SET_LOCATION_TO_TEAM_SUCCESS',
  SET_LOCATION_TO_TEAM_FAILURE: 'SET_LOCATION_TO_TEAM_FAILURE',

  UNSET_LOCATION_FROM_TEAM_REQUEST: 'UNSET_LOCATION_FROM_TEAM_REQUEST',
  UNSET_LOCATION_FROM_TEAM_SUCCESS: 'UNSET_LOCATION_FROM_TEAM_SUCCESS',
  UNSET_LOCATION_FROM_TEAM_FAILURE: 'UNSET_LOCATION_FROM_TEAM_FAILURE',

  GET_USER_TEAMS_REQUEST: 'GET_USER_TEAMS_REQUEST',
  GET_USER_TEAMS_SUCCESS: 'GET_USER_TEAMS_SUCCESS',
  GET_USER_TEAMS_FAILURE: 'GET_USER_TEAMS_FAILURE',

  REMOVE_USER_FROM_TEAM_REQUEST: 'REMOVE_USER_FROM_TEAM_REQUEST',
  REMOVE_USER_FROM_TEAM_SUCCESS: 'REMOVE_USER_FROM_TEAM_SUCCESS',
  REMOVE_USER_FROM_TEAM_FAILURE: 'REMOVE_USER_FROM_TEAM_FAILURE',

  INVITE_USER_WITH_EMAIL: 'INVITE_USER_WITH_EMAIL',
  INVITE_USER_WITH_EMAIL_SUCCESS: 'INVITE_USER_WITH_EMAIL_SUCCESS',
  INVITE_USER_WITH_EMAIL_FAILURE: 'INVITE_USER_WITH_EMAIL_FAILURE',
  INVITE_USER_WITH_EMAIL_RESET: 'INVITE_USER_WITH_EMAIL_RESET',

  GET_INVITE_USER_LINK: 'GET_INVITE_USER_LINK',
  GET_INVITE_USER_LINK_SUCCESS: 'GET_INVITE_USER_LINK_SUCCESS',
  GET_INVITE_USER_LINK_FAILURE: 'GET_INVITE_USER_LINK_FAILURE',

  CREATE_PASSWORD_FOR_INVITE_USER: 'CREATE_PASSWORD_FOR_INVITE_USER',
  CREATE_PASSWORD_FOR_INVITE_USER_SUCCESS:
    'CREATE_PASSWORD_FOR_INVITE_USER_SUCCESS',
  CREATE_PASSWORD_FOR_INVITE_USER_FAILURE:
    'CREATE_PASSWORD_FOR_INVITE_USER_FAILURE',
  RESET_INVITE_REDIRECT_DATA: 'RESET_INVITE_REDIRECT_DATA',

  CREATE_LINK_INVITE_USER: 'CREATE_LINK_INVITE_USER',
  CREATE_LINK_INVITE_USER_SUCCESS: 'CREATE_LINK_INVITE_USER_SUCCESS',
  CREATE_LINK_INVITE_USER_FAILURE: 'CREATE_LINK_INVITE_USER_FAILURE',

  GET_INVITE_EMAIL: 'GET_INVITE_EMAIL',
  GET_INVITE_EMAIL_SUCCESS: 'GET_INVITE_EMAIL_SUCCESS',
  GET_INVITE_EMAIL_FAILURE: 'GET_INVITE_EMAIL_FAILURE',

  CHECK_INVITE_EMAIL: 'CHECK_INVITE_EMAIL',
  CHECK_INVITE_EMAIL_SUCCESS: 'CHECK_INVITE_EMAIL_SUCCESS',
  CHECK_INVITE_EMAIL_FAILURE: 'CHECK_INVITE_EMAIL_FAILURE',

  getInviteEmail: data => ({
    type: usersActions.GET_INVITE_EMAIL,
    payload: data,
  }),

  checkInviteEmail: data => ({
    type: usersActions.CHECK_INVITE_EMAIL,
    payload: data,
  }),

  checkInviteEmailSuccess: data => ({
    type: usersActions.CHECK_INVITE_EMAIL_SUCCESS,
    payload: data,
  }),
  checkInviteEmailFailure: data => ({
    type: usersActions.CHECK_INVITE_EMAIL_FAILURE,
    payload: data,
  }),

  getInviteEmailSuccess: data => ({
    type: usersActions.GET_INVITE_EMAIL_SUCCESS,
    payload: data,
  }),

  getInviteEmailFailure: data => ({
    type: usersActions.GET_INVITE_EMAIL_FAILURE,
    payload: data,
  }),

  createPasswordForInviteUser: data => ({
    type: usersActions.CREATE_PASSWORD_FOR_INVITE_USER,
    payload: data,
  }),

  createPasswordForInviteUserSuccess: data => ({
    type: usersActions.CREATE_PASSWORD_FOR_INVITE_USER_SUCCESS,
    payload: data,
  }),

  createPasswordForInviteUserFailure: error => ({
    type: usersActions.CREATE_PASSWORD_FOR_INVITE_USER_FAILURE,
    payload: error,
  }),

  createLinkInviteUser: data => ({
    type: usersActions.CREATE_LINK_INVITE_USER,
    payload: data,
  }),

  createLinkInviteUserSuccess: data => ({
    type: usersActions.CREATE_LINK_INVITE_USER_SUCCESS,
    payload: data,
  }),

  createLinkInviteUserFailure: error => ({
    type: usersActions.CREATE_LINK_INVITE_USER_FAILURE,
    payload: error,
  }),

  resetInviteRedirectData: () => ({
    type: usersActions.RESET_INVITE_REDIRECT_DATA,
  }),

  inviteUserWithEmail: data => ({
    type: usersActions.INVITE_USER_WITH_EMAIL,
    payload: data,
  }),

  inviteUserWithEmailSuccess: data => ({
    type: usersActions.INVITE_USER_WITH_EMAIL_SUCCESS,
    payload: data,
  }),

  inviteUserWithEmailFailure: error => ({
    type: usersActions.INVITE_USER_WITH_EMAIL_FAILURE,
    payload: error,
  }),

  inviteUserWithEmailReset: () => ({
    type: usersActions.INVITE_USER_WITH_EMAIL_RESET,
  }),

  getInviteUserLink: data => ({
    type: usersActions.GET_INVITE_USER_LINK,
    payload: data,
  }),

  getInviteUserLinkSuccess: data => ({
    type: usersActions.GET_INVITE_USER_LINK_SUCCESS,
    payload: data,
  }),

  getInviteUserLinkFailure: error => ({
    type: usersActions.GET_INVITE_USER_LINK_FAILURE,
    payload: error,
  }),

  getUserListRequest: () => ({ type: usersActions.GET_USER_LIST_REQUEST }),
  getUserListSuccess: users => ({
    type: usersActions.GET_USER_LIST_SUCCESS,
    payload: users,
  }),
  getUserListFailure: error => ({
    type: usersActions.GET_USER_LIST_FAILURE,
    payload: error,
  }),
  getUserRequest: id => ({
    type: usersActions.GET_USER_REQUEST,
    id,
  }),
  getUserSuccess: user => ({
    type: usersActions.GET_USER_SUCCESS,
    payload: user,
  }),
  getUserFailure: error => ({
    type: usersActions.GET_USER_FAILURE,
    payload: error,
  }),
  updateUserRequest: data => ({
    type: usersActions.UPDATE_USER_REQUEST,
    data,
  }),
  updateUserSuccess: user => ({
    type: usersActions.UPDATE_USER_SUCCESS,
    payload: user,
  }),
  updateUserFailure: error => ({
    type: usersActions.UPDATE_USER_FAILURE,
    payload: error,
  }),
  createUserRequest: user => ({
    type: usersActions.CREATE_USER_REQUEST,
    user,
  }),
  createUserSuccess: user => ({
    type: usersActions.CREATE_USER_SUCCESS,
    payload: user,
  }),
  createUserFailure: error => ({
    type: usersActions.CREATE_USER_FAILURE,
    payload: error,
  }),
  addUser: state => ({
    type: usersActions.ADD_USER,
    payload: state,
  }),
  removeUserRequest: id => ({
    type: usersActions.REMOVE_USER_REQUEST,
    id,
  }),
  removeUserSuccess: id => ({
    type: usersActions.REMOVE_USER_SUCCESS,
    payload: id,
  }),
  removeUserFailure: error => ({
    type: usersActions.REMOVE_USER_FAILURE,
    payload: error,
  }),

  getUserRolesRequest: () => ({ type: usersActions.GET_USER_ROLES_REQUEST }),
  getUserRolesSuccess: roles => ({
    type: usersActions.GET_USER_ROLES_SUCCESS,
    payload: roles,
  }),
  getUserRolesFailure: error => ({
    type: usersActions.GET_USER_ROLES_FAILURE,
    payload: error,
  }),

  setUserRoleRequest: data => ({
    type: usersActions.SET_USER_ROLE_REQUEST,
    data,
  }),
  setUserRoleSuccess: data => ({
    type: usersActions.SET_USER_ROLE_SUCCESS,
    payload: data,
  }),
  setUserRoleFailure: error => ({
    type: usersActions.SET_USER_ROLE_FAILURE,
    payload: error,
  }),

  unsetUserRoleRequest: data => ({
    type: usersActions.UNSET_USER_ROLE_REQUEST,
    data,
  }),
  unsetUserRoleSuccess: data => ({
    type: usersActions.UNSET_USER_ROLE_SUCCESS,
    payload: data,
  }),
  unsetUserRoleFailure: error => ({
    type: usersActions.UNSET_USER_ROLE_FAILURE,
    payload: error,
  }),

  uploadAvatarRequest: data => ({
    type: usersActions.UPLOAD_AVATAR_REQUEST,
    data,
  }),
  uploadAvatarSuccess: src => ({
    type: usersActions.UPLOAD_AVATAR_SUCCESS,
    payload: src,
  }),
  uploadAvatarFailure: error => ({
    type: usersActions.UPLOAD_AVATAR_FAILURE,
    payload: error,
  }),

  updateSectionsRequest: data => ({
    type: usersActions.UPDATE_SECTIONS_REQUEST,
    data,
  }),
  updateSectionsSuccess: data => ({
    type: usersActions.UPDATE_SECTIONS_SUCCESS,
    payload: data,
  }),
  updateSectionsFailure: error => ({
    type: usersActions.UPDATE_SECTIONS_FAILURE,
    payload: error,
  }),

  addTeam: state => ({
    type: usersActions.ADD_NEW_TEAM,
    payload: state,
  }),

  createTeamRequest: data => ({
    type: usersActions.CREATE_NEW_TEAM_REQUEST,
    data,
  }),
  createTeamSuccess: data => ({
    type: usersActions.CREATE_NEW_TEAM_SUCCESS,
    payload: data,
  }),
  createTeamFailure: error => ({
    type: usersActions.CREATE_NEW_TEAM_FAILURE,
    payload: error,
  }),

  fetchTeamsRequest: () => ({
    type: usersActions.FETCH_TEAMS_REQUEST,
  }),
  fetchTeamsSuccess: data => ({
    type: usersActions.FETCH_TEAMS_SUCCESS,
    payload: data,
  }),
  fetchTeamsFailure: error => ({
    type: usersActions.FETCH_TEAMS_FAILURE,
    payload: error,
  }),

  removeTeamRequest: data => ({
    type: usersActions.REMOVE_TEAM_REQUEST,
    data,
  }),
  removeTeamSuccess: data => ({
    type: usersActions.REMOVE_TEAM_SUCCESS,
    payload: data,
  }),
  removeTeamFailure: error => ({
    type: usersActions.REMOVE_TEAM_FAILURE,
    payload: error,
  }),

  updateTeamSectionsRequest: data => ({
    type: usersActions.UPDATE_TEAM_SECTIONS_REQUEST,
    data,
  }),
  updateTeamSectionsSuccess: data => ({
    type: usersActions.UPDATE_TEAM_SECTIONS_SUCCESS,
    payload: data,
  }),
  updateTeamSectionsFailure: error => ({
    type: usersActions.UPDATE_TEAM_SECTIONS_FAILURE,
    payload: error,
  }),

  addUserToTeamRequest: data => ({
    type: usersActions.ADD_USER_TO_TEAM_REQUEST,
    data,
  }),
  addUserToTeamSuccess: data => ({
    type: usersActions.ADD_USER_TO_TEAM_SUCCESS,
    payload: data,
  }),
  addUserToTeamFailure: error => ({
    type: usersActions.ADD_USER_TO_TEAM_FAILURE,
    payload: error,
  }),

  updateTeamTitleRequest: data => ({
    type: usersActions.UPDATE_TEAM_TITLE_REQUEST,
    data,
  }),
  updateTeamTitleSuccess: data => ({
    type: usersActions.UPDATE_TEAM_TITLE_SUCCESS,
    payload: data,
  }),
  updateTeamTitleFailure: error => ({
    type: usersActions.UPDATE_TEAM_TITLE_FAILURE,
    payload: error,
  }),

  setProjectRequest: data => ({
    type: usersActions.SET_PROJECT_TO_TEAM_REQUEST,
    data,
  }),
  setProjectSuccess: data => ({
    type: usersActions.SET_PROJECT_TO_TEAM_SUCCESS,
    payload: data,
  }),
  setProjectFailure: error => ({
    type: usersActions.SET_PROJECT_TO_TEAM_FAILURE,
    payload: error,
  }),

  unsetProjectRequest: data => ({
    type: usersActions.UNSET_PROJECT_FROM_TEAM_REQUEST,
    data,
  }),
  unsetProjectSuccess: data => ({
    type: usersActions.UNSET_PROJECT_FROM_TEAM_SUCCESS,
    payload: data,
  }),
  unsetProjectFailure: error => ({
    type: usersActions.UNSET_PROJECT_FROM_TEAM_FAILURE,
    payload: error,
  }),

  setLocationRequest: data => ({
    type: usersActions.SET_LOCATION_TO_TEAM_REQUEST,
    data,
  }),
  setLocationSuccess: data => ({
    type: usersActions.SET_LOCATION_TO_TEAM_SUCCESS,
    payload: data,
  }),
  setLocationFailure: error => ({
    type: usersActions.SET_LOCATION_TO_TEAM_FAILURE,
    payload: error,
  }),

  unsetLocationRequest: data => ({
    type: usersActions.UNSET_LOCATION_FROM_TEAM_REQUEST,
    data,
  }),
  unsetLocationSuccess: data => ({
    type: usersActions.UNSET_LOCATION_FROM_TEAM_SUCCESS,
    payload: data,
  }),
  unsetLocationFailure: error => ({
    type: usersActions.UNSET_LOCATION_FROM_TEAM_FAILURE,
    payload: error,
  }),

  getUserTeamsRequest: data => ({
    type: usersActions.GET_USER_TEAMS_REQUEST,
    data,
  }),
  getUserTeamsSuccess: data => ({
    type: usersActions.GET_USER_TEAMS_SUCCESS,
    payload: data,
  }),
  getUserTeamsFailure: error => ({
    type: usersActions.GET_USER_TEAMS_FAILURE,
    payload: error,
  }),

  removeUserFromTeamRequest: data => ({
    type: usersActions.REMOVE_USER_FROM_TEAM_REQUEST,
    data,
  }),
  removeUserFromTeamSuccess: data => ({
    type: usersActions.REMOVE_USER_FROM_TEAM_SUCCESS,
    payload: data,
  }),
  removeUserFromTeamFailure: error => ({
    type: usersActions.REMOVE_USER_FROM_TEAM_FAILURE,
    payload: error,
  }),
};

export default usersActions;
