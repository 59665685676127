import { all, takeEvery, put } from 'redux-saga/effects';

import metrcActions from './actions';
import anamiService from '../../services/anamiService';

function* getTransfers({ data }) {
  try {
    const response = yield anamiService.getTransfers(data);

    yield put(metrcActions.getTransfersSuccess(response.data.data));
  } catch (error) {
    yield put(metrcActions.getTransfersFailure(error));
  }
}

function* updateTransfer({ data }) {
  try {
    yield anamiService.updateTransfer(data);

    yield put(metrcActions.updateTransfersSuccess(data));
  } catch (error) {
    yield put(metrcActions.updateTransfersFailure(error));
  }
}

function* getReceipts({ data }) {
  try {
    const response = yield anamiService.getReceipts(data);

    yield put(metrcActions.getReceiptsSuccess(response.data.data));
  } catch (error) {
    yield put(metrcActions.getReceiptsFailure(error));
  }
}

function* getReceipt({ data }) {
  try {
    const response = yield anamiService.getReceipt(data);

    yield put(metrcActions.getReceiptSuccess(response.data));
  } catch (error) {
    yield put(metrcActions.getReceiptFailure(error));
  }
}

function* getPackage({ data }) {
  try {
    const response = yield anamiService.getPackage(data);

    yield put(metrcActions.getPackageSuccess(response.data));
  } catch (error) {
    yield put(metrcActions.getPackageFailure(error));
  }
}

function* getLabtests({ data }) {
  try {
    const response = yield anamiService.getLabtests(data);

    yield put(metrcActions.getLabtestsSuccess(response.data));
  } catch (error) {
    yield put(metrcActions.getLabtestsFailure(error));
  }
}

export default function* metrcSaga() {
  yield all([takeEvery(metrcActions.GET_TRANSFERS_REQUEST, getTransfers)]);
  yield all([takeEvery(metrcActions.UPDATE_TRANSFER_REQUEST, updateTransfer)]);
  yield all([takeEvery(metrcActions.GET_RECEIPTS_REQUEST, getReceipts)]);
  yield all([takeEvery(metrcActions.GET_RECEIPT_REQUEST, getReceipt)]);
  yield all([takeEvery(metrcActions.GET_PACKAGE_REQUEST, getPackage)]);
  yield all([takeEvery(metrcActions.GET_LABTESTS_REQUEST, getLabtests)]);
}
