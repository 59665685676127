import {
  all,
  takeEvery,
  put,
  fork,
  apply,
  select,
  delay,
} from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import jwtDecode from 'jwt-decode';

import actions from './actions';
import watcherActions from '../watcher/actions';
import meActions from '@iso/redux/me/actions';
import appAction from '@iso/redux/app/actions';
import anamiService from '../../services/anamiService';
import companiesActions from '../companies/actions';
import pushNotificationsActions from '@iso/redux/pushNotifications/actions';

import { getToken, clearToken } from '@iso/lib/helpers/utility';

import analytics from 'services/analytics';

import { APP_ROUTES } from '../../../packages/isomorphic-cra/src/route.constants';

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    try {
      const data = yield anamiService.login(payload);
      const { token, user } = data.data;

      if (token) {
        // yield put(usersActions.getUserSuccess(user));
        yield put(meActions.getMeSuccess(user));
        yield put({ type: actions.LOGIN_SUCCESS, token: token });
        yield put(appAction.clearMenu());
        history.push(APP_ROUTES.DASHBOARD);
      }
    } catch (e) {
      yield put(
        actions.loginFailure(
          e.response?.data?.message || e?.message || 'SignIn reqeust error'
        )
      );
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    try {
      yield localStorage.setItem('id_token', payload.token);
      const user = jwtDecode(payload.token);

      yield put(
        meActions.setUserSections({
          sections: user.sections,
          accessibleSections: user.accessibleSections,
        })
      );

      if (!payload.skipActing) {
        yield put(pushNotificationsActions.initPushNotificationsFlow());
        yield put(watcherActions.startWatchToken());
        yield put(meActions.getMeFilters(user));

        if (user.endTrialDate) {
          yield put(companiesActions.setEndTrialDate(user.endTrialDate));
        }

        yield delay(5000);
        const isTabDuplicate = yield select(state => state.App.isTabDuplicate);

        yield apply(analytics, analytics.trackSessionStart, [
          {
            userId: user.id,
          },
          isTabDuplicate,
        ]);
      }
    } catch (error) {
      console.log(`error`, error);
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    const user = yield select(state => state.me.user);
    // const history = createBrowserHistory({ forceRefresh: true});
    // history.push('/dashboard');
    yield clearToken();
    yield put(actions.logoutSuccess());
    yield put(watcherActions.stopWatchToken());

    yield apply(analytics, analytics.trackSessionEnd, [
      {
        userId: user.id,
      },
    ]);
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    try {
      yield apply(analytics, analytics.init);

      if (token) {
        const response = yield anamiService.checkAuthorization();

        if (response.data) {
          // yield put(usersActions.getUserRequest(response.data));
          yield put(meActions.getMeRequest());
          yield put({
            type: actions.LOGIN_SUCCESS,
            token,
            userId: response.data,
          });
        }
      } else {
        yield put(actions.tokenUnexist());
      }
    } catch (error) {
      console.log(error);
    }
  });
}

export function* updateToken() {
  yield takeEvery(actions.UPDATE_TOKEN, function*({ payload }) {
    try {
      const { id: companyId, needUpdateToken = true } = payload;
      if (needUpdateToken) {
        const response = yield anamiService.updateToken({ companyId });

        if (response && response.data) {
          yield put({
            type: actions.LOGIN_SUCCESS,
            token: response.data.token,
            skipActing: true,
          });
          yield put(meActions.getMeRequest());
        }
      }

      yield put(companiesActions.selectCompanySuccess(companyId));
    } catch (error) {
      console.log(error);
    }
  });
}

export function* sendResetPassword() {
  yield takeEvery(actions.SEND_RESET_PASSWORD_REQUEST, function*({ data }) {
    try {
      yield anamiService.sendResetPassword(data);

      yield put(actions.sendResetPasswordSuccess());
      // notifications.success({
      // message: 'Request received',
      // description: 'Please check your email for a reset link',
      // });
    } catch (error) {
      yield put(actions.sendResetPasswordFailure(error));
    }
  });
}

export function* resetPassword() {
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, function*({ data }) {
    try {
      const response = yield anamiService.resetPassword(data);
      const { token, user } = response.data;

      if (token) {
        yield put(meActions.getMeSuccess(user));
        yield put({ type: actions.LOGIN_SUCCESS, token: token });
        yield put(appAction.clearMenu());
        history.push('/dashboard');
      }

      yield put(actions.resetPasswordSuccess());
    } catch (error) {
      yield put(actions.resetPasswordFailure(error));
    }
  });
}

export function* signUp() {
  yield takeEvery(actions.SIGN_UP_REQUEST, function*({ data }) {
    try {
      const response = yield anamiService.signUp(data);
      const { token, user } = response.data;

      if (token) {
        yield put(meActions.getMeSuccess(user));
        yield put({ type: actions.LOGIN_SUCCESS, token: token });
        yield put(appAction.clearMenu());
        history.push('/dashboard');
      }

      yield put(actions.signUpSuccess());
    } catch (error) {
      yield put(actions.signUpFailure(error));
    }
  });
}

function* createAccount({ data }) {
  try {
    yield anamiService.createAccount(data);
    yield put(actions.createAccountSuccess());
  } catch (error) {
    yield put(actions.createAccountFailure(error));
  }
}

function* verifyAccount({ data }) {
  try {
    const response = yield anamiService.verifyAccount(data);

    yield put(actions.verifyAccountSuccess(response.data));
  } catch (error) {
    yield put(actions.verifyAccountFailure(error));
  }
}

function* createPasswordAndCompany({ data }) {
  try {
    const response = yield anamiService.createPasswordAndCompany(data);
    const { token, user } = response.data;

    if (token) {
      yield put(meActions.getMeSuccess(user));
      yield put(actions.createPasswordAndCompanySuccess(token));
      history.push('/dashboard');

      yield localStorage.setItem('id_token', token);
      const { sections, accessibleSections, endTrialDate = null } = jwtDecode(
        token
      );

      yield put(
        meActions.setUserSections({
          sections: sections,
          accessibleSections: accessibleSections,
        })
      );

      yield put(meActions.getMeFilters(user));

      yield put(watcherActions.startWatchToken());
      yield put(appAction.clearMenu());

      if (endTrialDate) {
        yield put(companiesActions.setEndTrialDate(endTrialDate));
      }

      yield delay(5000);
      const isTabDuplicate = yield select(state => state.App.isTabDuplicate);

      yield apply(analytics, analytics.trackSessionStart, [
        {
          userId: user.id,
        },
        isTabDuplicate,
      ]);
    }
  } catch (error) {
    yield put(actions.createPasswordAndCompanyFailure(error));
  }
}

function* resendVerifyEmail({ payload }) {
  try {
    const response = yield anamiService.resendVerifyEmail(payload);

    yield put(actions.resendVerifyEmailSuccess());
  } catch (error) {
    yield put(actions.resendVerifyEmailFailure(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(updateToken),
    fork(sendResetPassword),
    fork(resetPassword),
    fork(signUp),
  ]);
  yield takeEvery(actions.CREATE_ACCOUNT_REQUEST, createAccount);
  yield takeEvery(actions.VERIFY_ACCOUNT_REQUEST, verifyAccount);
  yield takeEvery(actions.RESEND_VERIFICATION_EMAIL_REQUEST, resendVerifyEmail);
  yield takeEvery(
    actions.CREATE_PASSWORD_AND_COMPANY_REQUEST,
    createPasswordAndCompany
  );
}
