import actionTypes from './actionTypes';
import projectActions from './projectActions';
import sectionActions from './sectionActions';
import taskActions from './taskActions';
import checklsitActions from './checklistActions';
import sopActions from './sopActions';

export default {
  ...actionTypes,
  ...projectActions,
  ...sectionActions,
  ...taskActions,
  ...checklsitActions,
  ...sopActions,

  addInviteUserToProjectState: data => ({
    type: actionTypes.ADD_INVITE_USER_TO_PROJECT_STATE,
    payload: data,
  }),

  getSpecificationsRequest: () => ({
    type: actionTypes.GET_SPECIFICATIONS_REQUEST,
  }),
  getSpecificationsSuccess: specifications => ({
    type: actionTypes.GET_SPECIFICATIONS_SUCCESS,
    payload: specifications,
  }),
  getSpecificationsFailure: error => ({
    type: actionTypes.GET_SPECIFICATIONS_FAILURE,
    payload: error,
  }),

  changeBusinessRequest: data => ({
    type: actionTypes.CHANGE_BUSINESS_REQUEST,
    data,
  }),
  changeBusinessSuccess: project => ({
    type: actionTypes.CHANGE_BUSINESS_SUCCESS,
    payload: project,
  }),
  changeBusinessFailure: error => ({
    type: actionTypes.CHANGE_BUSINESS_FAILURE,
    payload: error,
  }),

  removeAssignedProjectRequest: data => ({
    type: actionTypes.REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_REQUEST,
    data,
  }),
  removeAssignedProjectSuccess: project => ({
    type: actionTypes.REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_SUCCESS,
    payload: project,
  }),
  removeAssignedProjectFailure: error => ({
    type: actionTypes.REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_FAILURE,
    payload: error,
  }),
  checkExistenceOfProjectRequest: projectId => ({
    type: actionTypes.CHECK_EXISTENCE_OF_PROJECT_REQUEST,
    payload: projectId,
  }),

  checkExistenceOfProjectSuccess: data => ({
    type: actionTypes.CHECK_EXISTENCE_OF_PROJECT_SUCCESS,
    payload: data,
  }),

  checkExistenceOfProjectFailure: error => ({
    type: actionTypes.CHECK_EXISTENCE_OF_PROJECT_FAILURE,
    payload: error,
  }),

  resetExistenceOfProjectDate: () => ({
    type: actionTypes.RESET_EXISTENCE_OF_PROJECT_DATE,
  }),
};
