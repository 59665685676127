export default {
  siteName: 'Greenspace',
  siteIcon: 'ion-flash',
  footerText: `Greenspace ©2021 Anami`,
  enableAnimatedRoute: false,
  apiUrl: process.env.REACT_APP_API_URL,
  apiUrl_v2: process.env.REACT_APP_API_URL_V2,
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
