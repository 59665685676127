import p1 from './projects-1.png';
import p2 from './projects-2.png';
import p3 from './projects-3.png';
import p4 from './projects-4.png';
import p5 from './projects-5.png';
import p6 from './projects-6.png';
import p7 from './projects-7.png';
import p8 from './projects-8.png';
import p9 from './projects-9.png';
import p10 from './projects-10.png';
import p11 from './projects-11.png';
import p12 from './projects-12.png';
import p13 from './projects-13.png';
import p14 from './projects-14.png';
import p15 from './projects-15.png';
import p16 from './projects-16.png';
import p17 from './projects-17.png';

export default {
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
};
