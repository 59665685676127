import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import contactSagas from '@iso/redux/contacts/saga';
import invoicesSagas from '@iso/redux/invoice/saga';
import mailSagas from '@iso/redux/mail/saga';
import notesSagas from '@iso/redux/notes/saga';
import todosSagas from '@iso/redux/todos/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import cardsSagas from '@iso/redux/card/saga';
import chatSagas from '@iso/redux/chat/sagas';
import youtubeSearchSagas from '@iso/redux/youtubeSearch/sagas';
import githubSagas from '@iso/redux/githubSearch/sagas';
import articles from '@iso/redux/articles/sagas';
import investors from '@iso/redux/investors/sagas';
import scrumBoardSaga from '@iso/redux/scrumBoard/saga';
import profileSaga from '@iso/redux/profile/saga';
import quizSaga from '@iso/redux/quiz/saga';

import dashboardSaga from '@iso/redux/dashboard/saga';
import projectsSaga from '@iso/redux/projects/saga';
import businessesSaga from '@iso/redux/businesses/saga';
import usersSaga from '@iso/redux/users/saga';
import resourcesSaga from '@iso/redux/resources/saga';
import companiesSage from '@iso/redux/companies/saga';
import notificationsSage from '@iso/redux/notifications/saga';
import meSage from '@iso/redux/me/saga';
import dropboxSage from '@iso/redux/dropbox/saga';
import metrcSage from '@iso/redux/metrc/saga';
import licenseSage from '@iso/redux/license/saga';
import googleDrive from '@iso/redux/googleDrive/saga';
import watcherSaga from '@iso/redux/watcher/saga';
import pushNotificationsSaga from '@iso/redux/pushNotifications/saga';
import projectsCalendar from '@iso/redux/projectsCalendar/saga';

export default function* rootSaga(getState) {
  yield all([
    dashboardSaga(),
    businessesSaga(),
    projectsSaga(),
    usersSaga(),
    resourcesSaga(),
    companiesSage(),
    notificationsSage(),
    meSage(),
    dropboxSage(),
    metrcSage(),
    licenseSage(),
    googleDrive(),
    pushNotificationsSaga(),
    projectsCalendar(),

    authSagas(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    ecommerceSaga(),
    cardsSagas(),
    invoicesSagas(),
    chatSagas(),
    youtubeSearchSagas(),
    githubSagas(),
    articles(),
    investors(),
    scrumBoardSaga(),
    profileSaga(),
    quizSaga(),
    watcherSaga(),
  ]);
}
