import { all, takeEvery, put } from 'redux-saga/effects';
import { notification } from '@iso/components';
import { downloadFile, fileCopy } from 'helpers/utils';

import anamiService from '../../services/anamiService';
import resourcesActions from './actions';

function* getResourceList() {
  try {
    const response = yield anamiService.getResourceList();

    yield put(resourcesActions.getResourceListSuccess(response.data.resources));
  } catch (error) {
    yield put(resourcesActions.getResourceListFailure(error));
  }
}

function* createFolder({ data }) {
  try {
    const response = yield anamiService.createFolder(data);

    yield put(resourcesActions.createFolderSuccess(response.data.resource));
    yield put(resourcesActions.getResourceListRequest());
  } catch (error) {
    yield put(resourcesActions.createFolderFailure(error));
  }
}

function* updateResource({ data }) {
  try {
    const response = yield anamiService.updateResource(data);
    yield put(resourcesActions.updateResourceSuccess(response.data.resource));
    yield put(resourcesActions.getResourceListRequest());
  } catch (error) {
    yield put(resourcesActions.updateResourceFailure(error));
  }
}

function* uploadResource({ data, event }) {
  try {
    yield put(resourcesActions.uploadResourceErrorReset());

    const response = yield anamiService.uploadResource(data, event);

    yield put(resourcesActions.uploadResourceSuccess(response.data.resource));
  } catch (error) {
    yield put(resourcesActions.uploadResourceFailure(error));
  }
}

function* downloadResource({ file }) {
  try {
    const response = yield anamiService.downloadResource(file.id);

    downloadFile(response.data, response.headers, file);
    yield put(resourcesActions.downloadResourceSuccess());
  } catch (error) {
    notification(
      'error',
      `File downloading error: ${error.response.statusText || error}`
    );
    yield put(resourcesActions.downloadResourceFailure(error));
  }
}

function* removeResource({ id }) {
  try {
    yield anamiService.removeResource(id);
    yield put(resourcesActions.removeResourceSuccess(id));
  } catch (error) {
    yield put(resourcesActions.removeResourceFailure(error));
  }
}

function* moveResource({ data }) {
  try {
    const response = yield anamiService.moveResource(data);

    yield put(resourcesActions.moveResourceSuccess(response.data.resources));
  } catch (error) {
    yield put(resourcesActions.moveResourceFailure(error));
  }
}

function* getFileUsers({ id }) {
  try {
    const response = yield anamiService.getFileUsers(id);

    yield put(resourcesActions.getFileUsersSuccess(response.data.users));
  } catch (error) {
    yield put(resourcesActions.getFileUsersFailure(error));
  }
}

function* connectFileUser({ data }) {
  try {
    const response = yield anamiService.connectFileUser(data);

    yield put(resourcesActions.connectFileUserSuccess(response.data.users));
  } catch (error) {
    yield put(resourcesActions.connectFileUserFailure(error));
  }
}

function* disconnectFileUser({ data }) {
  try {
    const response = yield anamiService.disconnectFileUser(data);

    yield put(resourcesActions.disconnectFileUserSuccess(response.data.users));
  } catch (error) {
    yield put(resourcesActions.disconnectFileUserFailure(error));
  }
}

function* getResourceUrl({ payload }) {
  try {
    const response = yield anamiService.getResourceUrl(payload);

    yield put(resourcesActions.getResourceUrlSuccess(response.data.url));
  } catch (error) {
    yield put(resourcesActions.getResourceUrlFailure(error));
  }
}

function* getResourceSummary() {
  try {
    const response = yield anamiService.getResourceSummary();

    yield put(
      resourcesActions.getResourcesSummarySuccess(response.data.filesCount)
    );
  } catch (error) {
    yield put(resourcesActions.getResourcesSummaryFailure(error));
  }
}

function* restrictAccessToResource({ data }) {
  try {
    const { resourceId } = data;

    const response = yield anamiService.restrictAccessResource(data);

    yield put(
      resourcesActions.restrictAccessResourceSuccess({
        resourceId,
        users: response.data.users,
      })
    );
  } catch (error) {
    yield put(resourcesActions.restrictAccessResourceFailure(error));
  }
}

function* allowAccessToResource({ data }) {
  try {
    const { resourceId } = data;

    const response = yield anamiService.allowAccessResource(data);

    yield put(
      resourcesActions.allowAccessResourceSuccess({
        resourceId,
        users: response.data.users,
      })
    );
  } catch (error) {
    yield put(resourcesActions.allowAccessResourceFailure(error));
  }
}

function* shareWithExternalUser({ data }) {
  try {
    yield put(resourcesActions.setLoadingStatus(true));
    const res = yield anamiService.shareResource(data);

    fileCopy(res.data.link);

    notification('success', `Link was successfully shared`);

    yield put(resourcesActions.setLoadingStatus(false));

    yield put(resourcesActions.shareWithExternalUserSuccess());
  } catch (error) {
    yield put(resourcesActions.setLoadingStatus(false));
    yield put(resourcesActions.shareWithExternalUserFailure(error));
  }
}

function* downloadSharedResource({ data }) {
  try {
    const response = yield anamiService.directDownloadResource(data);
    const fileName = response.headers['content-disposition']
      .replace(/ /g, '')
      .match(/=([a-zA-z0-9.\S])+/g)[0]
      .replace(/=/g, '');

    downloadFile(response.data, response.headers, {
      name: fileName,
    });

    yield put(resourcesActions.downloadSharedResourceSuccess());
  } catch (error) {
    yield put(resourcesActions.downloadSharedResourceFailure(error));
  }
}

function* allowTeamAccess({ data }) {
  try {
    const { resourceId } = data;

    const response = yield anamiService.allowTeamAccess(data);

    yield put(
      resourcesActions.allowTeamAccessSuccess({
        resourceId,
        teams: response.data.teams,
      })
    );
  } catch (error) {
    yield put(resourcesActions.allowTeamAccessFailure(error));
  }
}

function* restrictTeamAccess({ data }) {
  try {
    const { resourceId } = data;

    const response = yield anamiService.restrictTeamAccess(data);

    yield put(
      resourcesActions.restrictTeamAccessSuccess({
        resourceId,
        teams: response.data.teams,
      })
    );
  } catch (error) {
    yield put(resourcesActions.restrictTeamAccessFailure(error));
  }
}

function* getFilePreviewImages({ payload: fileId }) {
  try {
    const response = yield anamiService.getResourceUrl({ id: fileId });
    const previewImageItem = { fileId, url: response.data.url };
    yield put(resourcesActions.getFilePreviewImagesSaccess(previewImageItem));
  } catch (error) {
    yield put(resourcesActions.getFilePreviewImagesFailure(fileId));
  }
}

function* uploadResourceWithCallback({ data }) {
  try {
    const { file, callback } = data;

    const response = yield anamiService.uploadResource(file);
    const { resource } = response.data;

    callback({
      id: resource.id,
      name: resource.name,
      attachmentId: resource.id,
    });
    yield put(resourcesActions.uploadResourceWithCallbackSuccess());
  } catch (error) {
    yield put(resourcesActions.uploadResourceWithCallbackFailure(error));
  }
}

function* setResourcePermission({ data }) {
  try {
    yield anamiService.setResourcePermission(data);

    yield put(
      resourcesActions.setResourcePermissionSuccess({
        resourceId: data.resourceId,
      })
    );
  } catch (error) {
    yield put(resourcesActions.setResourcePermissionFailure(error));
  }
}

function* shareResourceToUserOrTeam({ data }) {
  try {
    const { user, team, ...rest } = data;

    yield anamiService.shareResourceToUserOrTeam(rest);
    yield put(resourcesActions.shareResourceToUserOrTeamSuccess(data));
  } catch (error) {
    yield put(resourcesActions.shareResourceToUserOrTeamFailure(error));
  }
}

function* restrictResourceToUserOrTeam({ data }) {
  try {
    yield anamiService.restrictResourceToUserOrTeam(data);

    yield put(resourcesActions.restrictResourceToUserOrTeamSuccess(data));
  } catch (error) {
    yield put(resourcesActions.restrictResourceToUserOrTeamFailure(error));
  }
}

function* checkUserHasHigherPermissionsToResourceSaga({ data }) {
  try {
    const response = yield anamiService.checkUserHasHigherPermissionsToResource(
      data
    );

    if (response.data.isAttached) {
      yield put(resourcesActions.setUserPermissionsWarningData(data));
    } else {
      yield put(resourcesActions.restrictResourceToUserOrTeam(data));
    }

    yield put(
      resourcesActions.checkUserHasHigherPermissionsToResourceSuccess()
    );
  } catch (error) {
    yield put(
      resourcesActions.checkUserHasHigherPermissionsToResourceFailure(error)
    );
  }
}

export default function* resourcesSaga() {
  yield all([
    takeEvery(resourcesActions.GET_RESOURCE_LIST_REQUEST, getResourceList),
  ]);
  yield all([takeEvery(resourcesActions.CREATE_FOLDER_REQUEST, createFolder)]);
  yield all([
    takeEvery(resourcesActions.UPDATE_RESOURCE_REQUEST, updateResource),
  ]);
  yield all([
    takeEvery(resourcesActions.UPLOAD_RESOURCE_REQUEST, uploadResource),
  ]);
  yield all([
    takeEvery(resourcesActions.DOWNLOAD_RESOURCE_REQUEST, downloadResource),
  ]);
  yield all([
    takeEvery(resourcesActions.REMOVE_RESOURCE_REQUEST, removeResource),
  ]);
  yield all([takeEvery(resourcesActions.MOVE_RESOURCE_REQUEST, moveResource)]);
  yield all([takeEvery(resourcesActions.GET_FILE_USERS_REQUEST, getFileUsers)]);
  yield all([
    takeEvery(resourcesActions.CONNECT_FILE_USER_REQUEST, connectFileUser),
  ]);
  yield all([
    takeEvery(
      resourcesActions.DISCONNECT_FILE_USER_REQUEST,
      disconnectFileUser
    ),
  ]);
  yield all([
    takeEvery(resourcesActions.GET_RESOURCE_URL_REQUEST, getResourceUrl),
  ]);
  yield takeEvery(
    resourcesActions.GET_RESOURCE_SUMMARY_REQUEST,
    getResourceSummary
  );
  yield takeEvery(
    resourcesActions.RESTRICT_ACCESS_TO_RESOURCE_REQUEST,
    restrictAccessToResource
  );
  yield takeEvery(
    resourcesActions.ALLOW_ACCESS_TO_RESOURCE_REQUEST,
    allowAccessToResource
  );
  yield takeEvery(
    resourcesActions.SHARE_RESOURCE_WITH_EXTERNAL_USER_REQUEST,
    shareWithExternalUser
  );
  yield takeEvery(
    resourcesActions.DOWNLOAD_SHARED_RESOURCE_REQUEST,
    downloadSharedResource
  );
  yield takeEvery(
    resourcesActions.ALLOW_TEAM_ACCESS_TO_RESOURCE_REQUEST,
    allowTeamAccess
  );
  yield takeEvery(
    resourcesActions.RESTRICT_TEAM_ACCESS_TO_RESOURCE_REQUEST,
    restrictTeamAccess
  );
  yield takeEvery(
    resourcesActions.GET_FILE_PREVIEW_IMAGES_REQUEST,
    getFilePreviewImages
  );
  yield takeEvery(
    resourcesActions.UPLOAD_NEW_RESOURCE_WITH_CALLBACK_REQUEST,
    uploadResourceWithCallback
  );
  yield takeEvery(
    resourcesActions.SET_RESOURCE_PERMISSION_REQUEST,
    setResourcePermission
  );
  yield takeEvery(
    resourcesActions.SHARE_RESOURCE_TO_USER_OR_TEAM_REQUEST,
    shareResourceToUserOrTeam
  );
  yield takeEvery(
    resourcesActions.RESTRICT_RESOURCE_TO_USER_OR_TEAM_REQUEST,
    restrictResourceToUserOrTeam
  );
  yield takeEvery(
    resourcesActions.CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_REQUEST,
    checkUserHasHigherPermissionsToResourceSaga
  );
}
