import projectsActions from '../actions';
import taskCaseFunctions from './taskCaseFunctions';
import sectionCaseFunctions from './sectionCaseFunctions';
import projectCaseFunctions from './projectCaseFunctions';
import specificationCaseFunctions from './specificationCaseFunctions';
import sopCaseFunctions from './sopCaseFunctions';
import actionTypes from '../actions/actionTypes';

import { pagination, projectNameIsTakenServerErrMsg } from 'helpers/constants';

const projectsSortingByDefault = 'updatedAt';

const initialState = {
  data: [],
  auditProjectList: [],
  selectedProject: {},
  selectedAuditProject: null,
  selectedTask: null,
  users: [],
  connectedUsers: [],
  assignedUsersLoading: false,
  loading: false,
  projectLoading: false,
  sectionLoading: false,
  filesLoading: false,
  usersAndTeamsLoading: false,
  projectPartsLoading: false,
  projectListLoading: false,
  projectImageLoading: false,
  removeProjectLoading: false,
  taskLoading: false,
  commentLoading: false,
  error: null,
  specifications: {},
  labels: [],
  hasNew: false,
  previousProjectState: {},
  previousChecklists: [],
  taskType: 'assigned',
  recentViews: {
    projects: [],
    checklists: [],
    sops: [],
  },
  tasksLength: 0,
  trackerLoading: false,
  trackerData: [],
  assignedUsers: [],
  homeViewData: [],
  totalNumber: 0,
  filtersData: {},
  filtersActivated: false,
  loadingActiveItems: false,
  businessProjects: {},
  editAvailable: false,
  projectsList: [],
  newAssignedUser: null,
  assignLoading: false,
  isShowChangingBusinessPrompt: false,
  filterSettings: {
    page: pagination.PAGE,
    page_size: pagination.PAGE_SIZE,
    order: 'descend',
    orderBy: projectsSortingByDefault,
  },
  availableTags: [],
  templateList: {
    items: [],
    totalNumber: 0,
  },
  teamsLoading: false,
  usersLoading: false,
  simpleProjectsData: [],
  subtasksLoading: false,
  isProjectExist: null,
  isTitleExist: false,
  availableFilterTags: {
    projects: [],
    templates: [],
    archived: [],
  },
};

export default function projects(state = initialState, action) {
  const taskCases = taskCaseFunctions(state, action);
  const sectionCases = sectionCaseFunctions(state, action);
  const projectCases = projectCaseFunctions(state, action);
  const specificationCases = specificationCaseFunctions(state, action);
  const sopCases = sopCaseFunctions(state, action);

  switch (action.type) {
    case projectsActions.FETCH_DATA:
      return {
        ...state,
        data: [],
        projectLoading: true,
        projectListLoading: true,
        error: null,
      };

    case projectsActions.FETCH_DATA_SUCCESS:
      return projectCases.getProjectList();

    case projectsActions.FETCH_DATA_FAILURE:
      return {
        ...state,
        projectLoading: false,
        projectListLoading: false,
        error: action.payload,
      };

    case projectsActions.GET_PROJECT_REQUEST:
    case projectsActions.FETCH_SELECTED_PROJECT_REQUEST:
    case projectsActions.GET_PROJECT_DETAILS_REQUEST:
      return { ...state, projectLoading: true, error: null };

    case projectsActions.GET_PROJECT_LABELS_REQUEST:
    case projectsActions.CREATE_TASK_LABEL_REQUEST:
    case projectsActions.REMOVE_TASK_LABEL_REQUEST:
    case projectsActions.CREATE_PROJECT_LABEL_REQUEST:
    case projectsActions.UPDATE_PROJECT_LABEL_REQUEST:
    case projectsActions.REMOVE_PROJECT_LABEL_REQUEST:
    case projectsActions.GET_PROJECT_USERS_REQUEST:
    case projectsActions.CREATE_ATTACHMENT_REQUEST:
    case projectsActions.DELETE_ATTACHMENT_REQUEST:
    case projectsActions.CREATE_TASK_REQUEST:
    case projectsActions.UPDATE_SECTION_REQUEST:

    case projectsActions.CREATE_SECTION_REQUEST:
    case projectsActions.PUBLISH_PROJECT_REQUEST:
    case projectsActions.CONVERT_TO_SOP_REQUEST:
    case projectsActions.UPDATE_PROJECT_REQUEST:
    case projectsActions.CREATE_PROJECT_REQUEST:
    case projectsActions.CREATE_CHECKLIST_REQUEST:
    case projectsActions.COPY_PROJECT_REQUEST:
    case projectsActions.CHANGE_BUSINESS_REQUEST:
    case projectsActions.GET_CONNECTED_USERS_REQUEST:
    case projectsActions.MOVE_TASK_REQUEST:
    case projectsActions.GET_RECENT_VIEWS_REQUEST:
    case projectsActions.GET_CHECKLISTS_REQUEST:
    case projectsActions.GET_SOPS_REQUEST:
    case projectsActions.GET_AUDIT_LOGS_REQUEST:
    case projectsActions.RENAME_CHECKLIST_REQUEST:
    case projectsActions.CONNECT_USER_PROJECTS_HOME_REQUEST:
    case projectsActions.DISCONNECT_USER_PROJECTS_HOME_REQUEST:
    case projectsActions.FETCH_TASK_FILE_REQUEST:
    case projectsActions.FETCH_BUSINESS_PROJECTS_REQUEST:
    case projectsActions.REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_REQUEST:
    case projectsActions.FETCH_TEMPLATE_LIST_REQUEST:
    case projectsActions.FETCH_TEMPLATES_BY_NAME_REQUEST:
    case projectsActions.CHANGE_TEMPLATE_TYPE_REQUEST:
    case projectsActions.GET_SIMPLE_PROJECTS_DATA_REQUEST:
    case projectsActions.CHECK_EXISTENCE_OF_PROJECT_REQUEST:
      return { ...state, loading: true, error: null };

    case projectsActions.CONNECT_USER_REQUEST:
    case projectsActions.DISCONNECT_USER_REQUEST:
      return { ...state, usersLoading: true, error: null };

    case projectsActions.SET_TASK_ASSIGNEE_REQUEST:
      return {
        ...state,
        loading: true,
        assignLoading: true,
        error: null,
      };

    case projectsActions.UPDATE_TASK_REQUEST:
      return { ...state, loading: true, taskLoading: true, error: null };

    case projectsActions.CREATE_COMMENT_REQUEST:
      return { ...state, commentLoading: true, error: null };

    case projectsActions.SET_PROJECT_VIEWED:
      return { ...state, hasNew: false };

    case projectsActions.CREATE_PROJECT_SUCCESS:
    case projectsActions.CREATE_CHECKLIST_SUCCESS:
      return projectCases.createProject();

    case projectsActions.COPY_PROJECT_SUCCESS:
      return projectCases.copyProject();

    case projectsActions.SET_TASK_ASSIGNEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        assignLoading: false,
      };

    case projectsActions.GET_PROJECT_LABELS_FAILURE:
    case projectsActions.CREATE_TASK_LABEL_FAILURE:
    case projectsActions.REMOVE_TASK_LABEL_FAILURE:
    case projectsActions.CREATE_PROJECT_LABEL_FAILURE:
    case projectsActions.UPDATE_PROJECT_LABEL_FAILURE:
    case projectsActions.REMOVE_PROJECT_LABEL_FAILURE:
    case projectsActions.GET_PROJECT_USERS_FAILURE:
    case projectsActions.CREATE_ATTACHMENT_FAILURE:
    case projectsActions.DELETE_ATTACHMENT_FAILURE:
    case projectsActions.UPDATE_TASK_FAILURE:
    case projectsActions.CREATE_TASK_FAILURE:
    case projectsActions.UPDATE_SECTION_FAILURE:
    case projectsActions.CREATE_SECTION_FAILURE:
    case projectsActions.PUBLISH_PROJECT_FAILURE:
    case projectsActions.CONVERT_TO_SOP_FAILURE:
    case projectsActions.UPDATE_PROJECT_FAILURE:
    case projectsActions.CREATE_CHECKLIST_FAILURE:
    case projectsActions.COPY_PROJECT_FAILURE:
    case projectsActions.CHANGE_BUSINESS_FAILURE:
    case projectsActions.GET_CONNECTED_USERS_FAILURE:
    case projectsActions.GET_RECENT_VIEWS_FAILURE:
    case projectsActions.GET_CHECKLISTS_FAILURE:
    case projectsActions.GET_SOPS_FAILURE:
    case projectsActions.GET_AUDIT_LOGS_FAILURE:
    case projectsActions.RENAME_CHECKLIST_FAILURE:
    case projectsActions.CONNECT_USER_PROJECTS_HOME_FAILURE:
    case projectsActions.DISCONNECT_USER_PROJECTS_HOME_FAILURE:
    case projectsActions.FETCH_TASK_FILE_FAILURE:
    case projectsActions.SET_TASK_DATE_FAILURE:
    case projectsActions.FETCH_PROJECT_TAGS_FAILURE:
    case projectsActions.CREATE_PROJECT_TAG_FAILURE:
    case projectsActions.UPDATE_PROJECT_TAG_FAILURE:
    case projectsActions.DELETE_PROJECT_TAG_FAILURE:
    case projectsActions.FETCH_TEMPLATE_LIST_FAILURE:
    case projectsActions.FETCH_TEMPLATES_BY_NAME_FAILURE:
    case projectsActions.CHANGE_TEMPLATE_TYPE_FAILURE:
    case projectsActions.GET_SIMPLE_PROJECTS_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case projectsActions.PROJECT_NAME_IS_TAKEN_ERROR: {
      return { ...state, loading: false, isTitleExist: true };
    }

    case projectsActions.CREATE_PROJECT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case projectsActions.RESET_TITLE_EXIST_ERROR:
      return { ...state, loading: false, isTitleExist: false };

    case projectsActions.CONNECT_USER_FAILURE:
    case projectsActions.DISCONNECT_USER_FAILURE:
      return { ...state, usersLoading: false, error: action.payload };

    case projectsActions.CREATE_COMMENT_FAILURE:
      return { ...state, commentLoading: false, error: action.payload };

    case projectsActions.GET_PROJECT_FAILURE:
    case projectsActions.FETCH_SELECTED_PROJECT_FAILURE:
      return { ...state, projectLoading: false, error: action.payload };

    case projectsActions.GET_PROJECT_SUCCESS:
    case projectsActions.FETCH_SELECTED_PROJECT_SUCCESS:
      return projectCases.getProject();

    case projectsActions.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...projectCases.updateProject(),
        projectLoading: false,
      };

    case projectsActions.GET_PROJECT_DETAILS_FAILURE:
      return { ...state, projectLoading: false, error: action.payload };

    case projectsActions.GET_PROJECT_SECTION_REQUEST:
      return { ...state, sectionLoading: true, error: null };

    case projectsActions.GET_PROJECT_SECTION_SUCCESS:
      return {
        ...projectCases.updateProject(),
        sectionLoading: false,
      };

    case projectsActions.GET_PROJECT_SECTION_FAILURE:
      return { ...state, sectionLoading: false, error: action.payload };

    case projectsActions.GET_PROJECT_FILES_REQUEST:
      return { ...state, filesLoading: true, error: null };

    case projectsActions.GET_PROJECT_FILES_SUCCESS:
      return {
        ...projectCases.updateProject(),
        filesLoading: false,
      };

    case projectsActions.GET_PROJECT_FILES_FAILURE:
      return { ...state, filesLoading: false, error: null };

    case projectsActions.GET_PROJECT_USERS_AND_TEAMS_REQUEST:
      return { ...state, usersAndTeamsLoading: true, error: null };

    case projectsActions.GET_PROJECT_USERS_AND_TEAMS_SUCCESS:
      return {
        ...projectCases.updateProject(),
        usersAndTeamsLoading: false,
      };

    case projectsActions.GET_PROJECT_USERS_AND_TEAMS_FAILURE:
      return { ...state, usersAndTeamsLoading: false, error: null };

    case projectsActions.CHANGE_BUSINESS_SUCCESS:
    case projectsActions.PUBLISH_PROJECT_SUCCESS:
    case projectsActions.CONVERT_TO_SOP_SUCCESS:
    case projectsActions.UPDATE_PROJECT_SUCCESS:
    case projectsActions.RENAME_CHECKLIST_SUCCESS:
      return projectCases.updateProject();

    case projectsActions.GET_PROJECT_LABELS_SUCCESS:
      return {
        ...state,
        labels: action.payload,
        loading: false,
        error: false,
      };

    case projectsActions.GET_PROJECT_USERS_SUCCESS:
      return projectCases.getProjectUsers();

    case projectsActions.GET_CONNECTED_USERS_SUCCESS:
      return projectCases.getConnectedUsers();

    case projectsActions.CONNECT_USER_SUCCESS:
      return projectCases.connectUser();

    case projectsActions.DISCONNECT_USER_SUCCESS:
      return projectCases.disconnectUser();

    case projectsActions.REMOVE_SECTION_REQUEST:
      return { ...state, loading: true, error: null };

    case projectsActions.REMOVE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        removeProjectLoading: true,
        error: null,
      };

    case projectsActions.REMOVE_PROJECT_SUCCESS:
      return projectCases.removeProject();

    case projectsActions.REMOVE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        removeProjectLoading: false,
        error: action.payload,
      };

    case projectsActions.REMOVE_SECTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case projectsActions.CREATE_SECTION_SUCCESS:
      return sectionCases.createSection();

    case projectsActions.UPDATE_SECTION_SUCCESS:
      return sectionCases.updateSection();

    case projectsActions.REMOVE_SECTION_SUCCESS:
      return sectionCases.removeSection();

    case projectsActions.CREATE_TASK_SUCCESS:
      return taskCases.createTask();

    case projectsActions.UPDATE_TASK_SUCCESS:
    case projectsActions.SET_TASK_REPEAT_SUCCESS:
    case projectsActions.SET_TASK_DATE_SUCCESS:
      return taskCases.updateTaskOrSubtask();

    case projectsActions.REMOVE_TASK_REQUEST:
      return { ...state, taskLoading: true, error: null };

    case projectsActions.REMOVE_TASK_SUCCESS:
      return taskCases.removeTaskOrSubtask();

    case projectsActions.REMOVE_TASK_FAILURE:
      return { ...state, taskLoading: false, error: action.payload };

    case projectsActions.CREATE_ATTACHMENT_SUCCESS:
      return taskCases.createAttachment();

    case projectsActions.DELETE_ATTACHMENT_SUCCESS:
      return taskCases.removeAttachment();

    case projectsActions.GET_SPECIFICATIONS_REQUEST:
      return { ...state };

    case projectsActions.GET_SPECIFICATIONS_SUCCESS:
      return specificationCases.getSpecifications();

    case projectsActions.GET_SPECIFICATIONS_FAILURE:
      return { ...state };

    case projectsActions.SELECT_TASK_SUCCESS:
      return taskCases.selectTask();

    case projectsActions.CREATE_COMMENT_SUCCESS:
      return taskCases.createComment();

    case projectsActions.UPDATE_COMMENT_SUCCESS:
      return taskCases.updateComment();

    case projectsActions.REMOVE_COMMENT_SUCCESS:
      return taskCases.removeComment();

    case projectsActions.CREATE_TASK_LABEL_SUCCESS:
      return taskCases.createTaskLabel();

    case projectsActions.REMOVE_TASK_LABEL_SUCCESS:
      return taskCases.removeTaskLabel();

    case projectsActions.REMOVE_PROJECT_LABEL_SUCCESS:
      return projectCases.removeProjectLabel();

    case projectsActions.CREATE_PROJECT_LABEL_SUCCESS:
      return projectCases.createProjectLabel();

    case projectsActions.UPDATE_PROJECT_LABEL_SUCCESS:
      return projectCases.updateProjectLabel();

    case projectsActions.SET_TASK_ASSIGNEE_SUCCESS:
      return taskCases.setTaskOrSubtaskAssignee();

    case projectsActions.COPY_TASK_SUCCESS:
      return taskCases.copyTask();

    case projectsActions.MOVE_TASK_SUCCESS:
      return taskCases.moveTaskSuccess();

    case projectsActions.MOVE_TASK_FAILURE:
      return taskCases.moveTaskFailure();

    case projectsActions.MOVE_TASK_LOCAL:
      return taskCases.moveTaskLocal();

    case projectsActions.GET_ASSIGNED_TASKS_REQUEST:
    case projectsActions.GET_EXPIRED_PROJECT_TASKS:
    case projectsActions.GET_UPCOMING_PROJECT_TASKS:
    case projectsActions.GET_NOTIFICATION_PROJECT_TASKS:
    case projectsActions.GET_EXPIRED_TASKS_REQUEST:
    case projectsActions.GET_UPCOMING_TASKS_REQUEST:
    case projectsActions.GET_NOTIFICATION_TASKS_REQUEST:
    case projectsActions.UPDATE_PROJECT_DATA_AND_PUBLISH_REQUEST:
      return { ...state, projectLoading: true, error: null };

    case projectsActions.GET_EXPIRED_TASKS_SUCCESS:
      return {
        ...state,
        data: Array.isArray(action.payload.tasks)
          ? action.payload.tasks
          : [action.payload.tasks],
        tasksLength: action.payload.totalNumber,
        taskType: 'expired',
        projectLoading: false,
        error: null,
      };

    case projectsActions.GET_UPCOMING_TASKS_SUCCESS:
      return {
        ...state,
        data: Array.isArray(action.payload.tasks)
          ? action.payload.tasks
          : [action.payload.tasks],
        tasksLength: action.payload.totalNumber,
        taskType: 'upcoming',
        projectLoading: false,
        error: null,
      };

    case projectsActions.GET_NOTIFICATION_TASKS_SUCCESS:
      return {
        ...state,
        // data: Array.isArray(action.payload) ? action.payload : [action.payload],
        taskType: 'notification',
        // projectLoading: false,
        // error: null,
      };
    case projectsActions.GET_ASSIGNED_TASKS_SUCCESS:
      return {
        ...state,
        data: action.payload.assignedTasks,
        tasksLength: action.payload.totalNumber,
        taskType: 'assigned',
        projectLoading: false,
        error: null,
      };

    case projectsActions.GET_EXPIRED_TASKS_FAILURE:
    case projectsActions.GET_UPCOMING_TASKS_FAILURE:
    case projectsActions.GET_NOTIFICATION_TASKS_FAILURE:
    case projectsActions.GET_ASSIGNED_TASKS_FAILURE:
      return {
        ...state,
        projectLoading: false,
        error: action.payload.error,
        data: [],
        taskType: action.payload.taskType,
      };

    case projectsActions.GET_SOPS_SUCCESS:
    case projectsActions.GET_CHECKLISTS_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case projectsActions.GET_RECENT_VIEWS_SUCCESS:
      return {
        ...state,
        recentViews: action.payload,
        loading: false,
      };

    case projectsActions.UPLOAD_CHECKLIST_ICON_REQUEST:
    case projectsActions.REMOVE_CHECKLIST_ICON_REQUEST:
      return { ...state, loading: true };

    case projectsActions.UPLOAD_CHECKLIST_ICON_FAILURE:
    case projectsActions.REMOVE_CHECKLIST_ICON_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case projectsActions.UPLOAD_CHECKLIST_ICON_SUCCESS:
      return {
        ...state,
        data: state.data.map(project => {
          if (project.id === action.payload.projectId) {
            return {
              ...project,
              icon: action.payload.icon,
            };
          }
          return project;
        }),
        loading: false,
      };

    case projectsActions.REMOVE_CHECKLIST_ICON_SUCCESS:
      return {
        ...state,
        data: state.data.map(project => {
          if (project.id === action.payload) {
            return {
              ...project,
              icon: null,
            };
          }
          return project;
        }),
        loading: false,
      };

    case projectsActions.MOVE_CHECKLIST_REQUEST:
      return { ...state, loading: true, error: null };

    case projectsActions.MOVE_CHECKLIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        previousChecklists: [],
        loading: false,
      };

    case projectsActions.MOVE_CHECKLIST_FAILURE:
      return {
        ...state,
        data: state.previousChecklists,
        previousChecklists: [],
        error: action.payload,
        loading: false,
      };

    case projectsActions.MOVE_CHECKLIST_LOCAL:
      return {
        ...state,
        data: action.payload,
        previousChecklists: state.data,
      };

    case projectsActions.GET_AUDIT_LOGS_SUCCESS:
      return { ...state, loading: false };

    case projectsActions.GET_AUDIT_PROJECT_SUCCESS:
      return {
        ...state,
        selectedAuditProject: action.payload,
      };

    case projectsActions.GET_AUDIT_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        auditProjectList: action.payload,
      };

    case projectsActions.ASSIGNEE_SOP_SUCCESS:
      return sopCases.assigneeSop();

    case projectsActions.SET_SOP_DUE_DATE_SUCCESS:
      return sopCases.setSopDueDate();

    case projectsActions.SET_SOP_EFFECTIVE_DATE_SUCCESS:
      return sopCases.setSopEffectiveDate();

    case projectsActions.SET_DEFAULT_TASK_TYPE:
      return {
        ...state,
        taskType: 'assigned',
      };
    case projectsActions.GET_UPCOMING_TASKS_TRACKER_REQUEST:
    case projectsActions.GET_EXPIRED_TASKS_TRACKER_REQUEST:
    case projectsActions.GET_ASSIGNED_TASKS_TRACKER_REQUEST:
      return {
        ...state,
        trackerLoading: true,
      };

    case projectsActions.GET_UPCOMING_TASKS_TRACKER_SUCCESS:
    case projectsActions.GET_EXPIRED_TASKS_TRACKER_SUCCESS:
    case projectsActions.GET_ASSIGNED_TASKS_TRACKER_SUCCESS:
      return {
        ...state,
        trackerLoading: false,
        trackerData: action.payload,
      };

    case projectsActions.GET_UPCOMING_TASKS_TRACKER_FAILURE:
    case projectsActions.GET_EXPIRED_TASKS_TRACKER_FAILURE:
    case projectsActions.GET_ASSIGNED_TASKS_TRACKER_FAILURE:
      return {
        ...state,
        trackerLoading: false,
        error: action.payload,
      };

    case projectsActions.MOVE_SECTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case projectsActions.MOVE_SECTION_LOCAL:
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          section: action.payload,
        },
      };

    case projectsActions.HIDE_COLUMNS:
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          settings: {
            ...state.selectedProject.settings,
            hiddenColumns: { ...action.payload.hiddenColumns },
          },
        },
      };

    case projectsActions.GET_DASHBOARD_ASSIGNED_USERS_REQUEST:
      return {
        ...state,
        assignedUsersLoading: true,
      };

    case projectsActions.GET_DASHBOARD_ASSIGNED_USERS_SUCCESS:
      return {
        ...state,
        assignedUsersLoading: false,
        assignedUsers: action.payload,
      };

    case projectsActions.GET_DASHBOARD_ASSIGNED_USERS_FAILURE:
      return {
        ...state,
        assignedUsersLoading: false,
        error: action.payload,
      };

    case actionTypes.HIDE_SECTION:
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          settings: {
            ...state.selectedProject.settings,
            hiddenSections: action.payload.hiddenSections,
          },
        },
      };

    case actionTypes.FETCH_PROJECTS_HOME_VIEW_REQUEST:
    case actionTypes.FETCH_CHECKLISTS_HOME_VIEW_REQUEST:
    case actionTypes.FETCH_ARCHIVED_PROJECTS_HOME_VIEW_REQUEST:
    case actionTypes.FETCH_PROJECTS_BY_NAME_REQUEST:
    case actionTypes.FETCH_PROJECTS_LIST_DATA_REQUEST:
      return {
        ...state,
        projectListLoading: true,
      };

    case actionTypes.FETCH_PROJECTS_HOME_VIEW_SUCCESS:
    case actionTypes.FETCH_CHECKLISTS_HOME_VIEW_SUCCESS:
    case actionTypes.FETCH_ARCHIVED_PROJECTS_HOME_VIEW_SUCCESS:
      return {
        ...state,
        projectListLoading: false,
        homeViewData: action.payload.items,
        totalNumber: action.payload.totalNumber,
      };

    case actionTypes.FETCH_PROJECTS_HOME_VIEW_FAILURE:
    case actionTypes.FETCH_CHECKLISTS_HOME_VIEW_FAILURE:
    case actionTypes.FETCH_ARCHIVED_PROJECTS_HOME_VIEW_FAILURE:
    case actionTypes.FETCH_PROJECTS_BY_NAME_FAILURE:
    case actionTypes.FETCH_PROJECTS_LIST_DATA_FAILURE:
    case actionTypes.FETCH_PROJECTS_BY_EXTRA_ID_AND_NAME_FAILURE:
      return {
        ...state,
        projectListLoading: false,
        error: action.payload,
        homeViewData: [],
        totalNumber: 0,
        filtersActivated: false,
      };

    case actionTypes.UPDATE_PROJECT_PERMISSION_REQUEST:
    case actionTypes.ARCHIVE_PROJECT_REQUEST:
    case actionTypes.UNZIP_PROJECT_REQUEST:
    case actionTypes.UPDATE_PROJECT_ADMIN_TYPE_REQUEST:
      return {
        ...state,
        projectLoading: true,
      };

    case actionTypes.UPDATE_PROJECT_STARRED_SUCCESS:
      return {
        ...state,
        homeViewData: state.homeViewData.map(project => {
          if (project.id === action.payload) {
            return {
              ...project,
              settings: {
                ...project.settings,
                isStarred: !project.settings.isStarred,
              },
            };
          }
          return project;
        }),
        ...(state.selectedProject.id && {
          selectedProject: {
            ...state.selectedProject,
            settings: {
              ...state.selectedProject.settings,
              isStarred: !state.selectedProject.settings.isStarred,
            },
          },
        }),
      };

    case actionTypes.UPDATE_PROJECT_PERMISSION_SUCCESS:
      return {
        ...state,
        projectLoading: false,
        homeViewData: state.homeViewData.map(project => {
          if (project.id === action.payload.id) {
            return {
              ...project,
              type: action.payload.type,
              updatedAt: action.payload.updatedAt,
            };
          }
          return project;
        }),
      };

    case actionTypes.ARCHIVE_PROJECT_SUCCESS:
    case actionTypes.UNZIP_PROJECT_SUCCESS:
      return {
        ...state,
        projectLoading: false,
        homeViewData: state.homeViewData.filter(
          project => project.id !== action.payload.projectId
        ),
      };

    case actionTypes.UPDATE_PROJECT_STARRED_FAILURE:
    case actionTypes.UPDATE_PROJECT_PERMISSION_FAILURE:
    case actionTypes.ARCHIVE_PROJECT_FAILURE:
    case actionTypes.UNZIP_PROJECT_FAILURE:
    case actionTypes.UPDATE_PROJECT_ADMIN_TYPE_FAILURE:
    case actionTypes.UPDATE_PROJECT_DATA_AND_PUBLISH_FAILURE:
      return {
        ...state,
        projectLoading: false,
        error: action.payload,
      };

    case actionTypes.CREATE_PROJECT_IMAGE_REQUEST:
    case actionTypes.DELETE_PROJECT_IMAGE_REQUEST:
      return {
        ...state,
        projectImageLoading: true,
      };

    case actionTypes.CREATE_PROJECT_IMAGE_SUCCESS:
      return {
        ...state,
        projectImageLoading: false,
        selectedProject: {
          ...(state.selectedProject.id && {
            ...state.selectedProject,
            icon: action.payload.imagePath,
            updatedAt: action.payload.updatedAt,
          }),
        },
        homeViewData: state.homeViewData.map(project => {
          if (project.id === action.payload.projectId) {
            return {
              ...project,
              icon: action.payload.imagePath,
              updatedAt: action.payload.updatedAt,
            };
          }
          return project;
        }),
      };

    case actionTypes.DELETE_PROJECT_IMAGE_SUCCESS:
      return {
        ...state,
        projectImageLoading: false,
        homeViewData: state.homeViewData.map(project => {
          if (project.id === action.payload.id) {
            return {
              ...project,
              ...action.payload,
            };
          }
          return project;
        }),
      };

    case actionTypes.CREATE_PROJECT_IMAGE_FAILURE:
    case actionTypes.DELETE_PROJECT_IMAGE_FAILURE:
      return {
        ...state,
        projectImageLoading: false,
        error: action.payload,
      };

    case projectsActions.REMOVE_HOME_VIEW_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        removeProjectLoading: true,
        error: null,
      };

    case projectsActions.REMOVE_HOME_VIEW_PROJECT_SUCCESS:
      return {
        ...state,
        homeViewData: state.homeViewData.filter(
          project => project.id !== action.payload
        ),
        loading: false,
        removeProjectLoading: false,
        error: null,
      };

    case projectsActions.REMOVE_HOME_VIEW_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        removeProjectLoading: false,
        error: action.payload,
      };

    case projectsActions.SET_FILTER_STATE:
      return {
        ...state,
        filtersActivated: action.payload,
      };

    case projectsActions.FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_REQUEST:
      return {
        ...state,
        loadingActiveItems: true,
      };

    case projectsActions.FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_SUCCESS:
      return {
        ...state,
        filtersData: action.payload,
        loadingActiveItems: false,
      };

    case projectsActions.FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingActiveItems: false,
      };

    case actionTypes.UPDATE_PROJECT_ADMIN_TYPE_SUCCESS:
      return {
        ...state,
        projectLoading: false,
        homeViewData: state.homeViewData.map(project => {
          if (project.id === action.payload.id) {
            return {
              ...project,
              type: action.payload.type,
              updatedAt: action.payload.updatedAt,
            };
          }
          return project;
        }),
      };

    case actionTypes.FETCH_PROJECTS_BY_NAME_SUCCESS:
      return {
        ...state,
        projectListLoading: false,
        homeViewData: action.payload,
        totalNumber: null,
      };

    case actionTypes.FETCH_PROJECTS_BY_EXTRA_ID_AND_NAME_SUCCESS:
      return {
        ...state,
        projectListLoading: false,
        homeViewData: action.payload,
        totalNumber: null,
      };

    case actionTypes.CLEAR_PROJECT_USERS:
      return {
        ...state,
        users: [],
      };

    case projectsActions.CONNECT_USER_PROJECTS_HOME_SUCCESS:
      return {
        ...state,
        homeViewData: state.homeViewData.map(project => {
          if (project.id === action.payload.projectId) {
            return {
              ...project,
              users: [...project.users, action.payload],
            };
          }

          return project;
        }),
        loading: false,
      };

    case projectsActions.DISCONNECT_USER_PROJECTS_HOME_SUCCESS:
      return {
        ...state,
        homeViewData: state.homeViewData.map(project => {
          if (project.id === action.payload.projectId) {
            return {
              ...project,
              users: project.users.filter(
                user => user.id !== action.payload.userId
              ),
            };
          }

          return project;
        }),
        loading: false,
      };

    case projectsActions.FETCH_BUSINESS_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        businessProjects: action.payload,
      };

    case projectsActions.CLEAR_BUSINESS_PROJECTS:
      return {
        ...state,
        businessProjects: {},
      };

    case projectsActions.FETCH_BUSINESS_PROJECTS_FAILURE:
    case projectsActions.REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case projectsActions.REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        businessProjects: {
          ...state.businessProjects,
          projects: state.businessProjects.projects.filter(
            business => business.id !== action.payload.id
          ),
        },
      };

    case projectsActions.SET_CHECKLIST_SOP_EDIT_AVAILABLE:
      return {
        ...state,
        editAvailable: action.payload,
      };

    case projectsActions.FETCH_PROJECTS_LIST_DATA_SUCCESS:
      return {
        ...state,
        projectListLoading: false,
        projectsList: action.payload,
      };

    case projectsActions.ASSIGN_NEW_USER_TO_PROJECT_TASK_REQUEST:
      return {
        ...state,
        assignLoading: true,
      };

    case projectsActions.ASSIGN_NEW_USER_TO_PROJECT_TASK_SUCCESS:
      return taskCases.updateAssagneeTask();

    case projectsActions.ASSIGN_NEW_USER_TO_PROJECT_TASK_FAILURE:
      return {
        ...state,
        error: action.payload,
        assignLoading: false,
      };

    case projectsActions.SET_ASSIGNED_USER_TO_NULL:
      return {
        ...state,
        newAssignedUser: null,
      };

    case projectsActions.FETCH_TASK_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case projectsActions.SHOW_CHANGING_BUSINESS_PROMPT:
      return {
        ...state,
        isShowChangingBusinessPrompt: action.payload,
      };

    case projectsActions.DELETE_PROJECT_TAG_FROM_LIST_REQUEST_SUCCESS:
    case projectsActions.FETCH_PROJECT_TAGS_SUCCESS:
      return {
        ...state,
        availableTags: action.payload,
      };

    case projectsActions.UPDATE_PROJECT_TAG_SUCCESS: {
      const { project, idx, name } = action.payload;

      return {
        ...state,
        ...(state.selectedProject.id && {
          selectedProject: {
            ...state.selectedProject,
            tags: project.tags,
          },
        }),
        availableTags: state.availableTags.map((tag, index) => {
          if (index === idx) {
            return name;
          }

          return tag;
        }),
        homeViewData: state.homeViewData.map(item => {
          if (item.id === project.id) {
            return project;
          }

          return item;
        }),
      };
    }

    case projectsActions.CREATE_PROJECT_TAG_SUCCESS: {
      const { project, name } = action.payload;

      return {
        ...state,
        ...(state.selectedProject.id && {
          selectedProject: {
            ...state.selectedProject,
            tags: project.tags,
          },
        }),
        availableTags: [name, ...state.availableTags],
        homeViewData: state.homeViewData.map(item => {
          if (item.id === project.id) {
            return project;
          }

          return item;
        }),
      };
    }

    case projectsActions.DELETE_PROJECT_TAG_SUCCESS:
    case projectsActions.SELECT_AVAILABLE_PROJECT_TAG_SUCCESS:
      return {
        ...state,
        ...(state.selectedProject.id && {
          selectedProject: {
            ...state.selectedProject,
            tags: action.payload.tags,
          },
        }),
        homeViewData: state.homeViewData.map(project => {
          if (project.id === action.payload.id) {
            return {
              ...project,
              tags: action.payload.tags,
            };
          }

          return project;
        }),
      };

    case actionTypes.UPDATE_FILTERS_SETTINGS:
      return {
        ...state,
        filterSettings: Object.fromEntries(
          Object.entries({
            ...state.filterSettings,
            ...action.payload,
          }).filter(([key, val]) => !!val)
        ),
      };

    case actionTypes.SET_FILTERS_SETTINGS_BY_DEFAULT:
      return {
        ...state,
        filterSettings: {
          page: pagination.PAGE,
          page_size: pagination.PAGE_SIZE,
          order: 'descend',
          orderBy: projectsSortingByDefault,
        },
      };

    case actionTypes.REMOVE_FILTER_SETTINGS_ITEM:
      const { type, value } = action.payload;
      const updatedValues = state.filterSettings[type]
        .split(',')
        .filter(item => item !== value)
        .join(',');

      return {
        ...state,
        filterSettings: Object.fromEntries(
          Object.entries({
            ...state.filterSettings,
            [type]: updatedValues,
          }).filter(([key, val]) => !!val)
        ),
      };

    case actionTypes.SET_SAVED_FILTER_SETTINGS:
      const notFiltersFields = ['page', 'page_size', 'order', 'orderBy'];

      //saved filters fields
      const savedFilters = Object.fromEntries(
        Object.entries(action.payload).filter(([key, val]) => key !== 'type')
      );

      //sort, search, pagination fields
      const otherFields = Object.fromEntries(
        Object.entries(state.filterSettings).filter(([key, val]) =>
          notFiltersFields.includes(key)
        )
      );

      return {
        ...state,
        filterSettings: { ...otherFields, ...savedFilters },
      };

    case actionTypes.UPDATE_PROJECT_DATA_AND_PUBLISH_SUCCESS:
      return {
        ...state,
        projectLoading: false,
      };

    case actionTypes.FETCH_TEMPLATE_LIST_SUCCESS:
    case actionTypes.FETCH_TEMPLATES_BY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.payload,
      };

    case actionTypes.FETCH_PROJECT_AVATAR_URL_REQUEST:
      return {
        ...state,
        projectImageLoading: true,
      };

    case actionTypes.FETCH_PROJECT_AVATAR_URL_FAILURE:
      return {
        ...state,
        projectImageLoading: false,
        error: action.payload,
      };

    case actionTypes.FETCH_PROJECT_AVATAR_URL_SUCCESS:
      return {
        ...state,
        projectImageLoading: false,
        ...(state.selectedProject.id && {
          selectedProject: {
            ...state.selectedProject,
            avatarUrl: action.payload.avatarUrl,
          },
        }),
        data: state.data.map(item => {
          if (item.id === action.payload.projectId) {
            return {
              ...item,
              avatarUrl: action.payload.avatarUrl,
            };
          }
          return item;
        }),
      };

    case actionTypes.REMOVE_PROJECT_AVATAR_SUCCESS:
      return {
        ...state,
        projectImageLoading: false,
        ...(state.selectedProject.id && {
          selectedProject: {
            ...state.selectedProject,
            avatarUrl: null,
            icon: null,
          },
        }),
        data: state.data.map(item => {
          if (item.id === action.payload.projectId) {
            return {
              ...item,
              avatarUrl: null,
              icon: null,
            };
          }
          return item;
        }),
      };

    case actionTypes.CREATE_PROJECT_AVATAR_SUCCESS: {
      const { project, avatarUrl } = action.payload;

      return {
        ...state,
        ...(state.selectedProject.id && {
          selectedProject: {
            ...state.selectedProject,
            avatarId: project.avatarId,
            avatarUrl,
          },
        }),
        data: state.data.map(item => {
          if (item.id === project.id) {
            return {
              ...project,
              avatarUrl,
            };
          }
          return item;
        }),
        loading: false,
        error: null,
      };
    }

    case actionTypes.UPDATE_PROJECT_COMPLETING:
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          completing: action.payload,
        },
      };

    case actionTypes.CREATE_RELATED_TASKS_REQUEST:
      return {
        ...state,
        taskLoading: true,
      };

    case actionTypes.CREATE_RELATED_TASKS_FAILURE:
      return {
        ...state,
        taskLoading: false,
      };

    case actionTypes.CREATE_RELATED_TASKS_SUCCESS:
      return {
        ...state,
        taskLoading: false,
      };

    case actionTypes.CHANGE_TEMPLATE_TYPE_SUCCESS: {
      const { id, updatedAt, sop, checklist, typePublication } = action.payload;

      return {
        ...state,
        loading: false,
        homeViewData: state.homeViewData.map(item => {
          if (item.id === id) {
            return {
              ...item,
              sop,
              checklist,
              updatedAt,
              typePublication,
            };
          }

          return item;
        }),
        selectedProject: {
          ...state.selectedProject,
          sop,
          checklist,
          updatedAt,
          typePublication,
        },
      };
    }

    case projectsActions.ASSIGN_TEAM_TO_PROJECT_REQUEST:
    case projectsActions.DISMISS_TEAM_FROM_PROJECT_REQUEST:
      return {
        ...state,
        teamsLoading: true,
      };

    case projectsActions.ASSIGN_TEAM_TO_PROJECT_SUCCESS:
      return {
        ...state,
        teamsLoading: false,
        selectedProject: {
          ...state.selectedProject,
          connectedTeamUsers: [
            ...(state.selectedProject.connectedTeamUsers
              ? state.selectedProject.connectedTeamUsers
              : []),
            ...(action.payload.teamUsers
              ? action.payload.teamUsers.map(user => ({
                  ...user,
                  teamMember: true,
                }))
              : []),
          ],
          conectedTeams: [
            ...(state.selectedProject.conectedTeams
              ? state.selectedProject.conectedTeams
              : []),
            {
              id: action.payload.team.id,
              title: action.payload.team.title,
            },
          ],
        },
      };

    case projectsActions.DISMISS_TEAM_FROM_PROJECT_SUCCESS:
      return {
        ...state,
        teamsLoading: false,
        selectedProject: {
          ...state.selectedProject,
          conectedTeams: state.selectedProject.conectedTeams.filter(
            item => item.id !== action.payload.team.id
          ),
          ...(state.selectedProject.connectedTeamUsers && {
            connectedTeamUsers: state.selectedProject.connectedTeamUsers.filter(
              teamUser =>
                !action.payload.dismissedTeamUsers.find(
                  dismissedTeamUser => dismissedTeamUser.id === teamUser.id
                )
            ),
          }),
        },
      };

    case projectsActions.ASSIGN_TEAM_TO_PROJECT_FAILURE:
    case projectsActions.DISMISS_TEAM_FROM_PROJECT_FAILURE:
      return {
        ...state,
        teamsLoading: false,
        error: action.payload,
      };

    case projectsActions.UPDATE_PROJECT_SECTION_TASK_DATES:
      return sectionCases.updateSectionTaskDates();

    case projectsActions.GET_SIMPLE_PROJECTS_DATA_SUCCESS:
      return {
        ...state,
        simpleProjectsData: action.payload,
        loading: false,
      };

    case projectsActions.ADD_INVITE_USER_TO_PROJECT_STATE:
      return {
        ...state,
        connectedUsers: [
          ...state.connectedUsers,
          { ...action.payload.user, id: action.payload.user.userId },
        ],
      };
    // SUBTASKS LOGIC
    case projectsActions.FETCH_TASK_SUBTASKS_REQUEST:
    case projectsActions.FETCH_PROJECT_SUBTASKS_REQUEST:
      return {
        ...state,
        subtasksLoading: true,
      };

    case projectsActions.FETCH_TASK_SUBTASKS_FAILURE:
    case projectsActions.FETCH_PROJECT_SUBTASKS_FAILURE:
      return {
        ...state,
        subtasksLoading: false,
        error: action.payload,
      };

    case projectsActions.FETCH_TASK_SUBTASKS_SUCCESS:
    case projectsActions.REMOVE_TASK_SUBTASKS:
      return taskCases.handleTaskSubtasks();

    case projectsActions.FETCH_PROJECT_SUBTASKS_SUCCESS:
      return projectCases.handleProjectSubtasks('add');

    case projectsActions.REMOVE_PROJECT_SUBTASKS:
      return projectCases.handleProjectSubtasks('remove');

    case projectsActions.CREATE_SUBTASK_SUCCESS:
      return taskCases.handleCreatingSubtask();

    case projectsActions.MOVE_SUBTASK_LOCAL:
      return taskCases.moveSubtaskLocal();

    // END SUBTASKS LOGIC

    case projectsActions.CHECK_EXISTENCE_OF_PROJECT_SUCCESS:
      return {
        ...state,
        isProjectExist: action.payload,
        loading: false,
      };

    case projectsActions.CHECK_EXISTENCE_OF_PROJECT_FAILURE:
      return {
        ...state,
        isProjectExist: false,
        loading: false,
      };

    case projectsActions.RESET_EXISTENCE_OF_PROJECT_DATE:
      return {
        ...state,
        isProjectExist: null,
        loading: false,
      };

    case projectsActions.GET_PROJECTS_TAGS_SUCCESS:
      return {
        ...state,
        availableFilterTags: {
          ...state.availableFilterTags,
          projects: [...action.payload],
          templates: [...state.availableFilterTags.templates],
          archived: [...state.availableFilterTags.archived],
        },
      };

    case projectsActions.GET_TEMPLATES_TAGS_SUCCESS:
      return {
        ...state,
        availableFilterTags: {
          ...state.availableFilterTags,
          projects: [...state.availableFilterTags.projects],
          templates: [...action.payload],
          archived: [...state.availableFilterTags.archived],
        },
      };

    case projectsActions.GET_ARCHIVED_PROJECTS_TAGS_SUCCESS:
      return {
        ...state,
        availableFilterTags: {
          ...state.availableFilterTags,
          projects: [...state.availableFilterTags.projects],
          templates: [...state.availableFilterTags.templates],
          archived: [...action.payload],
        },
      };

    default:
      return state;
  }
}
