import { createSelector } from 'reselect';

const userRoleTitles = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Manager',
  4: 'Team Member',
};

const meSelector = state => state.me;
const meUserSelector = state => state.me.user;
const companiesSelector = state => state.companies;

export const selectMe = createSelector(meSelector, me => me.user);
export const selectUploadFileNotification = createSelector(
  meSelector,
  me => me.user.notifications.uploadFile
);
export const selectMeLoading = createSelector(meSelector, me => me.loading);

export const selectMeId = createSelector(meSelector, me => me.user?.id);

export const selectMeSections = createSelector(
  meSelector,
  me => me.user.sections
);

export const selectMeAaccessibleSections = createSelector(
  meSelector,
  me => me.user.accessibleSections
);

export const selectMeTableFields = createSelector(
  meSelector,
  me => me.filters.businessTableFields || []
);

export const selectMeFilters = createSelector(
  meSelector,
  me => me.filters || []
);

export const selectMeBusinessColumnsFilter = createSelector(
  meSelector,
  me => me.filters?.businessTableFields || []
);

export const selectProjectsColumns = createSelector(
  meSelector,
  me => me.filters.projectsColumns || []
);

export const selectMeProjectFilters = createSelector(meSelector, me => {
  return Object.fromEntries(
    Object.entries({ ...me.filters }).filter(
      ([_, val]) => val.type === 'project'
    )
  );
});

export const selectActiveColumnsByType = createSelector(
  meSelector,
  ({ filters }) =>
    _.memoize(type => {
      return filters[type]
        ? Object.values(filters[type]).filter(val => val.active)
        : [];
    })
);

export const userRoles = {
  superAdmin: 1,
  admin: 2,
  manager: 3,
  teamMember: 4,
};

export const getMe = state => state.me.user;

// export const meRole = state => {
//   const selectedCompany = state.companies.selectedCompany;
//   const user = state.me.user;
//   let userRole = null;

//   user.roles &&
//     user.roles.forEach(role => {
//       if (role.companyId === selectedCompany) {
//         userRole = role.role.id;
//       }
//     });

//   if (!userRole) {
//     userRole = userRoles.superAdmin;
//   }

//   return userRole;
// };

export const meRole = createSelector(
  meSelector,
  companiesSelector,
  ({ user }, { selectedCompany }) => {
    let userRole = null;

    if (user.roles && selectedCompany) {
      for (let i = 0; i < user.roles.length; i++) {
        if (user.roles[i].companyId === selectedCompany) {
          userRole = user.roles[i].role.id;
          break;
        }
      }
    }

    if (
      !userRole &&
      user.roles &&
      user.roles.find(item => item.role.id === userRoles.superAdmin)
    ) {
      return userRoles.superAdmin;
    }

    return userRole;
  }
);

export const selectMeRole = createSelector(
  meRole,
  userRoleId => userRoleTitles[userRoleId]
);
export const selectMeFullName = createSelector(meUserSelector, user => [
  user.firstName,
  user.lastName,
]);
export const selectMeEmail = createSelector(meUserSelector, user => user.email);
export const selectMeAvatar = createSelector(
  meUserSelector,
  user => user.avatar
);
