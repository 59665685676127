import Geocode from 'react-geocode';
import { apiKey } from './google_map.config';

const getCounty = ({ types, formatted_address }, zipCode) => {
  if (types.includes('postal_code')) {
    return formatted_address
      .split(zipCode)[0]
      .replace(/[^\w\s]|([A-Z]){2}/g, '')
      .trim();
  }
};

Geocode.setApiKey(apiKey);
Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();
Geocode.setLanguage('en');
Geocode.setRegion('us');

Geocode.getCoordinatesFromAddress = async ({
  address = '',
  city = '',
  state = '',
  zipCode = '',
}) => {
  if (address && city) {
    const { results, status } = await Geocode.fromAddress(
      `${address} ${city} ${state} ${zipCode}`
    );

    return status == 'OK'
      ? results[0].geometry.location
      : console.log('geocodeErrorStatus: ', status);
  } else {
    console.log('gmap coordinates: address is empty');
  }
};

Geocode.getCountyFromZipCode = async ({ zipCode = '' }) => {
  if (zipCode) {
    const { results, status } = await Geocode.fromAddress(`${zipCode}`);

    return status == 'OK'
      ? getCounty(results[0], zipCode)
      : console.log('geocodeErrorStatus: ', status);
  } else {
    console.log('gmap coordinates: address is empty');
  }
};

export default Geocode;
