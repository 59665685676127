import actionTypes from './actionTypes';

export default {
  getChecklistsRequest: () => ({
    type: actionTypes.GET_CHECKLISTS_REQUEST,
  }),
  getChecklistsSuccess: data => ({
    type: actionTypes.GET_CHECKLISTS_SUCCESS,
    payload: data,
  }),
  getChecklistsFailure: error => ({
    type: actionTypes.GET_CHECKLISTS_FAILURE,
    payload: error,
  }),

  createChecklistRequest: data => ({
    type: actionTypes.CREATE_CHECKLIST_REQUEST,
    data,
  }),
  createChecklistSuccess: checklist => ({
    type: actionTypes.CREATE_CHECKLIST_SUCCESS,
    payload: checklist,
  }),
  createChecklistFailure: error => ({
    type: actionTypes.CREATE_CHECKLIST_FAILURE,
    payload: error,
  }),

  getRecentViewsRequest: () => ({
    type: actionTypes.GET_RECENT_VIEWS_REQUEST,
  }),
  getRecentViewsSuccess: data => ({
    type: actionTypes.GET_RECENT_VIEWS_SUCCESS,
    payload: data,
  }),
  getRecentViewsFailure: error => ({
    type: actionTypes.GET_RECENT_VIEWS_FAILURE,
    payload: error,
  }),

  uploadChecklistIconRequest: data => ({
    type: actionTypes.UPLOAD_CHECKLIST_ICON_REQUEST,
    data,
  }),
  uploadChecklistIconSuccess: data => ({
    type: actionTypes.UPLOAD_CHECKLIST_ICON_SUCCESS,
    payload: data,
  }),
  uploadChecklistIconFailure: error => ({
    type: actionTypes.UPLOAD_CHECKLIST_ICON_FAILURE,
    payload: error,
  }),

  removeChecklistIconRequest: id => ({
    type: actionTypes.REMOVE_CHECKLIST_ICON_REQUEST,
    id,
  }),
  removeChecklistIconSuccess: data => ({
    type: actionTypes.REMOVE_CHECKLIST_ICON_SUCCESS,
    payload: data,
  }),
  removeChecklistIconFailure: error => ({
    type: actionTypes.REMOVE_CHECKLIST_ICON_FAILURE,
    payload: error,
  }),

  moveChecklistRequest: data => ({
    type: actionTypes.MOVE_CHECKLIST_REQUEST,
    data,
  }),
  moveChecklistSuccess: data => ({
    type: actionTypes.MOVE_CHECKLIST_SUCCESS,
    payload: data,
  }),
  moveChecklistFailure: error => ({
    type: actionTypes.MOVE_CHECKLIST_FAILURE,
    payload: error,
  }),
  moveChecklistLocal: data => ({
    type: actionTypes.MOVE_CHECKLIST_LOCAL,
    payload: data,
  }),
  renameChecklistRequest: data => ({
    type: actionTypes.RENAME_CHECKLIST_REQUEST,
    data,
  }),
  renameChecklistSuccess: data => ({
    type: actionTypes.RENAME_CHECKLIST_SUCCESS,
    payload: data,
  }),
  renameChecklistFailure: data => ({
    type: actionTypes.RENAME_CHECKLIST_FAILURE,
    payload: data,
  }),
};
