import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Mails from '@iso/redux/mail/reducer';
import Calendar from '@iso/redux/calendar/reducer';
import Box from '@iso/redux/box/reducer';
import Notes from '@iso/redux/notes/reducer';
import Todos from '@iso/redux/todos/reducer';
import Contacts from '@iso/redux/contacts/reducer';
import Cards from '@iso/redux/card/reducer';
import Chat from '@iso/redux/chat/reducers';
import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Invoices from '@iso/redux/invoice/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import YoutubeSearch from '@iso/redux/youtubeSearch/reducers';
import Articles from '@iso/redux/articles/reducers';
import Investors from '@iso/redux/investors/reducers';
import scrumBoard from '@iso/redux/scrumBoard/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';
import githubSearch from '@iso/redux/githubSearch/reducers';
import quiz from '@iso/redux/quiz/reducer';

import dashboard from '@iso/redux/dashboard/reducer';
import projects from '@iso/redux/projects/reducer';
import businesses from '@iso/redux/businesses/reducer';
import users from '@iso/redux/users/reducer';
import resources from '@iso/redux/resources/reducer';
import companies from '@iso/redux/companies/reducer';
import notifications from '@iso/redux/notifications/reducer';
import me from '@iso/redux/me/reducer';
import dropbox from '@iso/redux/dropbox/reducer';
import license from '@iso/redux/license/reducer';
import metrc from '@iso/redux/metrc/reducer';
import actions from '@iso/redux/auth/actions';
import googleDrive from '@iso/redux/googleDrive/reducer';
import pushNotifications from '@iso/redux/pushNotifications/reducer';
import projectsCalendar from '@iso/redux/projectsCalendar/reducer';

const appReducer = combineReducers({
  dashboard,
  businesses,
  projects,
  users,
  resources,
  companies,
  notifications,
  me,
  dropbox,
  license,
  metrc,
  googleDrive,
  pushNotifications,
  projectsCalendar,

  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  Articles,
  Investors,
  scrumBoard,
  modal,
  drawer,
  profile,
  githubSearch,
  quiz,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === actions.LOGOUT_SUCCESS) state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
