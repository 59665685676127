import _ from 'lodash';

import resourcesActions from './actions';
import resourceActions from './actions';
import resourceCaseFunctions from './resourceCaseFunctions';

const initialState = {
  data: null,
  loading: false,
  loadingList: false,
  loadingRoot: false,
  loadingAssign: false,
  error: null,
  uploadError: false,
  moveError: null,
  removePending: false,
  loadingFile: false,
  setNewResource: false,
  uploadNewResource: false,
  selectedResource: null,
  fileUsers: [],
  resourceUrl: null,
  filesCount: 0,
  selectedBusinessResource: null,
  // loadingResource: false,
  downloadResource: false,
  isResourceDetailVisible: false,
  previewImages: [],
  uploadedFile: null,
  resourceUploading: false,
  previewImageErrors: [],
  userPermissionsWarningData: null,
};

export default function users(state = initialState, action) {
  const resourceCases = resourceCaseFunctions(state, action);

  switch (action.type) {
    case resourceActions.DOWNLOAD_RESOURCE_REQUEST:
    case resourceActions.CREATE_FOLDER_REQUEST:
      return { ...state, loading: true, error: null };

    case resourceActions.GET_RESOURCE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        loadingList: action.loading,
        loadingRoot: true,
        error: null,
      };

    case resourceActions.UPLOAD_RESOURCE_ERROR_RESET:
      return {
        ...state,
        uploadError: false,
      };
    case resourceActions.UPLOAD_RESOURCE_REQUEST:
      return {
        ...state,
        loadingFile: true,
        error: null,
        // ANCHOR
        // setNewResource: true,
        uploadedFile: null,
      };

    case resourceActions.GET_RESOURCE_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload || [],
        loading: false,
        loadingList: false,
        loadingRoot: false,
        error: null,
      };

    case resourceActions.DOWNLOAD_RESOURCE_SUCCESS:
      return { ...state, loading: false };

    case resourceActions.DOWNLOAD_RESOURCE_FAILURE:
    case resourceActions.UPLOAD_RESOURCE_FAILURE:
    case resourceActions.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        loading: false,
        loadingFile: false,
        error: action.payload,
        uploadError:
          action.type === resourceActions.UPLOAD_RESOURCE_FAILURE
            ? !!action.payload
            : undefined,
      };

    case resourceActions.MOVE_RESOURCE_REQUEST:
      return { ...state, loading: true, loadingRoot: true, moveError: null };

    case resourceActions.MOVE_RESOURCE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loadingRoot: false,
      };

    case resourceActions.MOVE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        loadingRoot: false,
        moveError: action.payload,
      };

    case resourceActions.GET_RESOURCE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingFile: false,
        loadingList: false,
        loadingRoot: false,
        error: action.payload,
      };

    case resourceActions.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        setNewResource: false,
      };

    case resourceActions.SET_NEW_RESOURCE:
      return {
        ...state,
        setNewResource: action.payload,
      };

    case resourceActions.SELECT_RESOURCE:
      return {
        ...state,
        selectedResource: action.payload,
        resourceUrl: null,
      };

    case resourceActions.SELECT_BUSINESS_RESOURCE:
      return {
        ...state,
        selectedBusinessResource: action.payload,
      };

    case resourceActions.UPLOAD_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        // ANCHOR
        // setNewResource: false,
        data: [...state.data, action.payload],
        uploadedFile: action.payload,
        loadingFile: false,
      };

    case resourceActions.REMOVE_RESOURCE_REQUEST:
      return { ...state, removePending: true, error: null };

    case resourceActions.REMOVE_RESOURCE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(file => file.id !== action.payload),
        selectedResource: null,
        removePending: false,
        error: null,
      };

    case resourceActions.REMOVE_RESOURCE_FAILURE:
      return {
        ...state,
        removePending: false,
        error: action.payload,
      };

    case resourceActions.UPLOAD_NEW_RESOURCE:
      return { ...state, uploadNewResource: action.payload };

    case resourceActions.UPDATE_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case resourceActions.UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        selectedResource: action.payload,
        loading: false,
        error: null,
      };

    case resourceActions.UPDATE_RESOURCE_FAILURE:
    case resourceActions.GET_FILE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case resourceActions.GET_FILE_USERS_SUCCESS:
      return {
        ...state,
        fileUsers: action.payload,
      };

    case resourceActions.CONNECT_FILE_USER_REQUEST:
    case resourceActions.DISCONNECT_FILE_USER_REQUEST:
      return { ...state, loading: true, error: null };

    case resourceActions.CONNECT_FILE_USER_SUCCESS:
      return {
        ...state,
        fileUsers: action.payload,
        loading: false,
      };

    case resourceActions.DISCONNECT_FILE_USER_SUCCESS:
      return {
        ...state,
        fileUsers: action.payload,
        loading: false,
      };

    case resourceActions.GET_RESOURCE_URL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case resourceActions.GET_RESOURCE_URL_SUCCESS:
      return {
        ...state,
        resourceUrl: action.payload,
        loading: false,
      };

    case resourceActions.CONNECT_FILE_USER_FAILURE:
    case resourceActions.DISCONNECT_FILE_USER_FAILURE:
    case resourceActions.GET_RESOURCE_URL_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case resourceActions.GET_RESOURCE_SUMMARY_REQUEST:
      return {
        ...state,
        loadingList: true,
      };

    case resourceActions.GET_RESOURCE_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingList: false,
        filesCount: action.payload,
      };

    case resourceActions.GET_RESOURCE_SUMMARY_FAILURE:
      return {
        ...state,
        loadingList: false,
        erro: action.payload,
      };

    case resourceActions.ALLOW_ACCESS_TO_RESOURCE_REQUEST:
    case resourceActions.RESTRICT_ACCESS_TO_RESOURCE_REQUEST:
    case resourceActions.RESTRICT_TEAM_ACCESS_TO_RESOURCE_REQUEST:
    case resourceActions.ALLOW_TEAM_ACCESS_TO_RESOURCE_REQUEST:
      return {
        ...state,
        loadingRoot: true,
      };

    case resourceActions.ALLOW_ACCESS_TO_RESOURCE_FAILURE:
    case resourceActions.RESTRICT_ACCESS_TO_RESOURCE_FAILURE:
    case resourceActions.ALLOW_TEAM_ACCESS_TO_RESOURCE_FAILURE:
    case resourceActions.RESTRICT_TEAM_ACCESS_TO_RESOURCE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingRoot: false,
      };

    case resourceActions.RESTRICT_ACCESS_TO_RESOURCE_SUCCESS:
    case resourceActions.ALLOW_ACCESS_TO_RESOURCE_SUCCESS:
    case resourceActions.ALLOW_TEAM_ACCESS_TO_RESOURCE_SUCCESS:
    case resourceActions.RESTRICT_TEAM_ACCESS_TO_RESOURCE_SUCCESS:
      return resourceCases.updateResources();

    // case resourceActions.DOWNLOAD_RESOURCE_BY_EXTERNAL_USER_REQUEST:
    //   return {
    //     ...state,
    //     loadingResource: true,
    //   };

    // case resourceActions.DOWNLOAD_RESOURCE_BY_EXTERNAL_USER_SUCCESS:
    // case resourceActions.DOWNLOAD_RESOURCE_BY_EXTERNAL_USER_FAILURE:
    //   return {
    //     ...state,
    //     loadingResource: false,
    //   };

    case resourceActions.SHARE_RESOURCE_WITH_EXTERNAL_USER_REQUEST:
      return {
        ...state,
        downloadResource: true,
      };

    case resourceActions.SHARE_RESOURCE_WITH_EXTERNAL_USER_SUCCESS:
    case resourceActions.SHARE_RESOURCE_WITH_EXTERNAL_USER_FAILURE:
      return {
        ...state,
        downloadResource: false,
      };

    case resourcesActions.SET_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    case resourceActions.SET_DEFAULT_RESOURCE_URL:
      return {
        ...state,
        resourceUrl: null,
      };

    case resourceActions.SET_RESOURCE_DETAIL_VISIBILITY:
      return {
        ...state,
        isResourceDetailVisible: action.payload,
      };

    case resourcesActions.SET_FILE_PREVIEW_IMAGES_SACCESS:
      return {
        ...state,
        previewImages: [...state.previewImages, action.payload],
      };

    case resourcesActions.SET_FILE_PREVIEW_IMAGES_FAILURE:
      return {
        ...state,
        previewImageErrors: [...state.previewImageErrors, action.payload],
      };

    case resourcesActions.CLEAR_PREVIEW_IMAGE_ERRORS:
      return {
        ...state,
        previewImageErrors: [],
      };

    case resourcesActions.UPLOAD_NEW_RESOURCE_WITH_CALLBACK_REQUEST:
      return {
        ...state,
        resourceUploading: true,
      };

    case resourcesActions.UPLOAD_NEW_RESOURCE_WITH_CALLBACK_SUCCESS:
    case resourcesActions.UPLOAD_NEW_RESOURCE_WITH_CALLBACK_FAILURE:
      return {
        ...state,
        resourceUploading: false,
      };

    case resourcesActions.SET_UPLOADED_FILE_TO_DEFAULT:
      return {
        ...state,
        uploadedFile: null,
      };

    case resourcesActions.SET_RESOURCE_PERMISSION_SUCCESS:
      return {
        ...state,
        data: state.data.map(resource => {
          if (resource.id === action.payload.resourceId) {
            return {
              ...resource,
              isPrivate: !resource.isPrivate,
            };
          }

          return resource;
        }),
      };

    case resourcesActions.SHARE_RESOURCE_TO_USER_OR_TEAM_REQUEST:
    case resourcesActions.RESTRICT_RESOURCE_TO_USER_OR_TEAM_REQUEST:
      return { ...state, loadingAssign: true };

    case resourcesActions.SHARE_RESOURCE_TO_USER_OR_TEAM_SUCCESS:
      return {
        ...state,
        loadingAssign: false,
        data: state.data.map(resource => {
          if (resource.id === action.payload.resourceId) {
            const shareToUser = _.has(action.payload, 'user');

            return {
              ...resource,
              ...(shareToUser
                ? {
                    connectedUsers: [
                      ...(resource.connectedUsers
                        ? resource.connectedUsers
                        : []),
                      { user: action.payload.user },
                    ],
                  }
                : {
                    connectedTeams: [
                      ...(resource.connectedTeams
                        ? resource.connectedTeams
                        : []),
                      { team: action.payload.team },
                    ],
                  }),
            };
          }

          return resource;
        }),
      };

    case resourcesActions.RESTRICT_RESOURCE_TO_USER_OR_TEAM_SUCCESS:
      return {
        ...state,
        loadingAssign: false,
        data: state.data.map(resource => {
          if (resource.id === action.payload.resourceId) {
            const restrictUser = _.has(action.payload, 'userId');

            return {
              ...resource,
              ...(restrictUser
                ? {
                    connectedUsers: resource.connectedUsers.filter(
                      ({ user }) => user.id !== action.payload.userId
                    ),
                  }
                : {
                    connectedTeams: resource.connectedTeams.filter(
                      ({ team }) => team.id !== action.payload.teamId
                    ),
                  }),
            };
          }

          return resource;
        }),
      };

    case resourcesActions.SET_USER_PERMISSIONS_WARNING_DATA:
      return {
        ...state,
        userPermissionsWarningData: action.payload,
      };

    default:
      return state;
  }
}
