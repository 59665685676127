import { put, select } from 'redux-saga/effects';

import { selectDeletedSectionTasks } from '../selectors';
import projectsActions from '../actions';

import { getProjectService } from './helpers';

export default {
  createSection: function* createSection({ data }) {
    try {
      const service = yield getProjectService(data.projectId);
      const response = yield service.createSection(data);

      yield put(
        projectsActions.createSectionSuccess({
          ...response.data.section,
          projectId: data.projectId,
          tasks: [],
        })
      );
    } catch (error) {
      yield put(projectsActions.createSectionFailure(error));
    }
  },

  updateSection: function* updateSection({ data }) {
    try {
      const service = yield getProjectService(data.projectId);
      const response = yield service.updateSection(data);

      yield put(
        projectsActions.updateSectionSuccess({
          ...response.data.section,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.updateSectionFailure(error));
    }
  },

  removeSection: function* removeSection({ data }) {
    try {
      const sectionSelector = yield select(selectDeletedSectionTasks);
      const isUpdatingProject = sectionSelector(data.sectionId);

      const service = yield getProjectService(data.projectId);
      yield service.removeSection(data);

      if (isUpdatingProject) {
        const projectResponse = yield service.getProject(data.projectId);

        yield put(
          projectsActions.getProjectSuccess(
            projectResponse.data.project ||
              projectResponse.data.checklist ||
              projectResponse.data.sop ||
              projectResponse.data.template
          )
        );
      }

      yield put(projectsActions.removeSectionSuccess(data));
    } catch (error) {
      yield put(projectsActions.removeSectionFailure(error));
    }
  },

  moveSection: function*({ data }) {
    try {
      const { projectSection, ...params } = data;
      yield put(projectsActions.moveSectionLocal(projectSection));

      const service = yield getProjectService(data.projectId);

      yield service.moveSection(params);

      yield put(projectsActions.moveSectionSuccess());
    } catch (error) {
      yield put(projectsActions.moveSectionFailure(error));
    }
  },
};
