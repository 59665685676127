const metrcActions = {
  GET_TRANSFERS_REQUEST: 'GET_TRANSFERS_REQUEST',
  GET_TRANSFERS_SUCCESS: 'GET_TRANSFERS_SUCCESS',
  GET_TRANSFERS_FAILURE: 'GET_TRANSFERS_FAILURE',

  UPDATE_TRANSFER_REQUEST: 'UPDATE_TRANSFER_REQUEST',
  UPDATE_TRANSFER_SUCCESS: 'UPDATE_TRANSFER_SUCCESS',
  UPDATE_TRANSFER_FAILURE: 'UPDATE_TRANSFER_FAILURE',

  GET_RECEIPTS_REQUEST: 'GET_RECEIPTS_REQUEST',
  GET_RECEIPTS_SUCCESS: 'GET_RECEIPTS_SUCCESS',
  GET_RECEIPTS_FAILURE: 'GET_RECEIPTS_FAILURE',

  GET_RECEIPT_REQUEST: 'GET_RECEIPT_REQUEST',
  GET_RECEIPT_SUCCESS: 'GET_RECEIPT_SUCCESS',
  GET_RECEIPT_FAILURE: 'GET_RECEIPT_FAILURE',

  GET_PACKAGE_REQUEST: 'GET_PACKAGE_REQUEST',
  GET_PACKAGE_SUCCESS: 'GET_PACKAGE_SUCCESS',
  GET_PACKAGE_FAILURE: 'GET_PACKAGE_FAILURE',

  GET_LABTESTS_REQUEST: 'GET_LABTESTS_REQUEST',
  GET_LABTESTS_SUCCESS: 'GET_LABTESTS_SUCCESS',
  GET_LABTESTS_FAILURE: 'GET_LABTESTS_FAILURE',

  getTransfersRequest: data => ({
    type: metrcActions.GET_TRANSFERS_REQUEST,
    data,
  }),
  getTransfersSuccess: data => ({
    type: metrcActions.GET_TRANSFERS_SUCCESS,
    payload: data,
  }),
  getTransfersFailure: error => ({
    type: metrcActions.GET_TRANSFERS_FAILURE,
    payload: error,
  }),

  updateTransfersRequest: data => ({
    type: metrcActions.UPDATE_TRANSFER_REQUEST,
    data,
  }),
  updateTransfersSuccess: data => ({
    type: metrcActions.UPDATE_TRANSFER_SUCCESS,
    payload: data,
  }),
  updateTransfersFailure: error => ({
    type: metrcActions.UPDATE_TRANSFER_FAILURE,
    payload: error,
  }),

  getReceiptsRequest: data => ({
    type: metrcActions.GET_RECEIPTS_REQUEST,
    data,
  }),
  getReceiptsSuccess: data => ({
    type: metrcActions.GET_RECEIPTS_SUCCESS,
    payload: data,
  }),
  getReceiptsFailure: error => ({
    type: metrcActions.GET_RECEIPTS_FAILURE,
    payload: error,
  }),

  getReceiptRequest: data => ({
    type: metrcActions.GET_RECEIPT_REQUEST,
    data,
  }),
  getReceiptSuccess: data => ({
    type: metrcActions.GET_RECEIPT_SUCCESS,
    payload: data,
  }),
  getReceiptFailure: error => ({
    type: metrcActions.GET_RECEIPT_FAILURE,
    payload: error,
  }),

  getPackageRequest: data => ({
    type: metrcActions.GET_PACKAGE_REQUEST,
    data,
  }),
  getPackageSuccess: data => ({
    type: metrcActions.GET_PACKAGE_SUCCESS,
    payload: data,
  }),
  getPackageFailure: error => ({
    type: metrcActions.GET_PACKAGE_FAILURE,
    payload: error,
  }),

  getLabtestsRequest: data => ({
    type: metrcActions.GET_LABTESTS_REQUEST,
    data,
  }),
  getLabtestsSuccess: data => ({
    type: metrcActions.GET_LABTESTS_SUCCESS,
    payload: data,
  }),
  getLabtestsFailure: error => ({
    type: metrcActions.GET_LABTESTS_FAILURE,
    payload: error,
  }),
};

export default metrcActions;
