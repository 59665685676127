import { put } from 'redux-saga/effects';

import anamiService from '../../../services/anamiService';
import projectsActions from '../actions';

export default {
  getSops: function* getRecentViews() {
    try {
      const response = yield anamiService.sop.getProjectList();

      yield put(projectsActions.getSopsSuccess(response.data.sops));
    } catch (error) {
      yield put(projectsActions.getSopsFailure(error));
    }
  },

  createSop: function* createSop({ data }) {
    try {
      const response = yield anamiService.sop.createProject(data);

      yield put(projectsActions.createProjectSuccess(response.data.sop));
    } catch (error) {
      yield put(projectsActions.createProjectFailure(error));
    }
  },

  assigneeSop: function* createSop({ data }) {
    try {
      const { user, projectId } = data;
      yield user
        ? anamiService.sop.assigneeSop({ projectId, assigneeId: user.id })
        : anamiService.sop.removeAssigneeSop({ projectId, assigneeId: null });

      yield put(projectsActions.assigneeSopSuccess(user));
      yield put(projectsActions.getSopsRequest());
    } catch (error) {
      yield put(projectsActions.assigneeSopFailure(error));
    }
  },

  setSopDueDate: function* setSopDueDate({ data }) {
    try {
      yield anamiService.sop.setSopDueDate(data);

      yield put(projectsActions.setSopDueDateSuccess(data.dueDate));
    } catch (error) {
      yield put(projectsActions.setSopDueDateFailure(error));
    }
  },

  setSopEffectiveDate: function* setSopDueDate({ data }) {
    try {
      yield anamiService.sop.setSopDueDate(data);

      yield put(projectsActions.setSopEffectiveDateSuccess(data));
    } catch (error) {
      yield put(projectsActions.setSopEffectiveDateFailure(error));
    }
  },
};
