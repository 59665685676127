import React from 'react';
import { Icon } from 'antd';
import { FolderIcon, PdfIcon, ExelIcon } from 'img/ImageWrappers';
import icons from 'img/checklist';
import moment from 'moment';

export const getElementAbsoluteHeight = el => {
  el = typeof el === 'string' ? document.querySelector(el) : el;

  const styles = window.getComputedStyle(el);
  const margin =
    parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

  return Math.ceil(el.offsetHeight + margin);
};

export const handleUpgradeCompanyPayment = () => {
  window.open('https://www.greenspacepro.com/upgrade', '_blank');
};

export const getUserRole = (roles, selectedCompany) => {
  const sortedRolesByCreationDate = roles.sort((a, b) =>
    a.id < b.id ? -1 : 1
  );
  const role = roles.find(role => {
    return role.companyId === selectedCompany && !role.businessId;
  });

  if (!role && roles) {
    for (let i = 0; i < roles.length; i++) {
      if (sortedRolesByCreationDate[i].companyId === selectedCompany) {
        return sortedRolesByCreationDate[i].role.role;
      }
    }
  }

  return role && role.role.role;
};

export const downloadFile = (file, headers, fileObject) => {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: headers['content-type'] })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileObject.name);

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
};

export const fileCopy = data => {
  const el = document.createElement('input');
  document.body.append(el);
  el.value = data;
  el.select();
  document.execCommand('copy');
  el.remove(el);
};
export const sortInAlphaOrder = (a, b) => {
  if (a.title) {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
  }
  if (a.name) {
    const titleA = a.name.toLowerCase();
    const titleB = b.name.toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
  }
};

export const getFileIcon = file => {
  if (file && file.type === 'file') {
    const extension = file.name.split('.').pop();
    if (extension === 'pdf') {
      return <PdfIcon fill={file.allowDelete ? '' : '#2F90E9'} />;
    } else if (extension.includes('doc')) {
      return (
        <Icon
          type="file-word"
          className={file.allowDelete ? 'black' : 'disable-delete'}
          style={{ marginRight: '5px' }}
        />
      );
    } else if (extension.includes('xls')) {
      return <ExelIcon fill={file.allowDelete ? '' : '#2F90E9'} />;
    } else {
      return (
        <Icon
          className={file.allowDelete ? 'black' : 'disable-delete'}
          style={{ marginRight: '5px' }}
          type="file-image"
        />
      );
    }
  } else {
    return <FolderIcon fill={file.allowDelete ? 'black' : '#2F90E9'} />;
  }
};

export const getUrlParams = url => {
  return new Map(
    url
      .slice(1)
      .split('&')
      .map(p => p.split('='))
  );
};

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return range;
}

export const getCaretCharacterOffsetWithin = (chars, elem) => {
  if (chars >= 0) {
    const selection = window.getSelection();

    const range = createRange(elem, { count: chars });

    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
};

export const formatLocationAddress = ({
  propertyInformation: { address, city, county, zipCode },
}) => {
  let resAddress = '';
  if (address) resAddress += address;
  if (city) resAddress += resAddress.length ? `, ${city}` : city;
  if (county) resAddress += resAddress.length ? `, ${county}` : county;
  if (zipCode) resAddress += resAddress.length ? `, ${zipCode}` : zipCode;

  return resAddress;
};

export const getId = () => {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

export const getUserInitials = user => {
  let initials = '';
  if (user.firstName) {
    initials += user.firstName.charAt(0).toUpperCase();
  }
  if (user.lastName) {
    initials += user.lastName.charAt(0).toUpperCase();
  }
  return initials;
};

export const getDefaultSteps = () => {
  return [
    {
      id: getId(),
      title: 'Application',
      status: 'Submitted',
      active: true,
      editable: false,
      assignedUsers: [],
      dueDate: null,
    },
    {
      id: getId(),
      title: 'CUP',
      status: 'Preparing',
      active: true,
      editable: false,
      assignedUsers: [],
      dueDate: null,
    },
    {
      id: getId(),
      title: 'Public Hearing',
      status: 'Preparing',
      active: false,
      editable: false,
      assignedUsers: [],
      dueDate: null,
    },
    {
      id: getId(),
      title: 'Building Permit',
      status: 'Preparing',
      active: false,
      editable: false,
      assignedUsers: [],
      dueDate: null,
    },
    {
      id: getId(),
      title: 'Construction',
      status: 'Preparing',
      active: false,
      editable: false,
      assignedUsers: [],
      dueDate: null,
    },
    {
      id: getId(),
      title: 'State License',
      status: 'Preparing',
      active: false,
      editable: false,
      assignedUsers: [],
      dueDate: null,
    },
  ];
};

export const checkResourceName = name => /[\n\r,;\\/]/g.test(name);

export const checkBusinessName = name => /[\\/]/g.test(name);

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
  return re.test(String(email).toLowerCase());
};

export const defineProjectIconName = project => {
  if (project && project.icon) {
    for (let iconName in icons) {
      if (iconName.toUpperCase() === project.icon.toUpperCase()) {
        return icons[iconName];
      }
    }
    return project.icon;
  } else if (project && project.title) {
    const firstTitleWord = project.title.split(' ')[0];
    const wordStartInd = 0;
    const wordEndingInd = firstTitleWord.length - 3;
    const minBaseLength = 6;

    const wordBase = firstTitleWord.substring(wordStartInd, wordEndingInd);

    for (let iconName in icons) {
      const isContainsWordBase =
        wordBase.length >= minBaseLength && iconName.includes(wordBase);
      if (
        firstTitleWord.toUpperCase() === iconName.toUpperCase() ||
        isContainsWordBase
      ) {
        return icons[iconName];
      }
    }
  } else {
    return '';
  }
};

export const isValidProjectId = value => {
  const reg = /^[A-Za-z0-9\-]*$/;
  return reg.test(value) || value === '';
};

//converts the file size from bytes to KB, MB etc..
export const formatBytes = (bytes = 0, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

//get file type from url or name file
export const fileTypeFromLink = (link, type) => {
  if (type === 'folderByLink') return 'folder';
  let result = link?.match(/\.\w+$/);
  if (!result) return 'file';
  return result[0].replace('.', '');
};

//check is image from url or fileType
export const checkIsImage = (url = null, fileType = null) => {
  if (url) return url?.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
  if (fileType) return fileType?.match(/(jpeg|jpg|gif|png|svg)$/) != null;
};

//common sorting config
export const simpleSortConfig = (a, b, type) => {
  switch (type) {
    case 'date':
      if (a === null) return -1;
      if (b === null) return 1;
      if (!Object.keys(a).length) return -1;
      if (!Object.keys(b).length) return 1;

      if (a.date === b.date) return 0;
      if (a.date < b.date) return 1;
      if (a.date > b.date) return -1;
      break;

    case 'string':
      if (a === null || a === '') return -1;
      if (b === null || b === '') return 1;
      const firstValue = a.toLowerCase();
      const secondValue = b.toLowerCase();
      if (firstValue == secondValue) return 0;
      if (firstValue < secondValue) return 1;
      if (firstValue > secondValue) return -1;
      break;

    case 'bool':
      return a === b ? 0 : a ? 1 : -1;

    case 'array': {
      if (a === null || a === '') return -1;
      if (b === null || b === '') return 1;
      const firstValue = a.join().toLowerCase();
      const secondValue = b.join().toLowerCase();
      if (firstValue === secondValue) return 0;
      if (firstValue < secondValue) return 1;
      if (firstValue > secondValue) return -1;
      break;
    }
  }
};

export const mathDates = {
  add: (date = new Date(), days = 0) => {
    const time = new Date(date).getTime() + 864e5 * days;
    return new Date(time);
  },
  set: (type, date, value) => {
    switch (type) {
      case 'day':
        const hasDay = moment(date).daysInMonth() >= value;

        if (hasDay) {
          const dateArray = moment(date)
            .format('M-D-YYYY')
            .split('-');
          dateArray[1] = value;
          return moment(dateArray.join('-'));
        } else return null;

      case 'year':
        const dateArray = moment(date)
          .format('M-D-YYYY')
          .split('-');
        dateArray[2] = value;
        return moment(dateArray.join('-'));
    }
  },
};

export const filterObject = (obj, callback) => {
  const filteredData = Object.entries(obj).filter(callback);

  return Object.fromEntries(filteredData);
};
