import { all, takeEvery, put } from 'redux-saga/effects';

import googleDriveActions from './actions';
import anamiService from '../../services/anamiService';

function* getGoogleDriveAuthUrl() {
  try {
    let response = yield anamiService.getGoogleDriveAuthUrl();

    yield put(
      googleDriveActions.getGoogleDriveAuthUrlSuccess(response.data.authUrl)
    );
  } catch (error) {
    yield put(googleDriveActions.getGoogleDriveAuthUrlFailure(error));
  }
}

function* getGoogleDriveToken({ data }) {
  try {
    yield anamiService.getGoogleDriveToken(data);

    const checkResponse = yield anamiService.checkGoogleDriveToken();

    yield put(
      googleDriveActions.getGoogleDriveTokenSuccess(checkResponse.data)
    );
  } catch (error) {
    yield put(googleDriveActions.getGoogleDriveTokenFailure(error));
  }
}

function* checkGoogleDriveToken() {
  try {
    let response = yield anamiService.checkGoogleDriveToken();

    yield put(googleDriveActions.checkGoogleDriveTokenSuccess(response.data));
  } catch (error) {
    yield put(googleDriveActions.checkGoogleDriveTokenFailure(error));
  }
}

function* initGoogleDriveSync({ data }) {
  try {
    const response = yield anamiService.initGoogleDriveSync(data);
    const syncResponse = yield anamiService.checkGoogleDriveSync({
      id: response.data.id,
    });

    localStorage.setItem('googleDriveProcessId', response.data.id);

    yield put(
      googleDriveActions.initGoogleDriveSyncSuccess({
        id: response.data.id,
        syncData: syncResponse.data,
      })
    );
  } catch (error) {
    yield put(googleDriveActions.initGoogleDriveSyncFailure(error));
  }
}

function* checkGoogleDriveSync({ data }) {
  try {
    let response = yield anamiService.checkGoogleDriveSync(data);

    yield put(googleDriveActions.checkGoogleDriveSyncSuccess(response.data));
  } catch (error) {
    yield put(googleDriveActions.checkGoogleDriveSyncFailure(error));
  }
}

function* disconnectGoogleDrive({ data }) {
  try {
    yield anamiService.disconnectGoogleDrive(data);

    yield put(googleDriveActions.disconnectGoogleDriveSuccess());
  } catch (error) {
    yield put(googleDriveActions.disconnectGoogleDriveFailure(error));
  }
}

function* getGoogleDriveStorage() {
  try {
    const response = yield anamiService.getGoogleDriveStorage();

    yield put(
      googleDriveActions.getGoogleDriveStorageSuccess(
        response.data.sharedDrives
      )
    );
  } catch (error) {
    yield put(googleDriveActions.getGoogleDriveStorageFailure(error));
  }
}

export default function* googleDriveSaga() {
  yield all([
    takeEvery(
      googleDriveActions.GET_GOOGLE_DRIVE_AUTH_URL_REQUEST,
      getGoogleDriveAuthUrl
    ),
  ]);
  yield all([
    takeEvery(
      googleDriveActions.GET_GOOGLE_DRIVE_TOKEN_REQUEST,
      getGoogleDriveToken
    ),
  ]);
  yield all([
    takeEvery(
      googleDriveActions.CHECK_GOOGLE_DRIVE_TOKEN_REQUEST,
      checkGoogleDriveToken
    ),
  ]);
  yield all([
    takeEvery(
      googleDriveActions.INIT_GOOGLE_DRIVE_SYNC_REQUEST,
      initGoogleDriveSync
    ),
  ]);
  yield all([
    takeEvery(
      googleDriveActions.CHECK_GOOGLE_DRIVE_SYNC_REQUEST,
      checkGoogleDriveSync
    ),
  ]);
  yield all([
    takeEvery(
      googleDriveActions.DISCONNECT_GOOGLE_DRIVE_REQUEST,
      disconnectGoogleDrive
    ),
  ]);
  yield takeEvery(
    googleDriveActions.GET_GOOGLE_DRIVE_STORAGE_REQUEST,
    getGoogleDriveStorage
  );
}
