import moment from 'moment';

import { DATE_FORMAT, routes } from './constants';
export default class Segment {
  constructor() {
    this.sessionStart = null;
    this.sessionEnd = null;
    this.userId = null;
  }

  static init() {
    if (window.analytics) {
      return new Segment();
    }

    return null;
  }

  identify(userId, properties = {}) {
    try {
      this.userId = userId;

      window.analytics.identify(userId, properties);
    } catch (error) {
      console.log(`error`, error);
    }
  }

  trackSessionStart(event, properties = {}) {
    try {
      this.sessionStart = moment().format(DATE_FORMAT);

      const eventData = {
        sessionStart: this.sessionStart,
        ...properties,
      };

      window.analytics.track(event, eventData);
    } catch (error) {
      console.log(`error`, error);
    }
  }

  trackSessionEnd(event, properties = {}) {
    try {
      this.sessionEnd = moment().format(DATE_FORMAT);

      const diff = moment
        .utc(moment(this.sessionEnd).diff(moment(this.sessionStart)))
        .format('HH:mm:ss');

      const eventData = {
        sessionEnd: this.sessionEnd,
        sessionStart: this.sessionStart,
        sessionDuration: diff,
        ...properties,
      };

      window.analytics.track(event, eventData);
    } catch (error) {
      console.log(`error`, error);
    }
  }

  trackEvent(event, properties = {}) {
    try {
      const eventData = {
        userId: this.userId,
        ...properties,
      };

      window.analytics.track(event, eventData);
    } catch (error) {
      console.log(`error`, error);
    }
  }

  trackPage(page, properties = {}) {
    try {
      window.analytics.page(routes[page], properties);
    } catch (error) {
      console.log(`error`, error);
    }
  }
}
