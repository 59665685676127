import actions from './actions';

const initialState = {
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_PUSH_NOTIFICATIONS_FLOW_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
