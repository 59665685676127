const businessesActions = {
  FETCH_BUSINESSES_DATA_REQUEST: 'FETCH_BUSINESSES_DATA_REQUEST',
  FETCH_BUSINESSES_DATA_SUCCESS: 'FETCH_BUSINESSES_DATA_SUCCESS',
  FETCH_BUSINESSES_DATA_FAILURE: 'FETCH_BUSINESSES_DATA_FAILURE',

  FILTER_BUSINESSES: 'FILTER_BUSINESSES',

  BUSINESS_SHOW_CREATE_SECTION: 'BUSINESS_SHOW_CREATE_SECTION',
  BUSINESS_HIDE_CREATE_SECTION: 'BUSINESS_HIDE_CREATE_SECTION',

  BUSINESS_ADD_NEW: 'BUSINESS_ADD_NEW',
  BUSINESS_CREATE_REQUEST: 'BUSINESS_CREATE_REQUEST',
  BUSINESS_CREATE_SUCCESS: 'BUSINESS_CREATE_SUCCESS',
  BUSINESS_CREATE_FAILURE: 'BUSINESS_CREATE_FAILURE',

  BUSINESS_GET_REQUEST: 'BUSINESS_GET_REQUEST',
  BUSINESS_GET_SUCCESS: 'BUSINESS_GET_SUCCESS',
  BUSINESS_GET_FAILURE: 'BUSINESS_GET_FAILURE',

  BUSINESS_UPDATE_REQUEST: 'BUSINESS_UPDATE_REQUEST',
  BUSINESS_UPDATE_SUCCESS: 'BUSINESS_UPDATE_SUCCESS',
  BUSINESS_UPDATE_FAILURE: 'BUSINESS_UPDATE_FAILURE',

  BUSINESS_REMOVE_REQUEST: 'BUSINESS_REMOVE_REQUEST',
  BUSINESS_REMOVE_SUCCESS: 'BUSINESS_REMOVE_SUCCESS',
  BUSINESS_REMOVE_FAILURE: 'BUSINESS_REMOVE_FAILURE',

  CREATE_BUSINESS_ATTACHMENT_REQUEST: 'CREATE_BUSINESS_ATTACHMENT_REQUEST',
  CREATE_BUSINESS_ATTACHMENT_SUCCESS: 'CREATE_BUSINESS_ATTACHMENT_SUCCESS',
  CREATE_BUSINESS_ATTACHMENT_FAILURE: 'CREATE_BUSINESS_ATTACHMENT_FAILURE',

  DELETE_BUSINESS_ATTACHMENT_REQUEST: 'DELETE_BUSINESS_ATTACHMENT_REQUEST',
  DELETE_BUSINESS_ATTACHMENT_SUCCESS: 'DELETE_BUSINESS_ATTACHMENT_SUCCESS',
  DELETE_BUSINESS_ATTACHMENT_FAILURE: 'DELETE_BUSINESS_ATTACHMENT_FAILURE',

  CREATE_BUSINESS_AVATAR_REQUEST: 'CREATE_BUSINESS_AVATAR_REQUEST',
  CREATE_BUSINESS_AVATAR_SUCCESS: 'CREATE_BUSINESS_AVATAR_SUCCESS',
  CREATE_BUSINESS_AVATAR_FAILURE: 'CREATE_BUSINESS_AVATAR_FAILURE',

  UPDATE_METRC_KEY_REQUEST: 'UPDATE_METRC_KEY_REQUEST',
  UPDATE_METRC_KEY_SUCCESS: 'UPDATE_METRC_KEY_SUCCESS',
  UPDATE_METRC_KEY_FAILURE: 'UPDATE_METRC_KEY_FAILURE',

  FETCH_BUSINESS_USERS_REQUEST: 'FETCH_BUSINESS_USERS_REQUEST',
  FETCH_BUSINESS_USERS_SUCCESS: 'FETCH_BUSINESS_USERS_SUCCESS',
  FETCH_BUSINESS_USERS_FAILURE: 'FETCH_BUSINESS_USERS_FAILURE',

  CREATE_BUSINESS_USER_REQUEST: 'CREATE_BUSINESS_USER_REQUEST',
  CREATE_BUSINESS_USER_SUCCESS: 'CREATE_BUSINESS_USER_SUCCESS',
  CREATE_BUSINESS_USER_FAILURE: 'CREATE_BUSINESS_USER_FAILURE',

  DELETE_BUSINESS_USER_REQUEST: 'DELETE_BUSINESS_USER_REQUEST',
  DELETE_BUSINESS_USER_SUCCESS: 'DELETE_BUSINESS_USER_SUCCESS',
  DELETE_BUSINESS_USER_FAILURE: 'DELETE_BUSINESS_USER_FAILURE',

  REMOVE_DEFAULT_BUSINESS_DATA: 'REMOVE_DEFAULT_BUSINESS_DATA',

  FETCH_BUSINESS_LICENSES_REQUEST: 'FETCH_BUSINESS_LICENSES_REQUEST',
  FETCH_BUSINESS_LICENSES_SUCCESS: 'FETCH_BUSINESS_LICENSES_SUCCESS',
  FETCH_BUSINESS_LICENSES_FAILURE: 'FETCH_BUSINESS_LICENSES_FAILURE',

  FETCH_BUSINESS_ASSIGNED_LICENSES_REQUEST:
    'FETCH_BUSINESS_ASSIGNED_LICENSES_REQUEST',
  FETCH_BUSINESS_ASSIGNED_LICENSES_SUCCESS:
    'FETCH_BUSINESS_ASSIGNED_LICENSES_SUCCESS',
  FETCH_BUSINESS_ASSIGNED_LICENSES_FAILURE:
    'FETCH_BUSINESS_ASSIGNED_LICENSES_FAILURE',

  ASSIGN_BUSINESS_LICENSE_REQUEST: 'ASSIGN_BUSINESS_LICENSE_REQUEST',
  ASSIGN_BUSINESS_LICENSE_SUCCESS: 'ASSIGN_BUSINESS_LICENSE_SUCCESS',
  ASSIGN_BUSINESS_LICENSE_FAILURE: 'ASSIGN_BUSINESS_LICENSE_FAILURE',

  REMOVE_ASSIGNED_LICENSE_REQUEST: 'REMOVE_ASSIGNED_LICENSE_REQUEST',
  REMOVE_ASSIGNED_LICENSE_SUCCESS: 'REMOVE_ASSIGNED_LICENSE_SUCCESS',
  REMOVE_ASSIGNED_LICENSE_FAILURE: 'REMOVE_ASSIGNED_LICENSE_FAILURE',

  TOGGLE_BUSINESS_EXPORT_CSV_MODAL_STATE:
    'TOGGLE_BUSINESS_EXPORT_CSV_MODAL_STATE',

  EXPORT_BUSINESSES_SCV_REQUEST: 'EXPORT_BUSINESSES_SCV_REQUEST',
  EXPORT_BUSINESSES_SCV_SUCCESS: 'EXPORT_BUSINESSES_SCV_SUCCESS',
  EXPORT_BUSINESSES_SCV_FAILURE: 'EXPORT_BUSINESSES_SCV_FAILURE',

  CREATE_CUSTOM_BUSINESS_FIELD_SUCCESS: 'CREATE_CUSTOM_BUSINESS_FIELD_SUCCESS',
  STOP_BUSINESS_LOADING: 'STOP_BUSINESS_LOADING',

  CREATE_BUSINESS_FILE_REQUEST: 'CREATE_BUSINESS_FILE_REQUEST',
  CREATE_BUSINESS_FILE_SUCCESS: 'CREATE_BUSINESS_FILE_SUCCESS',
  CREATE_BUSINESS_FILE_FAILURE: 'CREATE_BUSINESS_FILE_FAILURE',

  FETCH_ARCHIVED_BUSINESSES_REQUEST: 'FETCH_ARCHIVED_BUSINESSES_REQUEST',
  FETCH_ARCHIVED_BUSINESSES_SUCCESS: 'FETCH_ARCHIVED_BUSINESSES_SUCCESS',
  FETCH_ARCHIVED_BUSINESSES_FAILURE: 'FETCH_ARCHIVED_BUSINESSES_FAILURE',

  ARCHIVE_BUSINESS_REQUEST: 'ARCHIVE_BUSINESS_REQUEST',
  ARCHIVE_BUSINESS_SUCCESS: 'ARCHIVE_BUSINESS_SUCCESS',
  ARCHIVE_BUSINESS_FAILURE: 'ARCHIVE_BUSINESS_FAILURE',

  UNZIP_BUSINESS_REQUEST: 'UNZIP_BUSINESS_REQUEST',
  UNZIP_BUSINESS_SUCCESS: 'UNZIP_BUSINESS_SUCCESS',
  UNZIP_BUSINESS_FAILURE: 'UNZIP_BUSINESS_FAILURE',

  FETCH_BUSINESS_STRUCTURE_REQUEST: 'FETCH_BUSINESS_STRUCTURE_REQUEST',
  FETCH_BUSINESS_STRUCTURE_SUCCESS: 'FETCH_BUSINESS_STRUCTURE_SUCCESS',
  FETCH_BUSINESS_STRUCTURE_FAILURE: 'FETCH_BUSINESS_STRUCTURE_FAILURE',

  CLEAR_BUSINESS_STRUCTURE: 'CLEAR_BUSINESS_STRUCTURE',

  FETCH_BUSINESS_COMMENTS_REQUEST: 'FETCH_BUSINESS_COMMENTS_REQUEST',
  FETCH_BUSINESS_COMMENTS_SUCCESS: 'FETCH_BUSINESS_COMMENTS_SUCCESS',
  FETCH_BUSINESS_COMMENTS_FAILURE: 'FETCH_BUSINESS_COMMENTS_FAILURE',

  CREATE_BUSINESS_COMMENT_REQUEST: 'CREATE_BUSINESS_COMMENT_REQUEST',
  CREATE_BUSINESS_COMMENT_SUCCESS: 'CREATE_BUSINESS_COMMENT_SUCCESS',
  CREATE_BUSINESS_COMMENT_FAILURE: 'CREATE_BUSINESS_COMMENT_FAILURE',

  UPDATE_BUSINESS_COMMENT_REQUEST: 'UPDATE_BUSINESS_COMMENT_REQUEST',
  UPDATE_BUSINESS_COMMENT_SUCCESS: 'UPDATE_BUSINESS_COMMENT_SUCCESS',
  UPDATE_BUSINESS_COMMENT_FAILURE: 'UPDATE_BUSINESS_COMMENT_FAILURE',

  REMOVE_BUSINESS_COMMENT_REQUEST: 'REMOVE_BUSINESS_COMMENT_REQUEST',
  REMOVE_BUSINESS_COMMENT_SUCCESS: 'REMOVE_BUSINESS_COMMENT_SUCCESS',
  REMOVE_BUSINESS_COMMENT_FAILURE: 'REMOVE_BUSINESS_COMMENT_FAILURE',

  SET_ACTIVE_BUSINESS_TO_STORE: 'SET_ACTIVE_BUSINESS_TO_STORE',

  FETCH_BUSINESS_AUDIT_LOGS_REQUEST: 'FETCH_BUSINESS_AUDIT_LOGS_REQUEST',
  FETCH_BUSINESS_AUDIT_LOGS_SUCCESS: 'FETCH_BUSINESS_AUDIT_LOGS_SUCCESS',
  FETCH_BUSINESS_AUDIT_LOGS_FAILURE: 'FETCH_BUSINESS_AUDIT_LOGS_FAILURE',

  READ_BUSINESS_COMMENTS_REQUEST: 'READ_BUSINESS_COMMENTS_REQUEST',
  READ_BUSINESS_COMMENTS_SUCCESS: 'READ_BUSINESS_COMMENTS_SUCCESS',
  READ_BUSINESS_COMMENTS_FAILURE: 'READ_BUSINESS_COMMENTS_FAILURE',

  SET_BUSINESS_MODE: 'SET_BUSINESS_MODE',

  GET_BUSINESS_AVATAR_URL_REQUEST: 'GET_BUSINESS_AVATAR_URL_REQUEST',
  GET_BUSINESS_AVATAR_URL_SUCCESS: 'GET_BUSINESS_AVATAR_URL_SUCCESS',
  GET_BUSINESS_AVATAR_URL_FAILURE: 'GET_BUSINESS_AVATAR_URL_FAILURE',

  GET_BUSINESSES_AVATAR_URL_REQUEST: 'GET_BUSINESSES_AVATAR_URL_REQUEST',
  GET_BUSINESSES_AVATAR_URL_SUCCESS: 'GET_BUSINESSES_AVATAR_URL_SUCCESS',
  GET_BUSINESSES_AVATAR_URL_FAILURE: 'GET_BUSINESSES_AVATAR_URL_FAILURE',

  ADD_TO_FAVORITES_REQUEST: 'ADD_TO_FAVORITES_REQUEST',
  ADD_TO_FAVORITES_SUCCESS: 'ADD_TO_FAVORITES_SUCCESS',
  ADD_TO_FAVORITES_FAILURE: 'ADD_TO_FAVORITES_FAILURE',

  REMOVE_FROM_FAVORITES_REQUEST: 'REMOVE_FROM_FAVORITES_REQUEST',
  REMOVE_FROM_FAVORITES_SUCCESS: 'REMOVE_FROM_FAVORITES_SUCCESS',
  REMOVE_FROM_FAVORITES_FAILURE: 'REMOVE_FROM_FAVORITES_FAILURE',

  SET_BUSINESS_HAS_NEW_SECTION_FLAG: 'SET_BUSINESS_HAS_NEW_SECTION_FLAG',

  ASSIGN_TEAM_TO_BUSINESS_REQUEST: 'ASSIGN_TEAM_TO_BUSINESS_REQUEST',
  ASSIGN_TEAM_TO_BUSINESS_SUCCESS: 'ASSIGN_TEAM_TO_BUSINESS_SUCCESS',
  ASSIGN_TEAM_TO_BUSINESS_FAILURE: 'ASSIGN_TEAM_TO_BUSINESS_FAILURE',

  DISMISS_TEAM_FROM_BUSINESS_REQUEST: 'DISMISS_TEAM_FROM_BUSINESS_REQUEST',
  DISMISS_TEAM_FROM_BUSINESS_SUCCESS: 'DISMISS_TEAM_FROM_BUSINESS_SUCCESS',
  DISMISS_TEAM_FROM_BUSINESS_FAILURE: 'DISMISS_TEAM_FROM_BUSINESS_FAILURE',

  CONNECT_USER_TO_BUSINESS_REQUEST: 'CONNECT_USER_TO_BUSINESS_REQUEST',
  CONNECT_USER_TO_BUSINESS_SUCCESS: 'CONNECT_USER_TO_BUSINESS_SUCCESS',
  CONNECT_USER_TO_BUSINESS_FAILURE: 'CONNECT_USER_TO_BUSINESS_FAILURE',

  DISCONNECT_USER_FROM_BUSINESS_REQUEST:
    'DISCONNECT_USER_FROM_BUSINESS_REQUEST',
  DISCONNECT_USER_FROM_BUSINESS_SUCCESS:
    'DISCONNECT_USER_FROM_BUSINESS_SUCCESS',
  DISCONNECT_USER_FROM_BUSINESS_FAILURE:
    'DISCONNECT_USER_FROM_BUSINESS_FAILURE',
  SET_BUSINESS_SORTER_DATA: 'SET_BUSINESS_SORTER_DATA',
  // TODO remove after updating businesse logic
  GET_BUSINESS_AVATAR_PREVIEW_SUCCESS: 'GET_BUSINESS_AVATAR_PREVIEW_SUCCESS',

  SET_BUSINESS_FILTERS: 'SET_BUSINESS_FILTERS',

  GET_SIMPLE_BUSINESSES_DATA_REQUEST: 'GET_SIMPLE_BUSINESSES_DATA_REQUEST',
  GET_SIMPLE_BUSINESSES_DATA_SUCCESS: 'GET_SIMPLE_BUSINESSES_DATA_SUCCESS',
  GET_SIMPLE_BUSINESSES_DATA_FAILURE: 'GET_SIMPLE_BUSINESSES_DATA_FAILURE',

  ADD_INVITE_USER_TO_STATE: 'ADD_INVITE_USER_TO_STATE',

  REVOKE_INVITE_USER_FROM_BUSINESS: 'REVOKE_INVITE_USER_FROM_BUSINESS',
  REVOKE_INVITE_USER_FROM_BUSINESS_SUCCESS:
    'REVOKE_INVITE_USER_FROM_BUSINESS_SUCCESS',
  REVOKE_INVITE_USER_FROM_BUSINESS_FAILURE:
    'REVOKE_INVITE_USER_FROM_BUSINESS_FAILURE',

  SET_BUSINESSES_FILTER: 'SET_BUSINESSES_FILTER',

  SAVE_BUSINESSES_FILTER_REQUEST: 'SAVE_BUSINESSES_FILTER_REQUEST',
  SAVE_BUSINESSES_FILTER_SUCCESS: 'SAVE_BUSINESSES_FILTER_SUCCESS',
  SAVE_BUSINESSES_FILTER_ERROR: 'SAVE_BUSINESSES_FILTER_ERROR',

  GET_BUSINESSES_FILTERS_INIT_DATA_REQUEST:
    'GET_BUSINESSES_FILTERS_INIT_DATA_REQUEST',
  GET_BUSINESSES_FILTERS_INIT_DATA_SUCCESS:
    'GET_BUSINESSES_FILTERS_INIT_DATA_SUCCESS',
  GET_BUSINESSES_FILTERS_INIT_DATA_FAILURE:
    'GET_BUSINESSES_FILTERS_INIT_DATA_FAILURE',

  SET_ACTIVE_FILTER: 'SET_ACTIVE_FILTER',

  // SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',

  RESET_FILTERS_AND_QUERY_PARAMS: 'RESET_FILTERS_AND_QUERY_PARAMS',

  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',

  SET_DEFAULT_ERROR_STATE: 'SET_DEFAULT_ERROR_STATE',

  setSearchValue: data => ({
    type: businessesActions.SET_SEARCH_VALUE,
    payload: data,
  }),

  resetFiltersAndQueryParams: () => ({
    type: businessesActions.RESET_FILTERS_AND_QUERY_PARAMS,
  }),

  // setQueryParams: data => ({
  //   type: businessesActions.SET_QUERY_PARAMS,
  //   payload: data,
  // }),

  setActiveFilter: data => ({
    type: businessesActions.SET_ACTIVE_FILTER,
    payload: data,
  }),

  getBusinessFilterInitDataRequest: () => ({
    type: businessesActions.GET_BUSINESSES_FILTERS_INIT_DATA_REQUEST,
  }),

  getBusinessFilterInitDataSuccess: data => ({
    type: businessesActions.GET_BUSINESSES_FILTERS_INIT_DATA_SUCCESS,
    payload: data,
  }),

  getBusinessFilterInitDataFailure: error => ({
    type: businessesActions.GET_BUSINESSES_FILTERS_INIT_DATA_FAILURE,
    payload: error,
  }),

  saveBusinessesFilter: data => ({
    type: businessesActions.SAVE_BUSINESSES_FILTER_REQUEST,
    payload: data,
  }),

  saveBusinessesFilterSuccess: data => ({
    type: businessesActions.SAVE_BUSINESSES_FILTER_SUCCESS,
    payload: data,
  }),

  saveBusinessesFilterError: error => ({
    type: businessesActions.SAVE_BUSINESSES_FILTER_SUCCESS,
    payload: error,
  }),

  setBusinessesFilter: data => ({
    type: businessesActions.SET_BUSINESSES_FILTER,
    payload: data,
  }),

  revokeInviteUserFromBusiness: data => ({
    type: businessesActions.REVOKE_INVITE_USER_FROM_BUSINESS,
    payload: data,
  }),

  revokeInviteUserFromBusinessSuccess: () => ({
    type: businessesActions.REVOKE_INVITE_USER_FROM_BUSINESS_SUCCESS,
  }),

  revokeInviteUserFromBusinessFailure: error => ({
    type: businessesActions.REVOKE_INVITE_USER_FROM_BUSINESS_FAILURE,
    payload: error,
  }),

  addInviteUserToState: data => ({
    type: businessesActions.ADD_INVITE_USER_TO_STATE,
    payload: data,
  }),

  getSimpleBusinessesDataRequest: () => ({
    type: businessesActions.GET_SIMPLE_BUSINESSES_DATA_REQUEST,
  }),

  getSimpleBusinessesDataSuccess: data => ({
    type: businessesActions.GET_SIMPLE_BUSINESSES_DATA_SUCCESS,
    payload: data,
  }),

  getSimpleBusinessesDataFailure: error => ({
    type: businessesActions.GET_SIMPLE_BUSINESSES_DATA_FAILURE,
    payload: error,
  }),

  setBusinessSorterData: payload => ({
    type: businessesActions.SET_BUSINESS_SORTER_DATA,
    payload,
  }),

  // fetch data list
  fetchBusinessData: data => ({
    type: businessesActions.FETCH_BUSINESSES_DATA_REQUEST,
    data,
  }),
  fetchBusinessDataSuccess: businesses => ({
    type: businessesActions.FETCH_BUSINESSES_DATA_SUCCESS,
    payload: businesses,
  }),
  fetchBusinessDataFailure: error => ({
    type: businessesActions.FETCH_BUSINESSES_DATA_FAILURE,
    payload: error,
  }),
  // filter
  filterBusinesses: filter => ({
    type: businessesActions.FILTER_BUSINESSES,
    filter,
  }),
  // show-hide section
  showCreateSection: payload => ({
    type: businessesActions.BUSINESS_SHOW_CREATE_SECTION,
    payload,
  }),
  businessHideCreateSection: business => ({
    type: businessesActions.BUSINESS_HIDE_CREATE_SECTION,
    business,
  }),

  // add new business
  businessAddNew: data => ({
    type: businessesActions.BUSINESS_ADD_NEW,
    data,
  }),
  businessCreate: data => ({
    type: businessesActions.BUSINESS_CREATE_REQUEST,
    data,
  }),
  businessCreateSuccess: business => ({
    type: businessesActions.BUSINESS_CREATE_SUCCESS,
    payload: business,
  }),
  businessCreateFailure: error => ({
    type: businessesActions.BUSINESS_CREATE_FAILURE,
    payload: error,
  }),

  // get busniess by id
  businessGetRequest: id => ({
    type: businessesActions.BUSINESS_GET_REQUEST,
    id,
  }),
  businessGetSuccess: business => ({
    type: businessesActions.BUSINESS_GET_SUCCESS,
    payload: business,
  }),
  businessGetFailure: error => ({
    type: businessesActions.BUSINESS_GET_FAILURE,
    payload: error,
  }),

  // update
  businessUpdate: business => ({
    type: businessesActions.BUSINESS_UPDATE_REQUEST,
    business,
  }),
  businessUpdateSuccess: business => ({
    type: businessesActions.BUSINESS_UPDATE_SUCCESS,
    payload: business,
  }),
  businessUpdateFailure: error => ({
    type: businessesActions.BUSINESS_UPDATE_FAILURE,
    payload: error,
  }),

  // remove business
  businessRemoveRequest: id => ({
    type: businessesActions.BUSINESS_REMOVE_REQUEST,
    id,
  }),
  businessRemoveSuccess: data => ({
    type: businessesActions.BUSINESS_REMOVE_SUCCESS,
    payload: data,
  }),
  businessRemoveFailure: error => ({
    type: businessesActions.BUSINESS_REMOVE_FAILURE,
    payload: error,
  }),

  // create attachment
  createBusinessAttachment: data => ({
    type: businessesActions.CREATE_BUSINESS_ATTACHMENT_REQUEST,
    data,
  }),
  createBusinessAttachmentSuccess: attachmentId => ({
    type: businessesActions.CREATE_BUSINESS_ATTACHMENT_SUCCESS,
    payload: attachmentId,
  }),
  createBusinessAttachmentFailure: error => ({
    type: businessesActions.CREATE_BUSINESS_ATTACHMENT_FAILURE,
    payload: error,
  }),

  // delete atachment
  deleteBusinessAttachment: data => ({
    type: businessesActions.DELETE_BUSINESS_ATTACHMENT_REQUEST,
    data,
  }),
  deleteBusinessAttachmentSuccess: data => ({
    type: businessesActions.DELETE_BUSINESS_ATTACHMENT_SUCCESS,
    payload: data,
  }),
  deleteBusinessAttachmentFailure: error => ({
    type: businessesActions.DELETE_BUSINESS_ATTACHMENT_FAILURE,
    payload: error,
  }),

  // create attachment
  createBusinessAvatarReqeust: data => ({
    type: businessesActions.CREATE_BUSINESS_AVATAR_REQUEST,
    data,
  }),
  createBusinessAvatarSuccess: attachmentId => ({
    type: businessesActions.CREATE_BUSINESS_AVATAR_SUCCESS,
    payload: attachmentId,
  }),
  createBusinessAvatarFailure: error => ({
    type: businessesActions.CREATE_BUSINESS_AVATAR_FAILURE,
    payload: error,
  }),

  updateMetrcKeyReqeust: data => ({
    type: businessesActions.UPDATE_METRC_KEY_REQUEST,
    data,
  }),
  updateMetrcKeySuccess: attachmentId => ({
    type: businessesActions.UPDATE_METRC_KEY_SUCCESS,
    payload: attachmentId,
  }),
  updateMetrcKeyFailure: error => ({
    type: businessesActions.UPDATE_METRC_KEY_FAILURE,
    payload: error,
  }),

  fetchBusinessUsersRequest: id => ({
    type: businessesActions.FETCH_BUSINESS_USERS_REQUEST,
    id,
  }),
  fetchBusinessUsersSuccess: data => ({
    type: businessesActions.FETCH_BUSINESS_USERS_SUCCESS,
    payload: data,
  }),
  fetchBusinessUsersFailure: data => ({
    type: businessesActions.FETCH_BUSINESS_USERS_FAILURE,
    payload: data,
  }),

  createBusinessUserRequest: data => ({
    type: businessesActions.CREATE_BUSINESS_USER_REQUEST,
    data,
  }),
  createBusinessUserSuccess: data => ({
    type: businessesActions.CREATE_BUSINESS_USER_SUCCESS,
    payload: data,
  }),
  createBusinessUserFailure: data => ({
    type: businessesActions.CREATE_BUSINESS_USER_FAILURE,
    payload: data,
  }),

  deleteBusinessUserRequest: data => ({
    type: businessesActions.DELETE_BUSINESS_USER_REQUEST,
    data,
  }),
  deleteBusinessUserSuccess: data => ({
    type: businessesActions.DELETE_BUSINESS_USER_SUCCESS,
    payload: data,
  }),
  deleteBusinessUserFailure: data => ({
    type: businessesActions.DELETE_BUSINESS_USER_FAILURE,
    payload: data,
  }),

  removeDefaultBusinessData: () => ({
    type: businessesActions.REMOVE_DEFAULT_BUSINESS_DATA,
  }),

  fetchBusinessLicensesRequest: () => ({
    type: businessesActions.FETCH_BUSINESS_LICENSES_REQUEST,
  }),
  fetchBusinessLicensesSuccess: data => ({
    type: businessesActions.FETCH_BUSINESS_LICENSES_SUCCESS,
    payload: data,
  }),
  fetchBusinessLicensesFailure: data => ({
    type: businessesActions.FETCH_BUSINESS_LICENSES_FAILURE,
    payload: data,
  }),

  fetchBusinessAssignedLicensesRequest: data => ({
    type: businessesActions.FETCH_BUSINESS_ASSIGNED_LICENSES_REQUEST,
    data,
  }),
  fetchBusinessAssignedLicensesSuccess: data => ({
    type: businessesActions.FETCH_BUSINESS_ASSIGNED_LICENSES_SUCCESS,
    payload: data,
  }),
  fetchBusinessAssignedLicensesFailure: data => ({
    type: businessesActions.FETCH_BUSINESS_ASSIGNED_LICENSES_FAILURE,
    payload: data,
  }),

  assignBusinessLicenseRequest: data => ({
    type: businessesActions.ASSIGN_BUSINESS_LICENSE_REQUEST,
    data,
  }),
  assignBusinessLicenseSuccess: data => ({
    type: businessesActions.ASSIGN_BUSINESS_LICENSE_SUCCESS,
    payload: data,
  }),
  assignBusinessLicenseFailure: data => ({
    type: businessesActions.ASSIGN_BUSINESS_LICENSE_FAILURE,
    payload: data,
  }),

  removeAssignedLicenseRequest: data => ({
    type: businessesActions.REMOVE_ASSIGNED_LICENSE_REQUEST,
    data,
  }),
  removeAssignedLicenseSuccess: data => ({
    type: businessesActions.REMOVE_ASSIGNED_LICENSE_SUCCESS,
    payload: data,
  }),
  removeAssignedLicenseFailure: data => ({
    type: businessesActions.REMOVE_ASSIGNED_LICENSE_FAILURE,
    payload: data,
  }),

  changeExportCSVModalState: data => ({
    type: businessesActions.TOGGLE_BUSINESS_EXPORT_CSV_MODAL_STATE,
    payload: data,
  }),

  exportSCVRequest: data => ({
    type: businessesActions.EXPORT_BUSINESSES_SCV_REQUEST,
    data,
  }),
  exportSCVSuccess: data => ({
    type: businessesActions.EXPORT_BUSINESSES_SCV_SUCCESS,
    payload: data,
  }),
  exportSCVFailure: error => ({
    type: businessesActions.EXPORT_BUSINESSES_SCV_FAILURE,
    payload: error,
  }),

  createCustomFieldSuccess: data => ({
    type: businessesActions.CREATE_CUSTOM_BUSINESS_FIELD_SUCCESS,
    payload: data,
  }),

  stopBusinessLoading: payload => ({
    type: businessesActions.STOP_BUSINESS_LOADING,
    payload,
  }),

  createBusinessFileRequest: data => ({
    type: businessesActions.CREATE_BUSINESS_FILE_REQUEST,
    data,
  }),
  createBusinessFileSuccess: data => ({
    type: businessesActions,
    payload: data,
  }),
  createBusinessFileFailure: error => ({
    type: businessesActions.CREATE_BUSINESS_FILE_FAILURE,
    payload: error,
  }),

  fetchArchivedBusinesses: data => ({
    type: businessesActions.FETCH_ARCHIVED_BUSINESSES_REQUEST,
    payload: data,
  }),
  fetchArchivedBusinessesSuccess: data => ({
    type: businessesActions.FETCH_ARCHIVED_BUSINESSES_SUCCESS,
    payload: data,
  }),
  fetchArchivedBusinessesFailure: data => ({
    type: businessesActions.FETCH_BUSINESSES_DATA_FAILURE,
    payload: data,
  }),

  archiveBusinessRequest: data => ({
    type: businessesActions.ARCHIVE_BUSINESS_REQUEST,
    payload: data,
  }),
  archiveBusinessSuccess: data => ({
    type: businessesActions.ARCHIVE_BUSINESS_SUCCESS,
    payload: data,
  }),
  archiveBusinessFailure: error => ({
    type: businessesActions.ARCHIVE_BUSINESS_FAILURE,
    payload: error,
  }),

  unzipBusinessRequest: data => ({
    type: businessesActions.UNZIP_BUSINESS_REQUEST,
    payload: data,
  }),
  unzipBusinessSuccess: data => ({
    type: businessesActions.UNZIP_BUSINESS_SUCCESS,
    payload: data,
  }),
  unzipBusinessFailure: error => ({
    type: businessesActions.UNZIP_BUSINESS_FAILURE,
    payload: error,
  }),

  fetchBusinessStructureRequest: data => ({
    type: businessesActions.FETCH_BUSINESS_STRUCTURE_REQUEST,
    data,
  }),
  fetchBusinessStructureSuccess: data => ({
    type: businessesActions.FETCH_BUSINESS_STRUCTURE_SUCCESS,
    payload: data,
  }),
  fetchBusinessStructureFailure: error => ({
    type: businessesActions.FETCH_BUSINESS_STRUCTURE_FAILURE,
    payload: error,
  }),

  clearBusinessStructure: error => ({
    type: businessesActions.CLEAR_BUSINESS_STRUCTURE,
    payload: error,
  }),

  fetchBusinessComments: data => ({
    type: businessesActions.FETCH_BUSINESS_COMMENTS_REQUEST,
    data,
  }),
  fetchBusinessCommentsSuccess: data => ({
    type: businessesActions.FETCH_BUSINESS_COMMENTS_SUCCESS,
    payload: data,
  }),
  fetchBusinessCommentsFailure: error => ({
    type: businessesActions.FETCH_BUSINESS_COMMENTS_FAILURE,
    payload: error,
  }),

  createBusinessComment: data => ({
    type: businessesActions.CREATE_BUSINESS_COMMENT_REQUEST,
    data,
  }),
  createBusinessCommentSuccess: data => ({
    type: businessesActions.CREATE_BUSINESS_COMMENT_SUCCESS,
    payload: data,
  }),
  createBusinessCommentFailure: error => ({
    type: businessesActions.CREATE_BUSINESS_COMMENT_FAILURE,
    payload: error,
  }),

  updateBusinessComment: data => ({
    type: businessesActions.UPDATE_BUSINESS_COMMENT_REQUEST,
    data,
  }),
  updateBusinessCommentSuccess: data => ({
    type: businessesActions.UPDATE_BUSINESS_COMMENT_SUCCESS,
    payload: data,
  }),
  updateBusinessCommentFailure: error => ({
    type: businessesActions.UPDATE_BUSINESS_COMMENT_FAILURE,
    payload: error,
  }),

  removeBusinessComment: data => ({
    type: businessesActions.REMOVE_BUSINESS_COMMENT_REQUEST,
    data,
  }),
  removeBusinessCommentSuccess: data => ({
    type: businessesActions.REMOVE_BUSINESS_COMMENT_SUCCESS,
    payload: data,
  }),
  removeBusinessCommentFailure: error => ({
    type: businessesActions.REMOVE_BUSINESS_COMMENT_FAILURE,
    payload: error,
  }),

  setActiveBusinessToStore: data => ({
    type: businessesActions.SET_ACTIVE_BUSINESS_TO_STORE,
    payload: data,
  }),

  fetchBusinessAuditLogsRequest: data => ({
    type: businessesActions.FETCH_BUSINESS_AUDIT_LOGS_REQUEST,
    data,
  }),
  fetchBusinessAuditLogsSuccess: data => ({
    type: businessesActions.FETCH_BUSINESS_AUDIT_LOGS_SUCCESS,
    payload: data,
  }),
  fetchBusinessAuditLogsFailure: error => ({
    type: businessesActions.FETCH_BUSINESS_AUDIT_LOGS_FAILURE,
    payload: error,
  }),

  readBusinessComments: data => ({
    type: businessesActions.READ_BUSINESS_COMMENTS_REQUEST,
    data,
  }),
  readBusinessCommentsSuccess: data => ({
    type: businessesActions.READ_BUSINESS_COMMENTS_SUCCESS,
    payload: data,
  }),
  readBusinessCommentsFailure: error => ({
    type: businessesActions.READ_BUSINESS_COMMENTS_FAILURE,
    payload: error,
  }),

  setBusinessMode: mode => ({
    type: businessesActions.SET_BUSINESS_MODE,
    payload: mode,
  }),

  getBusinessAvatarUrlRequest: data => ({
    type: businessesActions.GET_BUSINESS_AVATAR_URL_REQUEST,
    data,
  }),
  getBusinessAvatarUrlSuccess: data => ({
    type: businessesActions.GET_BUSINESS_AVATAR_URL_SUCCESS,
    payload: data,
  }),
  getBusinessAvatarUrlFailure: data => ({
    type: businessesActions.GET_BUSINESS_AVATAR_URL_FAILURE,
    payload: data,
  }),

  getBusinessesAvatarUrlRequest: data => ({
    type: businessesActions.GET_BUSINESSES_AVATAR_URL_REQUEST,
    data,
  }),
  // TODO remove after updating businesse logic
  getBusinessesAvatarUrlSuccess: data => ({
    type: businessesActions.GET_BUSINESSES_AVATAR_URL_SUCCESS,
    payload: data,
  }),
  getBusinessesAvatarUrlFailure: data => ({
    type: businessesActions.GET_BUSINESSES_AVATAR_URL_FAILURE,
    payload: data,
  }),
  // TODO remove after updating businesse logic
  getBusineseAvatarPreviewSuccess: data => ({
    type: businessesActions.GET_BUSINESS_AVATAR_PREVIEW_SUCCESS,
    payload: data,
  }),

  addToFavorites: data => ({
    type: businessesActions.ADD_TO_FAVORITES_REQUEST,
    data,
  }),
  addToFavoritesSuccess: data => ({
    type: businessesActions.ADD_TO_FAVORITES_SUCCESS,
    payload: data,
  }),
  addToFavoritesFailure: error => ({
    type: businessesActions.ADD_TO_FAVORITES_FAILURE,
    payload: error,
  }),

  removeFromFavorites: data => ({
    type: businessesActions.REMOVE_FROM_FAVORITES_REQUEST,
    data,
  }),
  removeFromFavoritesSuccess: data => ({
    type: businessesActions.REMOVE_FROM_FAVORITES_SUCCESS,
    payload: data,
  }),
  removeFromFavoritesFailure: error => ({
    type: businessesActions.REMOVE_FROM_FAVORITES_FAILURE,
    payload: error,
  }),

  setBusinessHasNewSectionFlag: payload => ({
    type: businessesActions.SET_BUSINESS_HAS_NEW_SECTION_FLAG,
    payload,
  }),

  assignTeam: data => ({
    type: businessesActions.ASSIGN_TEAM_TO_BUSINESS_REQUEST,
    data,
  }),
  assignTeamSuccess: data => ({
    type: businessesActions.ASSIGN_TEAM_TO_BUSINESS_SUCCESS,
    payload: data,
  }),
  assignTeamFailure: error => ({
    type: businessesActions.ASSIGN_TEAM_TO_BUSINESS_FAILURE,
    payload: error,
  }),

  dismissTeam: data => ({
    type: businessesActions.DISMISS_TEAM_FROM_BUSINESS_REQUEST,
    data,
  }),
  dismissTeamSuccess: data => ({
    type: businessesActions.DISMISS_TEAM_FROM_BUSINESS_SUCCESS,
    payload: data,
  }),
  dismissTeamFailure: error => ({
    type: businessesActions.DISMISS_TEAM_FROM_BUSINESS_FAILURE,
    payload: error,
  }),

  connectUser: data => ({
    type: businessesActions.CONNECT_USER_TO_BUSINESS_REQUEST,
    data,
  }),
  connectUserSuccess: user => ({
    type: businessesActions.CONNECT_USER_TO_BUSINESS_SUCCESS,
    payload: user,
  }),
  connectUserFailure: error => ({
    type: businessesActions.CONNECT_USER_TO_BUSINESS_FAILURE,
    payload: error,
  }),

  disconnectUser: data => ({
    type: businessesActions.DISCONNECT_USER_FROM_BUSINESS_REQUEST,
    data,
  }),
  disconnectUserSuccess: data => ({
    type: businessesActions.DISCONNECT_USER_FROM_BUSINESS_SUCCESS,
    payload: data,
  }),
  disconnectUserFailure: error => ({
    type: businessesActions.DISCONNECT_USER_FROM_BUSINESS_FAILURE,
    payload: error,
  }),

  setBusinessFilters: data => ({
    type: businessesActions.SET_BUSINESS_FILTERS,
    payload: data,
  }),

  setDefaultErrorState: () => ({
    type: businessesActions.SET_DEFAULT_ERROR_STATE,
  }),
};

export default businessesActions;
