export default (state, action) => ({
  getSpecifications: () => {
    return {
      ...state,
      specifications: {
        ...action.payload,
        taskStatuses: [
          {
            key: 'toDo',
            value: action.payload.taskStatuses.toDo,
            color: '#8377cc',
          },
          {
            key: 'doing',
            value: action.payload.taskStatuses.doing,
            color: '#ffa300',
          },
          {
            key: 'FF3600',
            value: action.payload.taskStatuses.stuck || 'Stuck',
            color: '#eb5a46',
          },
          {
            key: 'inReview',
            value: action.payload.taskStatuses.inReview,
            color: '#00b7e7',
          },
          {
            key: 'done',
            value: action.payload.taskStatuses.done,
            color: '#5bd700',
          },
          {
            key: 'notApplicable',
            value: action.payload.taskStatuses.notApplicable,
            color: '#788195',
          },
        ],
        taskPriorities: [
          {
            key: 'low',
            value: action.payload.taskPriorities.low,
            color: '#8377CC',
          },
          {
            key: 'medium',
            value: action.payload.taskPriorities.medium,
            color: '#ffa300',
          },
          {
            key: 'high',
            value: action.payload.taskPriorities.high,
            color: '#ff3600',
          },
        ],
        stepStatuses: [
          {
            key: 'default',
            value: action.payload.stepStatuses.default,
            color: '#eaeaea',
          },
          {
            key: 'preparing',
            value: action.payload.stepStatuses.preparing,
            color: '#8DACF5',
          },
          {
            key: 'inProgress',
            value: action.payload.stepStatuses.inProgress,
            color: '#FA58AC',
          },
          {
            key: 'inReview',
            value: action.payload.stepStatuses.inReview,
            color: '#FDB62F',
          },
          {
            key: 'submitted',
            value: action.payload.stepStatuses.submitted,
            color: '#A66EF8',
          },
          {
            key: 'approved',
            value: action.payload.stepStatuses.approved,
            color: '#4CD719',
          },
          {
            key: 'completed',
            value: action.payload.stepStatuses.completed,
            color: '#4CD719',
          },
          {
            key: 'accepted',
            value: action.payload.stepStatuses.accepted,
            color: '#4CD719',
          },
          {
            key: 'denied',
            value: action.payload.stepStatuses.denied,
            color: '#333333',
          },
        ],
      },
    };
  },
});
