import companiesActions from './actions';

const initialState = {
  data: [],
  newCompany: false,
  selectedCompany: null,
  users: [],
  loading: true,
  error: null,
  endTrialDate: null,
  trialData: {},
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case companiesActions.GET_COMPANY_LIST_REQUEST:
    case companiesActions.CREATE_COMPANY_REQUEST:
    case companiesActions.REMOVE_COMPANY_REQUEST:
    case companiesActions.UPDATE_COMPANY_REQUEST:
    case companiesActions.GET_COMPANY_USERS_REQUEST:
    case companiesActions.UPGRADE_COMPANY_PAYMENT_REQUEST:
      return { ...state, loading: true, error: null };

    case companiesActions.GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };

    case companiesActions.CREATE_COMPANY_FAILURE:
    case companiesActions.GET_COMPANY_LIST_FAILURE:
    case companiesActions.REMOVE_COMPANY_FAILURE:
    case companiesActions.UPDATE_COMPANY_FAILURE:
    case companiesActions.GET_COMPANY_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        users: [],
      };

    case companiesActions.ADD_COMPANY:
      return {
        ...state,
        newCompany: action.payload || false,
      };

    case companiesActions.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        newCompany: false,
        loading: false,
        error: null,
      };

    case companiesActions.REMOVE_COMPANY_SUCCESS:
      return {
        ...state,
        data: state.data.filter(x => x.id !== action.payload),
        loading: false,
        error: null,
      };

    case companiesActions.SELECT_COMPANY_SUCCESS:
      localStorage.setItem('selectedCompany', action.payload);
      return { ...state, selectedCompany: action.payload };

    case companiesActions.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map(company => {
          if (company.id === action.payload.id) return action.payload;
          return company;
        }),
      };

    case companiesActions.GET_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };

    case companiesActions.UPGRADE_COMPANY_PAYMENT_SUCCESS:
    case companiesActions.UPGRADE_COMPANY_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case companiesActions.SET_COMPANY_END_TRIAL_DATE:
      return {
        ...state,
        endTrialDate: action.payload,
      };

    case companiesActions.CHECK_COMPANY_TRIAL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        trialData: action.payload,
      };

    case companiesActions.SET_COMPANY_TRIAL_DATA_TO_DEFAULT:
      return {
        ...state,
        trialData: {},
      };

    case companiesActions.CHECK_COMPANY_TRIAL_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
