import Api from './api';
import axios from 'axios';
import config from '@iso/config/site.config';
const { apiUrl, apiUrl_v2 } = config;

import projectsService from './projectsService';

export default {
  resendVerifyEmail: data => {
    const api = new Api();
    return api.query('post', 'auth/re-sending-email', data);
  },

  inviteUserWithEmail: data => {
    const api = new Api();
    return api.query('post', 'users/invitedUserByEmail', data);
  },

  getInviteEmail: data => {
    const api = new Api();
    return api.query('post', 'auth/getInviteEmail', data);
  },

  checkInvitedUser: data => {
    const api = new Api();
    return api.query('post', 'auth/checkInvitedUser', data);
  },

  getInviteUserLink: data => {
    const api = new Api();
    return api.query('post', 'users/invitedUserByLink', data);
  },

  createPasswordForInviteUser: data => {
    const api = new Api();
    return api.query('post', 'auth/createPasswordForInviteUser', data);
  },

  createLinkInviteUser: data => {
    const api = new Api();
    return api.query('post', 'auth/inviteByLink', data);
  },
  revokeInviteUserFromBusiness: data => {
    const api = new Api();
    return api.query('post', 'revokeInviyeUser', data);
  },

  login: data => {
    const api = new Api();
    return api.query('post', `auth/login`, data);
  },

  checkAuthorization: () => {
    const api = new Api();
    return api.query('get', `auth/check`);
  },

  updateToken: data => {
    const api = new Api();
    return api.query('put', `me/token`, data);
  },

  sendResetPassword: data => {
    const api = new Api();
    return api.query('post', `auth/password/reset`, data);
  },

  resetPassword: ({ code, ...data }) => {
    const api = new Api();
    return api.query('post', `auth/password/reset/${code}`, data);
  },

  signUp: ({ code, ...data }) => {
    const api = new Api();
    return api.query('post', `auth/sign-up/${code}`, data);
  },

  createAccount: data => {
    const api = new Api();
    return api.query('post', 'auth/create-account', data);
  },

  verifyAccount: data => {
    const api = new Api();
    return api.query('post', 'auth/verify', data);
  },

  createPasswordAndCompany: data => {
    const api = new Api();
    return api.query('post', 'auth/create-password-and-company', data);
  },

  checkSignUpCode: ({ link }) => {
    const api = new Api();
    return api.query('get', `auth/validate/${link}`);
  },

  getUserList: () => {
    const api = new Api();
    return api.query('get', `users`);
  },

  getUser: () => {
    const api = new Api();
    return api.query('get', `me`);
  },
  // getUser: id => {
  //   const api = new Api();
  //   return api.query('get', `users/${id}`);
  // },

  getMe: () => {
    const api = new Api();
    return api.query('get', `me`);
  },

  getMeFilters: () => {
    const api = new Api();
    return api.query('get', 'me/filters');
  },

  updateMeFilters: data => {
    const api = new Api();
    return api.query('post', 'me/filters', data);
  },

  updateMe: data => {
    const api = new Api();
    return data.agreementAccepted !== undefined
      ? api.query('put', `me/agreement`, data)
      : api.query('put', `me`, data);
  },

  getAgreement: () => {
    const api = new Api();
    return api.query('get', `me/agreement`);
  },

  getDropboxAuthUrl: () => {
    const api = new Api();
    return api.query('get', `dropbox/auth-url`);
  },

  getDropboxToken: data => {
    const api = new Api();
    return api.query('post', `dropbox/oauth`, data);
  },

  checkDropboxToken: () => {
    const api = new Api();
    return api.query('get', `dropbox/check`);
  },

  initDropboxSync: ({ syncAll }) => {
    const api = new Api();
    return api.query('get', `dropbox/sync-init?all=${syncAll}`);
  },

  checkDropboxSync: data => {
    const api = new Api();
    return api.query('get', `dropbox/sync-check`, data);
  },

  disconnectDropbox: ({ disconnectAll }) => {
    const api = new Api();
    return api.query('post', `dropbox/disconnect?all=${disconnectAll}`);
  },

  uploadMeAvatar: data => {
    const customizedApi = new Api({
      extraHeaders: { 'Content-Type': 'multipart/form-data' },
    });
    return customizedApi.query('post', `me/avatar`, data);
  },

  createUser: data => {
    const api = new Api();
    return api.query('post', `users`, data);
  },

  removeUser: id => {
    const api = new Api();
    return api.query('delete', `users/${id}`);
  },

  updateUser: ({ id, ...data }) => {
    const api = new Api();
    return api.query('put', `users/${id}`, data);
  },

  getUserRoles: () => {
    const api = new Api();
    return api.query('get', `users/role`);
  },

  setUserRole: data => {
    const api = new Api();
    return api.query('post', `users/role`, data);
  },

  unsetUserRole: data => {
    const api = new Api();
    return api.query('delete', `users/role?id=${data.id}`);
  },

  updateUserSections: ({ userId, ...data }) => {
    const api = new Api();
    return api.query('post', `users/${userId}/sections`, data);
  },

  assignNewUser: data => {
    const api = new Api();
    return api.query('post', 'users/assign', data);
  },

  uploadUserAvatar: data => {
    const customizedApi = new Api({
      extraHeaders: { 'Content-Type': 'multipart/form-data' },
    });
    return customizedApi.query('post', `me/avatar`, data);
  },

  getUserTeams: () => {
    const api = new Api();
    return api.query('get', 'users/teams');
  },

  // -- Team API --
  createTeam: data => {
    const api = new Api();
    return api.query('post', `teams`, data);
  },

  fetchTeams: () => {
    const api = new Api();
    return api.query('get', `teams`);
  },

  updateTeamSections: ({ teamId, ...data }) => {
    const api = new Api();
    return api.query('put', `teams/${teamId}/sections`, data);
  },

  removeTeam: teamId => {
    const api = new Api();
    return api.query('delete', `teams/${teamId}`);
  },

  addUserToTeam: ({ teamId, ...data }) => {
    const api = new Api();
    return api.query('post', `teams/${teamId}/user`, data);
  },

  removeUserFromTeam: ({ teamId, userId }) => {
    const api = new Api();
    return api.query('delete', `teams/${teamId}/user?id=${userId}`);
  },

  updateTeamTitle: ({ teamId, ...data }) => {
    const api = new Api();
    return api.query('put', `teams/${teamId}`, data);
  },

  setProject: ({ teamId, ...data }) => {
    const api = new Api();
    return api.query('put', `teams/${teamId}/project`, data);
  },

  unsetProject: ({ teamId, projectId }) => {
    const api = new Api();
    return api.query('delete', `teams/${teamId}/project?id=${projectId}`);
  },

  setLocation: ({ teamId, ...data }) => {
    const api = new Api();
    return api.query('put', `teams/${teamId}/business`, data);
  },

  unsetLocation: ({ teamId, businessId }) => {
    const api = new Api();
    return api.query('delete', `teams/${teamId}/business?id=${businessId}`);
  },

  fetchTeamUsers: teamId => {
    const api = new Api();
    return api.query('get', `teams/${teamId}/user`);
  },
  // -- End Team API --

  // -- Dashboard API --
  getDashboard: () => {
    const api = new Api();
    return api.query('get', 'dashboard/summary');
  },

  getUpcomingTasks: ({ id, ...params }) => {
    const api = new Api();
    return api.query('get', 'dashboard/upcoming-tasks', params);
  },

  getExpiredTasks: ({ id, ...params }) => {
    const api = new Api();
    return api.query('get', 'dashboard/expired-tasks', params);
  },

  getNotificationTasks: id => {
    const api = new Api();
    return api.query('get', `companies/${id}/notifications`);
  },

  getUpcomingTasksTracker: () => {
    const api = new Api();
    return api.query('get', 'dashboard/subset/upcoming-tasks');
  },

  getExpiredTasksTracker: () => {
    const api = new Api();
    return api.query('get', 'dashboard/subset/expired-tasks');
  },

  fetchFavoritesAndRecents: params => {
    const api = new Api();
    return api.query('get', 'dashboard/favorites-and-recent', params);
  },

  fetchTodos: params => {
    const api = new Api();
    return api.query('get', 'todos', params);
  },

  completeTodo: id => {
    const api = new Api();
    return api.query('put', `todos/${id}/complete`);
  },

  restoreTodo: id => {
    const api = new Api();
    return api.query('put', `todos/${id}/restore`);
  },
  // -- End Dashboard Api --

  // --Business API--
  getBusinessList: data => {
    const api = new Api();
    return api.query('get', `businesses`, data);
  },

  getBusinessFilterInitData: () => {
    const api = new Api();
    return api.query('get', `businesses/getBusinessFilterInitData`);
  },

  createBusiness: data => {
    const api = new Api();
    return api.query('post', `businesses`, data);
  },

  getBusiness: id => {
    const api = new Api();
    return api.query('get', `businesses/${id}?allAttachments=true`); //?governmentId=${governmentId}
  },

  updateBusiness: ({ id, ...data }) => {
    const api = new Api();
    return api.query('put', `businesses/${id}`, data);
  },

  updateMetrcKey: ({ id, ...data }) => {
    const api = new Api();
    return api.query('post', `businesses/${id}/metrc`, data);
  },

  removeBusiness: id => {
    const api = new Api();
    return api.query('delete', `businesses/${id}`);
  },

  createBusinessAttachment: ({ businessId, ...data }) => {
    const customizedApi = new Api({
      extraHeaders: { 'Content-Type': 'multipart/form-data' },
    });
    return customizedApi.query(
      'post',
      `businesses/${businessId}/upload${data.attach ? '?attach=false' : ''}`,
      data.file
    );
  },

  deleteBusinessAttachment: ({ businessId, attachmentId }) => {
    const api = new Api();
    return api.query(
      'delete',
      `businesses/${businessId}/attachment?id=${attachmentId}`
    );
  },

  createBusinessFile: ({ businessId, ...data }) => {
    const api = new Api();
    return api.query('post', `businesses/${businessId}/attachment`, data);
  },

  createBusinessAvatar: ({ businessId, ...data }) => {
    const customizedApi = new Api({
      ...(data.file && {
        extraHeaders: { 'Content-Type': 'multipart/form-data' },
      }),
    });

    return customizedApi.query(
      'post',
      `v2/businesses/${businessId}/avatar`,
      data.fileUuid ? data : data.file
    );
  },

  fetchBusinessUsers: ({ id }) => {
    const api = new Api();
    return api.query('get', `businesses/${id}/users`);
  },

  createBusinessUser: ({ id, ...data }) => {
    const api = new Api();
    return api.query('post', `businesses/${id}/contact`, data);
  },

  deleteBusinessUser: ({ id }) => {
    const api = new Api();
    return api.query('delete', `businesses/${id}/contact`);
  },

  fetchBusinessLicenses: data => {
    const api = new Api();
    return api.query('get', 'licenses/list', data);
  },

  fetchBusinessesCSV: data => {
    const api = new Api();
    return api.query('post', 'businesses/csv', data);
  },

  createCustomField: ({ field, businessId }) => {
    const api = new Api();
    return api.query('put', `businesses/${businessId}/field`, field);
  },

  fetchBusinessComments: businessId => {
    const api = new Api();
    return api.query('get', `businesses/${businessId}/comments`);
  },

  createBusinessComment: ({ businessId, ...data }) => {
    const api = new Api();
    return api.query('post', `businesses/${businessId}/comments`, data);
  },

  updateBusinessComment: ({ businessId, commentId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `businesses/${businessId}/comments/${commentId}`,
      data
    );
  },

  deleteBusinessComment: ({ businessId, commentId }) => {
    const api = new Api();
    return api.query(
      'delete',
      `businesses/${businessId}/comments/${commentId}`
    );
  },

  fetchFullAuditLogs: ({ businessId }) => {
    const api = new Api();
    return api.query('get', `businesses/${businessId}/audit-logs`);
  },

  readBusinessComments: ({ businessId, ...data }) => {
    const api = new Api();
    return api.query(
      'post',
      `businesses/${businessId}/comments/mark-as-read`,
      data
    );
  },

  addBusinessToFavorites: ({ businessId }) => {
    const api = new Api();
    return api.query('post', `businesses/${businessId}/favorites`);
  },

  removeBusinessFromFavorites: ({ businessId }) => {
    const api = new Api();
    return api.query('delete', `businesses/${businessId}/favorites`);
  },

  connectUserToBusiness: ({ businessId, ...data }) => {
    const api = new Api();
    return api.query('post', `businesses/${businessId}/connected-user`, data);
  },

  disconnectUserFromBusiness: ({ businessId, ...data }) => {
    const api = new Api();
    return api.query('delete', `businesses/${businessId}/removed-user`, {
      data,
    });
  },

  getAllBusinessesSmallObject: () => {
    const api = new Api();
    return api.query('get', 'businesses/getAllBusinessesSmallObject');
  },

  getSimpleProjectsData: () => {
    const api = new Api();
    return api.query('get', 'projects/getAllProjectsSmallObject');
  },
  // --End Business API--

  // --Resources API--
  getResourceList: () => {
    const api = new Api();
    return api.query('get', `resources`);
  },

  createFolder: data => {
    const api = new Api();
    return api.query('post', `resources/folder`, data);
  },

  updateResource: ({ id, ...data }) => {
    const api = new Api();
    return data.expirationDate !== undefined
      ? api.query('post', `resources/${id}`, data)
      : api.query('put', `resources/${id}`, data);
  },

  uploadResource: (data, event) => {
    const customizedApi = new Api({
      extraHeaders: { 'Content-Type': 'multipart/form-data' },
      events: event,
    });
    return customizedApi.query('post', `resources/upload`, data);
  },

  downloadResource: id => {
    const headers = {};
    const token = localStorage.getItem('id_token');

    if (token) headers.Authorization = `Bearer ${token}`;

    return axios.request({
      url: `${apiUrl}resources/download/${id}`,
      method: 'get',
      responseType: 'blob',
      headers,
    });

    // const api = new Api();
    // return api.query('get', `resources/download/${id}`, {}, 'blob');
  },

  removeResource: id => {
    const api = new Api();
    return api.query('delete', `resources/${id}`);
  },

  moveResource: ({ resourceId, ...data }) => {
    const api = new Api();
    return api.query('put', `resources/move/${resourceId}`, data);
  },

  getFileUsers: id => {
    const api = new Api();
    return api.query('get', `resources/${id}/users`);
  },

  connectFileUser: ({ id, ...data }) => {
    const api = new Api();
    return api.query('post', `resources/${id}/users`, data);
  },

  disconnectFileUser: ({ id, ...data }) => {
    const api = new Api();
    return api.query('delete', `resources/${id}/users`, {
      data,
    });
  },

  getResourceUrl: ({ id }) => {
    const api = new Api();
    return api.query('get', `/resources/preview/${id}`);
  },

  getResourceSummary: () => {
    const api = new Api();
    return api.query('get', '/resources/summary');
  },

  restrictAccessResource: ({ resourceId, userId }) => {
    const api = new Api();
    return api.query('post', `/resources/${resourceId}/restrict`, {
      userId,
    });
  },

  allowAccessResource: ({ resourceId, ...data }) => {
    const api = new Api();
    return api.query('delete', `/resources/${resourceId}/restrict`, {
      data,
    });
  },

  shareResource: ({ fileId, ...data }) => {
    const api = new Api();
    return api.query('post', `/resources/${fileId}/share`, data);
  },

  shareResourceToUserOrTeam: ({ resourceId, ...data }) => {
    const api = new Api();
    return api.query('post', `/resources/${resourceId}/share-resource`, data);
  },

  restrictResourceToUserOrTeam: ({ resourceId, ...data }) => {
    const api = new Api();
    return api.query('delete', `/resources/${resourceId}/share-resource`, {
      data,
    });
  },

  directDownloadResource: ({ id, ...data }) => {
    const headers = {
      'Access-Control-Expose-Headers': 'Content-disposition',
    };
    const token = localStorage.getItem('id_token');

    if (token) headers.Authorization = `Bearer ${token}`;

    return axios.request({
      url: `${apiUrl}resources/direct-download/${id}`,
      method: 'post',
      responseType: 'arraybuffer',
      headers,
      data,
    });
  },

  restrictTeamAccess: ({ resourceId, ...data }) => {
    const api = new Api();
    return api.query('post', `/resources/${resourceId}/restrict-team`, data);
  },

  allowTeamAccess: ({ resourceId, ...data }) => {
    const api = new Api();
    return api.query('delete', `/resources/${resourceId}/restrict-team`, {
      data,
    });
  },

  setResourcePermission: ({ resourceId, ...data }) => {
    const api = new Api();
    return api.query('put', `/resources/${resourceId}/set-permission`, data);
  },

  checkUserHasHigherPermissionsToResource: ({ resourceId, userId }) => {
    const api = new Api();
    return api.query(
      'get',
      `/resources/${resourceId}/${userId}/check-for-attachment`
    );
  },
  // --End Resources API--

  // --Companies API--
  getCompanyList: () => {
    const api = new Api();
    return api.query('get', `companies`);
  },

  createCompany: data => {
    const api = new Api();
    return api.query('post', `companies`, data);
  },

  updateCompany: ({ id, ...data }) => {
    const api = new Api();
    return api.query('put', `companies/${id}`, data);
  },

  removeCompany: id => {
    const api = new Api();
    return api.query('delete', `companies/${id}`);
  },

  getCompanyUsers: id => {
    const api = new Api();
    return api.query('get', `companies/${id}/users`);
  },

  getAuditLogs: ({ companyId, ...query }) => {
    const api = new Api();
    return api.query('get', `companies/${companyId}/audit-logs`, query);
  },

  getCompanyInfo: companyId => {
    const api = new Api();
    return api.query('get', `companies/${companyId}`);
  },

  upgradeCompany: ({ companyId, ...data }) => {
    const api = new Api();
    return api.query('post', `companies/${companyId}/upgrade-request`, data);
  },

  checkTrial: ({ companyId }) => {
    const api = new Api();
    return api.query('get', `companies/${companyId}/check-trial`);
  },
  // --End Companies API--

  // --Notifications API--
  getDashboardNotificationList: (data = {}) => {
    const api = new Api();
    return api.query('get', 'dashboard/notifications', data);
    // return api.query('get', `notifications`, data);
  },

  getNotifications: data => {
    const api = new Api();
    return api.query('get', `notifications`, data);
  },

  updateNotification: ({ id, ...data }) => {
    const api = new Api();
    return api.query('put', `notifications/${id}`, data);
  },

  removeNotification: id => {
    const api = new Api();
    return api.query('delete', `notifications/${id}`);
  },

  markNotificationsAsRead: data => {
    const api = new Api();
    return api.query('put', `notifications`, data);
  },
  // --End Notifications API--

  // --METRC API--
  getTransfers: data => {
    const api = new Api();
    return api.query(
      'get',
      `metrc/transfers?locationId=${data.locationId}&lastModifiedStart=${data.lastModifiedStart}&lastModifiedEnd=${data.lastModifiedEnd}`
    );
  },

  updateTransfer: data => {
    const api = new Api();
    return api.query(
      'post',
      `metrc/transfers?locationId=${data.locationId}&deliveryId=${data.deliveryId}&licenseNumber=${data.licenseNumber}`,
      { packageLabel: data.packageLabel, note: data.note }
    );
  },

  getReceipts: data => {
    const api = new Api();
    return api.query('get', `metrc/receipts`, data);
  },

  getReceipt: ({ id, ...data }) => {
    const api = new Api();
    return api.query('get', `metrc/receipts/${id}`, data);
  },

  getPackage: data => {
    const api = new Api();
    return api.query('get', `metrc/package-wholesale`, data);
  },

  getLabtests: data => {
    const api = new Api();
    return api.query('get', `metrc/labtests`, data);
  },
  // --END METRC API--

  moveChecklist: data => {
    const api = new Api();
    return api.query('put', `checklists/reorder`, data);
  },

  // --LICENSE API--
  getLicenseList: () => {
    const api = new Api();
    return api.query('get', `licenses`);
  },

  getLicense: licenseId => {
    const api = new Api();
    return api.query('get', `licenses/${licenseId}`);
  },

  createLicense: data => {
    const api = new Api();
    return api.query('post', `licenses`, data);
  },

  updateLicense: ({ licenseId, ...data }) => {
    const api = new Api();
    return api.query('put', `licenses/${licenseId}`, data);
  },

  hideLicense: ({ licenseId, hide }) => {
    const api = new Api();
    return api.query('put', `licenses/${licenseId}/hide`, { hide });
  },

  removeLicense: ({ licenseId }) => {
    const api = new Api();
    return api.query('delete', `licenses/${licenseId}`);
  },

  getLicenseSteps: licenseId => {
    const api = new Api();
    return api.query('get', `licenses${licenseId}/steps`);
  },

  getLicenseStep: ({ licenseId, stepId }) => {
    const api = new Api();
    return api.query('get', `licenses${licenseId}/steps/${stepId}`);
  },

  createLicenseStep: ({ licenseId, ...data }) => {
    const api = new Api();
    return api.query('post', `licenses/${licenseId}/steps`, data);
  },

  updateLicenseStep: ({ licenseId, stepId, ...data }) => {
    const api = new Api();
    return api.query('put', `licenses/${licenseId}/steps/${stepId}`, data);
  },

  removeLicenseStep: ({ licenseId, stepId }) => {
    const api = new Api();
    return api.query('delete', `licenses/${licenseId}/steps/${stepId}`);
  },

  attachStepProject: ({ licenseId, stepId, ...data }) => {
    const api = new Api();
    return api.query(
      'post',
      `licenses/${licenseId}/steps/${stepId}/project`,
      data
    );
  },

  removeStepProject: ({ licenseId, stepId }) => {
    const api = new Api();
    return api.query('delete', `licenses/${licenseId}/steps/${stepId}/project`);
  },

  setLicenseAssignee: ({ licenseId, stepId, ...data }) => {
    const api = new Api();
    return api.query(
      'post',
      `licenses/${licenseId}/steps/${stepId}/assign`,
      data
    );
  },

  unsetLicenseAssignee: ({ licenseId, stepId, ...data }) => {
    const api = new Api();
    return api.query('delete', `licenses/${licenseId}/steps/${stepId}/assign`, {
      data,
    });
  },

  addLicenseMember: ({ licenseId, ...data }) => {
    const api = new Api();
    return api.query('post', `licenses/${licenseId}/member`, data);
  },

  removeLicenseMember: ({ licenseId, ...data }) => {
    const api = new Api();
    return api.query('delete', `licenses/${licenseId}/member`, {
      data,
    });
  },

  reorderSteps: ({ licenseId, ...data }) => {
    const api = new Api();
    return api.query('put', `licenses/${licenseId}/reorder`, data);
  },

  getLicenseLogs: ({ types, licenseId, stepId }) => {
    const api = new Api();
    let query = `types=${types}`;
    if (licenseId) query += `&license=${licenseId}`;
    if (stepId) query += `&step=${stepId}`;

    return api.query('get', `licenses/logs?${query}`);
  },

  reorderLicenses: data => {
    const api = new Api();
    return api.query('put', `licenses/reorder`, data);
  },

  createStepDate: ({ licenseId, stepId, ...data }) => {
    const api = new Api();
    return api.query(
      'post',
      `/licenses/${licenseId}/steps/${stepId}/date`,
      data
    );
  },

  updateStepDate: ({ licenseId, stepId, dateId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `/licenses/${licenseId}/steps/${stepId}/date/${dateId}`,
      data
    );
  },

  deleteStepDate: ({ licenseId, stepId, dateId, all = false }) => {
    const api = new Api();
    return api.query(
      'delete',
      `/licenses/${licenseId}/steps/${stepId}/date/${dateId}?all=${all}`
    );
  },

  setLicensesBusiness: ({ licenseId, ...data }) => {
    const api = new Api();
    return api.query('put', `/licenses/${licenseId}/assign`, data);
  },
  // --END LICENSE API--

  // -- Assigned Tasks API --
  getAssignedTasks: data => {
    const api = new Api();
    return api.query('get', 'dashboard/assigned-tasks', data);
  },
  getAssignedTasksTracker: () => {
    const api = new Api();
    return api.query('get', 'dashboard/subset/assigned-tasks');
  },
  getAssignedUsers: () => {
    const api = new Api();
    return api.query('get', 'dashboard/assigned-users');
  },
  // -- END Assigned Api --

  // -- GOOGLE DRIVE API --
  getGoogleDriveAuthUrl: () => {
    const api = new Api();
    return api.query('get', 'google-drive/auth-url');
  },

  getGoogleDriveToken: data => {
    const api = new Api();
    return api.query('post', 'google-drive/token', data);
  },

  checkGoogleDriveToken: () => {
    const api = new Api();
    return api.query('get', 'google-drive/check');
  },

  initGoogleDriveSync: ({ syncAll, driveId }) => {
    const api = new Api();
    return api.query(
      'get',
      `google-drive/sync-init?all=${syncAll}${
        driveId ? '&driveId=' + driveId : ''
      }`
    );
  },

  checkGoogleDriveSync: data => {
    const api = new Api();
    return api.query('get', 'google-drive/sync-check', data);
  },

  disconnectGoogleDrive: ({ disconnectAll }) => {
    const api = new Api();
    return api.query('post', `google-drive/disconnect?all=${disconnectAll}`);
  },

  getGoogleDriveStorage: () => {
    const api = new Api();
    return api.query('get', '/google-drive/storage');
  },
  // -- End GOOGLE DRIVE API --

  // -- USER CALENDAR API --
  getCalendarAuthUrl: calendarType => {
    const api = new Api();
    return api.query('get', `user-calendar/${calendarType}/auth-url`);
  },

  setCalendarToken: ({ calendarType, ...data }) => {
    const api = new Api();
    return api.query('post', `user-calendar/${calendarType}/token`, data);
  },

  checkCalendarStatus: calendarType => {
    const api = new Api();
    return api.query('get', `user-calendar/${calendarType}/status`);
  },

  revokeCalendarToken: calendarType => {
    const api = new Api();
    return api.query('post', `user-calendar/${calendarType}/revoke`);
  },
  // -- End USER CALENDAR API --

  renameChecklist: ({ id, ...data }) => {
    const api = new Api();
    return api.query('put', `checklists/${id}/rename`, data);
  },

  hideColumns: ({ id, hiddenColumns }) => {
    const api = new Api();
    return api.query('post', `projects/${id}/settings`, {
      hiddenColumns,
    });
  },

  hideSections: ({ payload: { id, hiddenSections } }) => {
    const api = new Api();
    return api.query('post', `projects/${id}/settings`, {
      hiddenSections,
    });
  },

  fetchArchivedBusinesses: data => {
    const api = new Api();
    return api.query('get', `businesses/archived`, data);
  },

  archiveBusiness: businessId => {
    const api = new Api();
    return api.query('put', `businesses/${businessId}/archive`);
  },

  unzipBusiness: businessId => {
    const api = new Api();
    return api.query('put', `businesses/${businessId}/unzip`);
  },

  // -- PUSH NOTIFICATIONS API --
  getPushNotificationsPublicKey: () => {
    const api = new Api();
    return api.query('get', 'push-notifications/public-key');
  },

  syncPushNotifications: data => {
    const api = new Api();
    return api.query('post', 'push-notifications/sync', data);
  },
  // -- END PUSH NOTIFICATIONS API --
  // -- DELETE TAG --
  // tag:string
  deleteProjectListTag: tag => {
    const api = new Api();
    return api.query('post', `project-tags/delete`, { tag });
  },
  setUploadFileNotification: data => {
    const api = new Api();
    return api.query('post', `me/setUserNotifications`, data);
  },
  checkExistenceOfProject: projectId => {
    const api = new Api();
    return api.query('get', `projects/${projectId}/checkExistenceOfProject`);
  },

  ...projectsService('projects'),
  checklists: projectsService('checklists'),
  sop: projectsService('sop'),
  template: projectsService('v2/templates'),
};
