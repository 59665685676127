import meActions from './actions';
import tableColumns from './me-business-table-columns';

const initialState = {
  user: {
    sections: {},
    notifications: { uploadFile: true },
  },
  agreement: '',
  loading: false,
  avatarLoading: false,
  error: null,
  filters: {},
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case meActions.GET_ME_REQUEST:
    case meActions.UPDATE_ME_REQUEST:
    case meActions.GET_AGREEMENT_REQUEST:
    case meActions.UPDATE_ME_FILTERS_REQUEST:
      return { ...state, loading: true, error: null };

    case meActions.UPLOAD_ME_AVATAR_REQUEST:
      return { ...state, avatarLoading: true, error: null };

    case meActions.UPLOAD_ME_AVATAR_FAILURE:
      return {
        ...state,
        avatarLoading: false,
        error: action.payload,
      };

    case meActions.GET_ME_FAILURE:
    case meActions.UPDATE_ME_FAILURE:
    case meActions.GET_AGREEMENT_FAILURE:
    case meActions.GET_ME_FILTERS_FAILURE:
    case meActions.UPDATE_ME_FILTERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case meActions.GET_ME_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          sections: state.user.sections || {},
        },
        loading: false,
        error: null,
      };

    case meActions.UPDATE_ME_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          sections: state.user.sections || {},
        },
        loading: false,
        error: null,
      };

    case meActions.UPLOAD_ME_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload,
          sections: state.user.sections || {},
        },
        avatarLoading: false,
        error: null,
      };

    case meActions.GET_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreement: action.payload,
        loading: false,
      };

    case meActions.SET_USER_SECTIONS:
      return {
        ...state,
        user: {
          ...state.user,
          sections: action.payload.sections,
          accessibleSections: action.payload.accessibleSections,
        },
      };

    case meActions.GET_ME_FILTERS_SUCCESS:
    case meActions.UPDATE_ME_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.payload,
        loading: false,
      };

    // Adding custom fields to the filter.
    case meActions.UPDATE_ME_CUSTOM_FIELDS_SUCCESS:
      const customFields = action.payload;

      // const staticFilterColumns = state.filters.businessTableFields.filter(
      //   field => tableColumns.find(({ key }) => key === field.key)
      // );

      const getCustomFieldActiveStatus = id => {
        return !!state?.filters?.businessTableFields?.find(
          cField => cField.key === id && cField?.active
        );
      };

      //static businessTableField
      const staticFilterColumns = tableColumns.map(field => ({
        ...field,
        active: getCustomFieldActiveStatus(field.key),
      }));

      //custom fields businessTableField
      const customFieldsFilterData = customFields.map(
        ({ name, target, id }) => ({
          name,
          target,
          key: id,
          active: getCustomFieldActiveStatus(id),
        })
      );

      // const baseColumnFilters = state.filters.businessTableFields
      //   .filter(item => )
      // const baseColumnsFilter = state.filters.businessTableFields
      // .filter(customField => !)

      // return {
      //   ...state,
      //   filters: {
      //     ...state.filters,
      //     businessTableFields: [

      //       // ...action.payload,
      //       // ...action.payload.filter(item => {
      //       //   const dublicate = state.filters.businessTableFields.filter(
      //       //     elem => elem.name === item.name
      //       //   );
      //       //   return dublicate.length ? false : true;
      //       // }),
      //     ],
      //   },
      // };

      return {
        ...state,
        filters: {
          ...state.filters,
          businessTableFields: [
            ...staticFilterColumns,
            ...customFieldsFilterData,
            // ...action.payload,
            // ...action.payload.filter(item => {
            //   const dublicate = state.filters.businessTableFields.filter(
            //     elem => elem.name === item.name
            //   );
            //   return dublicate.length ? false : true;
            // }),
          ],
        },
      };

    case meActions.SET_UPLOAD_FILE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: {
            ...state.user.notifications,
            uploadFile: false,
          },
        },
      };

    default:
      return state;
  }
}
