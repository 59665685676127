const notificationsActions = {
  GET_NOTIFICATION_LIST_REQUEST: 'GET_NOTIFICATION_LIST_REQUEST',
  GET_NOTIFICATION_LIST_SUCCESS: 'GET_NOTIFICATION_LIST_SUCCESS',
  GET_NOTIFICATION_LIST_FAILURE: 'GET_NOTIFICATION_LIST_FAILURE',

  UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_FAILURE: 'UPDATE_NOTIFICATION_FAILURE',

  REMOVE_NOTIFICATION_REQUEST: 'REMOVE_NOTIFICATION_REQUEST',
  REMOVE_NOTIFICATION_SUCCESS: 'REMOVE_NOTIFICATION_SUCCESS',
  REMOVE_NOTIFICATION_FAILURE: 'REMOVE_NOTIFICATION_FAILURE',

  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',

  SET_PAGE: 'SET_PAGE',

  SET_NOTIFICATIONS_FOR_TRACKER: 'SET_NOTIFICATIONS_FOR_TRACKER',

  UPDATE_TOTAL_NUMBER_OF_NOTIFICATIONS: 'UPDATE_TOTAL_NUMBER_OF_NOTIFICATIONS',

  FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',

  MARK_NOTIFICATIONS_AS_READ_REQUEST: 'MARK_NOTIFICATIONS_AS_READ_REQUEST',
  MARK_NOTIFICATIONS_AS_READ_SUCCESS: 'MARK_NOTIFICATIONS_AS_READ_SUCCESS',
  MARK_NOTIFICATIONS_AS_READ_FAILURE: 'MARK_NOTIFICATIONS_AS_READ_FAILURE',

  getNotificationListRequest: data => ({
    type: notificationsActions.GET_NOTIFICATION_LIST_REQUEST,
    data,
  }),
  getNotificationListSuccess: notifications => ({
    type: notificationsActions.GET_NOTIFICATION_LIST_SUCCESS,
    payload: notifications,
  }),
  getNotificationListFailure: error => ({
    type: notificationsActions.GET_NOTIFICATION_LIST_FAILURE,
    payload: error,
  }),

  updateNotificationRequest: data => ({
    type: notificationsActions.UPDATE_NOTIFICATION_REQUEST,
    data,
  }),
  updateNotificationSuccess: notifications => ({
    type: notificationsActions.UPDATE_NOTIFICATION_SUCCESS,
    payload: notifications,
  }),
  updateNotificationFailure: error => ({
    type: notificationsActions.UPDATE_NOTIFICATION_FAILURE,
    payload: error,
  }),

  removeNotificationRequest: id => ({
    type: notificationsActions.REMOVE_NOTIFICATION_REQUEST,
    id,
  }),
  removeNotificationSuccess: id => ({
    type: notificationsActions.REMOVE_NOTIFICATION_SUCCESS,
    payload: id,
  }),
  removeNotificationFailure: error => ({
    type: notificationsActions.REMOVE_NOTIFICATION_FAILURE,
    payload: error,
  }),

  setPage: data => ({
    type: notificationsActions.SET_PAGE,
    payload: data,
  }),

  updateNotifications: notifications => ({
    type: notificationsActions.UPDATE_NOTIFICATIONS,
    notifications,
  }),

  setNotificationListForTracker: notifications => ({
    type: notificationsActions.SET_NOTIFICATIONS_FOR_TRACKER,
    payload: notifications,
  }),

  updateTotalNotifications: data => ({
    type: notificationsActions.UPDATE_TOTAL_NUMBER_OF_NOTIFICATIONS,
    payload: data,
  }),

  fetchNotifications: data => ({
    type: notificationsActions.FETCH_NOTIFICATIONS_REQUEST,
    data,
  }),
  fetchNotificationsSuccess: data => ({
    type: notificationsActions.FETCH_NOTIFICATIONS_SUCCESS,
    payload: data,
  }),
  fetchNotificationsFailure: data => ({
    type: notificationsActions.FETCH_NOTIFICATIONS_FAILURE,
    payload: data,
  }),

  markNotificationsAsRead: data => ({
    type: notificationsActions.MARK_NOTIFICATIONS_AS_READ_REQUEST,
    data,
  }),
  markNotificationsAsReadSuccess: data => ({
    type: notificationsActions.MARK_NOTIFICATIONS_AS_READ_SUCCESS,
    payload: data,
  }),
  markNotificationsAsReadFailure: data => ({
    type: notificationsActions.MARK_NOTIFICATIONS_AS_READ_FAILURE,
    payload: data,
  }),
};

export default notificationsActions;
