import { all, takeEvery, put } from 'redux-saga/effects';

import dropboxActions from './actions';
import anamiService from '../../services/anamiService';

function* getDropboxAuthUrl() {
  try {
    let response = yield anamiService.getDropboxAuthUrl();

    yield put(dropboxActions.getDropboxAuthUrlSuccess(response.data.authUrl));
  } catch (error) {
    yield put(dropboxActions.getDropboxAuthUrlFailure(error));
  }
}

function* getDropboxToken({ data }) {
  try {
    let response = yield anamiService.getDropboxToken(data);

    const checkResponse = yield anamiService.checkDropboxToken();

    yield put(dropboxActions.getDropboxTokenSuccess(checkResponse.data));
  } catch (error) {
    yield put(dropboxActions.getDropboxTokenFailure(error));
  }
}

function* checkDropboxToken() {
  try {
    let response = yield anamiService.checkDropboxToken();

    yield put(dropboxActions.checkDropboxTokenSuccess(response.data));
  } catch (error) {
    yield put(dropboxActions.checkDropboxTokenFailure(error));
  }
}

function* initDropboxSync({ data }) {
  try {
    const response = yield anamiService.initDropboxSync(data);
    const syncResponse = yield anamiService.checkDropboxSync({
      id: response.data.id,
    });

    localStorage.setItem('dropboxProcessId', response.data.id);

    yield put(
      dropboxActions.initDropboxSyncSuccess({
        id: response.data.id,
        syncData: syncResponse.data,
      })
    );
  } catch (error) {
    yield put(dropboxActions.initDropboxSyncFailure(error));
  }
}

function* checkDropboxSync({ data }) {
  try {
    let response = yield anamiService.checkDropboxSync(data);

    yield put(dropboxActions.checkDropboxSyncSuccess(response.data));
  } catch (error) {
    yield put(dropboxActions.checkDropboxSyncFailure(error));
  }
}

function* disconnectDropbox({ data }) {
  try {
    yield anamiService.disconnectDropbox(data);

    yield put(dropboxActions.disconnectDropboxSuccess());
  } catch (error) {
    yield put(dropboxActions.disconnectDropboxFailure(error));
  }
}

export default function* dropboxSaga() {
  yield all([
    takeEvery(dropboxActions.GET_DROPBOX_AUTH_URL_REQUEST, getDropboxAuthUrl),
  ]);
  yield all([
    takeEvery(dropboxActions.GET_DROPBOX_TOKEN_REQUEST, getDropboxToken),
  ]);
  yield all([
    takeEvery(dropboxActions.CHECK_DROPBOX_TOKEN_REQUEST, checkDropboxToken),
  ]);
  yield all([
    takeEvery(dropboxActions.INIT_DROPBOX_SYNC_REQUEST, initDropboxSync),
  ]);
  yield all([
    takeEvery(dropboxActions.CHECK_DROPBOX_SYNC_REQUEST, checkDropboxSync),
  ]);
  yield all([
    takeEvery(dropboxActions.DISCONNECT_DROPBOX_REQUEST, disconnectDropbox),
  ]);
}
