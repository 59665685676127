const watcherActions = {
  START_WATCH_TOKEN: 'START_WATCH_TOKEN',
  STOP_WATCH_TOKEN: 'STOP_WATCH_TOKEN',

  startWatchToken: () => ({
    type: watcherActions.START_WATCH_TOKEN,
  }),

  stopWatchToken: () => ({
    type: watcherActions.STOP_WATCH_TOKEN,
  }),
};

export default watcherActions;
