import actionTypes from './actionTypes';

export default {
  ...actionTypes,
  setPage: page => ({
    type: 'SET_PAGE',
    payload: page,
  }),

  getLicenseListRequest: () => ({
    type: actionTypes.GET_LICENSE_LIST_REQUEST,
  }),
  getLicenseListSuccess: data => ({
    type: actionTypes.GET_LICENSE_LIST_SUCCESS,
    payload: data,
  }),
  getLicenseListFailure: error => ({
    type: actionTypes.GET_LICENSE_LIST_FAILURE,
    payload: error,
  }),

  createLicenseRequest: data => ({
    type: actionTypes.CREATE_LICENSE_REQUEST,
    data,
  }),
  createLicenseSuccess: data => ({
    type: actionTypes.CREATE_LICENSE_SUCCESS,
    payload: data,
  }),
  createLicenseFailure: error => ({
    type: actionTypes.CREATE_LICENSE_FAILURE,
    payload: error,
  }),

  updateLicenseRequest: data => ({
    type: actionTypes.UPDATE_LICENSE_REQUEST,
    data,
  }),
  updateLicenseSuccess: data => ({
    type: actionTypes.UPDATE_LICENSE_SUCCESS,
    payload: data,
  }),
  updateLicenseFailure: error => ({
    type: actionTypes.UPDATE_LICENSE_FAILURE,
    payload: error,
  }),

  hideLicenseRequest: data => ({
    type: actionTypes.HIDE_LICENSE_REQUEST,
    data,
  }),
  hideLicenseSuccess: data => ({
    type: actionTypes.HIDE_LICENSE_SUCCESS,
    data,
  }),
  hideLicenseFailure: error => ({
    type: actionTypes.HIDE_LICENSE_FAILURE,
    payload: error,
  }),

  removeLicenseRequest: data => ({
    type: actionTypes.REMOVE_LICENSE_REQUEST,
    data,
  }),
  removeLicenseSuccess: data => ({
    type: actionTypes.REMOVE_LICENSE_SUCCESS,
    payload: data,
  }),
  removeLicenseFailure: error => ({
    type: actionTypes.REMOVE_LICENSE_FAILURE,
    payload: error,
  }),

  createLicenseStepRequest: data => ({
    type: actionTypes.CREATE_LICENSE_STEP_REQUEST,
    data,
  }),
  createLicenseStepSuccess: data => ({
    type: actionTypes.CREATE_LICENSE_STEP_SUCCESS,
    payload: data,
  }),
  createLicenseStepFailure: error => ({
    type: actionTypes.CREATE_LICENSE_STEP_FAILURE,
    payload: error,
  }),

  updateLicenseStepRequest: data => ({
    type: actionTypes.UPDATE_LICENSE_STEP_REQUEST,
    data,
  }),
  updateLicenseStepSuccess: data => ({
    type: actionTypes.UPDATE_LICENSE_STEP_SUCCESS,
    payload: data,
  }),
  updateLicenseStepFailure: error => ({
    type: actionTypes.UPDATE_LICENSE_STEP_FAILURE,
    payload: error,
  }),

  removeLicenseStepRequest: data => ({
    type: actionTypes.REMOVE_LICENSE_STEP_REQUEST,
    data,
  }),
  removeLicenseStepSuccess: data => ({
    type: actionTypes.REMOVE_LICENSE_STEP_SUCCESS,
    payload: data,
  }),
  removeLicenseStepFailure: error => ({
    type: actionTypes.REMOVE_LICENSE_STEP_FAILURE,
    payload: error,
  }),

  setLicenseAssigneeRequest: data => ({
    type: actionTypes.SET_LICENSE_ASSIGNEE_REQUEST,
    data,
  }),
  setLicenseAssigneeSuccess: data => ({
    type: actionTypes.SET_LICENSE_ASSIGNEE_SUCCESS,
    payload: data,
  }),
  setLicenseAssigneeFailure: error => ({
    type: actionTypes.SET_LICENSE_ASSIGNEE_FAILURE,
    payload: error,
  }),

  unsetLicenseAssigneeRequest: data => ({
    type: actionTypes.UNSET_LICENSE_ASSIGNEE_REQUEST,
    data,
  }),
  unsetLicenseAssigneeSuccess: data => ({
    type: actionTypes.UNSET_LICENSE_ASSIGNEE_SUCCESS,
    payload: data,
  }),
  unsetLicenseAssigneeFailure: error => ({
    type: actionTypes.UNSET_LICENSE_ASSIGNEE_FAILURE,
    payload: error,
  }),

  addLicenseMemberRequest: data => ({
    type: actionTypes.ADD_LICENSE_MEMBER_REQUEST,
    data,
  }),
  addLicenseMemberSuccess: data => ({
    type: actionTypes.ADD_LICENSE_MEMBER_SUCCESS,
    payload: data,
  }),
  addLicenseMemberFailure: error => ({
    type: actionTypes.ADD_LICENSE_MEMBER_FAILURE,
    payload: error,
  }),

  removeLicenseMemberRequest: data => ({
    type: actionTypes.REMOVE_LICENSE_MEMBER_REQUEST,
    data,
  }),
  removeLicenseMemberSuccess: data => ({
    type: actionTypes.REMOVE_LICENSE_MEMBER_SUCCESS,
    payload: data,
  }),
  removeLicenseMemberFailure: error => ({
    type: actionTypes.REMOVE_LICENSE_MEMBER_FAILURE,
    payload: error,
  }),

  setLicenseFilters: data => ({
    type: actionTypes.SET_LICENSE_FILTERS,
    payload: data,
  }),
  setLicenseSort: data => ({
    type: actionTypes.SET_LICENSE_SORT,
    payload: data,
  }),

  moveStepRequest: data => ({
    type: actionTypes.MOVE_STEP_REQUEST,
    data,
  }),
  moveStepSuccess: data => ({
    type: actionTypes.MOVE_STEP_SUCCESS,
    payload: data,
  }),
  moveStepFailure: error => ({
    type: actionTypes.MOVE_STEP_FAILURE,
    payload: error,
  }),
  moveStepLocal: data => ({
    type: actionTypes.MOVE_STEP_LOCAL,
    payload: data,
  }),

  attachStepProjectRequest: data => ({
    type: actionTypes.ATTACH_STEP_PROJECT_REQUEST,
    data,
  }),
  attachStepProjectSuccess: data => ({
    type: actionTypes.ATTACH_STEP_PROJECT_SUCCESS,
    payload: data,
  }),
  attachStepProjectFailure: error => ({
    type: actionTypes.ATTACH_STEP_PROJECT_FAILURE,
    payload: error,
  }),

  removeStepProjectRequest: data => ({
    type: actionTypes.REMOVE_STEP_PROJECT_REQUEST,
    data,
  }),
  removeStepProjectSuccess: data => ({
    type: actionTypes.REMOVE_STEP_PROJECT_SUCCESS,
    payload: data,
  }),
  removeStepProjectFailure: error => ({
    type: actionTypes.REMOVE_STEP_PROJECT_FAILURE,
    payload: error,
  }),

  getAuditLicenseListRequest: () => ({
    type: actionTypes.GET_AUDIT_LICENSE_LIST_REQUEST,
  }),
  getAuditLicenseListSuccess: licenses => ({
    type: actionTypes.GET_AUDIT_LICENSE_LIST_SUCCESS,
    payload: licenses,
  }),
  getAuditLicenseListFailure: error => ({
    type: actionTypes.GET_AUDIT_LICENSE_LIST_FAILURE,
    payload: error,
  }),

  getLicenseLogsRequest: data => ({
    type: actionTypes.GET_LICENSE_LOGS_REQUEST,
    data,
  }),
  getLicenseLogsSuccess: data => ({
    type: actionTypes.GET_LICENSE_LOGS_SUCCESS,
    payload: data,
  }),
  getLicenseLogsFailure: error => ({
    type: actionTypes.GET_LICENSE_LOGS_FAILURE,
    payload: error,
  }),

  moveLicenseRequest: data => ({
    type: actionTypes.MOVE_LICENSE_REQUEST,
    data,
  }),
  moveLicenseSuccess: () => ({
    type: actionTypes.MOVE_LICENSE_SUCCESS,
  }),
  moveLicenseFailure: error => ({
    type: actionTypes.MOVE_LICENSE_FAILURE,
    payload: error,
  }),
  moveLicenseLocal: data => ({
    type: actionTypes.MOVE_LICENSE_LOCAL,
    payload: data,
  }),
  setCreatedLicenseFlag: () => ({
    type: actionTypes.SET_CREATED_LICENSE_FLAG_TO_FALSE,
  }),

  createStepDateRequest: data => ({
    type: actionTypes.CREATE_STEP_DATE_REQUEST,
    data,
  }),
  createStepDateSuccess: data => ({
    type: actionTypes.CREATE_STEP_DATE_SUCCESS,
    payload: data,
  }),
  createStepDateFailure: data => ({
    type: actionTypes.CREATE_STEP_DATE_FAILURE,
    payload: data,
  }),

  updateStepDateRequest: data => ({
    type: actionTypes.UPDATE_STEP_DATE_REQUEST,
    data,
  }),
  updateStepDateSuccess: data => ({
    type: actionTypes.UPDATE_STEP_DATE_SUCCESS,
    payload: data,
  }),
  updateStepDateFailure: data => ({
    type: actionTypes.UPDATE_STEP_DATE_FAILURE,
    payload: data,
  }),

  deleteStepDateRequest: data => ({
    type: actionTypes.DELETE_STEP_DATE_REQUEST,
    data,
  }),
  deleteStepDateSuccess: data => ({
    type: actionTypes.DELETE_STEP_DATE_SUCCESS,
    payload: data,
  }),
  deleteStepDateFailure: data => ({
    type: actionTypes.DELETE_STEP_DATE_FAILURE,
    payload: data,
  }),

  assignBusinessRequest: data => ({
    type: actionTypes.ASSIGN_BUSINESS_TO_LICENSE_REQUEST,
    data,
  }),
  assignBusinessSuccess: data => ({
    type: actionTypes.ASSIGN_BUSINESS_TO_LICENSE_SUCCESS,
    payload: data,
  }),
  assignBusinessFailure: data => ({
    type: actionTypes.ASSIGN_BUSINESS_TO_LICENSE_FAILURE,
    payload: data,
  }),

  createStepProjectRequest: data => ({
    type: actionTypes.CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_REQUEST,
    data,
  }),
  createStepProjectSuccess: data => ({
    type: actionTypes.CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_SUCCESS,
    payload: data,
  }),
  createStepProjectFailure: data => ({
    type: actionTypes.CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_FAILURE,
    payload: data,
  }),
};
