const dashboardActions = {
  FETCH_DATA: 'FETCH_DASHBOARD_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DASHBOARD_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'FETCH_DASHBOARD_DATA_FAILURE',

  GET_PROJECT_LIST_REQUEST: 'GET_PROJECT_LIST_REQUEST',
  GET_PROJECT_LIST_SUCCESS: 'GET_PROJECT_LIST_SUCCESS',
  GET_PROJECT_LIST_FAILURE: 'GET_PROJECT_LIST_FAILURE',

  SELECT_PROJECT: 'SELECT_PROJECT',
  GET_PROJECT_SUMMARY: 'GET_PROJECT_SUMMARY',
  VIEW_NOTIFICATION: 'VIEW_NOTIFICATION',

  FETCH_RECENT_REQUEST: 'FETCH_RECENT_REQUEST',
  FETCH_RECENT_SUCCESS: 'FETCH_RECENT_SUCCESS',
  FETCH_RECENT_FAILURE: 'FETCH_RECENT_FAILURE',

  FETCH_FAVORITES_REQUEST: 'FETCH_FAVORITES_REQUEST',
  FETCH_FAVORITES_SUCCESS: 'FETCH_FAVORITES_SUCCESS',
  FETCH_FAVORITES_FAILURE: 'FETCH_FAVORITES_FAILURE',

  FETCH_TODOS_REQUEST: 'FETCH_TODOS_REQUEST',
  FETCH_TODOS_SUCCESS: 'FETCH_TODOS_SUCCESS',
  FETCH_TODOS_FAILURE: 'FETCH_TODOS_FAILURE',

  COMPLETE_TODO_REQUEST: 'COMPLETE_TODO_REQUEST',
  COMPLETE_TODO_SUCCESS: 'COMPLETE_TODO_SUCCESS',
  COMPLETE_TODO_FAILURE: 'COMPLETE_TODO_FAILURE',

  RESTORE_TODO_REQUEST: 'RESTORE_TODO_REQUEST',
  RESTORE_TODO_SUCCESS: 'RESTORE_TODO_SUCCESS',
  RESTORE_TODO_FAILURE: 'RESTORE_TODO_FAILURE',

  CLEAR_DASHBOARD_ITEMS: 'CLEAR_DASHBOARD_ITEMS',

  SET_STATUS_FOR_TODO_TASK: 'SET_STATUS_FOR_TODO_TASK',

  fetchData: () => ({ type: dashboardActions.FETCH_DATA }),
  fetchDataSuccess: data => ({
    type: dashboardActions.FETCH_DATA_SUCCESS,
    payload: data,
  }),
  fetchDataFailure: error => ({
    type: dashboardActions.FETCH_DATA_FAILURE,
    payload: error,
  }),

  getProjectListRequest: () => ({
    type: dashboardActions.GET_PROJECT_LIST_REQUEST,
  }),
  getProjectListSuccess: projects => ({
    type: dashboardActions.GET_PROJECT_LIST_SUCCESS,
    payload: projects,
  }),
  getProjectListFailure: error => ({
    type: dashboardActions.GET_PROJECT_LIST_FAILURE,
    payload: error,
  }),

  getProjectSummary: id => ({
    type: dashboardActions.GET_PROJECT_SUMMARY,
    id,
  }),
  selectProject: project => ({
    type: dashboardActions.SELECT_PROJECT,
    payload: project,
  }),

  viewNotification: () => ({ type: dashboardActions.VIEW_NOTIFICATION }),

  fetchFavorites: data => ({
    type: dashboardActions.FETCH_FAVORITES_REQUEST,
    data,
  }),
  fetchFavoritesSuccess: data => ({
    type: dashboardActions.FETCH_FAVORITES_SUCCESS,
    payload: data,
  }),
  fetchFavoritesFailure: error => ({
    type: dashboardActions.FETCH_FAVORITES_FAILURE,
    payload: error,
  }),

  clearItems: () => ({
    type: dashboardActions.CLEAR_DASHBOARD_ITEMS,
  }),

  fetchRecent: data => ({
    type: dashboardActions.FETCH_RECENT_REQUEST,
    data,
  }),
  fetchRecentSuccess: data => ({
    type: dashboardActions.FETCH_RECENT_SUCCESS,
    payload: data,
  }),
  fetchRecentFailure: error => ({
    type: dashboardActions.FETCH_RECENT_FAILURE,
    payload: error,
  }),

  fetchTodos: data => ({
    type: dashboardActions.FETCH_TODOS_REQUEST,
    data,
  }),
  fetchTodosSuccess: data => ({
    type: dashboardActions.FETCH_TODOS_SUCCESS,
    payload: data,
  }),
  fetchTodosFailure: error => ({
    type: dashboardActions.FETCH_TODOS_FAILURE,
    payload: error,
  }),

  completeTodo: data => ({
    type: dashboardActions.COMPLETE_TODO_REQUEST,
    data,
  }),
  completeTodoSuccess: data => ({
    type: dashboardActions.COMPLETE_TODO_SUCCESS,
    payload: data,
  }),
  completeTodoFailure: error => ({
    type: dashboardActions.COMPLETE_TODO_FAILURE,
    payload: error,
  }),

  restoreTodo: data => ({
    type: dashboardActions.RESTORE_TODO_REQUEST,
    data,
  }),
  restoreTodoSuccess: data => ({
    type: dashboardActions.RESTORE_TODO_SUCCESS,
    payload: data,
  }),
  restoreTodoFailure: error => ({
    type: dashboardActions.RESTORE_TODO_FAILURE,
    payload: error,
  }),

  setTodoTaskStatus: data => ({
    type: dashboardActions.SET_STATUS_FOR_TODO_TASK,
    payload: data,
  }),
};

export default dashboardActions;
