import { select } from 'redux-saga/effects';

import anamiService from '../../../services/anamiService';
import { isChecklist, isSop, isGeneralOrGreenspace } from '../selectors';

export const getProjectService = function*(projectId) {
  const checklist = yield select(state => isChecklist(state, projectId));
  const sop = yield select(state => isSop(state, projectId));
  const generalGreenspace = yield select(state =>
    isGeneralOrGreenspace(state, projectId)
  );

  if (checklist) {
    return yield anamiService.checklists;
  } else if (sop) {
    return yield anamiService.sop;
  } else if (generalGreenspace) {
    return yield anamiService.template;
  } else {
    return yield anamiService;
  }
};
