import actionTypes from './actionTypes';

export default {
  createTaskRequest: data => ({
    type: actionTypes.CREATE_TASK_REQUEST,
    data,
  }),
  createTaskSuccess: task => ({
    type: actionTypes.CREATE_TASK_SUCCESS,
    payload: task,
  }),
  createTaskFailure: error => ({
    type: actionTypes.CREATE_TASK_FAILURE,
    payload: error,
  }),

  getTaskRequest: data => ({
    type: actionTypes.GET_TASK_REQUEST,
    data,
  }),
  getTaskSuccess: task => ({
    type: actionTypes.GET_TASK_SUCCESS,
    payload: task,
  }),
  getTaskFailure: error => ({
    type: actionTypes.GET_TASK_FAILURE,
    payload: error,
  }),

  updateTaskRequest: data => ({
    type: actionTypes.UPDATE_TASK_REQUEST,
    data,
  }),
  updateTaskSuccess: task => ({
    type: actionTypes.UPDATE_TASK_SUCCESS,
    payload: task,
  }),
  updateTaskFailure: error => ({
    type: actionTypes.UPDATE_TASK_FAILURE,
    payload: error,
  }),

  removeTaskRequest: data => ({
    type: actionTypes.REMOVE_TASK_REQUEST,
    data,
  }),
  removeTaskSuccess: data => ({
    type: actionTypes.REMOVE_TASK_SUCCESS,
    payload: data,
  }),
  removeTaskFailure: error => ({
    type: actionTypes.REMOVE_TASK_FAILURE,
    payload: error,
  }),

  selectTask: data => ({
    type: actionTypes.SELECT_TASK,
    data,
  }),
  selectTaskSuccess: task => ({
    type: actionTypes.SELECT_TASK_SUCCESS,
    payload: task,
  }),

  createAttachmentRequest: data => ({
    type: actionTypes.CREATE_ATTACHMENT_REQUEST,
    data,
  }),
  createAttachmentSuccess: attachmentId => ({
    type: actionTypes.CREATE_ATTACHMENT_SUCCESS,
    payload: attachmentId,
  }),
  createAttachmentFailure: error => ({
    type: actionTypes.CREATE_ATTACHMENT_FAILURE,
    payload: error,
  }),

  deleteAttachmentRequest: data => ({
    type: actionTypes.DELETE_ATTACHMENT_REQUEST,
    data,
  }),
  deleteAttachmentSuccess: attachmentId => ({
    type: actionTypes.DELETE_ATTACHMENT_SUCCESS,
    payload: attachmentId,
  }),
  deleteAttachmentFailure: error => ({
    type: actionTypes.DELETE_ATTACHMENT_FAILURE,
    payload: error,
  }),

  createCommentRequest: data => ({
    type: actionTypes.CREATE_COMMENT_REQUEST,
    data,
  }),
  createCommentSuccess: data => ({
    type: actionTypes.CREATE_COMMENT_SUCCESS,
    payload: data,
  }),
  createCommentFailure: error => ({
    type: actionTypes.CREATE_COMMENT_FAILURE,
    payload: error,
  }),

  updateCommentRequest: data => ({
    type: actionTypes.UPDATE_COMMENT_REQUEST,
    data,
  }),
  updateCommentSuccess: data => ({
    type: actionTypes.UPDATE_COMMENT_SUCCESS,
    payload: data,
  }),
  updateCommentFailure: error => ({
    type: actionTypes.UPDATE_COMMENT_FAILURE,
    payload: error,
  }),

  removeCommentRequest: data => ({
    type: actionTypes.REMOVE_COMMENT_REQUEST,
    data,
  }),
  removeCommentSuccess: data => ({
    type: actionTypes.REMOVE_COMMENT_SUCCESS,
    payload: data,
  }),
  removeCommentFailure: error => ({
    type: actionTypes.REMOVE_COMMENT_FAILURE,
    payload: error,
  }),

  createTaskLabelRequest: data => ({
    type: actionTypes.CREATE_TASK_LABEL_REQUEST,
    data,
  }),
  createTaskLabelSuccess: label => ({
    type: actionTypes.CREATE_TASK_LABEL_SUCCESS,
    payload: label,
  }),
  createTaskLabelFailure: error => ({
    type: actionTypes.CREATE_TASK_LABEL_FAILURE,
    payload: error,
  }),
  removeTaskLabelRequest: data => ({
    type: actionTypes.REMOVE_TASK_LABEL_REQUEST,
    data,
  }),
  removeTaskLabelSuccess: data => ({
    type: actionTypes.REMOVE_TASK_LABEL_SUCCESS,
    payload: data,
  }),
  removeTaskLabelFailure: error => ({
    type: actionTypes.REMOVE_TASK_LABEL_FAILURE,
    payload: error,
  }),

  setTaskAssigneeRequest: data => ({
    type: actionTypes.SET_TASK_ASSIGNEE_REQUEST,
    data,
  }),
  setTaskAssigneeSuccess: task => ({
    type: actionTypes.SET_TASK_ASSIGNEE_SUCCESS,
    payload: task,
  }),
  setTaskAssigneeFailure: error => ({
    type: actionTypes.SET_TASK_ASSIGNEE_FAILURE,
    payload: error,
  }),

  copyTaskRequest: data => ({
    type: actionTypes.COPY_TASK_REQUEST,
    data,
  }),
  copyTaskSuccess: task => ({
    type: actionTypes.COPY_TASK_SUCCESS,
    payload: task,
  }),
  copyTaskFailure: error => ({
    type: actionTypes.COPY_TASK_FAILURE,
    payload: error,
  }),

  moveTaskRequest: data => ({
    type: actionTypes.MOVE_TASK_REQUEST,
    data,
  }),
  moveTaskSuccess: data => ({
    type: actionTypes.MOVE_TASK_SUCCESS,
    payload: data,
  }),
  moveTaskFailure: error => ({
    type: actionTypes.MOVE_TASK_FAILURE,
    payload: error,
  }),
  moveTaskLocal: data => ({
    type: actionTypes.MOVE_TASK_LOCAL,
    payload: data,
  }),

  setTaskRepeatRequest: data => ({
    type: actionTypes.SET_TASK_REPEAT_REQUEST,
    data,
  }),
  setTaskRepeatSuccess: data => ({
    type: actionTypes.SET_TASK_REPEAT_SUCCESS,
    payload: data,
  }),
  setTaskRepeatFailure: error => ({
    type: actionTypes.SET_TASK_REPEAT_FAILURE,
    payload: error,
  }),

  moveSectionRequest: data => ({
    type: actionTypes.MOVE_SECTION_REQUEST,
    data,
  }),
  moveSectionSuccess: data => ({
    type: actionTypes.MOVE_SECTION_SUCCESS,
    payload: data,
  }),
  moveSectionFailure: error => ({
    type: actionTypes.MOVE_SECTION_FAILURE,
    payload: error,
  }),

  moveSectionLocal: data => ({
    type: actionTypes.MOVE_SECTION_LOCAL,
    payload: data,
  }),

  setTaskDateRequest: data => ({
    type: actionTypes.SET_TASK_DATE_REQUEST,
    data,
  }),
  setTaskDateSuccess: data => ({
    type: actionTypes.SET_TASK_DATE_SUCCESS,
    payload: data,
  }),
  setTaskDateFailure: error => ({
    type: actionTypes.SET_TASK_DATE_FAILURE,
    payload: error,
  }),

  createRelatedTasks: data => ({
    type: actionTypes.CREATE_RELATED_TASKS_REQUEST,
    data,
  }),
  createRelatedTasksSuccess: data => ({
    type: actionTypes.CREATE_RELATED_TASKS_SUCCESS,
    payload: data,
  }),
  createRelatedTasksFailure: error => ({
    type: actionTypes.CREATE_RELATED_TASKS_FAILURE,
    payload: error,
  }),

  removeRelatedTasks: data => ({
    type: actionTypes.REMOVE_RELATED_TASKS_REQUEST,
    data,
  }),
  removeRelatedTasksSuccess: data => ({
    type: actionTypes.REMOVE_RELATED_TASKS_SUCCESS,
    payload: data,
  }),
  removeRelatedTasksFailure: error => ({
    type: actionTypes.REMOVE_RELATED_TASKS_FAILURE,
    payload: error,
  }),

  fetchTaskSubtasksRequest: data => ({
    type: actionTypes.FETCH_TASK_SUBTASKS_REQUEST,
    data,
  }),
  fetchTaskSubtasksSuccess: data => ({
    type: actionTypes.FETCH_TASK_SUBTASKS_SUCCESS,
    payload: data,
  }),
  fetchTaskSubtasksFailure: error => ({
    type: actionTypes.FETCH_TASK_SUBTASKS_FAILURE,
    payload: error,
  }),

  removeTaskSubtasks: data => ({
    type: actionTypes.REMOVE_TASK_SUBTASKS,
    payload: data,
  }),

  createSubtaskSuccess: data => ({
    type: actionTypes.CREATE_SUBTASK_SUCCESS,
    payload: data,
  }),

  moveSubtask: data => ({
    type: actionTypes.MOVE_SUBTASK_REQUEST,
    data,
  }),
  moveSubtaskSuccess: data => ({
    type: actionTypes.MOVE_SUBTASK_SUCCESS,
    payload: data,
  }),
  moveSubtaskFailure: error => ({
    type: actionTypes.MOVE_SUBTASK_FAILURE,
    payload: error,
  }),
  moveSubtaskLocal: data => ({
    type: actionTypes.MOVE_SUBTASK_LOCAL,
    payload: data,
  }),
};
