const companiesActions = {
  GET_COMPANY_LIST_REQUEST: 'GET_COMPANY_LIST_REQUEST',
  GET_COMPANY_LIST_SUCCESS: 'GET_COMPANY_LIST_SUCCESS',
  GET_COMPANY_LIST_FAILURE: 'GET_COMPANY_LIST_FAILURE',
  ADD_COMPANY: 'ADD_COMPANY',

  CREATE_COMPANY_REQUEST: 'CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE: 'CREATE_COMPANY_FAILURE',

  UPDATE_COMPANY_REQUEST: 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',

  REMOVE_COMPANY_REQUEST: 'REMOVE_COMPANY_REQUEST',
  REMOVE_COMPANY_SUCCESS: 'REMOVE_COMPANY_SUCCESS',
  REMOVE_COMPANY_FAILURE: 'REMOVE_COMPANY_FAILURE',

  GET_COMPANY_USERS_REQUEST: 'GET_COMPANY_USERS_REQUEST',
  GET_COMPANY_USERS_SUCCESS: 'GET_COMPANY_USERS_SUCCESS',
  GET_COMPANY_USERS_FAILURE: 'GET_COMPANY_USERS_FAILURE',

  SELECT_COMPANY: 'SELECT_COMPANY',
  SELECT_COMPANY_SUCCESS: 'SELECT_COMPANY_SUCCESS',

  UPGRADE_COMPANY_PAYMENT_REQUEST: 'UPGRADE_COMPANY_PAYMENT_REQUEST',
  UPGRADE_COMPANY_PAYMENT_SUCCESS: 'UPGRADE_COMPANY_PAYMENT_SUCCESS',
  UPGRADE_COMPANY_PAYMENT_FAILURE: 'UPGRADE_COMPANY_PAYMENT_FAILURE',

  SET_COMPANY_END_TRIAL_DATE: 'SET_COMPANY_END_TRIAL_DATE',

  SET_COMPANY_TRIAL_DATA_TO_DEFAULT: 'SET_COMPANY_TRIAL_DATA_TO_DEFAULT',

  CHECK_COMPANY_TRIAL_NOTIFICATION_REQUEST:
    'CHECK_COMPANY_TRIAL_NOTIFICATION_REQUEST',
  CHECK_COMPANY_TRIAL_NOTIFICATION_SUCCESS:
    'CHECK_COMPANY_TRIAL_NOTIFICATION_SUCCESS',
  CHECK_COMPANY_TRIAL_NOTIFICATION_FAILURE:
    'CHECK_COMPANY_TRIAL_NOTIFICATION_FAILURE',

  getCompanyListRequest: () => ({
    type: companiesActions.GET_COMPANY_LIST_REQUEST,
  }),
  getCompanyListSuccess: companies => ({
    type: companiesActions.GET_COMPANY_LIST_SUCCESS,
    payload: companies,
  }),
  getCompanyListFailure: error => ({
    type: companiesActions.GET_COMPANY_LIST_FAILURE,
    payload: error,
  }),

  addCompany: state => ({
    type: companiesActions.ADD_COMPANY,
    payload: state,
  }),

  createCompanyRequest: data => ({
    type: companiesActions.CREATE_COMPANY_REQUEST,
    data,
  }),
  createCompanySuccess: company => ({
    type: companiesActions.CREATE_COMPANY_SUCCESS,
    payload: company,
  }),
  createCompanyFailure: error => ({
    type: companiesActions.CREATE_COMPANY_FAILURE,
    payload: error,
  }),

  updateCompanyRequest: data => ({
    type: companiesActions.UPDATE_COMPANY_REQUEST,
    data,
  }),
  updateCompanySuccess: company => ({
    type: companiesActions.UPDATE_COMPANY_SUCCESS,
    payload: company,
  }),
  updateCompanyFailure: error => ({
    type: companiesActions.UPDATE_COMPANY_FAILURE,
    payload: error,
  }),

  removeCompanyRequest: id => ({
    type: companiesActions.REMOVE_COMPANY_REQUEST,
    id,
  }),
  removeCompanySuccess: id => ({
    type: companiesActions.REMOVE_COMPANY_SUCCESS,
    payload: id,
  }),
  removeCompanyFailure: error => ({
    type: companiesActions.REMOVE_COMPANY_FAILURE,
    payload: error,
  }),

  getCompanyUsersRequest: id => ({
    type: companiesActions.GET_COMPANY_USERS_REQUEST,
    id,
  }),
  getCompanyUsersSuccess: data => ({
    type: companiesActions.GET_COMPANY_USERS_SUCCESS,
    payload: data,
  }),
  getCompanyUsersFailure: error => ({
    type: companiesActions.GET_COMPANY_USERS_FAILURE,
    payload: error,
  }),

  selectCompany: data => ({
    type: companiesActions.SELECT_COMPANY,
    data,
  }),
  selectCompanySuccess: id => ({
    type: companiesActions.SELECT_COMPANY_SUCCESS,
    payload: id,
  }),

  upgradeCompanyPaymentRequest: data => ({
    type: companiesActions.UPGRADE_COMPANY_PAYMENT_REQUEST,
    data,
  }),
  upgradeCompanyPaymentSuccess: () => ({
    type: companiesActions.UPGRADE_COMPANY_PAYMENT_SUCCESS,
  }),
  upgradeCompanyPaymentFailure: error => ({
    type: companiesActions.UPGRADE_COMPANY_PAYMENT_FAILURE,
    payload: error,
  }),

  setEndTrialDate: data => ({
    type: companiesActions.SET_COMPANY_END_TRIAL_DATE,
    payload: data,
  }),

  setCompanyTrialDateToDefault: () => ({
    type: companiesActions.SET_COMPANY_TRIAL_DATA_TO_DEFAULT,
  }),

  checkCompanyTrialNotification: data => ({
    type: companiesActions.CHECK_COMPANY_TRIAL_NOTIFICATION_REQUEST,
    data,
  }),
  checkCompanyTrialNotificationSuccess: data => ({
    type: companiesActions.CHECK_COMPANY_TRIAL_NOTIFICATION_SUCCESS,
    payload: data,
  }),
  checkCompanyTrialNotificationFailure: error => ({
    type: companiesActions.CHECK_COMPANY_TRIAL_NOTIFICATION_FAILURE,
    payload: error,
  }),
};

export default companiesActions;
