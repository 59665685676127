import React from 'react';

const PdfIcon = ({ fill = '#DB5565', width = 20, height = 16 }) => {
  fill = fill || '#DB5565';

  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43053 1.21777C9.78039 1.36984 9.70227 1.60003 9.58676 2.68201C9.46793 3.80305 9.0732 5.84484 8.31116 7.84925C7.54829 9.85614 6.41645 11.8256 5.43004 13.2857C4.44529 14.7442 3.60098 15.6923 2.95861 16.2391C2.31291 16.7859 1.86666 16.9314 1.57497 16.9812C1.28578 17.0311 1.15448 16.9812 1.07637 16.8358C0.998251 16.6904 0.970828 16.4452 1.03814 16.1552C1.10379 15.8619 1.26085 15.5211 1.70627 15.0857C2.15585 14.6478 2.8913 14.1134 3.9176 13.6131C4.9439 13.1154 6.25773 12.6525 7.33721 12.3375C8.4167 12.0209 9.25768 11.8514 9.99396 11.7184C10.7319 11.5846 11.3626 11.4874 11.9801 11.4392C12.5992 11.3894 13.205 11.3894 13.7967 11.4392C14.3883 11.4874 14.9676 11.5846 15.4678 11.7043C15.9673 11.8265 16.3886 11.9727 16.7567 12.2046C17.124 12.4348 17.4407 12.7514 17.5977 13.1037C17.7573 13.4569 17.7573 13.8458 17.6251 14.125C17.4938 14.4043 17.2296 14.5755 16.9279 14.671C16.6263 14.7683 16.2839 14.7924 15.8759 14.671C15.4678 14.5497 14.9925 14.2829 14.4939 13.9289C13.9953 13.5774 13.4684 13.1411 12.7845 12.4597C12.1006 11.7783 11.2579 10.8525 10.5482 9.9542C9.83856 9.05421 9.25768 8.17916 8.86295 7.43789C8.46988 6.6958 8.2588 6.08833 8.08762 5.4792C7.91643 4.87173 7.78513 4.26426 7.73277 3.71745C7.68042 3.16899 7.70535 2.68284 7.75853 2.29642C7.81089 1.90585 7.8915 1.61499 8.02197 1.42054C8.15327 1.22525 8.33858 1.12802 8.46988 1.07982C8.60118 1.02996 8.68096 1.02996 8.75907 1.01916C8.83719 1.00752 8.9153 0.982592 9.0358 1.01916C9.15048 1.0524 9.30339 1.14547 9.43053 1.21777V1.21777Z"
        stroke={fill}
        strokeWidth="1.57"
      />
    </svg>
  );
};

export default PdfIcon;
