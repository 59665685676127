import { takeEvery, put, call } from 'redux-saga/effects';

import pushNotifications from 'services/pushNotifications';
import actions from './actions';

function* initPushNotificationsFlowSaga() {
  try {
    yield pushNotifications.initServiceWorker();

    const subscription = yield call([pushNotifications, 'getUserSubscription']);
    const permissionNotSettled = yield call([
      pushNotifications,
      'isNotificationsNotSettled',
    ]);
    const permissionGranted = yield call([
      pushNotifications,
      'isNotificationsGranted',
    ]);
    const permissionDenied = yield call([
      pushNotifications,
      'isNotificationsDenied',
    ]);

    if (!subscription && permissionDenied) {
      pushNotifications.trackChangingNotificationsPermission();
    }

    if (!subscription && (permissionNotSettled || permissionGranted)) {
      pushNotifications.initPushNotificationProcess(null);
    }

    if (subscription) {
      pushNotifications.initPushNotificationProcess(subscription);
    }
  } catch (error) {
    yield put(actions.initPushNotificationsFlowError(error));
  }
}

export default function* pushNotificationsSaga() {
  yield takeEvery(
    actions.INIT_PUSH_NOTIFICATIONS_FLOW,
    initPushNotificationsFlowSaga
  );
}
