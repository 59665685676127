export default (state, action) => ({
  assigneeSop: () => {
    return {
      ...state,
      selectedProject: {
        ...state.selectedProject,
        section: state.selectedProject.section.map(section => {
          return {
            ...section,
            tasks: section.tasks
              ? section.tasks.map(task => ({
                  ...task,
                  assignedTo: action.payload,
                }))
              : section.tasks,
          };
        }),
      },
    };
  },

  setSopDueDate: () => {
    return {
      ...state,
      selectedProject: {
        ...state.selectedProject,
        section: state.selectedProject.section.map(section => {
          return {
            ...section,
            tasks: section.tasks
              ? section.tasks.map(task => ({
                  ...task,
                  dueDate: action.payload,
                }))
              : section.tasks,
          };
        }),
      },
    };
  },

  setSopEffectiveDate: () => {
    return {
      ...state,
      selectedProject: {
        ...state.selectedProject,
        effectiveDate: action.payload.effectiveDate,
        daysToComplete: action.payload.daysToComplete,
      },
    };
  },
});
