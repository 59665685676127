export default {
  apiKey: 'AIzaSyACdxHoVNl11wmsBxPUJOZA77MZAIozvT0',
};

export const apiKey = 'AIzaSyDpBlSTUrs0d7x80jS3gVPXqPX_Lt7eZp8';

export const mapboxConfig = {
  tileLayer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  maxZoom: 18,
  defaultZoom: 11,
  center: [40.706877, -74.011265],
};
