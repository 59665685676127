import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

  .ant-time-picker-redesign {
    & + div .ant-time-picker-panel {
      .ant-time-picker-panel-select li:hover {
background-color: ${({ theme }) => theme.colorSheet.lightGreen} !important;
      }

      .ant-time-picker-panel-input-wrap, .ant-time-picker-panel-select li {
        font-family: ${({ theme }) => theme.fonts.karla} !important;
        font-size: 14px;
        color: ${({ theme }) => theme.colorSheet.textColor};
      }
    }
  }

  .ant-select-dropdown-redesign-tags {

    .ant-select-dropdown-menu {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      
      padding: 6px;
    }

    .ant-select-dropdown-menu-item {
      padding: 0 !important;
    }

    .ant-select-dropdown-menu-item:hover, .ant-select-dropdown-menu-item-active {
      background-color: transparent !important;
    }
  }

  .ant-menu-submenu {
    .ant-menu-sub {
      padding: 6px 0 6px 0 !important;
      max-height: 400px;
      overflow-x: hidden;

      .ant-menu-item {
        padding: 0 10px !important;
        height: 32px !important;
        display: flex;
        align-items: center;
        margin: 0px !important;

        min-width: 150px;
        
        &:hover {
          background: ${({ theme }) => theme.colorSheet.lightGreen} !important;
          color: inherit;
        }
      }

      .ant-menu-item-selected {
        color: ${({ theme }) => theme.colorSheet.textColor} !important;
        background-color: transparent !important;
        p {
          font-weight: bold;
        }
      }
    }
  }

  .ant-select-dropdown-redesign {
    .ant-select-dropdown-menu-item {
      font-family: ${({ theme }) => theme.fonts.karla} !important;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.colorSheet.textColor};
    }

     .ant-select-dropdown-menu-item-selected {
      font-weight: 700;
     }

    .ant-select-dropdown-menu-item:hover, .ant-select-dropdown-menu-item-active {
      background-color: ${({ theme }) =>
        theme.colorSheet.lightGreen} !important;
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background:  ${({ theme }) =>
      process.env.REACT_APP_LOCATIONS_PAGE_TABLE_VIEW === 'true' &&
      theme.colorSheet
        ? theme.colorSheet.lightGreen
        : '#f8f8f8'} !important;
  }
  
  .ant-modal-mask {
    background-color: ${() =>
      process.env.REACT_APP_LOCATIONS_PAGE_TABLE_VIEW === 'true'
        ? 'rgb(108,141,145,.7)'
        : 'rgba(0, 0, 0, 0.7)'} !important;
  }

  .custom-tooltip {
    max-width: 500px !important;

    .ant-tooltip-inner {
      background-color: ${({ theme }) => theme.colorSheet.orange} !important;

      font-family: Karla, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0em;
    }

    .ant-tooltip-arrow::before {
      background-color: ${({ theme }) => theme.colorSheet.orange} !important;
    }
  } 
  
  .tooltip-file-from {
    .ant-tooltip-arrow::before {
   background-color: ${({ theme }) => theme.colorSheet.white} !important;
}

.ant-tooltip-inner {
   color: ${({ theme }) => theme.colorSheet.textColor} !important;
   background-color: ${({ theme }) => theme.colorSheet.white} !important;
}
  }

  @media not all and (min-resolution:.001dpcm) { @media {
    .ant-table-fixed-right { 
      overflow: initial !important;
    }
  }}
`;

export default GlobalStyles;
