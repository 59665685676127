export default [
  {
    name: 'Address',
    key: 'address',
    active: false,
  },
  {
    name: 'APN',
    key: 'apn',
    active: false,
  },
  {
    name: 'Business Status',
    key: 'businessStatus',
    active: false,
  },
  {
    name: 'Brand',
    key: 'brand',
    active: false,
  },
  {
    name: 'City',
    key: 'city',
    active: false,
  },
  {
    name: 'Community Benefit Fee',
    key: 'communityBenefitFee',
    active: false,
  },
  {
    name: 'Construction Transfer to Building Date',
    key: 'constructionTransferToBuildingDate',
    active: false,
  },
  {
    name: 'County',
    key: 'county',
    active: false,
  },
  {
    name: 'Entitlement Transfer to Planning Date',
    key: 'entitlementTransferToPlanningDate',
    active: false,
  },
  {
    name: 'Lease or Purchase',
    key: 'leaseOrPurchase',
    active: false,
  },
  {
    name: 'License Application Date',
    key: 'licenseApplicationDate',
    active: false,
  },
  {
    name: 'License Status',
    key: 'licenseStatus',
    active: false,
  },
  {
    name: 'License Type',
    key: 'licenseType',
    active: false,
  },
  {
    name: 'Local License Expiration',
    key: 'localLicenseExpiration',
    active: false,
  },
  {
    name: 'Opening Date',
    key: 'openingDate',
    active: false,
  },
  {
    name: 'Owners',
    key: 'owner',
    active: false,
  },
  {
    name: 'Phone',
    key: 'phone',
    active: false,
  },
  {
    name: 'Property Status',
    key: 'propertyStatus',
    active: false,
  },
  {
    name: 'Project Manager',
    key: 'projectManager',
    active: false,
  },
  {
    name: 'Population',
    key: 'population',
    active: false,
  },
  {
    name: 'Revenues',
    key: 'revenues',
    active: false,
  },
  {
    name: 'State',
    key: 'state',
    active: false,
  },
  {
    name: 'Square Footage',
    key: 'squareFootage',
    active: false,
  },
  {
    name: 'Store hours',
    key: 'storeHours',
    active: false,
  },
  {
    name: 'Stage',
    key: 'stage',
    active: false,
  },
  {
    name: 'State License Number',
    key: 'stateLicenseNumber',
    active: false,
  },
  {
    name: 'State License Expiration',
    key: 'stateLicenseExpiration',
    active: false,
  },
  {
    name: 'Tax Rate',
    key: 'taxRate',
    active: false,
  },
  {
    name: 'Zip Code',
    key: 'zipCode',
    active: false,
  },
];
