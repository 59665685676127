import React from 'react';

const FolderIcon = ({ fill = 'black', width = 20, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8 0Z"
        fill={fill}
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default FolderIcon;
