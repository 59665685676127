const actions = {
  GET_CALENDAR_AUTH_URL_REQUEST: 'GET_CALENDAR_AUTH_URL_REQUEST',
  GET_CALENDAR_AUTH_URL_SUCCESS: 'GET_CALENDAR_AUTH_URL_SUCCESS',
  GET_CALENDAR_AUTH_URL_FAILURE: 'GET_CALENDAR_AUTH_URL_FAILURE',

  SET_CALENDAR_TOKEN_REQUEST: 'SET_CALENDAR_TOKEN_REQUEST',
  SET_CALENDAR_TOKEN_SUCCESS: 'SET_CALENDAR_TOKEN_SUCCESS',
  SET_CALENDAR_TOKEN_FAILURE: 'SET_CALENDAR_TOKEN_FAILURE',

  DISCONNECT_CALENDAR_REQUEST: 'DISCONNECT_CALENDAR_REQUEST',
  DISCONNECT_CALENDAR_SUCCESS: 'DISCONNECT_CALENDAR_SUCCESS',
  DISCONNECT_CALENDAR_FAILURE: 'DISCONNECT_CALENDAR_FAILURE',

  CHECK_CALENDAR_STATUS_REQUEST: 'CHECK_CALENDAR_STATUS_REQUEST',
  CHECK_CALENDAR_STATUS_SUCCESS: 'CHECK_CALENDAR_STATUS_SUCCESS',
  CHECK_CALENDAR_STATUS_FAILURE: 'CHECK_CALENDAR_STATUS_FAILURE',
  CHECK_CALENDAR_STATUSES_SUCCESS: 'CHECK_CALENDAR_STATUSES_SUCCESS',

  SET_STATUS_TO_INITIAL_STATE: 'SET_STATUS_TO_INITIAL_STATE',

  checkCalendarStatusesSuccess: data => ({
    type: actions.CHECK_CALENDAR_STATUSES_SUCCESS,
    data,
  }),
  checkCalendarStatus: data => ({
    type: actions.CHECK_CALENDAR_STATUS_REQUEST,
    data,
  }),
  checkCalendarStatusSuccess: data => ({
    type: actions.CHECK_CALENDAR_STATUS_SUCCESS,
    payload: data,
  }),
  checkCalendarStatusFailure: error => ({
    type: actions.CHECK_CALENDAR_STATUS_FAILURE,
    payload: error,
  }),

  getCalendarAuthUrl: data => ({
    type: actions.GET_CALENDAR_AUTH_URL_REQUEST,
    data,
  }),
  getCalendarAuthUrlSuccess: data => ({
    type: actions.GET_CALENDAR_AUTH_URL_SUCCESS,
    payload: data,
  }),
  getCalendarAuthUrlFailure: error => ({
    type: actions.GET_CALENDAR_AUTH_URL_FAILURE,
    payload: error,
  }),

  setCalendarToken: data => ({
    type: actions.SET_CALENDAR_TOKEN_REQUEST,
    data,
  }),
  setCalendarTokenSuccess: data => ({
    type: actions.SET_CALENDAR_TOKEN_SUCCESS,
    payload: data,
  }),
  setCalendarTokenFailure: error => ({
    type: actions.SET_CALENDAR_TOKEN_FAILURE,
    payload: error,
  }),

  disconnectCalendar: data => ({
    type: actions.DISCONNECT_CALENDAR_REQUEST,
    data,
  }),
  disconnectCalendarSuccess: () => ({
    type: actions.DISCONNECT_CALENDAR_SUCCESS,
  }),
  disconnectCalendarFailure: error => ({
    type: actions.DISCONNECT_CALENDAR_FAILURE,
    payload: error,
  }),

  setStatusToInitianalState: () => ({
    type: actions.SET_STATUS_TO_INITIAL_STATE,
  }),
};

export default actions;
