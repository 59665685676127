import axios from 'axios';

import config from '@iso/config/site.config';
const { apiUrl } = config;

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export default {
  subscription: null,
  syncPushNotifications: async data => {
    const token = localStorage.getItem('id_token');

    if (token) headers.Authorization = `Bearer ${token}`;

    await axios.request({
      url: `${apiUrl}push-notifications/sync`,
      method: 'post',
      headers,
      data,
    });
  },
  fetchPushNotificationsPublicKey: async function() {
    const token = localStorage.getItem('id_token');

    if (token) headers.Authorization = `Bearer ${token}`;

    const response = await axios.request({
      url: `${apiUrl}push-notifications/public-key`,
      method: 'get',
      headers,
    });

    if (response.data && response.data.publicKey) {
      return response.data.publicKey;
    }

    return '';
  },
  initServiceWorker: async function() {
    const swUrl = `/push-notifications-sw.js`;

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      this.checkValidServiceWorker(swUrl);
    } else {
      // Is not localhost. Just register service worker
      await this.registerServiceWorker(swUrl);
    }
  },
  initPushNotificationProcess: async function(subscription) {
    const pushNotificationIsSupported = this.isPushNotificationSupported();

    if (pushNotificationIsSupported) {
      try {
        this.subscription = subscription;

        if (!subscription) {
          await this.askUserPermission();
        } else {
          this.syncPushNotifications({
            subscription,
            active: true,
          });

          await this.trackChangingNotificationsPermission();
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  checkValidServiceWorker: function(swUrl) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
      .then(response => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          this.registerServiceWorker(swUrl);
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  urlBase64ToUint8Array: function(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  },
  getUserSubscription: async function() {
    const serviceWorker = await navigator.serviceWorker.ready;
    const userSubscription = await serviceWorker.pushManager.getSubscription();

    return userSubscription;
  },
  createNotificationSubscription: async function() {
    //wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    // subscribe and return the subscription
    const pushServerPublicKey = await this.fetchPushNotificationsPublicKey();
    let subscription = null;

    if (pushServerPublicKey) {
      subscription = await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(pushServerPublicKey),
      });
    }

    return subscription;
  },
  registerServiceWorker: function(swUrl) {
    return navigator.serviceWorker.register(swUrl, { scope: '/' });
  },
  isNotificationsNotSettled: function() {
    return Notification.permission === 'default';
  },
  isNotificationsGranted: function() {
    return Notification.permission === 'granted';
  },
  isNotificationsDenied: function() {
    return Notification.permission === 'denied';
  },
  askUserPermission: async function() {
    const askUserPermissionHandler = async () => {
      const consent = await Notification.requestPermission();

      if (consent === 'granted') {
        document.removeEventListener('click', askUserPermissionHandler);

        const subscription = await this.createNotificationSubscription();
        this.subscription = subscription;

        await this.trackChangingNotificationsPermission();

        this.syncPushNotifications({
          subscription,
          active: true,
        });
      }
    };

    if (navigator.userAgent.indexOf('Firefox') > -1) {
      document.addEventListener('click', askUserPermissionHandler);
    } else {
      askUserPermissionHandler();
    }
  },
  isPushNotificationSupported: function() {
    return 'serviceWorker' in navigator && 'PushManager' in window;
  },
  trackChangingNotificationsPermission: async function() {
    if ('permissions' in navigator) {
      const notificationPerm = await navigator.permissions.query({
        name: 'notifications',
      });

      notificationPerm.onchange = async () => {
        if (
          !!this.subscription &&
          (notificationPerm.state === 'prompt' ||
            notificationPerm.state === 'denied')
        ) {
          this.syncPushNotifications({
            subscription: this.subscription,
            active: false,
          });

          this.subscription = null;
        }

        if (notificationPerm.state === 'granted') {
          const subscription = await this.createNotificationSubscription();
          this.subscription = subscription;

          await this.trackChangingNotificationsPermission();

          this.syncPushNotifications({
            subscription,
            active: true,
          });
        }
      };
    }
  },
};
