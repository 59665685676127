export default {
  GET_LICENSE_LIST_REQUEST: 'GET_LICENSE_LIST_REQUEST',
  GET_LICENSE_LIST_SUCCESS: 'GET_LICENSE_LIST_SUCCESS',
  GET_LICENSE_LIST_FAILURE: 'GET_LICENSE_LIST_FAILURE',

  CREATE_LICENSE_REQUEST: 'CREATE_LICENSE_REQUEST',
  CREATE_LICENSE_SUCCESS: 'CREATE_LICENSE_SUCCESS',
  CREATE_LICENSE_FAILURE: 'CREATE_LICENSE_FAILURE',

  UPDATE_LICENSE_REQUEST: 'UPDATE_LICENSE_REQUEST',
  UPDATE_LICENSE_SUCCESS: 'UPDATE_LICENSE_SUCCESS',
  UPDATE_LICENSE_FAILURE: 'UPDATE_LICENSE_FAILURE',

  HIDE_LICENSE_REQUEST: 'HIDE_LICENSE_REQUEST',
  HIDE_LICENSE_SUCCESS: 'HIDE_LICENSE_SUCCESS',
  HIDE_LICENSE_FAILURE: 'HIDE_LICENSE_FAILURE',

  REMOVE_LICENSE_REQUEST: 'REMOVE_LICENSE_REQUEST',
  REMOVE_LICENSE_SUCCESS: 'REMOVE_LICENSE_SUCCESS',
  REMOVE_LICENSE_FAILURE: 'REMOVE_LICENSE_FAILURE',

  CREATE_LICENSE_STEP_REQUEST: 'CREATE_LICENSE_STEP_REQUEST',
  CREATE_LICENSE_STEP_SUCCESS: 'CREATE_LICENSE_STEP_SUCCESS',
  CREATE_LICENSE_STEP_FAILURE: 'CREATE_LICENSE_STEP_FAILURE',

  UPDATE_LICENSE_STEP_REQUEST: 'UPDATE_LICENSE_STEP_REQUEST',
  UPDATE_LICENSE_STEP_SUCCESS: 'UPDATE_LICENSE_STEP_SUCCESS',
  UPDATE_LICENSE_STEP_FAILURE: 'UPDATE_LICENSE_STEP_FAILURE',

  REMOVE_LICENSE_STEP_REQUEST: 'REMOVE_LICENSE_STEP_REQUEST',
  REMOVE_LICENSE_STEP_SUCCESS: 'REMOVE_LICENSE_STEP_SUCCESS',
  REMOVE_LICENSE_STEP_FAILURE: 'REMOVE_LICENSE_STEP_FAILURE',

  SET_LICENSE_ASSIGNEE_REQUEST: 'SET_LICENSE_ASSIGNEE_REQUEST',
  SET_LICENSE_ASSIGNEE_SUCCESS: 'SET_LICENSE_ASSIGNEE_SUCCESS',
  SET_LICENSE_ASSIGNEE_FAILURE: 'SET_LICENSE_ASSIGNEE_FAILURE',

  UNSET_LICENSE_ASSIGNEE_REQUEST: 'UNSET_LICENSE_ASSIGNEE_REQUEST',
  UNSET_LICENSE_ASSIGNEE_SUCCESS: 'UNSET_LICENSE_ASSIGNEE_SUCCESS',
  UNSET_LICENSE_ASSIGNEE_FAILURE: 'UNSET_LICENSE_ASSIGNEE_FAILURE',

  ADD_LICENSE_MEMBER_REQUEST: 'ADD_LICENSE_MEMBER_REQUEST',
  ADD_LICENSE_MEMBER_SUCCESS: 'ADD_LICENSE_MEMBER_SUCCESS',
  ADD_LICENSE_MEMBER_FAILURE: 'ADD_LICENSE_MEMBER_FAILURE',

  REMOVE_LICENSE_MEMBER_REQUEST: 'REMOVE_LICENSE_MEMBER_REQUEST',
  REMOVE_LICENSE_MEMBER_SUCCESS: 'REMOVE_LICENSE_MEMBER_SUCCESS',
  REMOVE_LICENSE_MEMBER_FAILURE: 'REMOVE_LICENSE_MEMBER_FAILURE',

  SET_LICENSE_FILTERS: 'SET_LICENSE_FILTERS',
  SET_LICENSE_SORT: 'SET_LICENSE_SORT',

  MOVE_STEP_REQUEST: 'MOVE_STEP_REQUEST',
  MOVE_STEP_SUCCESS: 'MOVE_STEP_SUCCESS',
  MOVE_STEP_FAILURE: 'MOVE_STEP_FAILURE',
  MOVE_STEP_LOCAL: 'MOVE_STEP_LOCAL',

  ATTACH_STEP_PROJECT_REQUEST: 'ATTACH_STEP_PROJECT_REQUEST',
  ATTACH_STEP_PROJECT_SUCCESS: 'ATTACH_STEP_PROJECT_SUCCESS',
  ATTACH_STEP_PROJECT_FAILURE: 'ATTACH_STEP_PROJECT_FAILURE',

  REMOVE_STEP_PROJECT_REQUEST: 'REMOVE_STEP_PROJECT_REQUEST',
  REMOVE_STEP_PROJECT_SUCCESS: 'REMOVE_STEP_PROJECT_SUCCESS',
  REMOVE_STEP_PROJECT_FAILURE: 'REMOVE_STEP_PROJECT_FAILURE',

  GET_AUDIT_LICENSE_LIST_REQUEST: 'GET_AUDIT_LICENSE_LIST_REQUEST',
  GET_AUDIT_LICENSE_LIST_SUCCESS: 'GET_AUDIT_LICENSE_LIST_SUCCESS',
  GET_AUDIT_LICENSE_LIST_FAILURE: 'GET_AUDIT_LICENSE_LIST_FAILURE',

  GET_LICENSE_LOGS_REQUEST: 'GET_LICENSE_LOGS_REQUEST',
  GET_LICENSE_LOGS_SUCCESS: 'GET_LICENSE_LOGS_SUCCESS',
  GET_LICENSE_LOGS_FAILURE: 'GET_LICENSE_LOGS_FAILURE',

  MOVE_LICENSE_REQUEST: 'MOVE_LICENSE_REQUEST',
  MOVE_LICENSE_SUCCESS: 'MOVE_LICENSE_SUCCESS',
  MOVE_LICENSE_FAILURE: 'MOVE_LICENSE_FAILURE',
  MOVE_LICENSE_LOCAL: 'MOVE_LICENSE_LOCAL',

  SET_CREATED_LICENSE_FLAG_TO_FALSE: 'SET_CREATED_LICENSE_FLAG_TO_FALSE',

  CREATE_STEP_DATE_REQUEST: 'CREATE_STEP_DATE_REQUEST',
  CREATE_STEP_DATE_SUCCESS: 'CREATE_STEP_DATE_SUCCESS',
  CREATE_STEP_DATE_FAILURE: 'CREATE_STEP_DATE_FAILURE',

  UPDATE_STEP_DATE_REQUEST: 'UPDATE_STEP_DATE_REQUEST',
  UPDATE_STEP_DATE_SUCCESS: 'UPDATE_STEP_DATE_SUCCESS',
  UPDATE_STEP_DATE_FAILURE: 'UPDATE_STEP_DATE_FAILURE',

  DELETE_STEP_DATE_REQUEST: 'DELETE_STEP_DATE_REQUEST',
  DELETE_STEP_DATE_SUCCESS: 'DELETE_STEP_DATE_SUCCESS',
  DELETE_STEP_DATE_FAILURE: 'DELETE_STEP_DATE_FAILURE',

  ASSIGN_BUSINESS_TO_LICENSE_REQUEST: 'ASSIGN_BUSINESS_TO_LICENSE_REQUEST',
  ASSIGN_BUSINESS_TO_LICENSE_SUCCESS: 'ASSIGN_BUSINESS_TO_LICENSE_SUCCESS',
  ASSIGN_BUSINESS_TO_LICENSE_FAILURE: 'ASSIGN_BUSINESS_TO_LICENSE_FAILURE',

  CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_REQUEST:
    'CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_REQUEST',
  CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_SUCCESS:
    'CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_SUCCESS',
  CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_FAILURE:
    'CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_FAILURE',
};
