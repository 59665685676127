import React from 'react';

const ExelIcon = ({ fill = '#02955F', width = 20, height = 16 }) => {
  fill = fill || '#02955F';

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92014 15.8616V0L0 1.61387V14.1803L8.92014 15.8616ZM15.4545 1.84784H9.30348V2.70231H11.5332V4.34007H9.30348V4.83139H11.5332V6.46915H9.30348V6.96048H11.5332V8.59824H9.30348V9.08957H11.5332V10.7273H9.30348V11.2187H11.5332V12.8564H9.30348V14.0492H15.4545V1.84784ZM14.5319 2.70231H11.9946V4.34007H14.5319V2.70231ZM11.9946 4.83139H14.5319V6.46915H11.9946V4.83139ZM14.5319 6.96048H11.9946V8.59824H14.5319V6.96048ZM11.9946 9.08957H14.5319V10.7273H11.9946V9.08957ZM14.5319 11.2187H11.9946V12.8564H14.5319V11.2187Z"
        fill={fill}
      />
    </svg>
  );
};

export default ExelIcon;
