import styled from 'styled-components';

export const ErrorBoundaryWrapper = styled.div`
  height: 500px;
  display: flex;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;

  .Container {
    text-align: center;
    position: relative;
  }

  .MainTitle {
    display: block;
    font-size: 2rem;
    text-align: center;
  }

  .MainDescription {
    max-width: 50%;
    font-size: 1.2rem;
    font-weight: lighter;
  }

  .MainGraphic {
    position: relative;
  }

  .Cog {
    width: 10rem;
    height: 10rem;
    fill: #333; /*#6AAFE6*/
    transition: easeInOutQuint();
    animation: CogAnimation 5s infinite;
  }

  @keyframes CogAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
