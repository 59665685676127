const recursiveUpdatingResource = (resource, payload) => {
  let resources = {};

  if (resource.id === payload.resourceId) {
    resources = {
      ...resource,
      ...(payload.users && { restrictedUsers: payload.users }),
      ...(payload.teams && { restrictedTeams: payload.teams }),
    };
  } else if (resource.nested) {
    resources = {
      ...resource,
      nested: [
        ...resource.nested.map(nestedFolder =>
          recursiveUpdatingResource(nestedFolder, payload)
        ),
      ],
    };
  } else {
    resources = {
      ...resource,
    };
  }

  return resources;
};

export default (state, action) => ({
  updateResources: () => {
    return {
      ...state,
      data: recursiveUpdatingResource(state.data, action.payload),
      loadingRoot: false,
    };
  },
});
