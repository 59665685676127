const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  UPDATE_TOKEN: 'UPDATE_TOKEN',

  SEND_RESET_PASSWORD_REQUEST: 'SEND_RESET_PASSWORD_REQUEST',
  SEND_RESET_PASSWORD_REQUEST: 'SEND_RESET_PASSWORD_REQUEST',
  SEND_RESET_PASSWORD_SUCCESS: 'SEND_RESET_PASSWORD_SUCCESS',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',

  VERIFY_ACCOUNT_REQUEST: 'VERIFY_ACCOUNT_REQUEST',
  VERIFY_ACCOUNT_SUCCESS: 'VERIFY_ACCOUNT_SUCCESS',
  VERIFY_ACCOUNT_FAILURE: 'VERIFY_ACCOUNT_FAILURE',

  CREATE_PASSWORD_AND_COMPANY_REQUEST: 'CREATE_PASSWORD_AND_COMPANY_REQUEST',
  CREATE_PASSWORD_AND_COMPANY_SUCCESS: 'CREATE_PASSWORD_AND_COMPANY_SUCCESS',
  CREATE_PASSWORD_AND_COMPANY_FAILURE: 'CREATE_PASSWORD_AND_COMPANY_FAILURE',

  RESEND_VERIFICATION_EMAIL_REQUEST: 'RESEND_VERIFICATION_EMAIL_REQUEST',
  RESEND_VERIFICATION_EMAIL_SUCCESS: 'RESEND_VERIFICATION_EMAIL_SUCCESS',
  RESEND_VERIFICATION_EMAIL_FAILURE: 'RESEND_VERIFICATION_EMAIL_FAILURE',

  TOKEN_UNEXIST: 'TOKEN_UNEXIST',

  resendVerifyEmail: data => ({
    type: actions.RESEND_VERIFICATION_EMAIL_REQUEST,
    payload: data,
  }),
  resendVerifyEmailSuccess: () => ({
    type: actions.RESEND_VERIFICATION_EMAIL_SUCCESS,
  }),
  resendVerifyEmailFailure: error => ({
    type: actions.RESEND_VERIFICATION_EMAIL_FAILURE,
    payload: error,
  }),

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  tokenUnexist: () => ({ type: actions.TOKEN_UNEXIST }),
  login: payload => ({
    type: actions.LOGIN_REQUEST,
    payload,
  }),
  // loginSuccess: () => ({
  //   type: actions.LOGIN_REQUEST,
  // }),
  loginFailure: error => ({
    type: actions.LOGIN_ERROR,
    payload: error,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  logoutSuccess: () => ({
    type: actions.LOGOUT_SUCCESS,
  }),
  updateToken: payload => ({
    type: actions.UPDATE_TOKEN,
    payload,
  }),

  sendResetPasswordRequest: data => ({
    type: actions.SEND_RESET_PASSWORD_REQUEST,
    data,
  }),
  sendResetPasswordSuccess: () => ({
    type: actions.SEND_RESET_PASSWORD_SUCCESS,
  }),
  sendResetPasswordFailure: error => ({
    type: actions.SEND_RESET_PASSWORD_FAILURE,
    payload: error,
  }),

  resetPasswordRequest: data => ({
    type: actions.RESET_PASSWORD_REQUEST,
    data,
  }),
  resetPasswordSuccess: () => ({
    type: actions.RESET_PASSWORD_SUCCESS,
  }),
  resetPasswordFailure: error => ({
    type: actions.RESET_PASSWORD_FAILURE,
    payload: error,
  }),

  signUpRequest: data => ({
    type: actions.SIGN_UP_REQUEST,
    data,
  }),
  signUpSuccess: () => ({
    type: actions.SIGN_UP_SUCCESS,
  }),
  signUpFailure: error => ({
    type: actions.SIGN_UP_FAILURE,
    payload: error,
  }),

  createAccount: data => ({
    type: actions.CREATE_ACCOUNT_REQUEST,
    data,
  }),
  createAccountSuccess: () => ({
    type: actions.CREATE_ACCOUNT_SUCCESS,
  }),
  createAccountFailure: error => ({
    type: actions.CREATE_ACCOUNT_FAILURE,
    payload: error,
  }),

  verifyAccount: data => ({
    type: actions.VERIFY_ACCOUNT_REQUEST,
    data,
  }),
  verifyAccountSuccess: data => ({
    type: actions.VERIFY_ACCOUNT_SUCCESS,
    payload: data,
  }),
  verifyAccountFailure: error => ({
    type: actions.VERIFY_ACCOUNT_FAILURE,
    payload: error,
  }),

  createPasswordAndCompany: data => ({
    type: actions.CREATE_PASSWORD_AND_COMPANY_REQUEST,
    data,
  }),
  createPasswordAndCompanySuccess: data => ({
    type: actions.CREATE_PASSWORD_AND_COMPANY_SUCCESS,
    payload: data,
  }),
  createPasswordAndCompanyFailure: error => ({
    type: actions.CREATE_PASSWORD_AND_COMPANY_FAILURE,
    payload: error,
  }),
};

export default actions;
