import React from 'react';

const SearchIcon = ({ fill = '#BABABA', height = 14, width = 14 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M9.77242 8.59545L13.6957 12.5187L12.5187 13.6957L8.63111 9.77242V9.1661L8.38145 8.95211C7.43037 9.76053 6.32473 10.1647 5.06454 10.1647C3.66168 10.1647 2.46688 9.67136 1.48013 8.68461C0.493376 7.69786 0 6.50306 0 5.1002C0 3.69735 0.493376 2.4966 1.48013 1.49796C2.46688 0.49932 3.6498 0 5.02887 0C6.43173 0 7.62653 0.49932 8.61328 1.49796C9.60003 2.4966 10.0934 3.69735 10.0934 5.1002C10.0934 6.36039 9.6892 7.46603 8.88077 8.41712L9.13043 8.59545H9.77242ZM5.06454 8.59545C6.0394 8.59545 6.8716 8.25662 7.56114 7.57897C8.25068 6.90132 8.59545 6.07507 8.59545 5.1002C8.59545 4.12534 8.25068 3.29314 7.56114 2.6036C6.8716 1.91406 6.0394 1.56929 5.06454 1.56929C4.08967 1.56929 3.26342 1.91406 2.58577 2.6036C1.90812 3.29314 1.56929 4.12534 1.56929 5.1002C1.56929 6.07507 1.90812 6.90132 2.58577 7.57897C3.26342 8.25662 4.08967 8.59545 5.06454 8.59545Z"
    />
  </svg>
);

export default SearchIcon;
