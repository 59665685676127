const actions = {
  INIT_PUSH_NOTIFICATIONS_FLOW: 'INIT_PUSH_NOTIFICATIONS_FLOW',
  INIT_PUSH_NOTIFICATIONS_FLOW_ERROR: 'INIT_PUSH_NOTIFICATIONS_FLOW_ERROR',

  initPushNotificationsFlow: () => ({
    type: actions.INIT_PUSH_NOTIFICATIONS_FLOW,
  }),
  initPushNotificationsFlowError: error => ({
    type: actions.INIT_PUSH_NOTIFICATIONS_FLOW_ERROR,
    payload: error,
  }),
};

export default actions;
