import Cultivator from './Cultivator.svg';
import Distributor from './Distributor.svg';
import General from './General.svg';
import Manufacturer from './Manufacturer.svg';
import Packaging from './Packaging and Labeling.svg';
import Retailer from './Retailer.svg';
import retailer_delivery from './retailer_delivery.svg';

export default {
  Cultivator,
  General,
  Distributor,
  Manufacturer,
  'Packaging and Labeling': Packaging,
  Retailer,
  'Retailer - Delivery': retailer_delivery,
};
