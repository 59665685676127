import actionTypes from './actionTypes';

export default {
  fetchData: data => ({ type: actionTypes.FETCH_DATA, data }),
  fetchDataSuccess: projects => ({
    type: actionTypes.FETCH_DATA_SUCCESS,
    payload: projects,
  }),
  fetchDataFailure: error => ({
    type: actionTypes.FETCH_DATA_FAILURE,
    payload: error,
  }),

  fetchProjectsListDataRequest: () => ({
    type: actionTypes.FETCH_PROJECTS_LIST_DATA_REQUEST,
  }),
  fetchProjectsListDataSuccess: projects => ({
    type: actionTypes.FETCH_PROJECTS_LIST_DATA_SUCCESS,
    payload: projects,
  }),
  fetchProjectsListDataFailure: error => ({
    type: actionTypes.FETCH_PROJECTS_LIST_DATA_FAILURE,
    payload: error,
  }),
  fetchProjectAvatarUrl: data => ({
    type: actionTypes.FETCH_PROJECT_AVATAR_URL_REQUEST,
    data,
  }),

  getAuditProjectRequest: id => ({
    type: actionTypes.GET_AUDIT_PROJECT_REQUEST,
    id,
  }),
  getAuditProjectSuccess: project => ({
    type: actionTypes.GET_AUDIT_PROJECT_SUCCESS,
    payload: project,
  }),
  getAuditProjectFailure: error => ({
    type: actionTypes.GET_AUDIT_PROJECT_FAILURE,
    payload: error,
  }),

  getAuditProjectListRequest: data => ({
    type: actionTypes.GET_AUDIT_PROJECT_LIST_REQUEST,
    data,
  }),
  getAuditProjectListSuccess: projects => ({
    type: actionTypes.GET_AUDIT_PROJECT_LIST_SUCCESS,
    payload: projects,
  }),
  getAuditProjectListFailure: error => ({
    type: actionTypes.GET_AUDIT_PROJECT_LIST_FAILURE,
    payload: error,
  }),

  getProjectRequest: id => ({
    type: actionTypes.GET_PROJECT_REQUEST,
    id,
  }),
  getProjectSuccess: project => ({
    type: actionTypes.GET_PROJECT_SUCCESS,
    payload: project,
  }),
  getProjectFailure: error => ({
    type: actionTypes.GET_PROJECT_FAILURE,
    payload: error,
  }),

  getProjectParts: id => ({
    type: actionTypes.GET_PROJECT_PARTS,
    id,
  }),
  getProjectDetailsRequest: id => ({
    type: actionTypes.GET_PROJECT_DETAILS_REQUEST,
    id,
  }),
  getProjectDetailsSuccess: project => ({
    type: actionTypes.GET_PROJECT_DETAILS_SUCCESS,
    payload: project,
  }),
  getProjectDetailsFailure: error => ({
    type: actionTypes.GET_PROJECT_DETAILS_FAILURE,
    payload: error,
  }),

  getProjectSectionRequest: id => ({
    type: actionTypes.GET_PROJECT_SECTION_REQUEST,
    id,
  }),
  getProjectSectionSuccess: payload => ({
    type: actionTypes.GET_PROJECT_SECTION_SUCCESS,
    payload,
  }),
  getProjectSectionFailure: error => ({
    type: actionTypes.GET_PROJECT_SECTION_FAILURE,
    payload: error,
  }),

  getProjectFilesRequest: id => ({
    type: actionTypes.GET_PROJECT_FILES_REQUEST,
    id,
  }),
  getProjectFilesSuccess: payload => ({
    type: actionTypes.GET_PROJECT_FILES_SUCCESS,
    payload,
  }),
  getProjectFilesFailure: error => ({
    type: actionTypes.GET_PROJECT_FILES_FAILURE,
    payload: error,
  }),

  getProjectUsersAndTeamsRequest: id => ({
    type: actionTypes.GET_PROJECT_USERS_AND_TEAMS_REQUEST,
    id,
  }),
  getProjectUsersAndTeamsSuccess: payload => ({
    type: actionTypes.GET_PROJECT_USERS_AND_TEAMS_SUCCESS,
    payload,
  }),
  getProjectUsersAndTeamsFailure: error => ({
    type: actionTypes.GET_PROJECT_USERS_AND_TEAMS_FAILURE,
    payload: error,
  }),

  setProjectViewed: () => ({ type: actionTypes.SET_PROJECT_VIEWED }),

  createProjectRequest: data => ({
    type: actionTypes.CREATE_PROJECT_REQUEST,
    data,
  }),
  createProjectUseTemplateRequest: data => ({
    type: actionTypes.CREATE_PROJECT_USE_TEMPLATE,
    payload: data,
  }),
  createProjectSuccess: project => ({
    type: actionTypes.CREATE_PROJECT_SUCCESS,
    payload: project,
  }),

  createProjectFailure: error => ({
    type: actionTypes.CREATE_PROJECT_FAILURE,
    payload: error,
  }),

  resetTitleIsExistError: () => ({
    type: actionTypes.RESET_TITLE_EXIST_ERROR,
  }),

  updateProjectRequest: data => ({
    type: actionTypes.UPDATE_PROJECT_REQUEST,
    data,
  }),
  updateProjectSuccess: project => ({
    type: actionTypes.UPDATE_PROJECT_SUCCESS,
    payload: project,
  }),
  updateProjectFailure: error => ({
    type: actionTypes.UPDATE_PROJECT_FAILURE,
    payload: error,
  }),

  removeProjectRequest: id => ({
    type: actionTypes.REMOVE_PROJECT_REQUEST,
    id,
  }),
  removeProjectSuccess: id => ({
    type: actionTypes.REMOVE_PROJECT_SUCCESS,
    payload: id,
  }),
  removeProjectFailure: error => ({
    type: actionTypes.REMOVE_PROJECT_FAILURE,
    payload: error,
  }),

  publishProjectRequest: data => ({
    type: actionTypes.PUBLISH_PROJECT_REQUEST,
    data,
  }),
  publishProjectSuccess: project => ({
    type: actionTypes.PUBLISH_PROJECT_SUCCESS,
    payload: project,
  }),
  publishProjectFailure: error => ({
    type: actionTypes.PUBLISH_PROJECT_FAILURE,
    payload: error,
  }),

  convertToSOPRequest: id => ({
    type: actionTypes.CONVERT_TO_SOP_REQUEST,
    id,
  }),
  convertToSOPSuccess: project => ({
    type: actionTypes.CONVERT_TO_SOP_SUCCESS,
    payload: project,
  }),
  convertToSOPFailure: error => ({
    type: actionTypes.CONVERT_TO_SOP_FAILURE,
    payload: error,
  }),

  getProjectUsersRequest: id => ({
    type: actionTypes.GET_PROJECT_USERS_REQUEST,
    id,
  }),
  getProjectUsersSuccess: users => ({
    type: actionTypes.GET_PROJECT_USERS_SUCCESS,
    payload: users,
  }),
  getProjectUsersFailure: error => ({
    type: actionTypes.GET_PROJECT_USERS_FAILURE,
    payload: error,
  }),

  getConnectedUsersRequest: id => ({
    type: actionTypes.GET_CONNECTED_USERS_REQUEST,
    id,
  }),
  getConnectedUsersSuccess: users => ({
    type: actionTypes.GET_CONNECTED_USERS_SUCCESS,
    payload: users,
  }),
  getConnectedUsersFailure: error => ({
    type: actionTypes.GET_CONNECTED_USERS_FAILURE,
    payload: error,
  }),

  connectUser: data => ({
    type: actionTypes.CONNECT_USER_REQUEST,
    data,
  }),
  connectUserSuccess: user => ({
    type: actionTypes.CONNECT_USER_SUCCESS,
    payload: user,
  }),
  connectUserFailure: error => ({
    type: actionTypes.CONNECT_USER_FAILURE,
    payload: error,
  }),

  disconnectUser: data => ({
    type: actionTypes.DISCONNECT_USER_REQUEST,
    data,
  }),
  disconnectUserSuccess: data => ({
    type: actionTypes.DISCONNECT_USER_SUCCESS,
    payload: data,
  }),
  disconnectUserFailure: error => ({
    type: actionTypes.DISCONNECT_USER_FAILURE,
    payload: error,
  }),
  createProjectLabelRequest: data => ({
    type: actionTypes.CREATE_PROJECT_LABEL_REQUEST,
    data,
  }),
  createProjectLabelSuccess: label => ({
    type: actionTypes.CREATE_PROJECT_LABEL_SUCCESS,
    payload: label,
  }),
  createProjectLabelFailure: error => ({
    type: actionTypes.CREATE_PROJECT_LABEL_FAILURE,
    payload: error,
  }),
  updateProjectLabelRequest: data => ({
    type: actionTypes.UPDATE_PROJECT_LABEL_REQUEST,
    data,
  }),
  updateProjectLabelSuccess: label => ({
    type: actionTypes.UPDATE_PROJECT_LABEL_SUCCESS,
    payload: label,
  }),
  updateProjectLabelFailure: error => ({
    type: actionTypes.UPDATE_PROJECT_LABEL_FAILURE,
    payload: error,
  }),
  removeProjectLabelRequest: data => ({
    type: actionTypes.REMOVE_PROJECT_LABEL_REQUEST,
    data,
  }),
  removeProjectLabelSuccess: data => ({
    type: actionTypes.REMOVE_PROJECT_LABEL_SUCCESS,
    payload: data,
  }),
  removeProjectLabelFailure: error => ({
    type: actionTypes.REMOVE_PROJECT_LABEL_FAILURE,
    payload: error,
  }),

  getProjectLabelsRequest: id => ({
    type: actionTypes.GET_PROJECT_LABELS_REQUEST,
    id,
  }),
  getProjectLabelsSuccess: label => ({
    type: actionTypes.GET_PROJECT_LABELS_SUCCESS,
    payload: label,
  }),
  getProjectLabelsFailure: error => ({
    type: actionTypes.GET_PROJECT_LABELS_FAILURE,
    payload: error,
  }),

  copyProjectRequest: id => ({
    type: actionTypes.COPY_PROJECT_REQUEST,
    id,
  }),
  copyProjectSuccess: project => ({
    type: actionTypes.COPY_PROJECT_SUCCESS,
    payload: project,
  }),
  copyProjectFailure: error => ({
    type: actionTypes.COPY_PROJECT_FAILURE,
    payload: error,
  }),

  getExpiredProjectTasks: id => ({
    type: actionTypes.GET_EXPIRED_PROJECT_TASKS,
    id,
  }),
  getUpcomingProjectTasks: id => ({
    type: actionTypes.GET_UPCOMING_PROJECT_TASKS,
    id,
  }),
  getNotificationProjectTasks: id => ({
    type: actionTypes.GET_NOTIFICATION_PROJECT_TASKS,
    id,
  }),

  getUpcomingTasksRequest: data => ({
    type: actionTypes.GET_UPCOMING_TASKS_REQUEST,
    data,
  }),
  getUpcomingTasksSuccess: data => ({
    type: actionTypes.GET_UPCOMING_TASKS_SUCCESS,
    payload: data,
  }),
  getUpcomingTasksFailure: error => ({
    type: actionTypes.GET_UPCOMING_TASKS_FAILURE,
    payload: error,
  }),

  getExpiredTasksRequest: data => ({
    type: actionTypes.GET_EXPIRED_TASKS_REQUEST,
    data,
  }),
  getExpiredTasksSuccess: data => ({
    type: actionTypes.GET_EXPIRED_TASKS_SUCCESS,
    payload: data,
  }),
  getExpiredTasksFailure: error => ({
    type: actionTypes.GET_EXPIRED_TASKS_FAILURE,
    payload: { error, taskType: 'expired' },
  }),

  getNotificationTasksRequest: id => ({
    type: actionTypes.GET_NOTIFICATION_TASKS_REQUEST,
    id,
  }),
  getNotificationTasksSuccess: data => ({
    type: actionTypes.GET_NOTIFICATION_TASKS_SUCCESS,
    payload: data,
  }),
  getNotificationTasksFailure: error => ({
    type: actionTypes.GET_NOTIFICATION_TASKS_FAILURE,
    payload: error,
  }),

  getAuditLogsRequest: data => ({
    type: actionTypes.GET_AUDIT_LOGS_REQUEST,
    data,
  }),
  getAuditLogsSuccess: data => ({
    type: actionTypes.GET_AUDIT_LOGS_SUCCESS,
    payload: data,
  }),
  getAuditLogsFailure: error => ({
    type: actionTypes.GET_AUDIT_LOGS_FAILURE,
    payload: error,
  }),
  getAssignedTasksRequest: data => ({
    type: actionTypes.GET_ASSIGNED_TASKS_REQUEST,
    data,
  }),
  getAssignedTasksSuccess: data => ({
    type: actionTypes.GET_ASSIGNED_TASKS_SUCCESS,
    payload: data,
  }),
  getAssignedTasksFailure: error => ({
    type: actionTypes.GET_ASSIGNED_TASKS_FAILURE,
    payload: error,
  }),
  setDefaultTaskType: () => ({
    type: actionTypes.SET_DEFAULT_TASK_TYPE,
  }),
  getAssignedTasksTrackerRequest: () => ({
    type: actionTypes.GET_ASSIGNED_TASKS_TRACKER_REQUEST,
  }),
  getAssignedTasksTrackerSuccess: data => ({
    type: actionTypes.GET_ASSIGNED_TASKS_TRACKER_SUCCESS,
    payload: data,
  }),
  getAssignedTasksTrackerFailure: error => ({
    type: actionTypes.GET_ASSIGNED_TASKS_TRACKER_FAILURE,
    payload: error,
  }),
  getUpcomingTasksTrackerRequest: () => ({
    type: actionTypes.GET_UPCOMING_TASKS_TRACKER_REQUEST,
  }),
  getUpcomingTasksTrackerSuccess: data => ({
    type: actionTypes.GET_UPCOMING_TASKS_TRACKER_SUCCESS,
    payload: data,
  }),
  getUpcomingTasksTrackerFailure: error => ({
    type: actionTypes.GET_UPCOMING_TASKS_TRACKER_FAILURE,
    payload: error,
  }),
  getExpiredTasksTrackerRequest: () => ({
    type: actionTypes.GET_EXPIRED_TASKS_TRACKER_REQUEST,
  }),
  getExpiredTasksTrackerSuccess: data => ({
    type: actionTypes.GET_EXPIRED_TASKS_TRACKER_SUCCESS,
    payload: data,
  }),
  getExpiredTasksTrackerFailure: error => ({
    type: actionTypes.GET_EXPIRED_TASKS_TRACKER_FAILURE,
    payload: error,
  }),

  hideColumns: columnsState => ({
    type: actionTypes.HIDE_COLUMNS,
    payload: columnsState,
  }),
  hideColumnsFailure: error => ({
    type: actionTypes.HIDE_COLUMNS_FAILURE,
    payload: error,
  }),
  getDashboardAssignedUsersRequest: () => ({
    type: actionTypes.GET_DASHBOARD_ASSIGNED_USERS_REQUEST,
  }),
  getDashboardAssignedUsersSuccess: data => ({
    type: actionTypes.GET_DASHBOARD_ASSIGNED_USERS_SUCCESS,
    payload: data,
  }),
  getDashboardAssignedUsersFailure: error => ({
    type: actionTypes.GET_DASHBOARD_ASSIGNED_USERS_FAILURE,
    payload: error,
  }),

  getProjectsHomeViewRequest: data => ({
    type: actionTypes.FETCH_PROJECTS_HOME_VIEW_REQUEST,
    data,
  }),
  getProjectsHomeViewSuccess: data => ({
    type: actionTypes.FETCH_PROJECTS_HOME_VIEW_SUCCESS,
    payload: data,
  }),
  getProjectsHomeViewFailure: error => ({
    type: actionTypes.FETCH_PROJECTS_HOME_VIEW_FAILURE,
    payload: error,
  }),

  getChecklistsHomeViewRequest: data => ({
    type: actionTypes.FETCH_CHECKLISTS_HOME_VIEW_REQUEST,
    data,
  }),
  getChecklistsHomeViewSuccess: data => ({
    type: actionTypes.FETCH_CHECKLISTS_HOME_VIEW_SUCCESS,
    payload: data,
  }),
  getChecklistsHomeViewFailure: error => ({
    type: actionTypes.FETCH_CHECKLISTS_HOME_VIEW_FAILURE,
    payload: error,
  }),

  getArchivedProjectsHomeViewRequest: data => ({
    type: actionTypes.FETCH_ARCHIVED_PROJECTS_HOME_VIEW_REQUEST,
    data,
  }),
  getArchivedProjectsHomeViewSuccess: data => ({
    type: actionTypes.FETCH_ARCHIVED_PROJECTS_HOME_VIEW_SUCCESS,
    payload: data,
  }),
  getArchivedProjectsHomeViewFailure: error => ({
    type: actionTypes.FETCH_ARCHIVED_PROJECTS_HOME_VIEW_FAILURE,
    payload: error,
  }),

  updateProjectStarredRequest: data => ({
    type: actionTypes.UPDATE_PROJECT_STARRED_REQUEST,
    data,
  }),
  updateProjectStarredSuccess: data => ({
    type: actionTypes.UPDATE_PROJECT_STARRED_SUCCESS,
    payload: data,
  }),
  updateProjectStarredFailure: error => ({
    type: actionTypes.UPDATE_PROJECT_STARRED_FAILURE,
    payload: error,
  }),

  createProjectImageRequest: data => ({
    type: actionTypes.CREATE_PROJECT_IMAGE_REQUEST,
    data,
  }),
  createProjectImageSuccess: data => ({
    type: actionTypes.CREATE_PROJECT_IMAGE_SUCCESS,
    payload: data,
  }),
  createProjectImageFailure: error => ({
    type: actionTypes.CREATE_PROJECT_IMAGE_FAILURE,
    payload: error,
  }),

  deleteProjectImageRequest: data => ({
    type: actionTypes.DELETE_PROJECT_IMAGE_REQUEST,
    data,
  }),
  deleteProjectImageSuccess: data => ({
    type: actionTypes.DELETE_PROJECT_IMAGE_SUCCESS,
    payload: data,
  }),
  deleteProjectImageFailure: error => ({
    type: actionTypes.DELETE_PROJECT_IMAGE_FAILURE,
    payload: error,
  }),

  updateProjectPermissionRequest: data => ({
    type: actionTypes.UPDATE_PROJECT_PERMISSION_REQUEST,
    data,
  }),
  updateProjectPermissionSuccess: data => ({
    type: actionTypes.UPDATE_PROJECT_PERMISSION_SUCCESS,
    payload: data,
  }),
  updateProjectPermissionFailure: error => ({
    type: actionTypes.UPDATE_PROJECT_PERMISSION_FAILURE,
    payload: error,
  }),

  archiveProjectRequest: data => ({
    type: actionTypes.ARCHIVE_PROJECT_REQUEST,
    data,
  }),
  archiveProjectSuccess: data => ({
    type: actionTypes.ARCHIVE_PROJECT_SUCCESS,
    payload: data,
  }),
  archiveProjectFailure: error => ({
    type: actionTypes.ARCHIVE_PROJECT_FAILURE,
    payload: error,
  }),

  unzipProjectRequest: data => ({
    type: actionTypes.UNZIP_PROJECT_REQUEST,
    data,
  }),
  unzipProjectSuccess: data => ({
    type: actionTypes.UNZIP_PROJECT_SUCCESS,
    payload: data,
  }),
  unzipProjectFailure: error => ({
    type: actionTypes.UNZIP_PROJECT_FAILURE,
    payload: error,
  }),

  removeHomeViewProjectRequest: id => ({
    type: actionTypes.REMOVE_HOME_VIEW_PROJECT_REQUEST,
    id,
  }),
  removeHomeViewProjectSuccess: id => ({
    type: actionTypes.REMOVE_HOME_VIEW_PROJECT_SUCCESS,
    payload: id,
  }),
  removeHomeViewProjectFailure: error => ({
    type: actionTypes.REMOVE_HOME_VIEW_PROJECT_FAILURE,
    payload: error,
  }),

  setFilterState: payload => ({
    type: actionTypes.SET_FILTER_STATE,
    payload,
  }),

  getHomeViewActiveUsersLocationsRequest: () => ({
    type: actionTypes.FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_REQUEST,
  }),
  getHomeViewActiveUsersLocationsSuccess: data => ({
    type: actionTypes.FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_SUCCESS,
    payload: data,
  }),
  getHomeViewActiveUsersLocationsFailure: error => ({
    type: actionTypes.FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_FAILURE,
    payload: error,
  }),

  updateProjectAdminTypeRequest: data => ({
    type: actionTypes.UPDATE_PROJECT_ADMIN_TYPE_REQUEST,
    data,
  }),
  updateProjectAdminTypeSuccess: data => ({
    type: actionTypes.UPDATE_PROJECT_ADMIN_TYPE_SUCCESS,
    payload: data,
  }),
  updateProjectAdminTypeFailure: error => ({
    type: actionTypes.UPDATE_PROJECT_ADMIN_TYPE_FAILURE,
    payload: error,
  }),

  getProjectsListByNameRequest: data => ({
    type: actionTypes.FETCH_PROJECTS_BY_NAME_REQUEST,
    data,
  }),
  getProjectsListByNameSuccess: data => ({
    type: actionTypes.FETCH_PROJECTS_BY_NAME_SUCCESS,
    payload: data,
  }),
  getProjectsListByNameFailure: error => ({
    type: actionTypes.FETCH_PROJECTS_BY_NAME_FAILURE,
    payload: error,
  }),

  clearProjectUsers: () => ({
    type: actionTypes.CLEAR_PROJECT_USERS,
  }),

  connectUserProjectsHomeRequest: data => ({
    type: actionTypes.CONNECT_USER_PROJECTS_HOME_REQUEST,
    data,
  }),
  connectUserProjectsHomeSuccess: user => ({
    type: actionTypes.CONNECT_USER_PROJECTS_HOME_SUCCESS,
    payload: user,
  }),
  connectUserProjectsHomeFailure: error => ({
    type: actionTypes.CONNECT_USER_PROJECTS_HOME_FAILURE,
    payload: error,
  }),

  disconnectUserProjectsHomeRequest: data => ({
    type: actionTypes.DISCONNECT_USER_PROJECTS_HOME_REQUEST,
    data,
  }),
  disconnectUserProjectsHomeSuccess: data => ({
    type: actionTypes.DISCONNECT_USER_PROJECTS_HOME_SUCCESS,
    payload: data,
  }),
  disconnectUserProjectsHomeFailure: error => ({
    type: actionTypes.DISCONNECT_USER_PROJECTS_HOME_FAILURE,
    payload: error,
  }),

  fetchBusinessProjectsRequest: data => ({
    type: actionTypes.FETCH_BUSINESS_PROJECTS_REQUEST,
    data,
  }),
  fetchBusinessProjectsSuccess: projects => ({
    type: actionTypes.FETCH_BUSINESS_PROJECTS_SUCCESS,
    payload: projects,
  }),
  fetchBusinessProjectsFailure: error => ({
    type: actionTypes.FETCH_BUSINESS_PROJECTS_FAILURE,
    payload: error,
  }),

  clearBusinessProjects: () => ({
    type: actionTypes.CLEAR_BUSINESS_PROJECTS,
  }),

  setEditAvailable: available => ({
    type: actionTypes.SET_CHECKLIST_SOP_EDIT_AVAILABLE,
    payload: available,
  }),

  assignNewUserToTaskRequest: data => ({
    type: actionTypes.ASSIGN_NEW_USER_TO_PROJECT_TASK_REQUEST,
    data,
  }),
  assignNewUserToTaskSuccess: data => ({
    type: actionTypes.ASSIGN_NEW_USER_TO_PROJECT_TASK_SUCCESS,
    payload: data,
  }),
  assignNewUserToTaskFailure: error => ({
    type: actionTypes.ASSIGN_NEW_USER_TO_PROJECT_TASK_FAILURE,
    payload: error,
  }),

  setAssignedUserToNull: () => ({
    type: actionTypes.SET_ASSIGNED_USER_TO_NULL,
  }),

  fetchTaskFileRequest: data => ({
    type: actionTypes.FETCH_TASK_FILE_REQUEST,
    data,
  }),
  fetchTaskFileSuccess: data => ({
    type: actionTypes.FETCH_TASK_FILE_SUCCESS,
    payload: data,
  }),
  fetchTaskFileFailure: error => ({
    type: actionTypes.FETCH_TASK_FILE_FAILURE,
    payload: error,
  }),

  showChangingBusinessPrompt: state => ({
    type: actionTypes.SHOW_CHANGING_BUSINESS_PROMPT,
    payload: state,
  }),

  // TAGS Actions
  fetchProjectTagsRequest: data => ({
    type: actionTypes.FETCH_PROJECT_TAGS_REQUEST,
    data,
  }),
  fetchProjectTagsSuccess: data => ({
    type: actionTypes.FETCH_PROJECT_TAGS_SUCCESS,
    payload: data,
  }),
  fetchProjectTagsFailure: error => ({
    type: actionTypes.FETCH_PROJECT_TAGS_FAILURE,
    payload: error,
  }),

  createProjectTagRequest: data => ({
    type: actionTypes.CREATE_PROJECT_TAG_REQUEST,
    data,
  }),
  createProjectTagSuccess: data => ({
    type: actionTypes.CREATE_PROJECT_TAG_SUCCESS,
    payload: data,
  }),
  createProjectTagFailure: error => ({
    type: actionTypes.CREATE_PROJECT_TAG_FAILURE,
    payload: error,
  }),

  updateProjectTagRequest: data => ({
    type: actionTypes.UPDATE_PROJECT_TAG_REQUEST,
    data,
  }),
  updateProjectTagSuccess: data => ({
    type: actionTypes.UPDATE_PROJECT_TAG_SUCCESS,
    payload: data,
  }),
  updateProjectTagFailure: error => ({
    type: actionTypes.UPDATE_PROJECT_TAG_FAILURE,
    payload: error,
  }),

  deleteProjectTagRequest: data => ({
    type: actionTypes.DELETE_PROJECT_TAG_REQUEST,
    data,
  }),
  deleteProgectTagFromListRequest: data => ({
    type: actionTypes.DELETE_PROJECT_TAG_FROM_LIST_REQUEST,
    data,
  }),
  deleteProgectTagFromListSuccess: data => ({
    type: actionTypes.DELETE_PROJECT_TAG_FROM_LIST_REQUEST_SUCCESS,
    payload: data,
  }),
  deleteProjectTagSuccess: data => ({
    type: actionTypes.DELETE_PROJECT_TAG_SUCCESS,
    payload: data,
  }),
  deleteProjectTagFailure: error => ({
    type: actionTypes.DELETE_PROJECT_TAG_FAILURE,
    payload: error,
  }),

  selectProjectTagRequest: data => ({
    type: actionTypes.SELECT_AVAILABLE_PROJECT_TAG_REQUEST,
    data,
  }),
  selectProjectTagSuccess: data => ({
    type: actionTypes.SELECT_AVAILABLE_PROJECT_TAG_SUCCESS,
    payload: data,
  }),
  selectProjectTagFailure: error => ({
    type: actionTypes.SELECT_AVAILABLE_PROJECT_TAG_FAILURE,
    payload: error,
  }),

  // End TAGS Actions

  updateFilterSettings: data => ({
    type: actionTypes.UPDATE_FILTERS_SETTINGS,
    payload: data,
  }),

  setFilterSettingsByDefault: data => ({
    type: actionTypes.SET_FILTERS_SETTINGS_BY_DEFAULT,
    payload: data,
  }),

  removeFilterSettingsItem: data => ({
    type: actionTypes.REMOVE_FILTER_SETTINGS_ITEM,
    payload: data,
  }),

  setSavedFilterSettingsItem: data => ({
    type: actionTypes.SET_SAVED_FILTER_SETTINGS,
    payload: data,
  }),

  updateProjectDataRequest: data => ({
    type: actionTypes.UPDATE_PROJECT_DATA_AND_PUBLISH_REQUEST,
    data,
  }),

  updateProjectDataSuccess: data => ({
    type: actionTypes.UPDATE_PROJECT_DATA_AND_PUBLISH_SUCCESS,
    payload: data,
  }),

  updateProjectDataFailure: error => ({
    type: actionTypes.UPDATE_PROJECT_DATA_AND_PUBLISH_FAILURE,
    payload: error,
  }),

  fetchTemplateList: data => ({
    type: actionTypes.FETCH_TEMPLATE_LIST_REQUEST,
    data,
  }),
  fetchTemplateListSuccess: data => ({
    type: actionTypes.FETCH_TEMPLATE_LIST_SUCCESS,
    payload: data,
  }),
  fetchTemplateListFailure: error => ({
    type: actionTypes.FETCH_TEMPLATE_LIST_FAILURE,
    payload: error,
  }),

  fetchTemplatesByName: data => ({
    type: actionTypes.FETCH_TEMPLATES_BY_NAME_REQUEST,
    data,
  }),
  fetchTemplatesByNameSuccess: data => ({
    type: actionTypes.FETCH_TEMPLATES_BY_NAME_SUCCESS,
    payload: data,
  }),
  fetchTemplatesByNameFailure: error => ({
    type: actionTypes.FETCH_TEMPLATES_BY_NAME_FAILURE,
    payload: error,
  }),

  fetchSelectedProject: id => ({
    type: actionTypes.FETCH_SELECTED_PROJECT_REQUEST,
    id,
  }),
  fetchSelectedProjectSuccess: project => ({
    type: actionTypes.FETCH_SELECTED_PROJECT_SUCCESS,
    payload: project,
  }),
  fetchSelectedProjectFailure: error => ({
    type: actionTypes.FETCH_SELECTED_PROJECT_FAILURE,
    payload: error,
  }),

  createProjectAvatar: data => ({
    type: actionTypes.CREATE_PROJECT_AVATAR_REQUEST,
    data,
  }),
  createProjectAvatarSuccess: data => ({
    type: actionTypes.CREATE_PROJECT_AVATAR_SUCCESS,
    payload: data,
  }),
  createProjectAvatarFailure: error => ({
    type: actionTypes.CREATE_PROJECT_AVATAR_FAILURE,
    payload: error,
  }),

  fetchProjectAvatarUrl: data => ({
    type: actionTypes.FETCH_PROJECT_AVATAR_URL_REQUEST,
    data,
  }),
  fetchProjectAvatarUrlSuccess: data => ({
    type: actionTypes.FETCH_PROJECT_AVATAR_URL_SUCCESS,
    payload: data,
  }),
  fetchProjectAvatarUrlFailure: error => ({
    type: actionTypes.FETCH_PROJECT_AVATAR_URL_FAILURE,
    payload: error,
  }),

  removeProjectAvatar: data => ({
    type: actionTypes.REMOVE_PROJECT_AVATAR_REQUEST,
    data,
  }),
  removeProjectAvatarSuccess: data => ({
    type: actionTypes.REMOVE_PROJECT_AVATAR_SUCCESS,
    payload: data,
  }),
  removeProjectAvatarFailure: error => ({
    type: actionTypes.REMOVE_PROJECT_AVATAR_FAILURE,
    payload: error,
  }),

  updateProjectCompleting: data => ({
    type: actionTypes.UPDATE_PROJECT_COMPLETING,
    payload: data,
  }),

  changeTemplateType: data => ({
    type: actionTypes.CHANGE_TEMPLATE_TYPE_REQUEST,
    data,
  }),
  changeTemplateTypeSuccess: data => ({
    type: actionTypes.CHANGE_TEMPLATE_TYPE_SUCCESS,
    payload: data,
  }),
  changeTemplateTypeFailure: error => ({
    type: actionTypes.CHANGE_TEMPLATE_TYPE_FAILURE,
    payload: error,
  }),

  assignTeam: data => ({
    type: actionTypes.ASSIGN_TEAM_TO_PROJECT_REQUEST,
    data,
  }),
  assignTeamSuccess: data => ({
    type: actionTypes.ASSIGN_TEAM_TO_PROJECT_SUCCESS,
    payload: data,
  }),
  assignTeamFailure: error => ({
    type: actionTypes.ASSIGN_TEAM_TO_PROJECT_FAILURE,
    payload: error,
  }),

  dismissTeam: data => ({
    type: actionTypes.DISMISS_TEAM_FROM_PROJECT_REQUEST,
    data,
  }),
  dismissTeamSuccess: data => ({
    type: actionTypes.DISMISS_TEAM_FROM_PROJECT_SUCCESS,
    payload: data,
  }),
  dismissTeamFailure: error => ({
    type: actionTypes.DISMISS_TEAM_FROM_PROJECT_FAILURE,
    payload: error,
  }),

  getSimpleProjectsData: () => ({
    type: actionTypes.GET_SIMPLE_PROJECTS_DATA_REQUEST,
  }),

  getSimpleProjectsDataSuccess: data => ({
    type: actionTypes.GET_SIMPLE_PROJECTS_DATA_SUCCESS,
    payload: data,
  }),

  getSimpleProjectsDataFailure: error => ({
    type: actionTypes.GET_SIMPLE_PROJECTS_DATA_FAILURE,
    payload: error,
  }),

  fetchProjectSubtasksRequest: data => ({
    type: actionTypes.FETCH_PROJECT_SUBTASKS_REQUEST,
    data,
  }),
  fetchProjectSubtasksSuccess: payload => ({
    type: actionTypes.FETCH_PROJECT_SUBTASKS_SUCCESS,
    payload,
  }),
  fetchProjectSubtasksFailure: error => ({
    type: actionTypes.FETCH_PROJECT_SUBTASKS_FAILURE,
    payload: error,
  }),

  removeProjectSubtasks: data => ({
    type: actionTypes.REMOVE_PROJECT_SUBTASKS,
    payload: data,
  }),

  getProjectsTagsRequest: () => ({
    type: actionTypes.GET_PROJECTS_TAGS_REQUEST,
  }),

  getProjectsTagsSuccess: payload => ({
    type: actionTypes.GET_PROJECTS_TAGS_SUCCESS,
    payload,
  }),

  getProjectsTagsFailure: () => ({
    type: actionTypes.GET_PROJECTS_TAGS_FAILURE,
  }),

  getTemplatesTagsRequest: () => ({
    type: actionTypes.GET_TEMPLATES_TAGS_REQUEST,
  }),

  getTemplatesTagsSuccess: payload => ({
    type: actionTypes.GET_TEMPLATES_TAGS_SUCCESS,
    payload,
  }),

  getTemplatesTagsFailure: () => ({
    type: actionTypes.GET_TEMPLATES_TAGS_FAILURE,
  }),

  getArchivedProjectsTagsRequest: () => ({
    type: actionTypes.GET_ARCHIVED_PROJECTS_TAGS_REQUEST,
  }),

  getArchivedProjectsTagsSuccess: payload => ({
    type: actionTypes.GET_ARCHIVED_PROJECTS_TAGS_SUCCESS,
    payload,
  }),

  getArchivedProjectsTagsFailure: () => ({
    type: actionTypes.GET_ARCHIVED_PROJECTS_TAGS_FAILURE,
  }),

  projectNameIsTaken: error => ({
    type: actionTypes.PROJECT_NAME_IS_TAKEN_ERROR,
    payload: error,
  }),
};
