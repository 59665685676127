import axios from 'axios';

import config from '@iso/config/site.config';
const { apiUrl } = config;
import Api from './api';

export default baseUrl => ({
  // --Project API--
  getProjectList: data => {
    const api = new Api();
    return api.query('get', `${baseUrl}`, data);
  },

  getProjectsActiveTags: () => {
    const api = new Api();
    return api.query('get', `projects/getProjectsActiveTags`);
  },

  getTemplatesActiveTags: () => {
    const api = new Api();
    return api.query('get', `projects/getTemplatesActiveTags`);
  },

  getArchivedProjectsActiveTags: () => {
    const api = new Api();
    return api.query('get', `projects/getArchivedProjectsActiveTags`);
  },

  getProjectSummary: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/summary`);
  },

  getUpcomingProjectTask: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/upcoming-tasks`);
  },

  getExpiredProjectTask: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/expired-tasks`);
  },

  getNotificationProjectTasks: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/notifications`);
  },

  createProject: data => {
    const api = new Api();

    return api.query('post', `${baseUrl}`, data);
  },

  createProjectFromChecklist: projectId => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/project`);
  },

  createProjectFromSop: projectId => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/internal`);
  },

  createProjectUseTemplate: (temlateId, data) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${temlateId}`, data);
  },

  copyProject: id => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${id}/copy`);
  },

  assigneeSop: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/assign`, data);
  },

  setSopDueDate: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/date`, data);
  },

  removeAssigneeSop: ({ projectId }) => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${projectId}/assign`);
  },

  copyTask: ({ projectId, taskId }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/task/${taskId}/copy`);
  },

  getProject: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}`);
  },

  getProjectDetails: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/details`);
  },

  getProjectSection: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/section`);
  },

  getProjectFiles: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/files`);
  },

  getProjectConnectedUsersTeams: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/connected-users-teams`);
  },

  updateProject: ({ id, projectId, ...data }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${id || projectId}`, data);
  },

  removeProject: id => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${id}`);
  },

  getRecentViews: () => {
    const api = new Api();
    return api.query('get', `${baseUrl}/home-view`);
  },

  getProjectUsers: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/users`);
  },

  getConnectedUsers: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/connected-users`);
  },

  connectUserToProject: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/connected-users`, data);
  },

  disconnectUserFromProject: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${projectId}/connected-users`, {
      data,
    });
  },

  publishProject: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/publish`, data);
  },

  convertToSOP: id => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${id}/sop`);
  },

  getProjectLabels: id => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${id}/labels`);
  },

  createProjectLabel: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/labels`, data);
  },

  updateProjectLabel: ({ projectId, id, ...data }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/labels/${id}`, data);
  },

  removeProjectLabel: ({ projectId, labelId }) => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${projectId}/labels/${labelId}`);
  },

  changeBusiness: ({ projectId, force, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/assign${force ? '?force=true' : ''}`,
      data
    );
  },

  changeBusinessSop: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/business`, data);
  },

  uploadChecklistIcon: ({ projectId, ...data }) => {
    const api = new Api({
      extraHeaders: { 'Content-Type': 'multipart/form-data' },
    });
    return api.query('post', `${baseUrl}/${projectId}/icon`, data.icon);
  },

  removeChecklistIcon: id => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${id}/icon`);
  },
  // --End Project API--

  // --Section API--
  createSection: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/section`, data);
  },

  updateSection: ({ projectId, sectionId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/section/${sectionId}`,
      data
    );
  },

  removeSection: ({ projectId, sectionId }) => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${projectId}/section/${sectionId}`);
  },

  moveSection: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/section/reorder`, data);
  },
  // --End Section API--

  // --Task API--
  createTask: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/task`, data);
  },

  getTask: ({ projectId, taskId }) => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${projectId}/task/${taskId}`);
  },

  updateTask: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/task/${taskId}`, data);
  },

  updateTaskDetails: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/task/${taskId}`, data);
  },

  repeatTask: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/task/${taskId}/repeat`,
      data
    );
  },

  removeTask: ({ projectId, taskId, parentTaskId }) => {
    const api = new Api();
    return api.query(
      'delete',
      `${baseUrl}/${projectId}/task/${taskId}${
        parentTaskId ? `?parentId=${parentTaskId}` : ''
      }`
    );
  },

  createTaskLabel: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'post',
      `${baseUrl}/${projectId}/task/${taskId}/label`,
      data
    );
  },

  removeTaskLabel: ({ projectId, taskId, labelId }) => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${projectId}/task/${taskId}/label`, {
      data: { labelId },
    });
  },

  setTaskAssignee: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/task/${taskId}/assign`,
      data
    );
  },

  removeTaskAssignee: ({ projectId, taskId, assigneeId }) => {
    const api = new Api();
    return api.query(
      'delete',
      `${baseUrl}/${projectId}/task/${taskId}/assign`,
      {
        data: { assigneeId },
      }
    );
  },

  moveTask: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/task/${taskId}/move`,
      data
    );
  },

  setTaskDate: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/task/${taskId}/date`,
      data
    );
  },

  createRelatedTasks: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/task/${taskId}/createRelatedTask`,
      data
    );
  },

  removeRelatedTasks: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'delete',
      `${baseUrl}/${projectId}/task/${taskId}/removeRelatedTask`,
      {
        data,
      }
    );
  },
  // --End Task API--

  // --Members API--
  createMember: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'post',
      `${baseUrl}/${projectId}/task/${taskId}/members`,
      data
    );
  },

  getMember: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/task/${taskId}/members`,
      data
    );
  },

  removeMember: ({ projectId, taskId, memberId }) => {
    const api = new Api();
    return api.query(
      'delete',
      `${baseUrl}/${projectId}/task/${taskId}/members`,
      {
        data: { userId: memberId },
      }
    );
  },
  // --End Members API--

  // --Attachment API--
  uploadFile: ({ projectId, file }) => {
    const api = new Api({
      extraHeaders: { 'Content-Type': 'multipart/form-data' },
    });
    return api.query('post', `${baseUrl}/${projectId}/upload`, file);
  },

  createAttachment: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'post',
      `${baseUrl}/${projectId}/task/${taskId}/attachments`,
      data
    );
  },

  deleteAttachment: ({ projectId, taskId, attachmentId }) => {
    const api = new Api();
    return api.query(
      'delete',
      `${baseUrl}/${projectId}/task/${taskId}/attachments`,
      {
        data: { attachmentId },
      }
    );
  },
  // --End Attachment API--

  getSpecifications: () => {
    const api = new Api();
    return api.query('get', `specifications`);
  },

  // --Comments API--
  createComment: ({ projectId, taskId, ...data }) => {
    const api = new Api();
    return api.query(
      'post',
      `${baseUrl}/${projectId}/task/${taskId}/comments`,
      data
    );
  },

  updateComment: ({ projectId, taskId, commentId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/task/${taskId}/comments/${commentId}`,
      data
    );
  },

  removeComment: ({ projectId, taskId, commentId }) => {
    const api = new Api();
    return api.query(
      'delete',
      `${baseUrl}/${projectId}/task/${taskId}/comments/${commentId}`
    );
  },
  // --End Comments API--

  // --New Projects Home view redesign API--
  getHomeViewProjects: data => {
    const api = new Api();
    return api.query('get', `${baseUrl}/home-view/projects`, data);
  },

  getHomeViewTemplates: data => {
    const api = new Api();
    return api.query('get', `${baseUrl}/home-view/templates`, data);
  },

  getHomeViewArchivedProjects: data => {
    const api = new Api();
    return api.query('get', `${baseUrl}/home-view/archived`, data);
  },

  createProjectImage: ({ projectId, image }) => {
    const api = new Api({
      extraHeaders: { 'Content-Type': 'multipart/form-data' },
    });
    return api.query('post', `${baseUrl}/${projectId}/icon`, image);
  },

  deleteProjectImage: ({ projectId }) => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${projectId}/icon`);
  },

  updateProjectStarred: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/settings`, data);
  },

  archiveProject: ({ projectId }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/archive`);
  },

  unzipProject: ({ projectId }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/unzip`);
  },

  getHomeViewActiveUsersLocations: () => {
    const api = new Api();
    return api.query('get', `${baseUrl}/home-view/filter`);
  },

  updateProjectAdminType: ({ id, adminOnly }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${id}/admin`, { adminOnly });
  },

  getProjectsListByName: data => {
    const api = new Api();
    return api.query('get', `${baseUrl}/home-view/find`, data);
  },

  getProjectsList: data => {
    const api = new Api();
    return api.query('get', `${baseUrl}/list`, data);
  },

  fetchTaskFile: ({ projectId, fileId }) => {
    const headers = {};
    const token = localStorage.getItem('id_token');

    if (token) headers.Authorization = `Bearer ${token}`;

    return axios.request({
      url: `${apiUrl}${baseUrl}/${projectId}/attachment?id=${fileId}`,
      method: 'get',
      responseType: 'blob',
      headers,
    });
  },

  getProjectTags: ({ projectId }) => {
    const api = new Api();
    return api.query('get', `${baseUrl}/${projectId}/tag`);
  },

  createProjectTag: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/tag`, data);
  },

  updateProjectTag: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('put', `${baseUrl}/${projectId}/tag`, data);
  },

  deleteProjectTag: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('delete', `${baseUrl}/${projectId}/tag`, {
      data,
    });
  },

  createProjectAttachment: ({ projectId, ...data }) => {
    const api = new Api();
    return api.query('post', `${baseUrl}/${projectId}/attachment`, data);
  },

  createProjectAvatar: ({ projectId, ...data }) => {
    const customizedApi = new Api({
      ...(data.file && {
        extraHeaders: { 'Content-Type': 'multipart/form-data' },
      }),
    });

    return customizedApi.query(
      'post',
      `${baseUrl}/${projectId}/avatar`,
      data.fileUuid ? data : data.file
    );
  },

  removeProjectAvatar: ({ projectId }) => {
    const customizedApi = new Api();
    return customizedApi.query('delete', `${baseUrl}/${projectId}/avatar`);
  },

  changeTemplateType: ({ projectId, ...data }) => {
    const customizedApi = new Api();
    return customizedApi.query('put', `${baseUrl}/${projectId}`, data);
  },
  // --End new Projects API--

  // -- SUBTASKS API --
  fetchProjectAllSubtasks: ({ projectId }) => {
    const customizedApi = new Api();
    return customizedApi.query('get', `${baseUrl}/${projectId}/subtasks`);
  },

  fetchTaskSubtasks: ({ projectId, taskId }) => {
    const customizedApi = new Api();
    return customizedApi.query(
      'get',
      `${baseUrl}/${projectId}/task/${taskId}/subtasks`
    );
  },

  moveSubtask: ({ projectId, subtaskId, ...data }) => {
    const api = new Api();
    return api.query(
      'put',
      `${baseUrl}/${projectId}/task/${subtaskId}/moveSubTask`,
      data
    );
  },
  // --End SUBTASKS API--
});
