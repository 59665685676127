import { takeEvery, put } from 'redux-saga/effects';

import actions from './actions';
import anamiService from '../../services/anamiService';

function* checkCalendarStatusSaga({ data }) {
  try {
    const { calendarType } = data;

    const response = yield anamiService.checkCalendarStatus(calendarType);

    yield put(actions.checkCalendarStatusSuccess(response.data.status));

    //get status of calendars
    const statusesData = {
      type: calendarType,
      value: response.data.status === 'AUTHORIZED' ? true : false,
    };

    yield put(actions.checkCalendarStatusesSuccess(statusesData));
  } catch (error) {
    yield put(actions.checkCalendarStatusFailure(error));
  }
}

function* getCalendarAuthUrlSaga({ data }) {
  try {
    const { calendarType } = data;

    const response = yield anamiService.getCalendarAuthUrl(calendarType);

    yield put(actions.getCalendarAuthUrlSuccess(response.data.authUrl));
  } catch (error) {
    yield put(actions.getCalendarAuthUrlFailure(error));
  }
}

function* setCalendarTokenSaga({ data }) {
  try {
    yield anamiService.setCalendarToken(data);

    yield put(actions.setCalendarTokenSuccess());
  } catch (error) {
    yield put(actions.setCalendarTokenFailure(error));
  }
}

function* disconnectCalendarSaga({ data }) {
  try {
    const { calendarType } = data;

    yield anamiService.revokeCalendarToken(calendarType);

    yield put(actions.disconnectCalendarSuccess());

    const statusesData = {
      type: calendarType,
      value: false,
    };

    yield put(actions.checkCalendarStatusesSuccess(statusesData));
  } catch (error) {
    yield put(actions.disconnectCalendarFailure(error));
  }
}

export default function* projectsCalendarSaga() {
  yield takeEvery(
    actions.CHECK_CALENDAR_STATUS_REQUEST,
    checkCalendarStatusSaga
  );
  yield takeEvery(
    actions.GET_CALENDAR_AUTH_URL_REQUEST,
    getCalendarAuthUrlSaga
  );
  yield takeEvery(actions.SET_CALENDAR_TOKEN_REQUEST, setCalendarTokenSaga);
  yield takeEvery(actions.DISCONNECT_CALENDAR_REQUEST, disconnectCalendarSaga);
}
