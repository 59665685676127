import dropboxActions from './actions';

const initialState = {
  isValid: false,
  isConnected: false,
  authUrl: '',
  processId: localStorage.getItem('dropboxProcessId') || '', //7f9e63be-c327-4c66-88d2-81d5d43d4810
  syncData: {},
  loading: false,
  syncPending: false,
  error: null,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case dropboxActions.GET_DROPBOX_TOKEN_REQUEST:
    case dropboxActions.DISCONNECT_DROPBOX_REQUEST:
    case dropboxActions.CHECK_DROPBOX_TOKEN_REQUEST:
      return { ...state, loading: true, error: null };

    case dropboxActions.INIT_DROPBOX_SYNC_REQUEST:
      return { ...state, loading: true, syncPending: true, error: null };

    case dropboxActions.CHECK_DROPBOX_SYNC_REQUEST:
      return { ...state, error: null };

    case dropboxActions.GET_DROPBOX_AUTH_URL_REQUEST:
      return { ...state, loading: true, error: null, authUrl: '' };

    case dropboxActions.GET_DROPBOX_AUTH_URL_FAILURE:
    case dropboxActions.GET_DROPBOX_TOKEN_FAILURE:
    case dropboxActions.DISCONNECT_DROPBOX_FAILURE:
    case dropboxActions.CHECK_DROPBOX_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case dropboxActions.INIT_DROPBOX_SYNC_FAILURE:
      return {
        ...state,
        loading: false,
        syncPending: false,
        error: action.payload,
      };

    case dropboxActions.CHECK_DROPBOX_SYNC_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case dropboxActions.GET_DROPBOX_TOKEN_SUCCESS:
    case dropboxActions.CHECK_DROPBOX_TOKEN_SUCCESS:
      return {
        ...state,
        isValid: action.payload.isValid,
        isConnected: action.payload.isConnected,
        loading: false,
        error: null,
      };

    case dropboxActions.INIT_DROPBOX_SYNC_SUCCESS:
      return {
        ...state,
        processId: action.payload.id,
        syncData: action.payload.syncData,
        loading: false,
        syncPending: false,
        error: null,
      };

    case dropboxActions.CHECK_DROPBOX_SYNC_SUCCESS:
      return {
        ...state,
        syncData: action.payload,
        error: null,
      };

    case dropboxActions.GET_DROPBOX_AUTH_URL_SUCCESS:
      return {
        ...state,
        authUrl: action.payload,
        loading: false,
        error: null,
      };

    case dropboxActions.DISCONNECT_DROPBOX_SUCCESS:
    case dropboxActions.CLEAR_DROPBOX_INFO:
      localStorage.setItem('dropboxProcessId', '');
      return {
        ...state,
        processId: '',
        syncData: {},
        isValid: false,
        isConnected: false,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}
