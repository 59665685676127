import _ from 'lodash';

const getFullProjectOrTask = (state, id, taskId) => {
  let item = null;

  if (state.selectedProject && id === state.selectedProject.id) {
    item = state.selectedProject;
  } else {
    item = state.data.find(x => {
      const result = taskId
        ? x.id === id || (x.projectId === id && x.id === taskId)
        : x.id === id ||
          x.projectId === id ||
          (x.project && x.project.id === id);

      return result;
    });
  }

  return item;
};

export default function helpers(state, action) {
  return {
    moveSubtaskLocal: () => ({
      ...state,
      selectedTask: {
        ...state.selectedTask,
        subtasks: action.payload.moveParams.subtasks,
      },
      selectedProject: {
        ...state.selectedProject,
        section: state.selectedProject.section.map(section => {
          if (section.id === action.payload.sectionId) {
            return {
              ...section,
              tasks: section.tasks.map(task => {
                if (task.id === action.payload.parentTaskId) {
                  return {
                    ...task,
                    subtasks: action.payload.moveParams.subtasks,
                  };
                }

                return task;
              }),
            };
          }

          return section;
        }),
      },
    }),
    setTaskOrSubtaskAssignee: () => {
      if (action.payload.parentTaskId) {
        return helpers(state, action).handleUpdateSubtask();
      } else {
        return helpers(state, action).setTaskAssignee();
      }
    },
    selectTask: () => {
      return { ...state, selectedTask: action.payload };
    },
    handleRemoveSubtask: () => {
      const selectedTask =
        state.selectedTask && state.selectedTask.id
          ? state.selectedTask.id === action.payload.taskId
            ? null
            : {
                ...state.selectedTask,
                subtasks: (state.selectedTask.subtasks || []).filter(
                  subtask => subtask.id !== action.payload.taskId
                ),
              }
          : null;

      return {
        ...state,
        loading: false,
        taskLoading: false,
        error: null,
        selectedTask,
        selectedProject: {
          ...state.selectedProject,
          section: state.selectedProject.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.parentTaskId) {
                    return {
                      ...task,
                      subtasks: (task.subtasks || []).filter(
                        subtask => subtask.id !== action.payload.taskId
                      ),
                    };
                  }

                  return task;
                }),
              };
            }

            return section;
          }),
        },
      };
    },
    removeTaskOrSubtask: () => {
      if (action.payload.parentTaskId) {
        return helpers(state, action).handleRemoveSubtask();
      } else {
        return helpers(state, action).removeTask();
      }
    },
    handleCreatingSubtask: () => {
      const newSubtask = {
        ...action.payload.task,
        isNewSubtask: true,
      };

      return {
        ...state,
        loading: false,
        taskLoading: false,
        error: null,
        selectedTask: {
          ...state.selectedTask,
          subtasks: [
            ...(state.selectedTask.subtasks ? state.selectedTask.subtasks : []),
            newSubtask,
          ],
        },
        selectedProject: {
          ...state.selectedProject,
          section: state.selectedProject.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.parentTaskId) {
                    return {
                      ...task,
                      hasSubtasks: true,
                      subtasks: [
                        ...(task.subtasks ? task.subtasks : []),
                        newSubtask,
                      ],
                    };
                  }

                  return task;
                }),
              };
            }

            return section;
          }),
        },
      };
    },
    updateTaskOrSubtask: () => {
      if (action.payload.parentTaskId) {
        return helpers(state, action).handleUpdateSubtask();
      } else {
        return helpers(state, action).updateTask();
      }
    },
    handleUpdateSubtask: () => {
      const selectedTask =
        state.selectedTask && state.selectedTask.id
          ? state.selectedTask.id === action.payload.task.id
            ? {
                ...state.selectedTask,
                ...action.payload.task,
              }
            : {
                ...state.selectedTask,
                subtasks: state.selectedTask.subtasks.map(task => {
                  if (task.id === action.payload.task.id) {
                    return {
                      ...task,
                      ...action.payload.task,
                      isNewSubtask: false,
                    };
                  }

                  return task;
                }),
              }
          : null;

      return {
        ...state,
        loading: false,
        taskLoading: false,
        assignLoading: false,
        error: null,
        selectedTask,
        selectedProject: {
          ...state.selectedProject,
          section: state.selectedProject.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.parentTaskId) {
                    return {
                      ...task,
                      ...(task.subtasks && {
                        subtasks: task.subtasks.map(task => {
                          if (task.id === action.payload.task.id) {
                            return {
                              ...task,
                              ...action.payload.task,
                              isNewSubtask: false,
                            };
                          }

                          return task;
                        }),
                      }),
                    };
                  }

                  return task;
                }),
              };
            }

            return section;
          }),
        },
      };
    },
    handleTaskSubtasks: () => {
      const { subtasks, sectionId, taskId } = action.payload;

      try {
        return {
          ...state,
          subtasksLoading: false,
          ...(state.selectedTask && {
            selectedTask: {
              ...state.selectedTask,
              subtasks: subtasks ? subtasks : [],
            },
          }),
          selectedProject: {
            ...state.selectedProject,
            section: state.selectedProject.section.map(section => {
              if (section.id === sectionId) {
                return {
                  ...section,
                  tasks: section.tasks.map(task => {
                    if (task.id === taskId) {
                      return {
                        ...task,
                        subtasks: subtasks ? subtasks : [],
                      };
                    }

                    return task;
                  }),
                };
              }

              return section;
            }),
          },
        };
      } catch (error) {
        console.log('%c error', 'color: red; font-weight: bold;', error);
      }
    },
    createTask: () => {
      let project = getFullProjectOrTask(state, action.payload.projectId);
      action.payload.isNewTask = true;

      const { movedPosition, setBoardPosition, ...rest } = action.payload;

      project = {
        ...project,
        section: project.section.map(section => {
          if (section.id === rest.sectionId) {
            if (movedPosition >= 0) {
              return section.tasks
                ? {
                    ...section,
                    tasks: [
                      ...section.tasks.slice(0, movedPosition + 1),
                      rest,
                      ...section.tasks.slice(movedPosition + 1),
                    ],
                  }
                : { ...section, tasks: [rest] };
            } else {
              return section.tasks
                ? {
                    ...section,
                    tasks: setBoardPosition
                      ? [rest, ...section.tasks]
                      : [...section.tasks, rest],
                  }
                : { ...section, tasks: [rest] };
            }
          }
          return section;
        }),
      };

      return {
        ...state,
        selectedProject: project,
        loading: false,
        error: null,
      };
    },

    updateTask: () => {
      let project = getFullProjectOrTask(
        state,
        action.payload.projectId,
        action.payload.id
      );

      if (project?.section && _.has(project, 'global')) {
        project = {
          ...project,
          section: project.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.task.id) {
                    return {
                      ...task,
                      ...action.payload.task,
                      isNewTask: false,
                    };
                  }
                  return task;
                }),
              };
            }
            return section;
          }),
        };
      } else {
        project = {
          ...project,
          ...action.payload,
        };
      }

      return {
        ...state,
        selectedTask: state.selectedTask
          ? { ...state.selectedTask, ...action.payload.task, isNewTask: false }
          : state.selectedTask,
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
        loading: false,
        taskLoading: false,
        error: null,
      };
    },

    removeTask: () => {
      let project = getFullProjectOrTask(state, action.payload.projectId);

      project = {
        ...project,
        section: project.section.map(section => {
          if (section.id === action.payload.sectionId) {
            return {
              ...section,
              tasks: section.tasks.filter(
                task => task.id !== action.payload.taskId
              ),
            };
          }
          return section;
        }),
      };

      return {
        ...state,
        selectedTask: null,
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
        taskLoading: false,
        error: null,
      };
    },

    createComment: () => {
      let project = getFullProjectOrTask(state, action.payload.projectId);

      if (project.section && _.has(project, 'global') && !project.comments) {
        project = {
          ...project,
          section: project.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.taskId) {
                    return {
                      ...task,
                      comments: [...task.comments, action.payload],
                    };
                  }
                  return task;
                }),
              };
            }
            return section;
          }),
        };
      } else {
        project = {
          ...project,
          comments: [...project.comments, action.payload],
        };
      }

      return {
        ...state,
        commentLoading: false,
        selectedTask: {
          ...state.selectedTask,
          comments: [...state.selectedTask.comments, action.payload],
        },
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
      };
    },

    updateComment: () => {
      let project = getFullProjectOrTask(state, action.payload.projectId);

      if (project.section && _.has(project, 'global') && !project.comments) {
        project = {
          ...project,
          section: project.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.taskId) {
                    return {
                      ...task,
                      comments: task.comments.map(comment => {
                        if (comment.id === action.payload.comment.id) {
                          return action.payload.comment;
                        }
                        return comment;
                      }),
                    };
                  }
                  return task;
                }),
              };
            }
            return section;
          }),
        };
      } else {
        project = {
          ...project,
          comments: project.comments.map(comment => {
            if (comment.id === action.payload.comment.id) {
              return action.payload.comment;
            }
            return comment;
          }),
        };
      }

      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          comments: state.selectedTask.comments.map(comment => {
            if (comment.id === action.payload.comment.id) {
              return action.payload.comment;
            }
            return comment;
          }),
        },
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
      };
    },

    removeComment: () => {
      let project = getFullProjectOrTask(state, action.payload.projectId);

      if (project.section && _.has(project, 'global') && !project.comments) {
        project = {
          ...project,
          section: project.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.taskId) {
                    return {
                      ...task,
                      comments: task.comments.filter(
                        comment => comment.id !== action.payload.commentId
                      ),
                    };
                  }
                  return task;
                }),
              };
            }
            return section;
          }),
        };
      } else {
        project = {
          ...project,
          comments: project.comments.filter(
            comment => comment.id !== action.payload.commentId
          ),
        };
      }

      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          comments: state.selectedTask.comments.filter(
            comment => comment.id !== action.payload.commentId
          ),
        },
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
      };
    },

    createTaskLabel: () => {
      let project = getFullProjectOrTask(
        state,
        action.payload.projectId,
        action.payload.taskId
      );

      if (project.section && _.has(project, 'global') && !project.labels) {
        project = {
          ...project,
          section: project.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.taskId) {
                    return {
                      ...task,
                      labels: [action.payload.label],
                    };
                  }
                  return task;
                }),
              };
            }
            return section;
          }),
        };
      } else {
        project = {
          ...project,
          labels: [action.payload.label],
        };
      }

      return {
        ...state,
        selectedTask: state.selectedTask
          ? {
              ...state.selectedTask,
              labels: [action.payload.label],
            }
          : state.selectedTask,
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
        loading: false,
        error: null,
      };
    },

    removeTaskLabel: () => {
      let project = getFullProjectOrTask(state, action.payload.projectId);

      project = {
        ...project,
        section: project.section.map(section => {
          return {
            ...section,
            tasks: section.tasks.map(task => {
              return {
                ...task,
                labels:
                  action.payload.taskId === task.id
                    ? task.labels.filter(
                        label => label.id !== action.payload.labelId
                      )
                    : task.labels,
              };
            }),
          };
        }),
      };

      return {
        ...state,
        selectedTask: state.selectedTask
          ? {
              ...state.selectedTask,
              labels: [],
            }
          : state.selectedTask,
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
        loading: false,
        error: null,
      };
    },

    setTaskAssignee: () => {
      const { task: updatedTask, sectionId, projectId } = action.payload;
      let project = getFullProjectOrTask(state, projectId);

      if (project.section && _.has(project, 'global') && !project.assignedTo) {
        project = {
          ...project,
          section: project.section.map(section => {
            if (section.id === sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === updatedTask.id) {
                    return { ...task, ...updatedTask };
                  }
                  return task;
                }),
              };
            }
            return section;
          }),
        };
      } else {
        project = {
          ...project,
          assignedTo: updatedTask.assignedTo,
        };
      }

      return {
        ...state,
        selectedTask: state.selectedTask
          ? { ...state.selectedTask, ...action.payload.task }
          : null,
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
        assignLoading: false,
        loading: false,
        error: null,
      };
    },

    createAttachment: () => {
      let project = getFullProjectOrTask(
        state,
        action.payload.projectId,
        action.payload.taskId
      );

      if (project.section && _.has(project, 'global') && !project.attachments) {
        project = {
          ...project,
          section: project.section.map(section => {
            if (section.id === action.payload.sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === action.payload.taskId) {
                    return {
                      ...task,
                      attachments: [...task.attachments, action.payload],
                    };
                  }
                  return task;
                }),
              };
            }
            return section;
          }),
        };
      } else {
        project = {
          ...project,
          attachments: [...project.attachments, action.payload],
        };
      }

      return {
        ...state,
        selectedTask: state.selectedTask
          ? {
              ...state.selectedTask,
              attachments: [...state.selectedTask.attachments, action.payload],
            }
          : state.selectedTask,
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
      };
    },

    removeAttachment: () => {
      let attachments = state.selectedTask.attachments;
      let index = attachments.findIndex(
        a => a.id === action.payload.attachmentId
      );
      if (index > -1) {
        attachments.splice(index, 1);
      }

      let project = getFullProjectOrTask(state, action.payload.projectId);

      project = {
        ...project,
        section: project.section.map(section => {
          if (section.id === action.payload.sectionId) {
            return {
              ...section,
              tasks: section.tasks.map(task => {
                if (task.id === action.payload.taskId) {
                  return {
                    ...task,
                    attachments: [...attachments],
                  };
                }
                return task;
              }),
            };
          }
          return section;
        }),
      };

      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          attachments: [...attachments],
        },
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
      };
    },

    copyTask: () => {
      let project = getFullProjectOrTask(state, action.payload.projectId);

      project = {
        ...state.selectedProject,
        section: state.selectedProject.section.map(section => {
          if (section.id === action.payload.sectionId) {
            return { ...section, tasks: [...section.tasks, action.payload] };
          }
          return section;
        }),
      };

      return {
        ...state,
        selectedTask: action.payload,
        selectedProject:
          state.selectedProject.id === project.id
            ? project
            : state.selectedProject,
        loading: false,
        error: null,
      };
    },

    moveTaskSuccess: () => ({
      ...state,
      loading: false,
      error: null,
    }),

    moveTaskFailure: () => ({
      ...state,
      selectedProject: {
        ...state.selectedProject,
        section: state.previousProjectState.section,
      },
      loading: false,
      error: action.payload,
    }),

    moveTaskLocal: () => ({
      ...state,
      previousProjectState: state.selectedProject,
      selectedProject: {
        ...state.selectedProject,
        section: state.selectedProject.section.map(section => {
          if (action.payload.destinationId !== action.payload.sourceId) {
            if (section.id === action.payload.sourceId) {
              return { ...section, tasks: action.payload.sourceTaskList };
            }
          }

          if (section.id === action.payload.destinationId) {
            return { ...section, tasks: action.payload.destinationTaskList };
          }

          return section;
        }),
      },
    }),

    updateAssagneeTask: () => {
      const { user, sectionId, taskId } = action.payload;

      return {
        ...state,
        assignLoading: false,
        newAssignedUser: user,
        selectedProject: {
          ...state.selectedProject,
          section: state.selectedProject.section.map(section => {
            if (section.id === sectionId) {
              return {
                ...section,
                tasks: section.tasks.map(task => {
                  if (task.id === taskId) {
                    return {
                      ...task,
                      assignedTo: [...task.assignedTo, user],
                    };
                  }

                  return task;
                }),
              };
            }

            return section;
          }),
        },
        ...(state.selectedTask && {
          selectedTask: {
            ...state.selectedTask,
            assignedTo: [...state.selectedTask.assignedTo, user],
          },
        }),
      };
    },
  };
}
