import usersActions from './actions';

const initialState = {
  data: [],
  user: {},
  roleList: [],
  newUser: false,
  loading: true,
  error: null,
  newTeam: false,
  teamList: [],
  teamsLoading: false,
  inviteLoading: false,
  inviteRedirect: null,
  inviteLink: null,
  inviteSuccess: false,
  inviteInfo: null,
  userExist: null,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case usersActions.UPLOAD_AVATAR_REQUEST:
    case usersActions.REMOVE_USER_REQUEST:
    case usersActions.CREATE_USER_REQUEST:
    case usersActions.GET_USER_REQUEST:
    case usersActions.GET_USER_LIST_REQUEST:
    case usersActions.UPDATE_USER_REQUEST:
    case usersActions.GET_USER_ROLES_REQUEST:
    case usersActions.SET_USER_ROLE_REQUEST:
    case usersActions.UNSET_USER_ROLE_REQUEST:
    case usersActions.UPDATE_SECTIONS_REQUEST:
    case usersActions.GET_USER_TEAMS_REQUEST:
    case usersActions.ADD_USER_TO_TEAM_REQUEST:
    case usersActions.REMOVE_USER_FROM_TEAM_REQUEST:
      return { ...state, loading: true, error: null };

    case usersActions.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };

    case usersActions.SET_USER_ROLE_FAILURE:
    case usersActions.UNSET_USER_ROLE_FAILURE:
    case usersActions.UPLOAD_AVATAR_FAILURE:
    case usersActions.GET_USER_ROLES_FAILURE:
    case usersActions.REMOVE_USER_FAILURE:
    case usersActions.CREATE_USER_FAILURE:
    case usersActions.GET_USER_FAILURE:
    case usersActions.GET_USER_LIST_FAILURE:
    case usersActions.UPDATE_USER_FAILURE:
    case usersActions.UPDATE_SECTIONS_FAILURE:
    case usersActions.GET_USER_TEAMS_FAILURE:
    case usersActions.REMOVE_USER_FROM_TEAM_FAILURE:
    case usersActions.ADD_USER_TO_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case usersActions.SET_USER_ROLE_SUCCESS:
      return {
        ...state,
        data: state.data.map(user => {
          if (user.id === action.payload.id) {
            return {
              ...user,
              ...action.payload,
            };
          }
          return user;
        }),
        loading: false,
        error: null,
      };

    case usersActions.UNSET_USER_ROLE_SUCCESS:
      return {
        ...state,
        data: state.data.map(user => {
          if (user.id === action.payload.userId) {
            return {
              ...user,
              roles: user.roles.filter(role => role.id !== action.payload.id),
            };
          }
          return user;
        }),
        loading: false,
        error: null,
      };

    case usersActions.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };

    case usersActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case usersActions.ADD_USER:
      return {
        ...state,
        newUser: action.payload || false,
      };

    case usersActions.CREATE_USER_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        newUser: false,
        loading: false,
      };

    case usersActions.REMOVE_USER_SUCCESS:
      return {
        ...state,
        data: state.data.filter(user => user.id !== action.payload),
        loading: false,
      };

    case usersActions.GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        roleList: action.payload,
        loading: false,
        error: null,
      };

    case usersActions.UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        user: { ...state.user, avatar: action.payload },
        loading: false,
        error: null,
      };

    case usersActions.UPDATE_SECTIONS_SUCCESS:
      return {
        ...state,
        data: state.data.map(user => {
          if (user.id === action.payload.id) {
            return action.payload;
          }
          return user;
        }),
        loading: false,
        error: null,
      };

    case usersActions.ADD_NEW_TEAM:
      return {
        ...state,
        newTeam: action.payload,
      };

    case usersActions.FETCH_TEAMS_REQUEST:
    case usersActions.CREATE_NEW_TEAM_REQUEST:
    case usersActions.REMOVE_TEAM_REQUEST:
    case usersActions.UPDATE_TEAM_SECTIONS_REQUEST:
    case usersActions.UPDATE_TEAM_TITLE_REQUEST:
    case usersActions.SET_PROJECT_TO_TEAM_REQUEST:
    case usersActions.SET_LOCATION_TO_TEAM_REQUEST:
    case usersActions.UNSET_PROJECT_FROM_TEAM_REQUEST:
    case usersActions.UNSET_LOCATION_FROM_TEAM_REQUEST:
      return {
        ...state,
        teamsLoading: true,
      };

    case usersActions.FETCH_TEAMS_FAILURE:
    case usersActions.CREATE_NEW_TEAM_FAILURE:
    case usersActions.REMOVE_TEAM_FAILURE:
    case usersActions.UPDATE_TEAM_SECTIONS_FAILURE:
    case usersActions.UPDATE_TEAM_TITLE_FAILURE:
    case usersActions.SET_PROJECT_TO_TEAM_FAILURE:
    case usersActions.SET_LOCATION_TO_TEAM_FAILURE:
    case usersActions.UNSET_PROJECT_FROM_TEAM_FAILURE:
    case usersActions.UNSET_LOCATION_FROM_TEAM_FAILURE:
      return {
        ...state,
        teamsLoading: false,
        error: action.payload,
      };

    case usersActions.FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teamsLoading: false,
        teamList: action.payload,
      };

    case usersActions.CREATE_NEW_TEAM_SUCCESS:
      return {
        ...state,
        teamsLoading: false,
        teamList: [...state.teamList, action.payload],
        newTeam: false,
      };

    case usersActions.REMOVE_TEAM_SUCCESS:
      return {
        ...state,
        teamsLoading: false,
        teamList: state.teamList.filter(team => team.id !== action.payload),
      };

    case usersActions.UPDATE_TEAM_SECTIONS_SUCCESS:
    case usersActions.UPDATE_TEAM_TITLE_SUCCESS:
    case usersActions.SET_PROJECT_TO_TEAM_SUCCESS:
    case usersActions.UNSET_PROJECT_FROM_TEAM_SUCCESS:
    case usersActions.SET_LOCATION_TO_TEAM_SUCCESS:
    case usersActions.UNSET_LOCATION_FROM_TEAM_SUCCESS:
      return {
        ...state,
        teamsLoading: false,
        teamList: state.teamList.map(team => {
          if (team.id === action.payload.id) {
            return action.payload;
          }

          return team;
        }),
      };

    case usersActions.GET_USER_TEAMS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };

    case usersActions.ADD_USER_TO_TEAM_SUCCESS:
      return {
        ...state,
        data: state.data.map(user => {
          if (user.id === action.payload.userId) {
            return {
              ...user,
              teams: [
                ...(user.teams || []),
                {
                  id: action.payload.teamId,
                },
              ],
            };
          }
          return user;
        }),
        loading: false,
      };

    case usersActions.REMOVE_USER_FROM_TEAM_SUCCESS:
      return {
        ...state,
        data: state.data.map(user => {
          if (user.id === action.payload.userId) {
            return {
              ...user,
              teams: user.teams.filter(
                team => team.id !== action.payload.teamId
              ),
            };
          }
          return user;
        }),
        loading: false,
      };

    case usersActions.INVITE_USER_WITH_EMAIL:
      return {
        ...state,
        inviteLoading: true,
      };

    case usersActions.INVITE_USER_WITH_EMAIL_SUCCESS:
      return {
        ...state,
        inviteLoading: false,
        inviteRedirect: action.payload,
        inviteSuccess: true,
        userExist: !!action.payload,
      };

    case usersActions.INVITE_USER_WITH_EMAIL_RESET:
      return {
        ...state,
        inviteSuccess: false,
        userExist: null,
      };

    case usersActions.INVITE_USER_WITH_EMAIL_FAILURE:
      return {
        ...state,
        inviteLoading: false,
        error: action.payload,
      };

    case usersActions.GET_INVITE_USER_LINK:
      return {
        ...state,
        inviteLoading: true,
      };

    case usersActions.GET_INVITE_USER_LINK_SUCCESS:
      return {
        ...state,
        inviteLoading: false,
        inviteLink: action.payload,
      };

    case usersActions.GET_INVITE_USER_LINK_FAILURE:
      return {
        ...state,
        inviteLoading: false,
        error: action.payload,
        inviteLink: null,
      };

    case usersActions.CREATE_PASSWORD_FOR_INVITE_USER:
      return {
        ...state,
        inviteLoading: true,
      };

    case usersActions.CREATE_PASSWORD_FOR_INVITE_USER_SUCCESS:
      return {
        ...state,
        inviteLoading: false,
        inviteRedirect: action.payload,
      };

    case usersActions.CREATE_PASSWORD_FOR_INVITE_USER_FAILURE:
      return {
        ...state,
        inviteLoading: false,
        error: action.payload,
      };

    case usersActions.RESET_INVITE_REDIRECT_DATA:
      return {
        ...state,
        inviteRedirect: null,
      };

    case usersActions.CREATE_LINK_INVITE_USER:
      return {
        ...state,
        inviteLoading: true,
      };

    case usersActions.CREATE_LINK_INVITE_USER_SUCCESS:
      return {
        ...state,
        inviteLoading: false,
        inviteRedirect: action.payload,
      };

    case usersActions.CREATE_LINK_INVITE_USER_FAILURE:
      return {
        ...state,
        inviteLoading: false,
        error: action.payload,
      };

    case usersActions.GET_INVITE_EMAIL_SUCCESS:
      return {
        ...state,
        inviteInfo: action.payload,
      };

    case usersActions.CHECK_INVITE_EMAIL_SUCCESS:
      if (action.payload?.isExist) {
        return {
          ...state,
          userExist: action.payload.isExist,
          inviteRedirect: action.payload.data,
          inviteInfo: action.payload.inviter,
        };
      } else {
        return {
          ...state,
          userExist: action.payload.isExist,
          inviteInfo: action.payload.inviter,
        };
      }

    case usersActions.GET_INVITE_EMAIL_FAILURE:
    case usersActions.CHECK_INVITE_EMAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
