import { all, takeEvery, put } from 'redux-saga/effects';
import _ from 'lodash';

import notificationsActions from './actions';
import projectsActions from '../projects/actions';
import anamiService from '../../services/anamiService';
import dashboardActions from '../dashboard/actions';

function* getNotificationList({ data }) {
  try {
    const { fullList, ...params } = data;

    const response = yield anamiService.getDashboardNotificationList(params);

    yield put(notificationsActions.getNotificationListSuccess(response.data));

    if (fullList) {
      const fullListResponse = yield anamiService.getDashboardNotificationList();

      yield put(projectsActions.getNotificationTasksSuccess(response.data));
      yield put(
        notificationsActions.setNotificationListForTracker(
          fullListResponse.data
        )
      );
    }
  } catch (error) {
    yield put(notificationsActions.getNotificationListFailure(error));
  }
}

function* updateNotification({ data }) {
  try {
    const response = yield anamiService.updateNotification(data);

    yield put(
      notificationsActions.updateNotificationSuccess(response.data.notification)
    );

    if (typeof data.unread === 'boolean' && !data.unread) {
      yield put(dashboardActions.viewNotification());
    }
  } catch (error) {
    yield put(notificationsActions.updateNotificationFailure(error));
  }
}

function* updateNotifications({ notifications }) {
  try {
    yield put(
      notificationsActions.updateTotalNotifications(notifications.length)
    );

    for (let i = 0; i < notifications.length; i++) {
      const response = yield anamiService.updateNotification(notifications[i]);

      yield put(
        notificationsActions.updateNotificationSuccess(
          response.data.notification
        )
      );

      if (
        typeof notifications[i].unread === 'boolean' &&
        !notifications[i].unread
      ) {
        yield put(dashboardActions.viewNotification());
      }
    }
  } catch (error) {
    yield put(notificationsActions.updateNotificationFailure(error));
  }
}

function* removeNotification({ id }) {
  try {
    yield anamiService.removeNotification(id);

    yield put(notificationsActions.removeNotificationSuccess(id));
  } catch (error) {
    yield put(notificationsActions.removeNotificationFailure(error));
  }
}

function* fetchNotifications({ data }) {
  try {
    const response = yield anamiService.getNotifications(data);

    yield put(notificationsActions.fetchNotificationsSuccess(response.data));
  } catch (error) {
    yield put(notificationsActions.fetchNotificationsFailure(error));
  }
}

function* markAsRead({ data }) {
  try {
    yield anamiService.markNotificationsAsRead(data);

    yield put(notificationsActions.markNotificationsAsReadSuccess());
  } catch (error) {
    yield put(notificationsActions.markNotificationsAsReadFailure(error));
  }
}

export default function* notificationsSaga() {
  yield all([
    takeEvery(
      notificationsActions.GET_NOTIFICATION_LIST_REQUEST,
      getNotificationList
    ),
    takeEvery(
      notificationsActions.UPDATE_NOTIFICATION_REQUEST,
      updateNotification
    ),
    takeEvery(notificationsActions.UPDATE_NOTIFICATIONS, updateNotifications),
    takeEvery(
      notificationsActions.REMOVE_NOTIFICATION_REQUEST,
      removeNotification
    ),
  ]);
  yield takeEvery(
    notificationsActions.FETCH_NOTIFICATIONS_REQUEST,
    fetchNotifications
  );
  yield takeEvery(
    notificationsActions.MARK_NOTIFICATIONS_AS_READ_REQUEST,
    markAsRead
  );
}
