import googleDriveActions from './actions';

const initialState = {
  isValid: false,
  isConnected: false,
  authUrl: '',
  processId: localStorage.getItem('googleDriveProcessId') || '',
  syncData: {},
  loading: false,
  syncPending: false,
  error: null,
  sharedDrives: [],
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case googleDriveActions.GET_GOOGLE_DRIVE_TOKEN_REQUEST:
    case googleDriveActions.DISCONNECT_GOOGLE_DRIVE_REQUEST:
    case googleDriveActions.CHECK_GOOGLE_DRIVE_TOKEN_REQUEST:
    case googleDriveActions.GET_GOOGLE_DRIVE_STORAGE_REQUEST:
      return { ...state, loading: true, error: null };

    case googleDriveActions.CHECK_GOOGLE_DRIVE_SYNC_REQUEST:
      return { ...state, error: null };

    case googleDriveActions.INIT_GOOGLE_DRIVE_SYNC_REQUEST:
      return { ...state, loading: true, syncPending: true, error: null };

    case googleDriveActions.INIT_GOOGLE_DRIVE_SYNC_FAILURE:
      return {
        ...state,
        loading: false,
        syncPending: false,
        error: action.payload,
      };

    case googleDriveActions.GET_GOOGLE_DRIVE_AUTH_URL_REQUEST:
      return { ...state, loading: true, error: null, authUrl: '' };

    case googleDriveActions.GET_GOOGLE_DRIVE_AUTH_URL_FAILURE:
    case googleDriveActions.GET_GOOGLE_DRIVE_TOKEN_FAILURE:
    case googleDriveActions.DISCONNECT_GOOGLE_DRIVE_FAILURE:
    case googleDriveActions.CHECK_GOOGLE_DRIVE_TOKEN_FAILURE:
    case googleDriveActions.GET_GOOGLE_DRIVE_STORAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case googleDriveActions.CHECK_GOOGLE_DRIVE_SYNC_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case googleDriveActions.GET_GOOGLE_DRIVE_TOKEN_SUCCESS:
    case googleDriveActions.CHECK_GOOGLE_DRIVE_TOKEN_SUCCESS:
      return {
        ...state,
        isValid: action.payload.isValid,
        isConnected: action.payload.isConnected,
        loading: false,
        error: null,
      };

    case googleDriveActions.INIT_GOOGLE_DRIVE_SYNC_SUCCESS:
      return {
        ...state,
        processId: action.payload.id,
        syncData: action.payload.syncData,
        loading: false,
        syncPending: false,
        error: null,
      };

    case googleDriveActions.CHECK_GOOGLE_DRIVE_SYNC_SUCCESS:
      return {
        ...state,
        syncData: action.payload,
        error: null,
      };

    case googleDriveActions.GET_GOOGLE_DRIVE_AUTH_URL_SUCCESS:
      return {
        ...state,
        authUrl: action.payload,
        loading: false,
        error: null,
      };

    case googleDriveActions.DISCONNECT_GOOGLE_DRIVE_SUCCESS:
    case googleDriveActions.CLEAR_GOOGLE_DRIVE_INFO:
      localStorage.setItem('googleDriveProcessId', '');
      return {
        ...state,
        processId: '',
        syncData: {},
        isValid: false,
        isConnected: false,
        loading: false,
        error: null,
      };

    case googleDriveActions.GET_GOOGLE_DRIVE_STORAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        sharedDrives: action.payload,
      };

    default:
      return state;
  }
}
