import moment from 'moment';
import mixpanel from 'mixpanel-browser';

import { DATE_FORMAT, events, routes } from './constants';

export default class Mixpanel {
  constructor(provider = null) {
    this.provider = provider;
    this.sessionStart = null;
    this.sessionEnd = null;
    this.userId = null;
  }

  static init() {
    if (!this.provider) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

      return new Mixpanel(mixpanel);
    }

    return null;
  }

  identify(userId, properties = {}) {
    try {
      this.userId = userId;

      this.provider.identify(userId, properties);
    } catch (error) {
      console.log(`error`, error);
    }
  }

  trackSessionStart(event, properties = {}) {
    try {
      this.sessionStart = moment().format(DATE_FORMAT);

      const eventData = {
        sessionStart: this.sessionStart,
        ...properties,
      };

      this.provider.track(event, eventData);
    } catch (error) {
      console.log(`error`, error);
    }
  }

  trackSessionEnd(event, properties = {}) {
    try {
      this.sessionEnd = moment().format(DATE_FORMAT);

      const diff = moment
        .utc(moment(this.sessionEnd).diff(moment(this.sessionStart)))
        .format('HH:mm:ss');

      const eventData = {
        sessionEnd: this.sessionEnd,
        sessionStart: this.sessionStart,
        sessionDuration: diff,
        ...properties,
      };

      this.provider.track(event, eventData);

      this.provider.reset();
    } catch (error) {
      console.log(`error`, error);
    }
  }

  trackEvent(event, properties = {}) {
    try {
      const eventData = {
        userId: this.userId,
        ...properties,
      };

      this.provider.track(event, eventData);
    } catch (error) {
      console.log(`error`, error);
    }
  }

  trackPage(page, properties = {}) {
    try {
      routes[page] &&
        this.provider.track(
          `${routes[page]} ${events.WENT_TO_PAGE} `,
          properties
        );
    } catch (error) {
      console.log(`error`, error);
    }
  }
}
