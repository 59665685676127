import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

const projectsSelector = state => state.projects;
const companiesSelector = state => state.companies;
const businessesSelector = state => state.businesses;
const notificationSelector = state => state.notifications;

export const selectProjectOwnerId = createSelector(
  projectsSelector,
  ({ selectedProject }) => selectedProject?.createdBy?.id || null
);

export const selectProjectsError = createSelector(
  projectsSelector,
  ({ error }) => error
);

export const selectTeamsLoading = createSelector(
  projectsSelector,
  ({ teamsLoading }) => teamsLoading
);

export const selectProjectPartsLoading = createSelector(
  projectsSelector,
  ({ projectLoading, sectionLoading, filesLoading, usersAndTeamsLoading }) =>
    projectLoading || sectionLoading || filesLoading || usersAndTeamsLoading
);

export const selectProjectList = createSelector(
  projectsSelector,
  projects => projects.projectsList
);

export const selectSpecificationField = createSelector(
  projectsSelector,
  projects =>
    _.memoize(section => {
      const field = projects?.specifications[section];

      if (_.isPlainObject(field)) {
        return Object.entries(field).map(([key, value]) => ({
          key,
          value,
        }));
      } else {
        return field;
      }
    })
);

export const selectSpecifications = createSelector(
  projectsSelector,
  projects => projects.specifications
);

export const selectSelectedProject = createSelector(
  projectsSelector,
  projects => projects.selectedProject
);

export const selectTemplateListCount = createSelector(
  projectsSelector,
  projects => projects.templateList?.totalNumber || null
);

export const selectTemplateList = createSelector(
  projectsSelector,
  projects => projects.templateList.items
);
export const selectIsTitleExist = createSelector(
  projectsSelector,
  projects => projects.isTitleExist
);

export const selectFirstProjectId = createSelector(
  projectsSelector,
  projects => projects.homeViewData[0]?.id || null
);

export const selectAvailableTags = createSelector(
  projectsSelector,
  projects => projects.availableTags || []
);

export const selectLoading = createSelector(
  projectsSelector,
  projects => projects.usersLoading
);

export const selectUsersAndTeamsLoading = createSelector(
  projectsSelector,
  projects => projects.usersAndTeamsLoading
);

export const selectDeletedSectionTasks = createSelector(
  projectsSelector,
  projects =>
    _.memoize(sectionId => {
      let result = false;

      projects.selectedProject.section &&
        projects.selectedProject.section.forEach(section => {
          if (section.id === sectionId) {
            if (!!section.tasks.length) {
              result = true;
            }
          }
        });

      return result;
    })
);

export const selectFilterSettings = createSelector(
  projectsSelector,
  projects => projects.filterSettings
);

export const selectTrackerData = createSelector(
  projectsSelector,
  projects => projects.trackerData
);

export const selectTrackerLoading = createSelector(
  projectsSelector,
  projects => projects.trackerLoading
);

export const selectDataTracker = createSelector(
  notificationSelector,
  notifications => notifications.dataTracker
);

export const selectFilterTypes = createSelector(projectsSelector, projects =>
  _.memoize(type => {
    const types = projects?.filtersData[type];

    if (Array.isArray(types)) {
      if (type === 'userTemplateTypes') {
        const spacedTemplateTypes = [
          'General',
          'Guide',
          'Self-audit',
          'Greenspace',
        ];

        const existedTypes = types
          .filter(item => item)
          .map(item => {
            let name = item;

            if (item === 'SOP') {
              name = 'Guide';
            }

            if (item.charAt(0) !== item.charAt(0).toUpperCase()) {
              name = `${item.charAt(0).toUpperCase()}${item.substring(1)}`;
            }

            return {
              name,
              type: item,
            };
          });

        return spacedTemplateTypes
          .map(type =>
            existedTypes.find(existedType => existedType.name === type)
          )
          .filter(type => type);
      } else {
        return types
          .filter(item => item)
          .map(item => ({
            name: item,
            type: item,
          }));
      }
    }

    return [];
  })
);

export const selectProjectsMembers = createSelector(
  companiesSelector,
  companies => companies.users
);

export const selectProjectsLocations = createSelector(
  businessesSelector,
  projects => (projects.data ? projects.data : [])
);

// REMOVE AFTER REDESIGN
export const selectActiveProjectsMembers = createSelector(
  projectsSelector,
  projects => projects.filtersData.users
);

export const selectOnlyFilterSettings = createSelector(
  projectsSelector,
  projects => {
    const hiddenValue = ['page', 'page_size', 'order', 'orderBy'];

    const filters = Object.fromEntries(
      Object.entries({ ...projects.filterSettings }).filter(
        ([key, val]) => !hiddenValue.includes(key)
      )
    );

    return Object.keys(filters).length ? filters : null;
  }
);

export const selectFilterMembers = createSelector(projectsSelector, projects =>
  projects.filterSettings?.members?.split(',')
);

export const selectFilterLocations = createSelector(
  projectsSelector,
  projects => projects.filterSettings?.locations?.split(',')
);

export const selectFilterFavorite = createSelector(projectsSelector, projects =>
  projects.filterSettings?.starred?.split(',')
);

export const selectTypesFilter = createSelector(projectsSelector, projects =>
  projects.filterSettings?.types?.split(',')
);

export const selectCompletionFilter = createSelector(
  projectsSelector,
  projects => projects.filterSettings?.completion?.split(',')
);

export const selectFilterTags = createSelector(
  projectsSelector,
  projects => projects.filterSettings?.tags?.split(',') || []
);

export const selectAvailableFilterTags = createSelector(
  projectsSelector,
  projects => projects.availableFilterTags
);

export const selectCompletionRange = createSelector(
  projectsSelector,
  ({ filterSettings }) => filterSettings?.completion?.split(',') || null
);

export const selectFilterDateRange = createSelector(
  projectsSelector,
  ({ filterSettings }) => {
    return filterSettings?.from
      ? [moment(filterSettings.from), moment(filterSettings.to)]
      : [];
  }
);

export const selectActiveProjectsLocations = createSelector(
  projectsSelector,
  projects => projects.filtersData.locations
);
// // //

export const selectFiltersState = createSelector(
  projectsSelector,
  projects => projects.filtersActivated
);

export const selectActiveLoading = createSelector(
  projectsSelector,
  projects => projects.loadingActiveItems
);

export const selectProjectSectionTasks = createSelector(
  projectsSelector,
  ({ selectedProject }) =>
    selectedProject.section.map(section => section.tasks).flat(1)
);

export const getSectionTasks = (state, id) => {
  return (
    state.projects.selectedProject.section.find(section => section.id === id)
      .tasks || []
  );
};

export const isChecklist = (state, id) => {
  const checklist = state.projects.data.find(
    project => project.id === id && project.checklist
  );

  if (checklist) {
    return true;
  } else if (_.has(state.projects.selectedProject, 'id')) {
    return state.projects.selectedProject.checklist;
  } else {
    return false;
  }
};

export const isGeneralOrGreenspace = (state, id) => {
  const template = state.projects.data.find(
    project =>
      project.id === id &&
      (project.typePublication === 'general' ||
        project.typePublication === 'greenspace') &&
      project.isTemplate
  );

  if (template) {
    return true;
  } else if (_.has(state.projects.selectedProject, 'id')) {
    const { selectedProject } = state.projects;
    return (
      (selectedProject.typePublication === 'general' ||
        selectedProject.typePublication === 'greenspace') &&
      selectedProject.isTemplate
    );
  } else {
    return false;
  }
};

export const isSop = (state, id) => {
  const sop = state.projects.data.find(
    project => project.id === id && project.sop && project.isTemplate
  );

  if (sop) {
    return true;
  } else if (_.has(state.projects.selectedProject, 'id')) {
    const { selectedProject } = state.projects;
    return selectedProject.sop && selectedProject.isTemplate;
  } else {
    return false;
  }
};

export const getChecklists = state => {
  return state.projects.data.filter(project => project.checklist);
};

export const getAuditProjectTasks = createSelector(
  projectsSelector,
  ({ selectedAuditProject }) => {
    if (selectedAuditProject) {
      const tasks = [];

      if (selectedAuditProject.section) {
        selectedAuditProject.section.forEach(section => {
          section.tasks &&
            tasks.push(
              ...section.tasks.filter(
                task => task.auditLogs && task.auditLogs.length
              )
            );
        });
      }

      return tasks;
    }

    return [];
  }
);
