import React from 'react';

const GreenspaceLogo = ({ height = 91, width = 91, fill = '#FFFFFF' }) => (
  <svg
    width={height}
    height={width}
    viewBox="0 0 91 91"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>greenspace-logo</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Dark-Green-BG"
        transform="translate(-129.000000, -172.000000)"
        fill={fill}
      >
        <path
          d="M174.6729,172.8182 C185.0888,172.8182 194.6803,176.3238 202.3203,182.2096 L194.7688,187.0759 C188.9898,183.335 182.0871,181.1594 174.6723,181.1594 C154.3337,181.1594 137.8454,197.5193 137.8454,217.7003 C137.8454,230.3652 144.3402,241.5243 154.2047,248.08 C166.9918,240.7397 173.9336,198.0851 218.971,208.9069 C219.5396,211.7503 219.8401,214.6904 219.8401,217.6997 C219.8401,242.4875 199.618,262.5818 174.6729,262.5818 C149.7257,262.5818 129.5036,242.4875 129.5036,217.6997 C129.5036,192.9124 149.7257,172.8182 174.6729,172.8182 Z M165.293,249.3134 C175.1244,244.6878 191.1949,236.0903 204.2382,224.1635 C197.9609,232.9314 178.4529,246.3857 164.8638,252.9281 C167.9865,253.7827 171.2755,254.2405 174.6723,254.2405 C195.5333,254.2405 212.3708,237.0147 211.462,216.1021 C179.6706,213.3868 175.0584,237.6499 165.293,249.3134 Z"
          id="greenspace-logo"
        ></path>
      </g>
    </g>
  </svg>
);

export default GreenspaceLogo;
