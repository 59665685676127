const dropboxActions = {
  GET_DROPBOX_AUTH_URL_REQUEST: 'GET_DROPBOX_AUTH_URL_REQUEST',
  GET_DROPBOX_AUTH_URL_SUCCESS: 'GET_DROPBOX_AUTH_URL_SUCCESS',
  GET_DROPBOX_AUTH_URL_FAILURE: 'GET_DROPBOX_AUTH_URL_FAILURE',

  GET_DROPBOX_TOKEN_REQUEST: 'GET_DROPBOX_TOKEN_REQUEST',
  GET_DROPBOX_TOKEN_SUCCESS: 'GET_DROPBOX_TOKEN_SUCCESS',
  GET_DROPBOX_TOKEN_FAILURE: 'GET_DROPBOX_TOKEN_FAILURE',

  CHECK_DROPBOX_TOKEN_REQUEST: 'CHECK_DROPBOX_TOKEN_REQUEST',
  CHECK_DROPBOX_TOKEN_SUCCESS: 'CHECK_DROPBOX_TOKEN_SUCCESS',
  CHECK_DROPBOX_TOKEN_FAILURE: 'CHECK_DROPBOX_TOKEN_FAILURE',

  INIT_DROPBOX_SYNC_REQUEST: 'INIT_DROPBOX_SYNC_REQUEST',
  INIT_DROPBOX_SYNC_SUCCESS: 'INIT_DROPBOX_SYNC_SUCCESS',
  INIT_DROPBOX_SYNC_FAILURE: 'INIT_DROPBOX_SYNC_FAILURE',

  CHECK_DROPBOX_SYNC_REQUEST: 'CHECK_DROPBOX_SYNC_REQUEST',
  CHECK_DROPBOX_SYNC_SUCCESS: 'CHECK_DROPBOX_SYNC_SUCCESS',
  CHECK_DROPBOX_SYNC_FAILURE: 'CHECK_DROPBOX_SYNC_FAILURE',

  DISCONNECT_DROPBOX_REQUEST: 'DISCONNECT_DROPBOX_REQUEST',
  DISCONNECT_DROPBOX_SUCCESS: 'DISCONNECT_DROPBOX_SUCCESS',
  DISCONNECT_DROPBOX_FAILURE: 'DISCONNECT_DROPBOX_FAILURE',

  CLEAR_DROPBOX_INFO: 'CLEAR_DROPBOX_INFO',

  getDropboxAuthUrlRequest: () => ({
    type: dropboxActions.GET_DROPBOX_AUTH_URL_REQUEST,
  }),
  getDropboxAuthUrlSuccess: data => ({
    type: dropboxActions.GET_DROPBOX_AUTH_URL_SUCCESS,
    payload: data,
  }),
  getDropboxAuthUrlFailure: error => ({
    type: dropboxActions.GET_DROPBOX_AUTH_URL_FAILURE,
    payload: error,
  }),

  getDropboxTokenRequest: data => ({
    type: dropboxActions.GET_DROPBOX_TOKEN_REQUEST,
    data,
  }),
  getDropboxTokenSuccess: data => ({
    type: dropboxActions.GET_DROPBOX_TOKEN_SUCCESS,
    payload: data,
  }),
  getDropboxTokenFailure: error => ({
    type: dropboxActions.GET_DROPBOX_TOKEN_FAILURE,
    payload: error,
  }),

  checkDropboxTokenRequest: () => ({
    type: dropboxActions.CHECK_DROPBOX_TOKEN_REQUEST,
  }),
  checkDropboxTokenSuccess: data => ({
    type: dropboxActions.CHECK_DROPBOX_TOKEN_SUCCESS,
    payload: data,
  }),
  checkDropboxTokenFailure: error => ({
    type: dropboxActions.CHECK_DROPBOX_TOKEN_FAILURE,
    payload: error,
  }),

  initDropboxSyncRequest: data => ({
    type: dropboxActions.INIT_DROPBOX_SYNC_REQUEST,
    data,
  }),
  initDropboxSyncSuccess: data => ({
    type: dropboxActions.INIT_DROPBOX_SYNC_SUCCESS,
    payload: data,
  }),
  initDropboxSyncFailure: error => ({
    type: dropboxActions.INIT_DROPBOX_SYNC_FAILURE,
    payload: error,
  }),

  disconnectDropboxRequest: data => ({
    type: dropboxActions.DISCONNECT_DROPBOX_REQUEST,
    data,
  }),
  disconnectDropboxSuccess: () => ({
    type: dropboxActions.DISCONNECT_DROPBOX_SUCCESS,
  }),
  disconnectDropboxFailure: error => ({
    type: dropboxActions.DISCONNECT_DROPBOX_FAILURE,
    payload: error,
  }),

  checkDropboxSyncRequest: data => ({
    type: dropboxActions.CHECK_DROPBOX_SYNC_REQUEST,
    data,
  }),
  checkDropboxSyncSuccess: data => ({
    type: dropboxActions.CHECK_DROPBOX_SYNC_SUCCESS,
    payload: data,
  }),
  checkDropboxSyncFailure: error => ({
    type: dropboxActions.CHECK_DROPBOX_SYNC_FAILURE,
    payload: error,
  }),

  clearDropboxInfo: () => ({
    type: dropboxActions.CLEAR_DROPBOX_INFO,
  }),
};

export default dropboxActions;
