import React from 'react';
import { Provider } from 'react-redux';
// import ClearCache from 'react-clear-cache';
import { ClearBrowserCache } from 'react-clear-browser-cache';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import AppStyles from './appStyles';
import 'react-contexify/dist/ReactContexify.min.css';

const App = () => (
  <ClearBrowserCache auto duration={360000}>
    {() => (
      <Provider store={store}>
        <AppProvider>
          <>
            <GlobalStyles />
            <AppStyles />
            <Routes />
          </>
        </AppProvider>
      </Provider>
    )}
  </ClearBrowserCache>
);

Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
