import history from '@iso/lib/helpers/history';
import notifications from '@iso/components/Feedback/Notification';

import { APP_ROUTES } from '../route.constants';
import {
  skippingNotificationUrls,
  planResponseErrMsg,
  planLocationsLimitServerErr,
} from '../helpers/constants';

const showErrorNotification = ({ type, error }) => {
  let message = ``;

  if (error?.details?.body) {
    message = error.details.body.map(error => {
      return `${error.name}: ${error.description}`;
    });
  } else if (error?.details) {
    message = error.details.toString();
  } else if (error?.message) {
    message = error.message;
  }

  if (message === 'Location with the same name and dba already exists') {
    notifications[type]({
      message: error?.message,
      description: 'Please update the name of the DBA or the business name',
    });
    return;
  }

  if (message !== 'User is not authorized in Dropbox') {
    notifications[type]({
      message: error?.message,
      description: message,
    });
  }
};

const errorHandlerMiddleware = () => next => action => {
  if (action.payload instanceof Error && action.payload?.response?.status) {
    const { data, config, status } = action.payload.response;
    const { pathname } = window.location;
    const isWorkspacePage = pathname.includes(APP_ROUTES.PROFILE_WORKSPACE);

    if (status === 403) {
      if (
        config.method === 'post' &&
        config?.url === 'businesses' &&
        data.message === planLocationsLimitServerErr
      ) {
        next(action);

        return;
      }

      if (data?.message.includes(planResponseErrMsg)) {
        if (!isWorkspacePage) {
          history.replace({
            pathname: APP_ROUTES.PROFILE_WORKSPACE,
            state: {
              needUpdatePlan: true,
            },
          });
        }
      } else if (
        skippingNotificationUrls.every(url => !config?.url.includes(url))
      ) {
        showErrorNotification({
          type: 'error',
          error: data,
        });
      }
    }
  }

  next(action);
};

export { errorHandlerMiddleware };
