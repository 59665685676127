import React from 'react';

const PlusIcon = ({
  fill = '#02955F',
  width = 26,
  height = 26,
  opacity = 1,
}) => {
  fill = fill || '#16AF70';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.80602 3.80602C8.88072 -1.26867 17.1193 -1.26867 22.194 3.80602C27.2687 8.8494 27.2687 17.1193 22.194 22.194C17.1193 27.2687 8.88072 27.2687 3.80602 22.194C-1.26867 17.1193 -1.26867 8.88072 3.80602 3.80602ZM17.9697 14.0303C18.2424 14.0303 18.5152 13.9091 18.697 13.7273C18.8788 13.5455 18.9697 13.2727 19 13C19 12.4242 18.5455 11.9697 17.9697 11.9697H14.0303V8.0303C14.0303 7.45455 13.5758 7 13 7C12.4242 7 11.9697 7.45455 11.9697 8.0303V11.9697H8.0303C7.45455 11.9697 7 12.4242 7 13C7 13.5758 7.45455 14.0303 8.0303 14.0303H11.9697V17.9697C11.9697 18.5455 12.4242 19 13 19C13.2727 19 13.5455 18.8788 13.7273 18.697C13.9091 18.5152 14.0303 18.2424 14.0303 17.9697V14.0303H17.9697Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusIcon;
