import { all, takeEvery, put } from 'redux-saga/effects';

import projectsActions from '../actions';
import anamiService from '../../../services/anamiService';

import checklistSaga from './checklist';
import sopSaga from './sop';
import projectSaga from './project';
import taskSaga from './task';
import sectionSaga from './section';
import actionTypes from '../actions/actionTypes';

function* getSpecifications() {
  try {
    const response = yield anamiService.getSpecifications();

    yield put(projectsActions.getSpecificationsSuccess(response.data));
  } catch (error) {
    yield put(projectsActions.getSpecificationsFailure(error));
  }
}

function* getAuditLogs({ data }) {
  try {
    const response = yield anamiService.getAuditLogs(data);

    yield put(projectsActions.getAuditLogsSuccess(response.data.projects));

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'audit-logs.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    yield put(projectsActions.getAuditLogsFailure(error));
  }
}

export default function* projectsSaga() {
  yield all([
    takeEvery(projectsActions.GET_SPECIFICATIONS_REQUEST, getSpecifications),
  ]);

  yield all([
    takeEvery(projectsActions.GET_AUDIT_LOGS_REQUEST, getAuditLogs),
    takeEvery(
      projectsActions.GET_AUDIT_PROJECT_LIST_REQUEST,
      projectSaga.getAuditProjectList
    ),
    takeEvery(
      projectsActions.GET_AUDIT_PROJECT_REQUEST,
      projectSaga.getAuditProject
    ),
  ]);

  yield all([
    takeEvery(projectsActions.FETCH_DATA, projectSaga.fetchData),
    takeEvery(projectsActions.GET_PROJECT_REQUEST, projectSaga.getProject),
    takeEvery(projectsActions.GET_PROJECT_PARTS, projectSaga.getProjectParts),
    takeEvery(
      projectsActions.GET_PROJECT_DETAILS_REQUEST,
      projectSaga.getProjectDetails
    ),
    takeEvery(
      projectsActions.GET_PROJECT_SECTION_REQUEST,
      projectSaga.getProjectSection
    ),
    takeEvery(
      projectsActions.GET_PROJECT_FILES_REQUEST,
      projectSaga.getProjectFiles
    ),
    takeEvery(
      projectsActions.GET_PROJECT_USERS_AND_TEAMS_REQUEST,
      projectSaga.getProjectUsersAndTeams
    ),
    takeEvery(
      projectsActions.UPDATE_PROJECT_REQUEST,
      projectSaga.updateProject
    ),
    takeEvery(
      projectsActions.CREATE_PROJECT_REQUEST,
      projectSaga.createProject
    ),
    takeEvery(projectsActions.COPY_PROJECT_REQUEST, projectSaga.copyProject),
    takeEvery(
      projectsActions.REMOVE_PROJECT_REQUEST,
      projectSaga.removeProject
    ),
    takeEvery(
      projectsActions.PUBLISH_PROJECT_REQUEST,
      projectSaga.publishProject
    ),
    takeEvery(projectsActions.CONVERT_TO_SOP_REQUEST, projectSaga.convertToSOP),
    takeEvery(
      projectsActions.CREATE_PROJECT_LABEL_REQUEST,
      projectSaga.createProjectLabel
    ),
    takeEvery(
      projectsActions.UPDATE_PROJECT_LABEL_REQUEST,
      projectSaga.updateProjectLabel
    ),
    takeEvery(
      projectsActions.REMOVE_PROJECT_LABEL_REQUEST,
      projectSaga.removeProjectLabel
    ),
    takeEvery(
      projectsActions.GET_PROJECT_LABELS_REQUEST,
      projectSaga.getProjectLabels
    ),
    takeEvery(
      projectsActions.GET_PROJECT_USERS_REQUEST,
      projectSaga.getProjectUsers
    ),
    takeEvery(
      projectsActions.GET_CONNECTED_USERS_REQUEST,
      projectSaga.getConnectedUsers
    ),
    takeEvery(projectsActions.CONNECT_USER_REQUEST, projectSaga.connectUser),
    takeEvery(
      projectsActions.DISCONNECT_USER_REQUEST,
      projectSaga.disconnectUser
    ),
    takeEvery(
      projectsActions.CHANGE_BUSINESS_REQUEST,
      projectSaga.changeBusiness
    ),
    takeEvery(projectsActions.HIDE_COLUMNS, projectSaga.hideColumns),
    takeEvery(
      projectsActions.CONNECT_USER_PROJECTS_HOME_REQUEST,
      projectSaga.connectUserProjectsHome
    ),
    takeEvery(
      projectsActions.DISCONNECT_USER_PROJECTS_HOME_REQUEST,
      projectSaga.disconnectUserProjectsHome
    ),
    takeEvery(
      projectsActions.FETCH_BUSINESS_PROJECTS_REQUEST,
      projectSaga.fetchBusinessProjects
    ),
    takeEvery(
      projectsActions.FETCH_PROJECTS_LIST_DATA_REQUEST,
      projectSaga.fetchProjectsList
    ),
    takeEvery(
      projectsActions.REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_REQUEST,
      projectSaga.removeAssignedProject
    ),
    takeEvery(
      projectsActions.ASSIGN_NEW_USER_TO_PROJECT_TASK_REQUEST,
      projectSaga.assignNewUserToTask
    ),
  ]);

  yield all([
    takeEvery(
      projectsActions.GET_UPCOMING_TASKS_REQUEST,
      projectSaga.getUpcomingTasks
    ),
    takeEvery(
      projectsActions.GET_EXPIRED_TASKS_REQUEST,
      projectSaga.getExpiredTasks
    ),
    takeEvery(
      projectsActions.GET_NOTIFICATION_TASKS_REQUEST,
      projectSaga.getNotificationTasks
    ),
    takeEvery(
      projectsActions.GET_EXPIRED_PROJECT_TASKS,
      projectSaga.getExpiredProjectTasks
    ),
    takeEvery(
      projectsActions.GET_UPCOMING_PROJECT_TASKS,
      projectSaga.getUpcomingProjectTask
    ),
    takeEvery(
      projectsActions.GET_NOTIFICATION_PROJECT_TASKS,
      projectSaga.getNotificationProjectTasks
    ),
    takeEvery(
      projectsActions.GET_ASSIGNED_TASKS_REQUEST,
      projectSaga.getAssignedTasks
    ),
    takeEvery(
      projectsActions.GET_UPCOMING_TASKS_TRACKER_REQUEST,
      projectSaga.getUpcomingTasksTracker
    ),
    takeEvery(
      projectsActions.GET_EXPIRED_TASKS_TRACKER_REQUEST,
      projectSaga.getExpiredTasksTracker
    ),
    takeEvery(
      projectsActions.GET_ASSIGNED_TASKS_TRACKER_REQUEST,
      projectSaga.getAssignedTasksTracker
    ),
    takeEvery(
      projectsActions.GET_DASHBOARD_ASSIGNED_USERS_REQUEST,
      projectSaga.getDashboardAssignedUsers
    ),
  ]);

  yield all([
    takeEvery(
      projectsActions.CREATE_SECTION_REQUEST,
      sectionSaga.createSection
    ),
    takeEvery(
      projectsActions.UPDATE_SECTION_REQUEST,
      sectionSaga.updateSection
    ),
    takeEvery(
      projectsActions.REMOVE_SECTION_REQUEST,
      sectionSaga.removeSection
    ),
    takeEvery(projectsActions.MOVE_SECTION_REQUEST, sectionSaga.moveSection),
    takeEvery(actionTypes.HIDE_SECTION, projectSaga.hideSection),
  ]);

  yield all([
    takeEvery(projectsActions.CREATE_TASK_REQUEST, taskSaga.createTask),
    takeEvery(projectsActions.GET_TASK_REQUEST, taskSaga.getTask),
    takeEvery(projectsActions.SELECT_TASK, taskSaga.selectTask),
    takeEvery(projectsActions.UPDATE_TASK_REQUEST, taskSaga.updateTask),
    takeEvery(projectsActions.REMOVE_TASK_REQUEST, taskSaga.removeTask),
    takeEvery(projectsActions.COPY_TASK_REQUEST, taskSaga.copyTask),
    takeEvery(
      projectsActions.CREATE_TASK_LABEL_REQUEST,
      taskSaga.createTaskLabel
    ),
    takeEvery(
      projectsActions.REMOVE_TASK_LABEL_REQUEST,
      taskSaga.removeTaskLabel
    ),
    takeEvery(projectsActions.MOVE_TASK_REQUEST, taskSaga.moveTask),
    takeEvery(projectsActions.SET_TASK_REPEAT_REQUEST, taskSaga.repeatTask),
    takeEvery(
      projectsActions.SET_TASK_ASSIGNEE_REQUEST,
      taskSaga.setTaskAssignee
    ),
    takeEvery(projectsActions.SET_TASK_DATE_REQUEST, taskSaga.setTaskDate),
  ]);

  yield all([
    takeEvery(
      projectsActions.CREATE_ATTACHMENT_REQUEST,
      taskSaga.createAttachment
    ),
    takeEvery(
      projectsActions.DELETE_ATTACHMENT_REQUEST,
      taskSaga.deleteAttachment
    ),
  ]);

  yield all([
    takeEvery(projectsActions.CREATE_COMMENT_REQUEST, taskSaga.createComment),
    takeEvery(projectsActions.UPDATE_COMMENT_REQUEST, taskSaga.updateComment),
    takeEvery(projectsActions.REMOVE_COMMENT_REQUEST, taskSaga.removeComment),
  ]);

  yield all([
    takeEvery(
      projectsActions.GET_RECENT_VIEWS_REQUEST,
      checklistSaga.getRecentViews
    ),
    takeEvery(
      projectsActions.GET_CHECKLISTS_REQUEST,
      checklistSaga.getChecklists
    ),
    takeEvery(
      projectsActions.UPLOAD_CHECKLIST_ICON_REQUEST,
      checklistSaga.uploadChecklistIcon
    ),
    takeEvery(
      projectsActions.REMOVE_CHECKLIST_ICON_REQUEST,
      checklistSaga.removeChecklistIcon
    ),
    takeEvery(
      projectsActions.CREATE_CHECKLIST_REQUEST,
      checklistSaga.createChecklist
    ),
    takeEvery(
      projectsActions.MOVE_CHECKLIST_REQUEST,
      checklistSaga.moveChecklist
    ),
    takeEvery(
      projectsActions.RENAME_CHECKLIST_REQUEST,
      checklistSaga.renameChecklist
    ),
  ]);

  yield all([
    takeEvery(projectsActions.CREATE_SOP_REQUEST, sopSaga.createSop),
    takeEvery(projectsActions.GET_SOPS_REQUEST, sopSaga.getSops),
    takeEvery(projectsActions.ASSIGNEE_SOP_REQUEST, sopSaga.assigneeSop),
    takeEvery(projectsActions.SET_SOP_DUE_DATE_REQUEST, sopSaga.setSopDueDate),
    takeEvery(
      projectsActions.SET_SOP_EFFECTIVE_DATE_REQUEST,
      sopSaga.setSopEffectiveDate
    ),
  ]);

  yield takeEvery(
    projectsActions.FETCH_PROJECTS_HOME_VIEW_REQUEST,
    projectSaga.fetchProjectsHomeView
  );
  yield takeEvery(
    projectsActions.FETCH_CHECKLISTS_HOME_VIEW_REQUEST,
    projectSaga.fetchChecklistsHomeView
  );
  yield takeEvery(
    projectsActions.FETCH_ARCHIVED_PROJECTS_HOME_VIEW_REQUEST,
    projectSaga.fetchArchivedProjectsHomeView
  );
  yield takeEvery(
    projectsActions.UPDATE_PROJECT_STARRED_REQUEST,
    projectSaga.updateProjectStarred
  );
  yield takeEvery(
    projectsActions.CREATE_PROJECT_IMAGE_REQUEST,
    projectSaga.createProjectImage
  );
  yield takeEvery(
    projectsActions.DELETE_PROJECT_IMAGE_REQUEST,
    projectSaga.deleteProjectImage
  );
  yield takeEvery(
    projectsActions.UPDATE_PROJECT_PERMISSION_REQUEST,
    projectSaga.updateProjectPermission
  );
  yield takeEvery(
    projectsActions.ARCHIVE_PROJECT_REQUEST,
    projectSaga.acrhiveProject
  );
  yield takeEvery(
    projectsActions.UNZIP_PROJECT_REQUEST,
    projectSaga.unzipProject
  );
  yield takeEvery(
    projectsActions.REMOVE_HOME_VIEW_PROJECT_REQUEST,
    projectSaga.removeHomeViewProject
  );
  yield takeEvery(
    projectsActions.FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_REQUEST,
    projectSaga.fetchHomeViewActiveUsersLocations
  );
  yield takeEvery(
    projectsActions.UPDATE_PROJECT_ADMIN_TYPE_REQUEST,
    projectSaga.updateProjectAdminType
  );
  yield takeEvery(
    projectsActions.FETCH_PROJECTS_BY_NAME_REQUEST,
    projectSaga.fetchProjectsListByName
  );

  yield takeEvery(
    projectsActions.CREATE_PROJECT_USE_TEMPLATE,
    projectSaga.createProjectUseTemplate
  );
  yield takeEvery(
    projectsActions.FETCH_TASK_FILE_REQUEST,
    projectSaga.fetchTaskFile
  );
  yield takeEvery(
    projectsActions.FETCH_PROJECT_TAGS_REQUEST,
    projectSaga.fetchProjectTags
  );
  yield takeEvery(
    projectsActions.CREATE_PROJECT_TAG_REQUEST,
    projectSaga.createProjectTag
  );
  yield takeEvery(
    projectsActions.UPDATE_PROJECT_TAG_REQUEST,
    projectSaga.updateProjectTag
  );
  yield takeEvery(
    projectsActions.DELETE_PROJECT_TAG_REQUEST,
    projectSaga.deleteProjectTag
  );
  yield takeEvery(
    projectsActions.DELETE_PROJECT_TAG_FROM_LIST_REQUEST,
    projectSaga.deleteProjectListTag
  );
  yield takeEvery(
    projectsActions.SELECT_AVAILABLE_PROJECT_TAG_REQUEST,
    projectSaga.selectProjectTag
  );
  yield takeEvery(
    projectsActions.UPDATE_PROJECT_DATA_AND_PUBLISH_REQUEST,
    projectSaga.updateAndPublishProject
  );

  yield takeEvery(
    projectsActions.FETCH_TEMPLATE_LIST_REQUEST,
    projectSaga.fetchTemplateList
  );
  yield takeEvery(
    projectsActions.FETCH_TEMPLATES_BY_NAME_REQUEST,
    projectSaga.fetchTemplatesByName
  );
  yield takeEvery(
    projectsActions.FETCH_SELECTED_PROJECT_REQUEST,
    projectSaga.fetchSelectedProject
  );

  yield takeEvery(
    projectsActions.CREATE_PROJECT_AVATAR_REQUEST,
    projectSaga.createProjectAvatar
  );
  yield takeEvery(
    projectsActions.FETCH_PROJECT_AVATAR_URL_REQUEST,
    projectSaga.fetchProjectAvatarUrl
  );
  yield takeEvery(
    projectsActions.REMOVE_PROJECT_AVATAR_REQUEST,
    projectSaga.removeProjectAvatar
  );
  yield takeEvery(
    projectsActions.CREATE_RELATED_TASKS_REQUEST,
    taskSaga.createRelatedTasks
  );
  yield takeEvery(
    projectsActions.REMOVE_RELATED_TASKS_REQUEST,
    taskSaga.removeRelatedTasks
  );
  yield takeEvery(
    projectsActions.CHANGE_TEMPLATE_TYPE_REQUEST,
    projectSaga.changeTemplateType
  );
  yield takeEvery(
    projectsActions.ASSIGN_TEAM_TO_PROJECT_REQUEST,
    projectSaga.assignTeam
  );
  yield takeEvery(
    projectsActions.DISMISS_TEAM_FROM_PROJECT_REQUEST,
    projectSaga.dismissTeam
  );
  yield takeEvery(
    projectsActions.GET_SIMPLE_PROJECTS_DATA_REQUEST,
    projectSaga.getSimpleProjectsData
  );
  yield takeEvery(
    projectsActions.FETCH_TASK_SUBTASKS_REQUEST,
    taskSaga.fetchTaskSubtasksSaga
  );
  yield takeEvery(
    projectsActions.FETCH_PROJECT_SUBTASKS_REQUEST,
    projectSaga.fetchProjectSubtasksSaga
  );
  yield takeEvery(
    projectsActions.MOVE_SUBTASK_REQUEST,
    taskSaga.moveSubtaskSaga
  );
  yield takeEvery(
    projectsActions.CHECK_EXISTENCE_OF_PROJECT_REQUEST,
    projectSaga.checkExistenceOfProject
  );
  yield takeEvery(
    projectsActions.GET_PROJECTS_TAGS_REQUEST,
    projectSaga.getProjectsTags
  );

  yield takeEvery(
    projectsActions.GET_TEMPLATES_TAGS_REQUEST,
    projectSaga.getTemplatesTags
  );

  yield takeEvery(
    projectsActions.GET_ARCHIVED_PROJECTS_TAGS_REQUEST,
    projectSaga.getArchivedProjectsTags
  );
}
