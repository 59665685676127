import actionTypes from './actionTypes';

export default {
  createSectionRequest: data => ({
    type: actionTypes.CREATE_SECTION_REQUEST,
    data,
  }),
  createSectionSuccess: project => ({
    type: actionTypes.CREATE_SECTION_SUCCESS,
    payload: project,
  }),
  createSectionFailure: error => ({
    type: actionTypes.CREATE_SECTION_FAILURE,
    payload: error,
  }),

  updateSectionRequest: data => ({
    type: actionTypes.UPDATE_SECTION_REQUEST,
    data,
  }),
  updateSectionSuccess: project => ({
    type: actionTypes.UPDATE_SECTION_SUCCESS,
    payload: project,
  }),
  updateSectionFailure: error => ({
    type: actionTypes.UPDATE_SECTION_FAILURE,
    payload: error,
  }),

  removeSectionRequest: data => ({
    type: actionTypes.REMOVE_SECTION_REQUEST,
    data,
  }),
  removeSectionSuccess: sectionId => ({
    type: actionTypes.REMOVE_SECTION_SUCCESS,
    payload: sectionId,
  }),
  removeSectionFailure: error => ({
    type: actionTypes.REMOVE_SECTION_FAILURE,
    payload: error,
  }),

  hideSection: payload => ({
    type: actionTypes.HIDE_SECTION,
    payload,
  }),
  hideSectionFailure: error => ({
    type: actionTypes.HIDE_SECTION_FAILURE,
    payload: error,
  }),

  updateSectionTaskDates: data => ({
    type: actionTypes.UPDATE_PROJECT_SECTION_TASK_DATES,
    payload: data,
  }),
};
