import licenseActions from './actions';
import helpers from './licenseHelpers';

const initialState = {
  listLoading: false,
  loading: false,
  error: null,
  search: '',
  filters: {
    name: '',
    licenseType: '',
    status: '',
    assignedTo: '',
    dueDates: '',
    tileName: '',
  },
  sort: {
    name: '',
    date: '',
    isTilesHidden: false,
    active: false,
  },
  licenses: [],
  auditLicenseList: [],
  previousLicense: null,
  isShowCreatedLicense: false,
};

export default function(state = initialState, action) {
  const licenseHelpers = helpers(state, action);

  switch (action.type) {
    case licenseActions.GET_LICENSE_LIST_REQUEST:
      return { ...state, listLoading: true, error: null };

    case licenseActions.GET_AUDIT_LICENSE_LIST_REQUEST:
    case licenseActions.CREATE_LICENSE_REQUEST:
    case licenseActions.UPDATE_LICENSE_REQUEST:
    case licenseActions.REMOVE_LICENSE_REQUEST:
    case licenseActions.CREATE_LICENSE_STEP_REQUEST:
    case licenseActions.UPDATE_LICENSE_STEP_REQUEST:
    case licenseActions.REMOVE_LICENSE_STEP_REQUEST:
    case licenseActions.SET_LICENSE_ASSIGNEE_REQUEST:
    case licenseActions.UNSET_LICENSE_ASSIGNEE_REQUEST:
    case licenseActions.ADD_LICENSE_MEMBER_REQUEST:
    case licenseActions.REMOVE_LICENSE_MEMBER_REQUEST:
    case licenseActions.MOVE_STEP_REQUEST:
    case licenseActions.ATTACH_STEP_PROJECT_REQUEST:
    case licenseActions.REMOVE_STEP_PROJECT_REQUEST:
    case licenseActions.GET_LICENSE_LOGS_REQUEST:
    case licenseActions.CREATE_STEP_DATE_REQUEST:
    case licenseActions.UPDATE_STEP_DATE_REQUEST:
    case licenseActions.DELETE_STEP_DATE_REQUEST:
      return { ...state, loading: true, error: null };

    case licenseActions.GET_LICENSE_LIST_FAILURE:
      return { ...state, listLoading: false, error: action.payload };

    case licenseActions.GET_AUDIT_LICENSE_LIST_FAILURE:
    case licenseActions.CREATE_LICENSE_FAILURE:
    case licenseActions.UPDATE_LICENSE_FAILURE:
    case licenseActions.REMOVE_LICENSE_FAILURE:
    case licenseActions.CREATE_LICENSE_STEP_FAILURE:
    case licenseActions.UPDATE_LICENSE_STEP_FAILURE:
    case licenseActions.REMOVE_LICENSE_STEP_FAILURE:
    case licenseActions.SET_LICENSE_ASSIGNEE_FAILURE:
    case licenseActions.UNSET_LICENSE_ASSIGNEE_FAILURE:
    case licenseActions.ADD_LICENSE_MEMBER_FAILURE:
    case licenseActions.REMOVE_LICENSE_MEMBER_FAILURE:
    case licenseActions.ATTACH_STEP_PROJECT_FAILURE:
    case licenseActions.REMOVE_STEP_PROJECT_FAILURE:
    case licenseActions.GET_LICENSE_LOGS_FAILURE:
    case licenseActions.CREATE_STEP_DATE_FAILURE:
    case licenseActions.UPDATE_STEP_DATE_FAILURE:
    case licenseActions.DELETE_STEP_DATE_FAILURE:
    case licenseActions.CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case licenseActions.GET_LICENSE_LIST_SUCCESS:
      return licenseHelpers.sortLicenseByPosition();

    case licenseActions.GET_AUDIT_LICENSE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        auditLicenseList: action.payload,
      };

    case licenseActions.CREATE_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: [...state.licenses, action.payload],
        isShowCreatedLicense: true,
      };

    case licenseActions.UPDATE_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        license: state.licenses.map(license => {
          if (license.id === action.payload.id) {
            return { ...license, ...action.payload };
          }
          return license;
        }),
      };

    case licenseActions.REMOVE_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.filter(
          license => license.id !== action.payload.licenseId
        ),
      };

    case licenseActions.CREATE_LICENSE_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId)
            return { ...license, steps: [...license.steps, action.payload] };
          return license;
        }),
      };

    case licenseActions.UPDATE_LICENSE_STEP_SUCCESS:
    case licenseActions.CREATE_STEP_DATE_SUCCESS:
    case licenseActions.DELETE_STEP_DATE_SUCCESS:
      const { licenseId, ...rest } = action.payload;

      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === licenseId) {
            return {
              ...license,
              steps: license.steps.map(step => {
                if (step.id === action.payload.id) return { ...step, ...rest };
                return step;
              }),
            };
          }
          return license;
        }),
      };

    case licenseActions.UPDATE_STEP_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.id) {
            return action.payload;
          }
          return license;
        }),
      };

    case licenseActions.REMOVE_LICENSE_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId)
            return {
              ...license,
              steps: license.steps.filter(
                step => step.id !== action.payload.stepId
              ),
            };
          return license;
        }),
      };

    case licenseActions.SET_LICENSE_FILTERS:
      return {
        ...state,
        filters: action.payload
          ? { ...state.filters, ...action.payload }
          : {
              name: '',
              licenseType: '',
              status: '',
              assignedTo: '',
              dueDates: '',
              tileName: '',
            },
      };

    case licenseActions.SET_LICENSE_SORT:
      const sort = action.payload
        ? { ...state.sort, ...action.payload }
        : {
            name: '',
            date: '',
            isTilesHidden: false,
            active: false,
          };

      return {
        ...state,
        sort,
      };

    case licenseActions.SET_LICENSE_ASSIGNEE_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            license.steps = license.steps.map(step => {
              if (step.id === action.payload.stepId) {
                return {
                  ...step,
                  assignedTo: [...step.assignedTo, action.payload.user],
                };
              }
              return step;
            });
          }
          return license;
        }),
      };

    case licenseActions.UNSET_LICENSE_ASSIGNEE_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            license.steps = license.steps.map(step => {
              if (step.id === action.payload.stepId) {
                return {
                  ...step,
                  assignedTo: step.assignedTo.filter(
                    user => user.id !== action.payload.assigneeId
                  ),
                };
              }
              return step;
            });
          }
          return license;
        }),
      };

    case licenseActions.ADD_LICENSE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            return {
              ...license,
              connectedMembers: [
                ...license.connectedMembers,
                action.payload.user,
              ],
            };
          }
          return license;
        }),
      };

    case licenseActions.REMOVE_LICENSE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            return {
              ...license,
              connectedMembers: license.connectedMembers.filter(
                user => user.id !== action.payload.userId
              ),
            };
          }
          return license;
        }),
      };

    case licenseActions.MOVE_STEP_LOCAL:
      return {
        ...state,
        previousLicense: state.licenses.find(
          license => license.id === action.payload.licenseId
        ),
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            return { ...license, steps: action.payload.steps };
          }
          return license;
        }),
      };

    case licenseActions.MOVE_STEP_FAILURE:
      return {
        ...state,
        previousLicense: null,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            return state.previousLicense;
          }
          return license;
        }),
      };

    case licenseActions.MOVE_LICENSE_LOCAL:
      return {
        ...state,
        licenses: action.payload.steps,
      };

    case licenseActions.MOVE_LICENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case licenseActions.MOVE_LICENSE_SUCCESS:
    case licenseActions.MOVE_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case licenseActions.ATTACH_STEP_PROJECT_SUCCESS:
    case licenseActions.CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            license.steps = license.steps.map(step => {
              if (step.id === action.payload.stepId) {
                return {
                  ...step,
                  stepProject: action.payload.stepProject,
                };
              }
              return step;
            });
          }
          return license;
        }),
      };

    case licenseActions.REMOVE_STEP_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            license.steps = license.steps.map(step => {
              if (step.id === action.payload.stepId) {
                return {
                  ...step,
                  stepProject: null,
                };
              }
              return step;
            });
          }
          return license;
        }),
      };

    case licenseActions.GET_LICENSE_LOGS_SUCCESS:
      return { ...state, loading: false };

    case licenseActions.SET_CREATED_LICENSE_FLAG_TO_FALSE:
      return {
        ...state,
        isShowCreatedLicense: false,
      };

    case licenseActions.HIDE_LICENSE_SUCCESS:
      const { licenseId: id, hide } = action.data;

      return {
        ...state,
        licenses: state.licenses.map(license => {
          if (license.id === id) {
            license.isHidden = hide;
          }
          return license;
        }),
      };
    case licenseActions.HIDE_LICENSE_FAILURE:
      return state;

    case licenseActions.ASSIGN_BUSINESS_TO_LICENSE_SUCCESS:
      return {
        ...state,
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.id) {
            return {
              ...license,
              businessId: action.payload.businessId,
            };
          }
          return license;
        }),
      };

    case licenseActions.ASSIGN_BUSINESS_TO_LICENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
