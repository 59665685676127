import { notification } from 'antd';
import notificationsActions from './actions';

const initialState = {
  data: [],
  totalNumber: 0,
  page: 1,
  loading: false,
  loadingList: false,
  error: null,
  dataTracker: [],
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case notificationsActions.GET_NOTIFICATION_LIST_REQUEST:
    case notificationsActions.FETCH_NOTIFICATIONS_REQUEST:
      return { ...state, loadingList: true, error: null };

    case notificationsActions.UPDATE_NOTIFICATION_REQUEST:
    case notificationsActions.REMOVE_NOTIFICATION_REQUEST:
      return { ...state, loading: true, error: null };

    case notificationsActions.GET_NOTIFICATION_LIST_FAILURE:
    case notificationsActions.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload,
      };

    case notificationsActions.UPDATE_NOTIFICATION_FAILURE:
    case notificationsActions.REMOVE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case notificationsActions.GET_NOTIFICATION_LIST_SUCCESS:
    case notificationsActions.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.notifications,
        totalNumber: action.payload.totalNumber,
        loadingList: false,
        error: null,
      };

    case notificationsActions.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map(notification => {
          if (notification.id === action.payload.id) {
            return { ...notification, ...action.payload };
          }
          return notification;
        }),
      };

    case notificationsActions.SET_NOTIFICATIONS_FOR_TRACKER:
      return {
        ...state,
        dataTracker: action.payload.notifications,
        loadingList: false,
        error: null,
      };

    case notificationsActions.REMOVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: state.data.filter(x => x.id !== action.payload),
        loading: false,
        error: null,
      };

    case notificationsActions.SET_PAGE:
      return { ...state, page: action.payload };

    case notificationsActions.UPDATE_TOTAL_NUMBER_OF_NOTIFICATIONS:
      return {
        ...state,
        totalNumber: state.totalNumber - action.payload,
      };

    case notificationsActions.MARK_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        data: state.data.map(notification => ({
          ...notification,
          unread: false,
        })),
        totalNumber: state.totalNumber - state.data.length,
      };

    default:
      return state;
  }
}
