import metrcActions from './actions';

const initialState = {
  transfers: { rejected: [] },
  receipts: [],
  currentReceipt: null,
  currentPackage: null,
  labtests: [],
  loading: true,
  transfersLoading: true,
  error: null,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case metrcActions.GET_RECEIPTS_REQUEST:
    case metrcActions.GET_RECEIPT_REQUEST:
    case metrcActions.GET_PACKAGE_REQUEST:
    case metrcActions.GET_LABTESTS_REQUEST:
      return { ...state, loading: true, error: null };

    case metrcActions.GET_TRANSFERS_REQUEST:
      return { ...state, transfersLoading: true, error: null };

    case metrcActions.UPDATE_TRANSFER_SUCCESS:
      return {
        ...state,
        transfers: {
          ...state.transfers,
          rejected: state.transfers.rejected.map(rejected => {
            if (rejected.DeliveryId === action.payload.deliveryId) {
              return {
                ...rejected,
                Packages: rejected.Packages.map(item => {
                  if (item.PackageId === action.payload.packageId) {
                    return { ...item, LabTestingState: action.payload.note };
                  }
                  return item;
                }),
              };
            }
            return rejected;
          }),
        },
        error: null,
      };

    case metrcActions.GET_TRANSFERS_SUCCESS:
      return { ...state, transfers: action.payload, transfersLoading: false };

    case metrcActions.GET_RECEIPTS_SUCCESS:
      return { ...state, receipts: action.payload, loading: false };

    case metrcActions.GET_RECEIPT_SUCCESS:
      return { ...state, currentReceipt: action.payload, loading: false };

    case metrcActions.GET_PACKAGE_SUCCESS:
      return { ...state, currentPackage: action.payload, loading: false };

    case metrcActions.GET_LABTESTS_SUCCESS:
      return { ...state, labtests: action.payload, loading: false };

    case metrcActions.GET_RECEIPTS_FAILURE:
    case metrcActions.GET_RECEIPT_FAILURE:
    case metrcActions.GET_PACKAGE_FAILURE:
    case metrcActions.GET_LABTESTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case metrcActions.GET_TRANSFERS_FAILURE:
      return { ...state, transfersLoading: false, error: action.payload };

    default:
      return state;
  }
}
