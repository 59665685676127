import _ from 'lodash';

import serviceProviders from './providers';
import { events, ANALYTIC_ROUTES, routes } from './constants';

import { APP_ROUTES } from '../../route.constants';

export { events };
export default class Analytics {
  static providers = [];

  static init() {
    Analytics.providers = serviceProviders
      .map(provider => provider.init())
      .filter(provider => provider);
  }

  static identify(userId, properties = {}, isTabDuplicate) {
    if (!isTabDuplicate) {
      Analytics.providers.forEach(provider => {
        provider.identify(userId, properties);
      });
    }
  }

  static trackSessionStart(properties = {}, isTabDuplicate) {
    if (!isTabDuplicate) {
      Analytics.providers.forEach(provider => {
        provider.trackSessionStart(events.SESSION_START, properties);
      });
    }
  }

  static trackSessionEnd(properties = {}) {
    Analytics.providers.forEach(provider => {
      provider.trackSessionEnd(events.SESSION_END, properties);
    });
  }

  static trackEvent(event, properties = {}) {
    Analytics.providers.forEach(provider => {
      provider.trackEvent(event, properties);
    });
  }

  static trackPage(page) {
    let properties = {};

    if (page.includes(`${APP_ROUTES.PROJECTS_HOME}/`)) {
      const path = page.split('/');

      if (path.length === 4) {
        properties = {
          projectId: _.last(page.split('/')),
        };
        page = ANALYTIC_ROUTES.PROJECT_PAGE;
      }
    }

    if (page.includes(`${APP_ROUTES.LOCATIONS}/`)) {
      const path = page.split('/');

      if (path.length === 4) {
        properties = {
          businessId: path[2],
        };

        page = routes[path[3]] ? path[3] : 'custom';
      } else {
        properties = {
          businessId: _.last(page.split('/')),
        };
        page = ANALYTIC_ROUTES.LOCATION_PAGE;
      }
    }

    Analytics.providers.forEach(provider => {
      provider.trackPage(page, properties);
    });
  }
}
