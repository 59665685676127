import { put, takeEvery, all } from 'redux-saga/effects';

import anamiService from '../../services/anamiService';
import licenseActions from './actions';
import { getProjectService } from '@iso/redux/projects/saga/helpers';

function* getLicenseList() {
  try {
    const response = yield anamiService.getLicenseList();

    yield put(licenseActions.getLicenseListSuccess(response.data.licenses));
  } catch (error) {
    yield put(licenseActions.getLicenseListFailure(error));
  }
}

function* getAuditLicenseList() {
  try {
    const response = yield anamiService.getLicenseList();

    yield put(
      licenseActions.getAuditLicenseListSuccess(response.data.licenses)
    );
  } catch (error) {
    yield put(licenseActions.getAuditLicenseListFailure(error));
  }
}

function* createLicense({ data }) {
  try {
    const response = yield anamiService.createLicense(data);

    yield put(licenseActions.createLicenseSuccess(response.data.license));
  } catch (error) {
    yield put(licenseActions.createLicenseFailure(error));
  }
}

function* updateLicense({ data }) {
  try {
    const response = yield anamiService.updateLicense(data);

    yield put(licenseActions.updateLicenseSuccess(response.data.license));
  } catch (error) {
    yield put(licenseActions.updateLicenseFailure(error));
  }
}

function* hideLicense({ data }) {
  try {
    yield anamiService.hideLicense(data);
    yield put(licenseActions.hideLicenseSuccess(data));
  } catch (error) {
    yield put(licenseActions.hideLicenseFailure);
  }
}

function* removeLicense({ data }) {
  try {
    yield anamiService.removeLicense(data);

    yield put(licenseActions.removeLicenseSuccess(data));
  } catch (error) {
    yield put(licenseActions.removeLicenseFailure(error));
  }
}

function* createLicenseStep({ data }) {
  try {
    const response = yield anamiService.createLicenseStep(data);

    yield put(
      licenseActions.createLicenseStepSuccess({
        licenseId: data.licenseId,
        ...response.data.step,
      })
    );
  } catch (error) {
    yield put(licenseActions.createLicenseStepFailure(error));
  }
}

function* updateLicenseStep({ data }) {
  try {
    const response = yield anamiService.updateLicenseStep(data);

    yield put(
      licenseActions.updateLicenseStepSuccess({
        licenseId: data.licenseId,
        ...response.data.step,
      })
    );
  } catch (error) {
    yield put(licenseActions.updateLicenseStepFailure(error));
  }
}

function* removeLicenseStep({ data }) {
  try {
    yield anamiService.removeLicenseStep(data);

    yield put(licenseActions.removeLicenseStepSuccess(data));
  } catch (error) {
    yield put(licenseActions.removeLicenseStepFailure(error));
  }
}

function* setLicenseAssignee({ data }) {
  try {
    const { user, ...params } = data;
    yield anamiService.setLicenseAssignee(params);

    yield put(licenseActions.setLicenseAssigneeSuccess(data));
  } catch (error) {
    yield put(licenseActions.setLicenseAssigneeFailure(error));
  }
}

function* unsetLicenseAssignee({ data }) {
  try {
    yield anamiService.unsetLicenseAssignee(data);

    yield put(licenseActions.unsetLicenseAssigneeSuccess(data));
  } catch (error) {
    yield put(licenseActions.unsetLicenseAssigneeFailure(error));
  }
}

function* addLicenseMember({ data }) {
  try {
    const { user, ...params } = data;
    yield anamiService.addLicenseMember(params);

    yield put(licenseActions.addLicenseMemberSuccess(data));
  } catch (error) {
    yield put(licenseActions.addLicenseMemberFailure(error));
  }
}

function* removeLicenseMember({ data }) {
  try {
    yield anamiService.removeLicenseMember(data);

    yield put(licenseActions.removeLicenseMemberSuccess(data));
  } catch (error) {
    yield put(licenseActions.removeLicenseMemberFailure(error));
  }
}

function* moveStep({ data }) {
  try {
    const { steps, ...params } = data;
    yield put(
      licenseActions.moveStepLocal({
        steps,
        licenseId: params.licenseId,
      })
    );

    yield anamiService.reorderSteps(params);

    yield put(licenseActions.moveStepSuccess({ licenseId: params.licenseId }));
  } catch (error) {
    yield put(
      licenseActions.moveStepFailure({ licenseId: data.licenseId, error })
    );
  }
}

function* attachSteProject({ data }) {
  try {
    const { isPrivate, stepProject, ...params } = data;
    if (data.isPrivate) {
      const service = yield getProjectService(data.projectId);
      yield service.updateProject({
        projectId: data.projectId,
        isPrivate: false,
      });
    }

    yield anamiService.attachStepProject(params);

    yield put(licenseActions.attachStepProjectSuccess(data));
  } catch (error) {
    yield put(licenseActions.attachStepProjectFailure(error));
  }
}

function* removeStepProject({ data }) {
  try {
    yield anamiService.removeStepProject(data);

    yield put(licenseActions.removeStepProjectSuccess(data));
  } catch (error) {
    yield put(licenseActions.removeStepProjectFailure(error));
  }
}

function* getLicenseLogs({ data }) {
  try {
    const response = yield anamiService.getLicenseLogs(data);

    yield put(licenseActions.getLicenseLogsSuccess(response.data.projects));

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'audit-logs.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    yield put(licenseActions.getLicenseLogsFailure(error));
  }
}

function* moveLicense({ data }) {
  try {
    const { steps, ...params } = data;
    yield put(licenseActions.moveLicenseLocal({ steps }));

    yield anamiService.reorderLicenses(params);

    yield put(licenseActions.moveLicenseSuccess());
  } catch (error) {
    yield put(licenseActions.moveLicenseFailure({ error }));
  }
}

function* createStepDate({ data }) {
  try {
    const response = yield anamiService.createStepDate(data);

    yield put(
      licenseActions.createStepDateSuccess({
        licenseId: data.licenseId,
        ...response.data.step,
      })
    );
  } catch (error) {
    yield put(licenseActions.createStepDateFailure(error));
  }
}

function* updateStepDate({ data }) {
  try {
    const { licenseId } = data;

    yield anamiService.updateStepDate(data);
    const response = yield anamiService.getLicense(licenseId);

    yield put(licenseActions.updateStepDateSuccess(response.data.license));
  } catch (error) {
    yield put(licenseActions.updateStepDateFailure(error));
  }
}

function* deleteStepDate({ data }) {
  try {
    const response = yield anamiService.deleteStepDate(data);

    yield put(
      licenseActions.deleteStepDateSuccess({
        licenseId: data.licenseId,
        ...response.data.step,
      })
    );
  } catch (error) {
    yield put(licenseActions.deleteStepDateFailure(error));
  }
}

function* assignBusinessToLicense({ data }) {
  try {
    const response = yield anamiService.setLicensesBusiness(data);

    yield put(licenseActions.assignBusinessSuccess(response.data.license));
  } catch (error) {
    yield put(licenseActions.assignBusinessFailure(error));
  }
}

function* attachStepToNewProject({ data }) {
  try {
    const { projectData, ...rest } = data;

    const response = yield anamiService.createProject(projectData);

    const { project } = response.data;

    const params = {
      stepId: rest.stepId,
      licenseId: rest.licenseId,
      projectId: project.id,
    };

    yield anamiService.attachStepProject(params);

    yield put(
      licenseActions.createStepProjectSuccess({
        ...params,
        stepProject: { id: project.id, title: project.title },
      })
    );
  } catch (error) {
    yield put(licenseActions.createStepProjectFailure(error));
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(licenseActions.GET_LICENSE_LIST_REQUEST, getLicenseList),
    takeEvery(
      licenseActions.GET_AUDIT_LICENSE_LIST_REQUEST,
      getAuditLicenseList
    ),
    takeEvery(licenseActions.CREATE_LICENSE_REQUEST, createLicense),
    takeEvery(licenseActions.UPDATE_LICENSE_REQUEST, updateLicense),
    takeEvery(licenseActions.HIDE_LICENSE_REQUEST, hideLicense),
    takeEvery(licenseActions.REMOVE_LICENSE_REQUEST, removeLicense),
    takeEvery(licenseActions.CREATE_LICENSE_STEP_REQUEST, createLicenseStep),
    takeEvery(licenseActions.UPDATE_LICENSE_STEP_REQUEST, updateLicenseStep),
    takeEvery(licenseActions.REMOVE_LICENSE_STEP_REQUEST, removeLicenseStep),
    takeEvery(licenseActions.SET_LICENSE_ASSIGNEE_REQUEST, setLicenseAssignee),
    takeEvery(
      licenseActions.UNSET_LICENSE_ASSIGNEE_REQUEST,
      unsetLicenseAssignee
    ),
    takeEvery(licenseActions.ADD_LICENSE_MEMBER_REQUEST, addLicenseMember),
    takeEvery(
      licenseActions.REMOVE_LICENSE_MEMBER_REQUEST,
      removeLicenseMember
    ),
    takeEvery(licenseActions.MOVE_STEP_REQUEST, moveStep),
    takeEvery(licenseActions.ATTACH_STEP_PROJECT_REQUEST, attachSteProject),
    takeEvery(licenseActions.REMOVE_STEP_PROJECT_REQUEST, removeStepProject),
    takeEvery(licenseActions.GET_LICENSE_LOGS_REQUEST, getLicenseLogs),
    takeEvery(licenseActions.MOVE_LICENSE_REQUEST, moveLicense),
    takeEvery(licenseActions.CREATE_STEP_DATE_REQUEST, createStepDate),
    takeEvery(licenseActions.UPDATE_STEP_DATE_REQUEST, updateStepDate),
    takeEvery(licenseActions.DELETE_STEP_DATE_REQUEST, deleteStepDate),
    takeEvery(
      licenseActions.ASSIGN_BUSINESS_TO_LICENSE_REQUEST,
      assignBusinessToLicense
    ),
  ]);
  yield takeEvery(
    licenseActions.CREATE_PROJECT_ASSIGNED_TO_LICENSE_STEP_REQUEST,
    attachStepToNewProject
  );
}
