import _ from 'lodash';

import dashboardActions from './actions';

const initialState = {
  data: [],
  projectList: [],
  loading: false,
  error: null,
  selectedProject: '',
  projectsTotalNumber: 0,
  loadingFavorites: false,
  loadingRecent: false,
  loadingTodos: false,
  favorites: [],
  recent: [],
  todos: [],
  todosTotal: 0,
  recentTotal: 0,
  favoritesTotal: 0,
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardActions.FETCH_DATA:
    case dashboardActions.GET_PROJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case dashboardActions.GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectList: action.payload.projects,
        projectsTotalNumber: action.payload.projects.length,
        loading: false,
        error: null,
      };

    case dashboardActions.FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };

    case dashboardActions.FETCH_DATA_FAILURE:
    case dashboardActions.GET_PROJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case dashboardActions.GET_PROJECT_SUMMARY:
      return { ...state, loading: true, error: null };

    case dashboardActions.SELECT_PROJECT:
      return { ...state, selectedProject: action.payload };

    case dashboardActions.VIEW_NOTIFICATION:
      return {
        ...state,
        data: state.data.notifications
          ? { ...state.data, notifications: state.data.notifications - 1 }
          : state.data,
      };

    case dashboardActions.FETCH_RECENT_REQUEST:
      return {
        ...state,
        loadingRecent: true,
        // recent: !_.has(action.data, 'showMore') ? [] : state.recent,
      };

    case dashboardActions.FETCH_RECENT_SUCCESS:
      return {
        ...state,
        loadingRecent: false,
        recent: action.payload.showMore
          ? [...state.recent, ...action.payload.data]
          : action.payload.data,
        recentTotal: action.payload.totalNumber,
      };

    case dashboardActions.FETCH_RECENT_FAILURE:
      return {
        ...state,
        loadingRecent: false,
        error: action.payload,
      };

    case dashboardActions.FETCH_FAVORITES_REQUEST:
      return {
        ...state,
        loadingFavorites: true,
        // favorites: !_.has(action.data, 'showMore') ? [] : state.favorites,
      };

    case dashboardActions.FETCH_FAVORITES_SUCCESS:
      return {
        ...state,
        loadingFavorites: false,
        favorites: action.payload.showMore
          ? [...state.favorites, ...action.payload.data]
          : action.payload.data,
        favoritesTotal: action.payload.totalNumber,
      };

    case dashboardActions.FETCH_FAVORITES_FAILURE:
      return {
        ...state,
        loadingFavorites: false,
        error: action.payload,
      };

    case dashboardActions.CLEAR_DASHBOARD_ITEMS:
      return {
        ...state,
        favorites: [],
        recent: [],
        todos: [],
      };

    case dashboardActions.FETCH_TODOS_REQUEST:
      return {
        ...state,
        loadingTodos: true,
        // todos: !_.has(action.data, 'showMore') ? [] : state.todos,
      };

    case dashboardActions.FETCH_TODOS_SUCCESS:
      return {
        ...state,
        loadingTodos: false,
        todos: action.payload.showMore
          ? [...state.todos, ...action.payload.data]
          : action.payload.data,
        todosTotal: action.payload.totalNumber,
      };

    case dashboardActions.FETCH_TODOS_FAILURE:
      return {
        ...state,
        loadingTodos: false,
        error: action.payload,
      };

    case dashboardActions.COMPLETE_TODO_SUCCESS:
    case dashboardActions.RESTORE_TODO_SUCCESS:
      return {
        ...state,
        todos: state.todos.map(todo => {
          if (todo.id === action.payload.id) {
            return {
              ...todo,
              completedAt: action.payload.completedAt,
            };
          }

          return todo;
        }),
      };

    case dashboardActions.SET_STATUS_FOR_TODO_TASK:
      return {
        ...state,
        todos: state.todos.map(todo => {
          if (todo.id === action.payload.todoId) {
            return {
              ...todo,
              task: {
                ...todo.task,
                status: action.payload.taskStatus,
              },
            };
          }

          return todo;
        }),
      };

    default:
      return state;
  }
}
