const getFullProject = (state, id) => {
  let project = null;

  if (state.selectedProject && id === state.selectedProject.id) {
    project = state.selectedProject;
  } else {
    project = state.data.find(x => x.id === id);
  }

  return project;
};

const updateProjectList = (state, project) => {
  return state.data.map(item => {
    if (item.id === project.id) {
      return project;
    }
    return item;
  });
};

export default (state, action) => ({
  getProjectList: () => {
    return {
      ...state,
      data: action.payload,
      projectListLoading: false,
      error: null,
    };
  },

  createProject: () => {
    return {
      ...state,
      data: [...state.data, action.payload],
      loading: false,
      hasNew: true,
    };
  },

  updateProject: () => {
    return {
      ...state,
      selectedProject: {
        ...state.selectedProject,
        ...action.payload,
        // createdBy: state.selectedProject.createdBy,
      },
      data: state.data.map(project => {
        if (project.id === action.payload.id) {
          return action.payload;
        }
        return project;
      }),
      // businessProjects: [...state.businessProjects, action.payload],
      loading: false,
    };
  },

  removeProject: () => {
    return {
      ...state,
      data: state.data.filter(project => project.id !== action.payload),
      loading: false,
      removeProjectLoading: false,
      error: null,
    };
  },

  getProject: () => {
    return {
      ...state,
      projectLoading: false,
      selectedProject: action.payload,
    };
  },

  copyProject: () => {
    return {
      ...state,
      data: [...state.data, action.payload],
      loading: false,
      hasNew: true,
    };
  },

  getProjectUsers: () => {
    return {
      ...state,
      users: action.payload.filter(user => user.id),
      loading: false,
      error: false,
    };
  },

  getConnectedUsers: () => {
    return {
      ...state,
      connectedUsers: action.payload.filter(user => user.id),
      loading: false,
      error: false,
    };
  },

  createProjectLabel: () => {
    return {
      ...state,
      labels: [...state.labels, action.payload],
      loading: false,
      error: null,
    };
  },

  updateProjectLabel: () => {
    return {
      ...state,
      labels: state.labels.map(label => {
        if (label.id === action.payload.id) {
          return action.payload;
        }
        return label;
      }),
      selectedProject: {
        ...state.selectedProject,
        section: state.selectedProject.section.map(section => {
          return {
            ...section,
            tasks: section.tasks.map(task => {
              return {
                ...task,
                labels: task.labels.map(label => {
                  if (label.id === action.payload.id) {
                    return action.payload;
                  }
                  return label;
                }),
              };
            }),
          };
        }),
      },
      loading: false,
      error: null,
    };
  },

  removeProjectLabel: () => {
    return {
      ...state,
      loading: false,
      error: null,
      labels: state.labels.filter(x => x.id !== action.payload.labelId),
      selectedTask: state.selectedTask
        ? {
            ...state.selectedTask,
            labels: state.selectedTask.labels.filter(
              label => label.id !== action.payload.labelId
            ),
          }
        : state.selectedTask,
      selectedProject: {
        ...state.selectedProject,
        section:
          state.selectedProject.section &&
          state.selectedProject.section.map(section => {
            return {
              ...section,
              tasks: section.tasks.map(task => {
                return {
                  ...task,
                  labels: task.labels.filter(
                    label => label.id !== action.payload.labelId
                  ),
                };
              }),
            };
          }),
      },
    };
  },

  connectUser: () => {
    let project = getFullProject(state, action.payload.projectId);

    project = {
      ...project,
      users: [...project.users, action.payload],
    };

    return {
      ...state,
      data: updateProjectList(state, project),
      selectedProject: project,
      connectedUsers: [...state.connectedUsers, action.payload],
      usersLoading: false,
      error: null,
    };
  },

  disconnectUser: () => {
    let project = getFullProject(state, action.payload.projectId);

    project = {
      ...project,
      users: project.users.filter(user => user.id !== action.payload.userId),
    };

    return {
      ...state,
      data: updateProjectList(state, project),
      selectedProject: project,
      connectedUsers: state.connectedUsers.filter(
        user => user.id !== action.payload.userId
      ),
      usersLoading: false,
      error: null,
    };
  },

  handleProjectSubtasks: mode => {
    let subtaskList = {};
    const isAddMode = mode === 'add';
    const { skipSettingParentTaskIds = false, subtasks } = action.payload;

    if (isAddMode) {
      subtaskList = subtasks.reduce((acc, listItem) => {
        Object.keys(listItem).forEach(itemKey => {
          if (itemKey in acc) {
            return {
              ...acc,
              [itemKey]: [...acc[itemKey], ...listItem[itemKey]],
            };
          }
        });

        return { ...acc, ...listItem };
      }, {});
    }

    const listParentTaskIds =
      isAddMode && !skipSettingParentTaskIds ? Object.keys(subtaskList) : null;

    return {
      ...state,
      subtasksLoading: false,
      selectedProject: {
        ...state.selectedProject,
        section: (state.selectedProject?.section || []).map(section => {
          return {
            ...section,
            listParentTaskIds,
            tasks: section.tasks.map(task => {
              if (isAddMode) {
                if (task.id in subtaskList) {
                  return {
                    ...task,
                    subtasks: subtaskList[task.id],
                  };
                }
              } else {
                if ('subtasks' in task) {
                  return {
                    ...task,
                    subtasks: [],
                  };
                }
              }

              return task;
            }),
          };
        }),
      },
    };
  },
});
