import defaultProjectThumbnailImages from './newAppThumbnails/projectDefaultThumbnailImages';

export const projectImages = [
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-1.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-2.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-3.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-4.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-5.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-6.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-7.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-8.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-9.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-10.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-11.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-12.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-13.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-14.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-15.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-16.png',
  'https://greenspace-files.s3-us-west-2.amazonaws.com/defaults/projects-17.png',
];

const dataURLtoFile = dataUrl => {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });

  const formData = new FormData();
  formData.append('image', blob);

  return formData;
};

const randomProjectImage = () => {
  try {
    const randomImage = Object.values(defaultProjectThumbnailImages)[
      Math.floor(Math.random() * 16)
    ];
    const image = dataURLtoFile(randomImage);

    return image;
  } catch (e) {
    return dataURLtoFile(defaultProjectThumbnailImages.p1);
  }
};

export default randomProjectImage;
