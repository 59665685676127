import actions from './actions';

const initState = {
  idToken: null,
  userId: null,
  loading: false,
  error: null,
  signUpData: null,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        loading: false,
        error: null,
      };

    case actions.LOGOUT_SUCCESS:
      return initState;

    case actions.LOGIN_REQUEST:
    case actions.SEND_RESET_PASSWORD_REQUEST:
    case actions.RESET_PASSWORD_REQUEST:
    case actions.SIGN_UP_REQUEST:
    case actions.VERIFY_ACCOUNT_REQUEST:
    case actions.CREATE_PASSWORD_AND_COMPANY_REQUEST:
    case actions.CREATE_ACCOUNT_REQUEST:
    case actions.RESEND_VERIFICATION_EMAIL_REQUEST:
    case actions.CHECK_AUTHORIZATION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actions.TOKEN_UNEXIST:
      return {
        ...state,
        loading: false,
      };

    case actions.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAccountCreated: true,
        error: null,
      };

    case actions.SEND_RESET_PASSWORD_SUCCESS:
    case actions.RESEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case actions.RESET_PASSWORD_SUCCESS:
    case actions.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case actions.LOGIN_ERROR:
    case actions.SEND_RESET_PASSWORD_FAILURE:
    case actions.RESET_PASSWORD_FAILURE:
    case actions.SIGN_UP_FAILURE:
    case actions.VERIFY_ACCOUNT_FAILURE:
    case actions.CREATE_PASSWORD_AND_COMPANY_FAILURE:
    case actions.CREATE_ACCOUNT_FAILURE:
    case actions.RESEND_VERIFICATION_EMAIL_FAILURE:
      if (action.payload.statusText === 'Verification code not found') {
        window.location.href = '/';
      }

      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actions.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        signUpData: action.payload,
      };

    case actions.CREATE_PASSWORD_AND_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        signUpData: null,
        idToken: action.payload,
      };

    default:
      return state;
  }
}
