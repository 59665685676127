import axios from 'axios';

import notifications from '@iso/components/Feedback/Notification';
import config from '@iso/config/site.config';
import auth from '../containers/Authentication/Auth0/Auth0';

const { apiUrl } = config;

// const loseAccessMsgErr = 'Some users will lose access to the project';
// const checkIsProjectAssigneeUrl = (url) => /^projects\/.*\/assign/.test(url);
export default class Api {
  constructor({
    baseUrl = apiUrl,
    extraHeaders = {},
    events,
    returnError = true,
  } = {}) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Content-Security-Policy': "frame-ancestors 'none'",
      ...extraHeaders,
    };

    const token = localStorage.getItem('id_token');

    if (token) headers.Authorization = `Bearer ${token}`;

    this.axios = axios.create({
      baseURL: `${baseUrl}/`,
      headers,
      ...(events && events),
    });

    if (returnError) {
      this.axios.interceptors.response.use(
        function(response) {
          return Promise.resolve(response);
        },
        function(error) {
          if (error.response) {
            if (error.response.status === 401) {
              notifications.info({
                message: 'Your session has ended. Please log in again.',
              });

              setTimeout(() => {
                auth.endSession();
              }, 2000);

              return;
            }
            // const isProjectAssingeeErr = checkIsProjectAssigneeUrl(error.response?.config?.url) && error.response.data.message.includes(loseAccessMsgErr);

            // if (error.response.status === 403 || isProjectAssingeeErr) {
            return Promise.reject(error);
            // }
          }
        }
      );
    }
  }

  query(method, url, data = {}) {
    if (method === 'get' && Object.keys(data).length) {
      return this.axios[method](url, { params: data });
    }
    return this.axios[method](url, data);
  }
}
