import { put, select, all, call, apply } from 'redux-saga/effects';
import moment from 'moment';
import _ from 'lodash';

import { notification } from '@iso/components';

import { meRole, userRoles } from '../../me/selectors';
import anamiService from '../../../services/anamiService';
import projectsActions from '../actions';
import { isSop } from '../selectors';
import { selectIsViewMobile } from '../../app/selectors';
import projectActions from '../actions/projectActions';
import sectionActions from '../actions/sectionActions';
import { selectSelectedProject } from './../selectors';

import randomProjectImage from '../../../../packages/isomorphic-cra/src/Assets';
import usersActions from '@iso/redux/users/actions';

import { getProjectService } from './helpers';
import companiesActions from '@iso/redux/companies/actions';
import { projectNameIsTakenServerErrMsg } from 'helpers/constants';

import analytics, { events } from 'services/analytics';

const projectSaga = {
  fetchData: function* fetchData({ data }) {
    try {
      let [projects, checklists, sops] = [[], [], []];
      const userRole = yield select(meRole);

      const response = yield anamiService.getProjectsList(data);
      projects = response.data.projects;

      // if (userRole === userRoles.superAdmin) {
      //   const response = yield anamiService.checklists.getProjectList();

      //   checklists = response.data.checklists;
      // }

      if (process.env.REACT_APP_SHOW_SOP === 'true') {
        const sopResponse = yield anamiService.sop.getProjectList();
        sops =
          userRole === userRoles.superAdmin
            ? sopResponse.data.sops
            : sopResponse.data.sops.filter(sop => !sop.global);
      }

      yield put(
        projectsActions.fetchDataSuccess([...projects, ...checklists, ...sops])
      );
    } catch (error) {
      yield put(projectsActions.fetchDataFailure(error));
    }
  },

  fetchProjectsList: function*() {
    try {
      const response = yield anamiService.getProjectsList();

      yield put(
        projectsActions.fetchProjectsListDataSuccess(response.data.projects)
      );
    } catch (error) {
      yield put(projectsActions.fetchProjectsListDataFailure(error));
    }
  },

  getProject: function* getProject({ id }) {
    try {
      if (id) {
        const response = yield anamiService.getProject(id);

        if (response.data?.project?.conectedTeams?.length) {
          const connectedTeams = response.data?.project?.conectedTeams;
          let teamUsers = [];

          for (let index = 0; index < connectedTeams.length; index++) {
            const teamUsersResponse = yield anamiService.fetchTeamUsers(
              connectedTeams[index].id
            );

            teamUsers = [
              ...teamUsers,
              ...(teamUsersResponse.data.users || []).map(user => ({
                ...user,
                teamMember: true,
              })),
            ];
          }

          response.data.project.connectedTeamUsers = _.uniqBy(teamUsers, 'id');
        }

        yield put(
          projectsActions.getProjectSuccess(
            response.data.project ||
              response.data.checklist ||
              response.data.sop
          )
        );
      } else {
        yield put(projectsActions.getProjectSuccess({}));
      }
    } catch (error) {
      yield put(projectsActions.getProjectFailure(error.response));
    }
  },

  getProjectParts: function* getProjectParts({ id }) {
    yield all([
      put(projectsActions.getProjectSectionRequest(id)),
      put(projectsActions.getProjectDetailsRequest(id)),
      put(projectsActions.getProjectUsersAndTeamsRequest(id)),
    ]);
  },

  getProjectDetails: function* getProjectDetails({ id }) {
    try {
      const response = yield anamiService.getProjectDetails(id);
      const { section, ...project } =
        response.data.project || response.data.checklist || response.data.sop;

      yield put(projectsActions.getProjectDetailsSuccess(project));
    } catch (error) {
      yield put(projectsActions.getProjectDetailsFailure(error.response));
    }
  },

  getProjectSection: function* getProjectSection({ id }) {
    try {
      const response = yield anamiService.getProjectSection(id);

      yield put(
        projectsActions.getProjectSectionSuccess({
          section: response.data.section,
        })
      );
    } catch (error) {
      yield put(projectsActions.getProjectSectionFailure(error.response));
    }
  },

  getProjectFiles: function* getProjectFiles({ id }) {
    try {
      const response = yield anamiService.getProjectFiles(id);

      yield put(
        projectsActions.getProjectFilesSuccess({
          projectFiles: response.data.projectFiles,
        })
      );
    } catch (error) {
      yield put(projectsActions.getProjectFilesFailure(error.response));
    }
  },

  getProjectUsersAndTeams: function* getProjectUsersAndTeams({ id }) {
    try {
      const response = yield anamiService.getProjectConnectedUsersTeams(id);

      const { connectedUsers, connectedTeams } = response.data;

      yield put(
        projectsActions.getProjectUsersAndTeamsSuccess({
          connectedTeams,
          users: connectedUsers.map(user => ({ id: user.userId, ...user })),
        })
      );
    } catch (error) {
      yield put(projectsActions.getProjectUsersAndTeamsFailure(error.response));
    }
  },

  getAuditProject: function* getAuditProject({ id }) {
    try {
      if (id) {
        const response = yield anamiService.getProjectSection(id);

        yield put(
          projectsActions.getAuditProjectSuccess({
            section: response.data.section,
          })
        );
      } else {
        yield put(projectsActions.getAuditProjectSuccess(null));
      }
    } catch (error) {
      yield put(projectsActions.getAuditProjectFailure(error));
    }
  },

  getAuditProjectList: function*({ data }) {
    try {
      const response = yield anamiService.getProjectsList(data);

      yield put(
        projectsActions.getAuditProjectListSuccess(response.data.projects)
      );
    } catch (error) {
      yield put(projectsActions.getAuditProjectListFailure(error));
    }
  },

  createProject: function* createProject({ data }) {
    try {
      const { file, fileUuid, privacy = 'private', ...params } = data;

      const response = yield anamiService.createProject(params);

      if (file) {
        yield put(
          projectsActions.createProjectAvatar({
            projectId: response.data.project.id,
            file,
            privacy,
          })
        );
      }

      if (fileUuid) {
        yield put(
          projectsActions.createProjectAvatar({
            projectId: response.data.project.id,
            fileUuid,
            privacy,
          })
        );
      }

      if (!file && !fileUuid) {
        yield put(
          projectActions.createProjectImageRequest({
            projectId: response.data.project.id,
            image: randomProjectImage(),
          })
        );
      }

      yield put(projectsActions.createProjectSuccess(response.data.project));
    } catch (error) {
      yield handleProjectCreatingErrors(error.response);
    }
  },

  createProjectUseTemplate: function*({ payload }) {
    try {
      const { privacy = 'privacy' } = payload.data;

      const response = yield anamiService.createProjectUseTemplate(
        payload.id,
        payload.data
      );

      const { file, fileUuid, icon = null } = payload.data;

      if (file) {
        yield put(
          projectsActions.createProjectAvatar({
            projectId: response.data.project.id || response.data.sop.id,
            file,
            privacy,
          })
        );
      }

      if (fileUuid) {
        yield put(
          projectsActions.createProjectAvatar({
            projectId: response.data.project.id || response.data.sop.id,
            fileUuid,
            privacy,
          })
        );
      }

      if (!file && !fileUuid && !icon) {
        yield put(
          projectActions.createProjectImageRequest({
            projectId: response.data.project.id || response.data.sop.id,
            image: randomProjectImage(),
          })
        );
      }

      yield put(
        projectsActions.createProjectSuccess(
          response.data.project || response.data.sop
        )
      );
    } catch (error) {
      yield handleProjectCreatingErrors(error.response);
    }
  },

  updateProject: function* updateProject({ data }) {
    try {
      const service = yield getProjectService(data.projectId || data.id);
      const { data: pesponseData } = yield service.updateProject(data);

      if (_.has(data, 'isPrivate')) {
        notification('success', `Project type was successfully updated!`);
      }

      yield put(
        projectsActions.updateProjectSuccess(
          pesponseData.project ||
            pesponseData.checklist ||
            pesponseData.sop ||
            pesponseData.template
        )
      );
    } catch (error) {
      yield put(projectsActions.updateProjectFailure(error));
    }
  },

  removeProject: function* removeProject({ id }) {
    try {
      yield anamiService.removeProject(id);

      yield apply(analytics, analytics.trackEvent, [
        events.DELETE_PROJECT,
        {
          projectId: id,
        },
      ]);

      yield put(projectsActions.removeProjectSuccess(id));
    } catch (error) {
      yield put(projectsActions.removeProjectFailure(error));
    }
  },

  publishProject: function* publishProject({ data }) {
    try {
      const service = yield getProjectService(data.projectId);
      const response = yield service.publishProject(data);

      yield put(
        projectsActions.publishProjectSuccess(
          response.data.project || response.data.checklist || response.data.sop
        )
      );
    } catch (error) {
      yield put(projectsActions.publishProjectFailure(error));
    }
  },

  convertToSOP: function* convertToSOP({ id }) {
    try {
      const response = yield anamiService.convertToSOP(id);
      yield put(projectsActions.convertToSOPSuccess(response.data.project));
    } catch (error) {
      yield put(projectsActions.convertToSOPFailure(error));
    }
  },

  getProjectUsers: function* getProjectUsers({ id }) {
    try {
      const response = yield anamiService.getProjectUsers(id);

      yield put(projectsActions.getProjectUsersSuccess(response.data.users));
    } catch (error) {
      yield put(projectsActions.getProjectUsersFailure(error));
    }
  },

  getConnectedUsers: function* getConnectedUsers({ id }) {
    try {
      const response = yield anamiService.getConnectedUsers(id);

      yield put(
        projectsActions.getConnectedUsersSuccess(response.data.members)
      );
    } catch (error) {
      yield put(projectsActions.getConnectedUsersFailure(error));
    }
  },

  connectUser: function* connectUser({ data }) {
    const { user, ...params } = data;
    try {
      yield anamiService.connectUserToProject(params);

      if (data?.forUser) {
        yield put(usersActions.getUserTeamsRequest());
      }

      yield put(
        projectsActions.connectUserSuccess({
          ...user,
          projectId: params.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.connectUserFailure(error));
    }
  },

  disconnectUser: function* disconnectUser({ data }) {
    try {
      yield anamiService.disconnectUserFromProject(data);

      if (data?.forUser) {
        yield put(usersActions.getUserTeamsRequest());
      }

      yield put(
        projectsActions.disconnectUserSuccess({
          userId: data.userId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.disconnectUserFailure(error));
    }
  },

  getProjectLabels: function* getProjectLabels({ id }) {
    try {
      const response = yield anamiService.getProjectLabels(id);

      yield put(projectsActions.getProjectLabelsSuccess(response.data.labels));
    } catch (error) {
      yield put(projectsActions.getProjectLabelsFailure(error));
    }
  },

  createProjectLabel: function* createProjectLabel({ data }) {
    try {
      const response = yield anamiService.createProjectLabel(data);

      yield put(projectsActions.createProjectLabelSuccess(response.data.label));
    } catch (error) {
      yield put(projectsActions.createProjectLabelFailure(error));
    }
  },

  updateProjectLabel: function* updateProjectLabel({ data }) {
    try {
      const response = yield anamiService.updateProjectLabel(data);

      yield put(projectsActions.updateProjectLabelSuccess(response.data.label));
    } catch (error) {
      yield put(projectsActions.updateProjectLabelFailure(error));
    }
  },

  removeProjectLabel: function* removeProjectLabel({ data }) {
    try {
      const { label, sectionId, ...params } = data;

      const service = yield getProjectService(data.projectId);
      yield service.removeProjectLabel({ labelId: label.id, ...params });

      yield put(
        projectsActions.removeProjectLabelSuccess({
          labelId: data.label.id,
          sectionId: data.sectionId,
          taskId: data.taskId,
        })
      );
    } catch (error) {
      yield put(projectsActions.removeProjectLabelFailure(error));
    }
  },

  copyProject: function* copyProject({ id }) {
    try {
      const response = yield anamiService.copyProject(id);

      yield put(projectsActions.copyProjectSuccess(response.data.project));
    } catch (error) {
      yield put(projectsActions.copyProjectFailure(error));
    }
  },

  changeBusiness: function* changeBusiness({ data }) {
    try {
      const sop = yield select(state => isSop(state, data.projectId));

      const response = yield sop
        ? anamiService.sop.changeBusinessSop(data)
        : anamiService.changeBusiness(data);

      yield put(
        projectsActions.changeBusinessSuccess(
          response.data.project || response.data.sop
        )
      );
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.message ===
          'Some users will lose access to the project'
      ) {
        yield put(projectsActions.showChangingBusinessPrompt(true));
      } else {
        yield put(projectsActions.changeBusinessFailure(error));
      }
    }
  },

  removeAssignedProject: function*({ data }) {
    try {
      const { project, ...rest } = data;

      yield anamiService.changeBusiness({
        projectId: project.id,
        ...rest,
      });

      yield put(projectsActions.removeAssignedProjectSuccess(project));
    } catch (error) {
      yield put(projectsActions.removeAssignedProjectFailure(error));
    }
  },

  getUpcomingTasks: function* getUpcomingTasks({ data }) {
    try {
      const response = yield anamiService.getUpcomingTasks(data);

      yield put(projectsActions.getUpcomingTasksSuccess(response.data));
    } catch (error) {
      yield put(projectsActions.getUpcomingTasksFailure(error));
    }
  },

  getExpiredTasks: function* getExpiredTasks({ data }) {
    try {
      const response = yield anamiService.getExpiredTasks(data);

      yield put(projectsActions.getExpiredTasksSuccess(response.data));
    } catch (error) {
      yield put(projectsActions.getExpiredTasksFailure(error));
    }
  },

  getNotificationTasks: function* getNotificationTasks({ id }) {
    try {
      const response = yield anamiService.getNotificationTasks(id);

      yield put(
        projectsActions.getNotificationTasksSuccess(response.data.projects)
      );
    } catch (error) {
      yield put(projectsActions.getNotificationTasksFailure(error));
    }
  },

  getAssignedTasks: function*({ data }) {
    try {
      const response = yield anamiService.getAssignedTasks(data);

      yield put(projectsActions.getAssignedTasksSuccess(response.data));
    } catch (error) {
      yield put(projectsActions.getAssignedTasksFailure(error));
    }
  },

  getDashboardAssignedUsers: function*() {
    try {
      const response = yield anamiService.getAssignedUsers();

      yield put(
        projectsActions.getDashboardAssignedUsersSuccess(
          response.data.assignedUsers
        )
      );
    } catch (error) {
      yield put(projectsActions.getDashboardAssignedUsersFailure(error));
    }
  },

  getUpcomingProjectTask: function* getUpcomingProjectTask({ id }) {
    try {
      const response = yield anamiService.getUpcomingProjectTask(id);

      yield put(
        projectsActions.getUpcomingTasksSuccess(response.data.projects)
      );
    } catch (error) {
      yield put(projectsActions.getUpcomingTasksFailure(error));
    }
  },

  getExpiredProjectTasks: function* getExpiredProjectTasks({ id }) {
    try {
      const response = yield anamiService.getExpiredProjectTask(id);

      yield put(projectsActions.getExpiredTasksSuccess(response.data.projects));
    } catch (error) {
      yield put(projectsActions.getExpiredTasksFailure(error));
    }
  },

  getNotificationProjectTasks: function* getNotificationProjectTasks({ id }) {
    try {
      const response = yield anamiService.getNotificationProjectTasks(id);

      yield put(
        projectsActions.getNotificationTasksSuccess(response.data.projects)
      );
    } catch (error) {
      yield put(projectsActions.getNotificationTasksFailure(error));
    }
  },

  getAssignedTasksTracker: function*() {
    try {
      const response = yield anamiService.getAssignedTasksTracker();
      yield put(
        projectsActions.getAssignedTasksTrackerSuccess(response.data.tasks)
      );
    } catch (error) {
      yield put(projectsActions.getAssignedTasksTrackerFailure(error));
    }
  },

  getUpcomingTasksTracker: function*() {
    try {
      const response = yield anamiService.getUpcomingTasksTracker();
      yield put(
        projectsActions.getUpcomingTasksTrackerSuccess(response.data.tasks)
      );
    } catch (error) {
      yield put(projectsActions.getUpcomingTasksTrackerFailure(error));
    }
  },

  getExpiredTasksTracker: function*() {
    try {
      const response = yield anamiService.getExpiredTasksTracker();

      yield put(
        projectsActions.getExpiredTasksTrackerSuccess(response.data.tasks)
      );
    } catch (error) {
      yield put(projectsActions.getExpiredTasksTrackerFailure(error));
    }
  },

  hideColumns: function*({ payload }) {
    try {
      yield anamiService.hideColumns(payload);
    } catch (error) {
      yield put(projectActions.hideColumnsFailure(error));
    }
  },

  hideSection: function*(payload) {
    try {
      yield anamiService.hideSections(payload);
    } catch (error) {
      yield sectionActions.hideSectionsFailure(error);
    }
  },

  fetchProjectsHomeView: function*({ data }) {
    try {
      let { savedFilter, ...rest } = data;
      // remove types = 'personal' for plain projects
      if (rest?.types && rest.types === 'personal') {
        delete rest.types;
      }

      const response = yield anamiService.getHomeViewProjects(rest);

      const responseData = {
        items: yield getItemsWithAvatarUrl(response.data.projects),
        totalNumber: response.data.totalNumber,
      };

      yield put(projectsActions.getProjectsHomeViewSuccess(responseData));
    } catch (error) {
      yield put(projectsActions.getProjectsHomeViewFailure(error));
    }
  },

  fetchChecklistsHomeView: function*({ data }) {
    try {
      const { savedFilter, ...rest } = data;

      const response = yield anamiService.getHomeViewTemplates(rest);

      const responseData = {
        items: yield getItemsWithAvatarUrl(response.data.templates),
        totalNumber: response.data.totalNumber,
      };

      // yield put(projectActions.updateFilterSettings(data));

      yield put(projectsActions.getChecklistsHomeViewSuccess(responseData));
    } catch (error) {
      yield put(projectsActions.getChecklistsHomeViewFailure(error));
    }
  },

  fetchTemplateList: function*({ data }) {
    try {
      const response = yield anamiService.getHomeViewTemplates(data);

      const responseData = {
        items: yield getItemsWithAvatarUrl(response.data.templates),
        totalNumber: response.data.totalNumber,
      };

      yield put(projectsActions.fetchTemplateListSuccess(responseData));
    } catch (error) {
      yield put(projectsActions.fetchTemplateListFailure(error));
    }
  },

  fetchArchivedProjectsHomeView: function*({ data }) {
    try {
      const { savedFilter, ...rest } = data;

      const response = yield anamiService.getHomeViewArchivedProjects(rest);

      const responseData = {
        items: yield getItemsWithAvatarUrl(response.data.projects),
        totalNumber: response.data.totalNumber,
      };

      // yield put(projectActions.updateFilterSettings(data));

      yield put(
        projectsActions.getArchivedProjectsHomeViewSuccess(responseData)
      );
    } catch (error) {
      yield put(projectsActions.getArchivedProjectsHomeViewFailure(error));
    }
  },

  updateProjectStarred: function*({ data }) {
    try {
      const { projectId, ...rest } = data;
      yield anamiService.updateProjectStarred({ projectId, ...rest });

      yield put(projectsActions.updateProjectStarredSuccess(projectId));
    } catch (error) {
      yield put(projectsActions.updateProjectStarredFailure(error));
    }
  },

  createProjectImage: function*({ data }) {
    try {
      const { projectId } = data;

      const response = yield anamiService.createProjectImage(data);

      yield put(
        projectsActions.createProjectImageSuccess({
          projectId,
          imagePath: response.data.icon,
          updatedAt: moment().format(),
        })
      );
    } catch (error) {
      yield put(projectsActions.createProjectImageFailure(error));
    }
  },

  deleteProjectImage: function*({ data }) {
    try {
      const response = yield anamiService.deleteProjectImage(data);

      yield put(
        projectsActions.deleteProjectImageSuccess(response.data.project)
      );
    } catch (error) {
      yield put(projectsActions.deleteProjectImageFailure(error));
    }
  },

  updateProjectPermission: function*({ data }) {
    try {
      const response = yield anamiService.updateProject(data);

      yield put(
        projectsActions.updateProjectPermissionSuccess(response.data.project)
      );
    } catch (error) {
      yield put(projectsActions.updateProjectPermissionFailure(error));
    }
  },

  acrhiveProject: function*({ data }) {
    try {
      const { projectId } = data;

      yield anamiService.archiveProject(data);

      yield apply(analytics, analytics.trackEvent, [
        events.ARCHIVE_PROJECT,
        {
          projectId,
        },
      ]);

      yield put(projectsActions.archiveProjectSuccess({ projectId }));
    } catch (error) {
      yield put(projectsActions.archiveProjectFailure(error));
    }
  },

  unzipProject: function*({ data }) {
    try {
      const { projectId } = data;

      yield anamiService.unzipProject(data);

      yield put(projectsActions.unzipProjectSuccess({ projectId }));
    } catch (error) {
      yield put(projectsActions.unzipProjectFailure(error));
    }
  },

  removeHomeViewProject: function*({ id }) {
    try {
      const service = yield getProjectService(id);
      yield service.removeProject(id);

      yield apply(analytics, analytics.trackEvent, [
        events.DELETE_PROJECT,
        {
          projectId: id,
        },
      ]);

      yield put(projectsActions.removeHomeViewProjectSuccess(id));
    } catch (error) {
      yield put(projectsActions.removeHomeViewProjectFailure(error));
    }
  },

  fetchHomeViewActiveUsersLocations: function*() {
    try {
      const response = yield anamiService.getHomeViewActiveUsersLocations();

      yield put(
        projectsActions.getHomeViewActiveUsersLocationsSuccess(response.data)
      );
    } catch (error) {
      yield put(projectsActions.getHomeViewActiveUsersLocationsFailure(error));
    }
  },

  updateProjectAdminType: function*({ data }) {
    try {
      const response = yield anamiService.updateProjectAdminType(data);

      yield put(
        projectsActions.updateProjectAdminTypeSuccess(response.data.project)
      );
    } catch (error) {
      yield put(projectsActions.updateProjectAdminTypeFailure(error));
    }
  },

  fetchProjectsListByName: function*({ data }) {
    try {
      const response = yield anamiService.getProjectsListByName(data);

      // yield put(projectActions.updateFilterSettings(data));

      yield put(
        projectsActions.getProjectsListByNameSuccess(response.data.projects)
      );
    } catch (error) {
      yield put(projectsActions.getProjectsListByNameFailure(error));
    }
  },

  fetchTemplatesByName: function*({ data }) {
    try {
      const userTemplateTypes = yield select(
        state => state.projects.filtersData?.userTemplateTypes
      );

      const response = yield anamiService.getProjectsListByName({
        q: data,
        types: userTemplateTypes.join(','),
      });

      yield put(
        projectsActions.fetchTemplatesByNameSuccess({
          items: response.data.projects,
        })
      );
    } catch (error) {
      yield put(projectsActions.fetchTemplatesByNameFailure(error));
    }
  },

  connectUserProjectsHome: function*({ data }) {
    const { user, ...params } = data;
    try {
      yield anamiService.connectUserToProject(params);

      yield put(
        projectsActions.connectUserProjectsHomeSuccess({
          ...user,
          projectId: params.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.connectUserProjectsHomeFailure(error));
    }
  },

  disconnectUserProjectsHome: function*({ data }) {
    try {
      yield anamiService.disconnectUserFromProject(data);

      yield put(
        projectsActions.disconnectUserProjectsHomeSuccess({
          userId: data.userId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.disconnectUserProjectsHomeFailure(error));
    }
  },

  fetchBusinessProjects: function*({ data }) {
    try {
      const response = yield anamiService.getProjectList(data);

      yield put(
        projectsActions.fetchBusinessProjectsSuccess({
          projects: yield getItemsWithAvatarUrl(response.data.projects, true),
          totalNumber: response.data.totalNumber,
        })
      );
    } catch (error) {
      yield put(projectsActions.fetchBusinessProjectsFailure(error));
    }
  },

  assignNewUserToTask: function*({ data }) {
    try {
      const { sectionId, taskId } = data;

      const response = yield anamiService.assignNewUser(data);

      yield put(
        projectsActions.assignNewUserToTaskSuccess({
          user: response.data.user,
          sectionId,
          taskId,
        })
      );
    } catch (error) {
      yield put(projectsActions.assignNewUserToTaskFailure(error));
    }
  },

  fetchTaskFile: function*({ data }) {
    try {
      const response = yield anamiService.fetchTaskFile(data);

      let url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers['content-type'],
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      yield put(projectActions.fetchTaskFileSuccess());
    } catch (error) {
      yield put(projectActions.fetchTaskFileFailure(error));
    }
  },

  fetchProjectTags: function*({ data }) {
    const selectedProject = yield select(selectSelectedProject);
    try {
      const response = yield anamiService.getProjectTags(data);

      yield put(
        projectActions.fetchProjectTagsSuccess([
          ...selectedProject.tags.filter(
            tag => !response.data.tags.includes(tag)
          ),
          ...response.data.tags,
        ])
      );
    } catch (error) {
      yield put(projectActions.fetchProjectTagsFailure(error));
    }
  },

  createProjectTag: function*({ data }) {
    try {
      const response = yield anamiService.createProjectTag(data);

      yield put(
        projectActions.createProjectTagSuccess({
          project: response.data.project,
          name: data.tag,
        })
      );
    } catch (error) {
      yield put(projectActions.createProjectTagFailure(error));
    }
  },

  updateProjectTag: function*({ data }) {
    try {
      const response = yield anamiService.updateProjectTag(data);

      yield put(
        projectActions.updateProjectTagSuccess({
          project: response.data.project,
          idx: data.idx,
          name: data.tag,
        })
      );
    } catch (error) {
      yield put(projectActions.updateProjectTagFailure(error));
    }
  },

  deleteProjectTag: function*({ data }) {
    try {
      const response = yield anamiService.deleteProjectTag(data);
      yield put(projectActions.deleteProjectTagSuccess(response.data.project));
    } catch (error) {
      yield put(projectActions.deleteProjectTagFailure(error));
    }
  },

  // data: { tag: string, projectId: string }
  deleteProjectListTag: function*({ data }) {
    try {
      // delete tag from list
      yield anamiService.deleteProjectListTag(data.tag);
      // refetch project data
      const projectSummary = yield anamiService.getProject(data.projectId);
      const tagsList = yield anamiService.getProjectTags(data);
      // update state
      yield put(
        projectActions.deleteProjectTagSuccess(projectSummary.data.project)
      );
      yield put(
        projectActions.deleteProgectTagFromListSuccess([
          ...projectSummary.data.project.tags.filter(
            tag => !tagsList.data.tags.includes(tag)
          ),
          ...tagsList.data.tags,
        ])
      );
    } catch (error) {
      yield put(projectActions.deleteProjectTagFailure(error));
    }
  },

  selectProjectTag: function*({ data }) {
    try {
      const response = yield anamiService.createProjectTag(data);

      yield put(projectActions.selectProjectTagSuccess(response.data.project));
    } catch (error) {
      yield put(projectActions.selectProjectTagFailure(error));
    }
  },

  updateAndPublishProject: function*({ data }) {
    try {
      const { projectId, tags, description, image, publishMode } = data;
      const service = yield getProjectService(projectId);

      yield all({
        ...(tags &&
          tags.length && {
            tagsRes: call(updateTagsSaga, tags, projectId),
          }),
        ...(description && {
          projectRes: call([service, 'updateProject'], {
            projectId,
            description,
          }),
        }),
        ...(image && {
          imageRes: call([service, 'createProjectImage'], {
            projectId,
            image,
          }),
        }),
      });

      const types = {
        sop: { sop: true, typePublication: 'guides' },
        'self-audit': {
          checklist: true,
          typePublication: 'self_audits',
        },
        general: { typePublication: 'general' },
        greenspace: { typePublication: 'greenspace' },
      };

      const publishRes = yield service.publishProject({
        projectId,
        ...types[publishMode],
      });

      yield put(projectActions.updateProjectDataSuccess());

      yield put(projectsActions.publishProjectSuccess(publishRes.data.project));
    } catch (error) {
      yield put(projectActions.updateProjectDataFailure(error));
    }
  },

  fetchSelectedProject: function*({ id }) {
    try {
      if (id) {
        const response = yield anamiService.getProject(id);
        yield put(
          projectsActions.fetchSelectedProjectSuccess(response.data.project)
        );
      } else {
        yield put(projectsActions.fetchSelectedProjectSuccess({}));
      }
    } catch (error) {
      yield put(projectsActions.fetchSelectedProjectFailure(error));
    }
  },

  createProjectAvatar: function*({ data }) {
    try {
      const { projectId, ...rest } = data;

      const response = yield anamiService.createProjectAvatar({
        projectId,
        ...rest,
      });

      const { avatarId } = response.data;

      const responseUrl = yield anamiService.getResourceUrl({
        id: avatarId,
      });

      yield put(
        projectsActions.createProjectAvatarSuccess({
          avatarUrl: responseUrl.data.url,
          project: response.data,
        })
      );
    } catch (error) {
      yield put(projectsActions.createProjectAvatarFailure(error));
    }
  },

  fetchProjectAvatarUrl: function*({ data }) {
    try {
      const { projectId, avatarId } = data;

      const response = yield anamiService.getResourceUrl({
        id: avatarId,
      });

      yield put(
        projectsActions.fetchProjectAvatarUrlSuccess({
          avatarUrl: response.data.url,
          projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.fetchProjectAvatarUrlFailure(error));
    }
  },

  removeProjectAvatar: function*({ data }) {
    try {
      yield anamiService.removeProjectAvatar(data);
      yield anamiService.deleteProjectImage(data);

      yield put(projectsActions.removeProjectAvatarSuccess(data));
    } catch (error) {
      yield put(projectsActions.removeProjectAvatarFailure(error));
    }
  },

  changeTemplateType: function*({ data }) {
    try {
      const response = yield anamiService.template.changeTemplateType(data);

      yield put(
        projectsActions.changeTemplateTypeSuccess(response.data.template)
      );
    } catch (error) {
      yield put(projectsActions.changeTemplateTypeFailure(error));
    }
  },

  assignTeam: function*({ data }) {
    try {
      const { teamId } = data;

      const response = yield anamiService.setProject(data);
      const teamUsersResponse = yield anamiService.fetchTeamUsers(teamId);

      yield put(
        projectsActions.assignTeamSuccess({
          team: response.data.team,
          teamUsers: teamUsersResponse.data.users,
        })
      );
    } catch (error) {
      yield put(projectsActions.assignTeamFailure(error));
    }
  },

  dismissTeam: function*({ data }) {
    try {
      const { teamId } = data;

      const response = yield anamiService.unsetProject(data);
      const teamUsersResponse = yield anamiService.fetchTeamUsers(teamId);

      yield put(
        projectsActions.dismissTeamSuccess({
          team: response.data.team,
          dismissedTeamUsers: teamUsersResponse.data.users,
        })
      );
    } catch (error) {
      yield put(projectsActions.dismissTeamFailure(error));
    }
  },

  getSimpleProjectsData: function*() {
    try {
      const response = yield anamiService.getSimpleProjectsData();
      yield put(projectsActions.getSimpleProjectsDataSuccess(response.data));
    } catch (error) {
      yield put(projectsActions.getSimpleProjectsDataFailure(error));
    }
  },

  fetchProjectSubtasksSaga: function*({ data }) {
    try {
      const response = yield anamiService.fetchProjectAllSubtasks(data);

      yield put(
        projectsActions.fetchProjectSubtasksSuccess({
          subtasks: response.data.subtasks,
          ...data,
        })
      );
    } catch (error) {
      yield put(projectsActions.fetchProjectSubtasksFailure(error));
    }
  },

  checkExistenceOfProject: function*({ payload }) {
    try {
      const response = yield anamiService.checkExistenceOfProject(payload);

      if (response.data?.companyId) {
        yield put(
          companiesActions.selectCompany({ id: response.data?.companyId })
        );
        notification('success', 'The active company has been changed');
      }

      yield put(projectsActions.checkExistenceOfProjectSuccess(response.data));
    } catch (error) {
      notification(
        'error',
        `Project not found, or you do not have access to the requested project`
      );
      yield put(projectsActions.checkExistenceOfProjectFailure(error));
    }
  },

  //GET ONLY ACTIVE TAGS
  getProjectsTags: function*() {
    try {
      const response = yield anamiService.getProjectsActiveTags();
      yield put(projectsActions.getProjectsTagsSuccess(response.data.tags));
    } catch (error) {
      yield put(projectsActions.getProjectsTagsFailure());
    }
  },

  getArchivedProjectsTags: function*() {
    try {
      const response = yield anamiService.getArchivedProjectsActiveTags();
      yield put(
        projectsActions.getArchivedProjectsTagsSuccess(response.data.tags)
      );
    } catch (error) {
      yield put(projectsActions.getArchivedProjectsTagsFailure());
    }
  },

  getTemplatesTags: function*() {
    try {
      const response = yield anamiService.getTemplatesActiveTags();

      yield put(projectsActions.getTemplatesTagsSuccess(response.data.tags));
    } catch (error) {
      yield put(projectsActions.getTemplatesTagsFailure());
    }
  },
  //GET ONLY ACTIVE TAGS
};

export default projectSaga;

function* handleProjectCreatingErrors(errResponse) {
  let isTitleExist = false;

  if (errResponse?.data?.message) {
    isTitleExist = errResponse?.data?.message.includes(
      projectNameIsTakenServerErrMsg
    );
  }

  if (isTitleExist) {
    yield put(projectsActions.projectNameIsTaken());
  } else {
    yield put(projectsActions.createProjectFailure(errResponse));
  }
}

function* updateTagsSaga(tags, projectId) {
  for (let i = 0; i < tags.length; i++) {
    yield call([anamiService, 'createProjectTag'], {
      ...tags[i],
      projectId,
    });
  }
}

function* getItemsWithAvatarUrl(data, ignoreMobile = false) {
  const isMobileView = yield select(selectIsViewMobile);

  if (isMobileView && !ignoreMobile) {
    return data;
  } else {
    const items = [];

    for (let index = 0; index < data.length; index++) {
      if (data[index].avatarId) {
        const responseUrl = yield call([anamiService, 'getResourceUrl'], {
          id: data[index].avatarId,
        });

        items.push({
          ...data[index],
          avatarUrl: responseUrl ? responseUrl.data.url : null,
        });
      } else {
        items.push(data[index]);
      }
    }

    return items;
  }
}
