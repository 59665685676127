import { all, takeEvery, put } from 'redux-saga/effects';
import _ from 'lodash';

import dashboardActions from './actions';
import anamiService from '../../services/anamiService';

function* fetchData() {
  try {
    const response = yield anamiService.getDashboard();

    yield put(dashboardActions.fetchDataSuccess(response.data));
  } catch (error) {
    yield put(dashboardActions.fetchDataFailure(error));
  }
}

function* getProjectSummary({ id }) {
  try {
    const response = yield anamiService.getProjectSummary(id);

    yield put(dashboardActions.fetchDataSuccess(response.data));
  } catch (error) {
    yield put(dashboardActions.fetchDataFailure(error));
  }
}

function* getProjectList() {
  try {
    const response = yield anamiService.getProjectsList();

    yield put(dashboardActions.getProjectListSuccess(response.data));
  } catch (error) {
    yield put(dashboardActions.getProjectListFailure(error));
  }
}

function* fetchFavorites({ data }) {
  try {
    const { showMore, ...rest } = data;
    let favorites = [];

    const response = yield anamiService.fetchFavoritesAndRecents(rest);
    const { items, totalNumber } = response.data;

    for (let index = 0; index < items.length; index++) {
      const { type, avatar, avatarId, id } = items[index];

      if (type === 'business' && avatar && avatarId && !avatar.includes(id)) {
        const urlResponse = yield anamiService.getResourceUrl({ id: avatarId });

        favorites.push({
          ...items[index],
          avatar: urlResponse.data.url,
        });
      } else {
        favorites.push(items[index]);
      }
    }

    yield put(
      dashboardActions.fetchFavoritesSuccess({
        data: favorites,
        showMore: _.has(data, 'showMore'),
        totalNumber,
      })
    );
  } catch (error) {
    yield put(dashboardActions.fetchFavoritesFailure(error));
  }
}

function* fetchRecent({ data }) {
  try {
    const { showMore, ...rest } = data;
    let recents = [];

    const response = yield anamiService.fetchFavoritesAndRecents(rest);
    const { items, totalNumber } = response.data;

    for (let index = 0; index < items.length; index++) {
      const { type, avatar, avatarId, id } = items[index];

      if (type === 'business' && avatar && avatarId && !avatar.includes(id)) {
        const urlResponse = yield anamiService.getResourceUrl({ id: avatarId });

        recents.push({
          ...items[index],
          avatar: urlResponse.data.url,
        });
      } else {
        recents.push(items[index]);
      }
    }

    yield put(
      dashboardActions.fetchRecentSuccess({
        data: recents,
        showMore: _.has(data, 'showMore'),
        totalNumber,
      })
    );
  } catch (error) {
    yield put(dashboardActions.fetchRecentFailure(error));
  }
}

function* fetchTodos({ data }) {
  try {
    const { showMore, ...rest } = data;

    const response = yield anamiService.fetchTodos(rest);

    yield put(
      dashboardActions.fetchTodosSuccess({
        data: response.data.items,
        showMore: _.has(data, 'showMore'),
        totalNumber: response.data.totalNumber,
      })
    );
  } catch (error) {
    yield put(dashboardActions.fetchTodosFailure(error));
  }
}

function* completeTodo({ data }) {
  try {
    const response = yield anamiService.completeTodo(data);

    yield put(dashboardActions.completeTodoSuccess(response.data.todo));
  } catch (error) {
    yield put(dashboardActions.completeTodoFailure(error));
  }
}

function* restoreTodoSaga({ data }) {
  try {
    const response = yield anamiService.restoreTodo(data);

    yield put(dashboardActions.restoreTodoSuccess(response.data.todo));
  } catch (error) {
    yield put(dashboardActions.restoreTodoFailure(error));
  }
}

export default function* dashboardSaga() {
  yield all([takeEvery(dashboardActions.FETCH_DATA, fetchData)]);
  yield all([
    takeEvery(dashboardActions.GET_PROJECT_SUMMARY, getProjectSummary),
  ]);
  yield all([
    takeEvery(dashboardActions.GET_PROJECT_LIST_REQUEST, getProjectList),
  ]);
  yield takeEvery(dashboardActions.FETCH_FAVORITES_REQUEST, fetchFavorites);
  yield takeEvery(dashboardActions.FETCH_RECENT_REQUEST, fetchRecent);
  yield takeEvery(dashboardActions.FETCH_TODOS_REQUEST, fetchTodos);
  yield takeEvery(dashboardActions.COMPLETE_TODO_REQUEST, completeTodo);
  yield takeEvery(dashboardActions.RESTORE_TODO_REQUEST, restoreTodoSaga);
}
