import { put } from 'redux-saga/effects';

import anamiService from '../../../services/anamiService';
import projectsActions from '../actions';

export default {
  getChecklists: function* getRecentViews() {
    try {
      const response = yield anamiService.checklists.getProjectList();

      yield put(projectsActions.getChecklistsSuccess(response.data.checklists));
    } catch (error) {
      yield put(projectsActions.getChecklistsFailure(error));
    }
  },

  createChecklist: function* createChecklist({ data }) {
    try {
      const { icon, ...params } = data;

      const response = yield anamiService.checklists.createProject(params);

      yield put(projectsActions.createProjectSuccess(response.data.checklist));

      if (icon) {
        yield put(
          projectsActions.uploadChecklistIconRequest({
            projectId: response.data.checklist.id,
            icon,
          })
        );
      }
    } catch (error) {
      yield put(projectsActions.createProjectFailure(error));
    }
  },

  uploadChecklistIcon: function* uploadChecklistIcon({ data }) {
    try {
      const response = yield anamiService.checklists.uploadChecklistIcon(data);

      yield put(
        projectsActions.uploadChecklistIconSuccess({
          icon: response.data.icon,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.uploadChecklistIconFailure(error));
    }
  },

  removeChecklistIcon: function* uploadChecklistIcon({ id }) {
    try {
      yield anamiService.checklists.removeChecklistIcon(id);

      yield put(projectsActions.removeChecklistIconSuccess(id));
    } catch (error) {
      yield put(projectsActions.removeChecklistIconFailure(error));
    }
  },

  getRecentViews: function* getRecentViews() {
    try {
      const response = yield anamiService.getRecentViews();

      yield put(projectsActions.getRecentViewsSuccess(response.data));
    } catch (error) {
      yield put(projectsActions.getRecentViewsFailure(error));
    }
  },

  moveChecklist: function* moveTask({ data }) {
    try {
      const { checklists, ...params } = data;
      yield put(projectsActions.moveChecklistLocal(checklists));

      const response = yield anamiService.moveChecklist(params);

      yield put(projectsActions.moveChecklistSuccess(response.data.checklists));
    } catch (error) {
      yield put(projectsActions.moveChecklistFailure(error));
    }
  },

  renameChecklist: function*({ data }) {
    try {
      const response = yield anamiService.renameChecklist(data);

      yield put(
        projectsActions.renameChecklistSuccess(response.data.checklist)
      );
    } catch (error) {
      yield put(projectsActions.renameChecklistFailure(error));
    }
  },
};
