import { put, select, apply } from 'redux-saga/effects';
import _ from 'lodash';

import {
  isChecklist,
  isSop,
  getSectionTasks,
  isGeneralOrGreenspace,
} from '../selectors';
import anamiService from '../../../services/anamiService';
import projectsActions from '../actions';

import { getProjectService } from './helpers';
import analytics, { events } from 'services/analytics';

export default {
  createTask: function* createTask({ data }) {
    try {
      const service = yield getProjectService(data.projectId);
      const response = yield service.createTask(data);

      if (data.parentId) {
        yield apply(analytics, analytics.trackEvent, [
          events.CREATE_SUBTASK,
          {
            taskId: response.data.task.id,
          },
        ]);

        yield put(
          projectsActions.createSubtaskSuccess({
            task: {
              ...response.data.task,
              users: [response.data.task.createdBy],
              comments: [],
            },
            sectionId: data.sectionId,
            parentTaskId: data.parentId,
          })
        );
      } else {
        yield apply(analytics, analytics.trackEvent, [
          events.CREATE_TASK,
          {
            taskId: response.data.task.id,
          },
        ]);

        yield put(
          projectsActions.createTaskSuccess({
            ...response.data.task,
            users: [response.data.task.createdBy],
            comments: [],
            sectionId: data.sectionId,
            projectId: data.projectId,
            setBoardPosition: data.setBoardPosition,
          })
        );

        if (data.movedPosition >= 0) {
          const tasks = yield select(state =>
            getSectionTasks(state, data.sectionId)
          );
          const newPositions = [];

          tasks.forEach((task, index) => {
            newPositions.push([task.id, index]);
          });

          const res = yield service.moveTask({
            tasksPositions: newPositions,
            projectId: data.projectId,
            sectionId: data.sectionId,
            taskId: response.data.task.id,
          });
          yield put(projectsActions.moveTaskSuccess(res.data.task));
        }
      }

      const checklist = yield select(state =>
        isChecklist(state, data.projectId)
      );
      const sop = yield select(state => isSop(state, data.projectId));
      const generalGreenspace = yield select(state =>
        isGeneralOrGreenspace(state, data.projectId)
      );
      const isPlainProject = !checklist && !sop && !generalGreenspace;

      if (isPlainProject) {
        const projectResponse = yield anamiService.getProjectDetails(
          data.projectId
        );

        yield put(
          projectsActions.updateProjectCompleting(
            projectResponse.data.project.completing
          )
        );
      }
    } catch (error) {
      yield put(projectsActions.createTaskFailure(error));
    }
  },

  getTask: function* getTask({ data }) {
    try {
      const response = yield anamiService.getTask(data);

      yield put(
        projectsActions.updateTaskSuccess({
          ...data,
          task: response.data.task,
        })
      );
    } catch (error) {
      console.log('%c error', 'color: green; font-weight: bold;', error);
    }
  },

  selectTask: function* selectTask({ data }) {
    try {
      yield put(
        projectsActions.selectTaskSuccess(
          data && {
            ...data.task,
            ...(data.parentTask
              ? {
                  parentTask: data.parentTask,
                  isSubtask: true,
                }
              : []),
          }
        )
      );

      if (data) {
        yield put(
          projectsActions.getTaskRequest({
            taskId: data.task.id,
            ...data,
          })
        );
      }
    } catch (error) {
      yield put(projectsActions.getTaskFailure(error));
    }
  },

  updateTask: function*({ data }) {
    try {
      const {
        isDetails,
        field,
        skipGettingProject = false,
        parentTaskId,
        ...params
      } = data;
      const isUpdatedFieldStatus = _.has(data, 'status');

      const checklist = yield select(state =>
        isChecklist(state, data.projectId)
      );
      const sop = yield select(state => isSop(state, data.projectId));
      const generalGreenspace = yield select(state =>
        isGeneralOrGreenspace(state, data.projectId)
      );
      const isPlainProject = !checklist && !sop && !generalGreenspace;

      const service = yield getProjectService(data.projectId);
      const response = yield isDetails
        ? service.updateTaskDetails(params)
        : service.updateTask(params);

      if (isUpdatedFieldStatus && isPlainProject && !skipGettingProject) {
        const projectResponse = yield anamiService.getProjectDetails(
          data.projectId
        );

        yield put(
          projectsActions.updateProjectCompleting(
            projectResponse.data.project.completing
          )
        );
      }

      yield put(
        projectsActions.updateTaskSuccess({
          task: response.data.task,
          sectionId: data.sectionId,
          projectId: data.projectId,
          parentTaskId,
        })
      );
    } catch (error) {
      yield put(projectsActions.updateTaskFailure(error));
    }
  },

  removeTask: function* removeTask({ data }) {
    try {
      const { projectId, parentTaskId, sectionId } = data;

      const service = yield getProjectService(projectId);
      yield service.removeTask(data);

      const checklist = yield select(state => isChecklist(state, projectId));
      const sop = yield select(state => isSop(state, projectId));
      const generalGreenspace = yield select(state =>
        isGeneralOrGreenspace(state, projectId)
      );
      const isPlainProject = !checklist && !sop && !generalGreenspace;

      if (isPlainProject) {
        const projectResponse = yield anamiService.getProjectDetails(projectId);

        yield put(
          projectsActions.updateProjectCompleting(
            projectResponse.data.project.completing
          )
        );
      }

      if (parentTaskId) {
        yield put(
          projectsActions.getTaskRequest({
            taskId: parentTaskId,
            sectionId: sectionId,
            projectId: projectId,
          })
        );
      }

      yield put(projectsActions.removeTaskSuccess(data));
    } catch (error) {
      yield put(projectsActions.removeTaskFailure(error));
    }
  },

  moveTask: function* moveTask({ data }) {
    try {
      const { moveParams, sectionId, ...params } = data;
      yield put(projectsActions.moveTaskLocal(moveParams));

      if (moveParams.destinationId !== moveParams.sourceId) {
        params.sectionId = sectionId;
      }

      const service = yield getProjectService(data.projectId);
      const response = yield service.moveTask(data);

      yield put(projectsActions.moveTaskSuccess(response.data.task));

      // REMOVE PROJECT AVATAR URL
      // const projectResponse = yield service.getProject(data.projectId);

      // yield put(
      //   projectsActions.getProjectSuccess(
      //     projectResponse.data.project ||
      //       projectResponse.data.checklist ||
      //       projectResponse.data.sop ||
      //       projectResponse.data.template
      //   )
      // );

      yield put(
        projectsActions.getTaskRequest({
          taskId: response.data.task.id,
          sectionId: params.sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.moveTaskFailure(error));
    }
  },

  createAttachment: function* createAttachment({ data }) {
    try {
      const { projectId, resource, taskId } = data;
      let params = {};

      if (_.has(resource, 'attachmentByLink')) {
        params = {
          projectId,
          taskId,
          attachmentByLink: resource.attachmentByLink,
        };
      } else {
        params = {
          projectId,
          taskId,
          attachmentId: resource.id,
        };
      }

      yield anamiService.createAttachment(params);

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId: data.sectionId,
          projectId: data.projectId,
          parentTaskId: data.parentTaskId,
        })
      );
    } catch (error) {
      yield put(projectsActions.createAttachmentFailure(error));
    }
  },

  deleteAttachment: function* deleteAttachment({ data }) {
    try {
      const { projectId, sectionId, taskId, attachmentId } = data;

      const service = yield getProjectService(data.projectId);
      yield service.deleteAttachment({
        projectId,
        taskId,
        attachmentId,
      });

      yield put(
        projectsActions.deleteAttachmentSuccess({
          projectId,
          sectionId,
          taskId,
          attachmentId,
        })
      );

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId: data.sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.createAttachmentFailure(error));
    }
  },

  createComment: function* createComment({ data }) {
    try {
      const response = yield anamiService.createComment(data);

      yield put(
        projectsActions.createCommentSuccess({
          ...response.data.comment,
          sectionId: data.sectionId,
          taskId: data.taskId,
          projectId: data.projectId,
        })
      );

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId: data.sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.createCommentFailure(error));
    }
  },

  updateComment: function* createComment({ data }) {
    try {
      const response = yield anamiService.updateComment(data);

      yield put(
        projectsActions.updateCommentSuccess({
          comment: response.data.comment,
          sectionId: data.sectionId,
          taskId: data.taskId,
          projectId: data.projectId,
        })
      );

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId: data.sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.updateCommentFailure(error));
    }
  },

  removeComment: function* createComment({ data }) {
    try {
      yield anamiService.removeComment(data);

      yield put(projectsActions.removeCommentSuccess(data));

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId: data.sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.removeCommentFailure(error));
    }
  },

  createTaskLabel: function* createTaskLabel({ data }) {
    try {
      const { label, sectionId, ...params } = data;

      // const service = yield getProjectService(data.projectId);
      yield anamiService.createTaskLabel({ labelId: label.id, ...params });

      yield put(
        projectsActions.createTaskLabelSuccess({
          label: data.label,
          sectionId: data.sectionId,
          taskId: data.taskId,
          projectId: data.projectId,
          parentTaskId: data.parentTaskId,
        })
      );

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId: data.sectionId,
          projectId: data.projectId,
          parentTaskId: data.parentTaskId,
        })
      );
    } catch (error) {
      yield put(projectsActions.createTaskLabelFailure(error));
    }
  },

  removeTaskLabel: function* removeTaskLabel({ data }) {
    try {
      const { label, sectionId, ...params } = data;
      // const service = yield getProjectService(data.projectId);
      yield anamiService.removeTaskLabel({ labelId: label.id, ...params });

      yield put(
        projectsActions.removeTaskLabelSuccess({
          labelId: data.label.id,
          sectionId: data.sectionId,
          taskId: data.taskId,
          projectId: data.projectId,
        })
      );

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId: data.sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.removeTaskLabelFailure(error));
    }
  },

  setTaskAssignee: function* setTaskAssignee({ data }) {
    try {
      const { sectionId, action, parentTaskId = null, ...params } = data;
      let response = null;

      if (action === 'add') {
        response = yield anamiService.setTaskAssignee({ ...params });
      } else {
        response = yield anamiService.removeTaskAssignee({ ...params });
      }

      if (response) {
        yield put(
          projectsActions.setTaskAssigneeSuccess({
            sectionId,
            projectId: data.projectId,
            parentTaskId,
            task: response.data.task,
          })
        );

        yield put(
          projectsActions.getTaskRequest({
            taskId: data.taskId,
            sectionId,
            projectId: data.projectId,
            parentTaskId,
          })
        );
      } else {
        yield put(projectsActions.setTaskAssigneeFailure({}));
      }
    } catch (error) {
      yield put(projectsActions.setTaskAssigneeFailure(error));
    }
  },

  copyTask: function* copyTask({ data }) {
    const { sectionId, ...params } = data;

    try {
      const response = yield anamiService.copyTask(params);

      yield put(
        projectsActions.copyTaskSuccess({
          sectionId,
          ...response.data.task,
        })
      );

      const projectResponse = yield anamiService.getProject(data.projectId);

      yield put(
        projectsActions.updateProjectCompleting(
          projectResponse.data.project.completing
        )
      );
    } catch (error) {
      yield put(projectsActions.copyTaskFailure(error));
    }
  },

  repeatTask: function*({ data }) {
    try {
      const { sectionId, task, ...params } = data;

      yield anamiService.repeatTask(params);

      yield put(
        projectsActions.setTaskRepeatSuccess({
          task,
          recurring: params.options,
          projectId: data.projectId,
          sectionId,
        })
      );

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId: data.sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.setTaskRepeatFailure(error));
    }
  },

  setTaskDate: function*({ data }) {
    try {
      const {
        sectionId,
        type,
        sections = null,
        parentTaskId = null,
        ...rest
      } = data;
      const taskResponse = yield anamiService.setTaskDate(rest);

      if (type === 'dueDate' && sections) {
        yield put(
          projectsActions.updateSectionTaskDates({
            projectId: data.projectId,
            sections,
          })
        );
      }

      yield put(
        projectsActions.setTaskDateSuccess({
          sectionId: sectionId,
          projectId: data.projectId,
          type,
          task: taskResponse.data.task,
          parentTaskId,
        })
      );
    } catch (error) {
      yield put(projectsActions.setTaskDateFailure(error));
    }
  },

  createRelatedTasks: function*({ data }) {
    try {
      const { sectionId, ...rest } = data;

      yield anamiService.createRelatedTasks(rest);

      yield put(projectsActions.createRelatedTasksSuccess());

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.createRelatedTasksFailure(error));
    }
  },

  removeRelatedTasks: function*({ data }) {
    try {
      const { sectionId, ...rest } = data;

      yield anamiService.removeRelatedTasks(rest);

      yield put(projectsActions.removeRelatedTasksSuccess());

      yield put(
        projectsActions.getTaskRequest({
          taskId: data.taskId,
          sectionId,
          projectId: data.projectId,
        })
      );
    } catch (error) {
      yield put(projectsActions.removeRelatedTasksFailure(error));
    }
  },

  fetchTaskSubtasksSaga: function*({ data }) {
    try {
      const response = yield anamiService.fetchTaskSubtasks(data);

      yield put(
        projectsActions.fetchTaskSubtasksSuccess({
          ...data,
          subtasks: response.data.subtasks,
        })
      );
    } catch (error) {
      yield put(projectsActions.fetchTaskSubtasksFailure(error));
    }
  },

  moveSubtaskSaga: function*({ data }) {
    try {
      yield put(projectsActions.moveSubtaskLocal(data));
      yield anamiService.moveSubtask(data);

      yield put(projectsActions.moveSubtaskSuccess());
    } catch (error) {
      yield put(projectsActions.moveSubtaskFailure(error));
    }
  },
};
