import { all, takeEvery, put, apply, select } from 'redux-saga/effects';

import usersActions from './actions';
import businessActions from '../businesses/actions';
import projectActions from '../projects/actions';
import anamiService from '../../services/anamiService';
import authActions from '@iso/redux/auth/actions';

import analytics, { events } from 'services/analytics';

function* getUserList() {
  try {
    const response = yield anamiService.getUserList();

    yield put(usersActions.getUserListSuccess(response.data.users));
  } catch (error) {
    yield put(usersActions.getUserListFailure(error));
  }
}

function* getUser({ id }) {
  try {
    const response = yield anamiService.getUser(id);

    yield put(usersActions.getUserSuccess(response.data));
  } catch (error) {
    yield put(usersActions.getUserFailure(error));
  }
}

function* userUpdate({ data }) {
  try {
    const response = yield anamiService.updateUser(data);

    yield put(usersActions.updateUserSuccess(response.data.user));
  } catch (error) {
    yield put(usersActions.updateUserFailure(error));
  }
}

function* createUser({ user }) {
  try {
    const { role, ...params } = user;
    const response = yield anamiService.createUser(params);

    yield put(usersActions.createUserSuccess(response.data.user));

    const roleParams = {
      roleId: role,
      userId: response.data.user.id,
    };

    if (params.companyId) roleParams.companyId = params.companyId;

    if (roleParams.roleId) {
      yield put(usersActions.setUserRoleRequest(roleParams));
    }
  } catch (error) {
    yield put(usersActions.createUserFailure(error));
  }
}

function* removeUser({ id }) {
  try {
    yield anamiService.removeUser(id);

    yield put(usersActions.removeUserSuccess(id));
  } catch (error) {
    yield put(usersActions.removeUserFailure(error));
  }
}

function* getUserRoles() {
  try {
    const response = yield anamiService.getSpecifications();

    yield put(usersActions.getUserRolesSuccess(response.data.roleList));
  } catch (error) {
    yield put(usersActions.getUserRolesFailure(error));
  }
}

function* setUserRole({ data }) {
  try {
    const response = yield anamiService.setUserRole(data);

    yield put(usersActions.setUserRoleSuccess(response.data.user));
  } catch (error) {
    yield put(usersActions.setUserRoleFailure(error));
  }
}

function* unsetUserRole({ data }) {
  try {
    yield anamiService.unsetUserRole(data);

    yield put(usersActions.unsetUserRoleSuccess(data));
  } catch (error) {
    yield put(usersActions.unsetUserRoleFailure(error));
  }
}

function* uploadAvatar({ data }) {
  try {
    const response = yield anamiService.uploadUserAvatar(data);
    const { avatar } = response.data;

    yield put(usersActions.uploadAvatarSuccess(avatar));
  } catch (error) {
    yield put(usersActions.uploadAvatarFailure(error));
  }
}

function* updateSections({ data }) {
  try {
    const response = yield anamiService.updateUserSections(data);
    yield put(usersActions.getUserTeamsRequest());
    yield put(usersActions.updateSectionsSuccess(response.data.user));
  } catch (error) {
    yield put(usersActions.updateSectionsFailure(error));
  }
}

function* addUserToTeam({ data }) {
  try {
    yield anamiService.addUserToTeam(data);

    yield put(usersActions.addUserToTeamSuccess(data));
    yield put(usersActions.getUserTeamsRequest());
  } catch (error) {
    yield put(usersActions.addUserToTeamFailure(error));
  }
}

function* removeUserFromTeam({ data }) {
  try {
    yield anamiService.removeUserFromTeam(data);
    yield put(usersActions.removeUserFromTeamSuccess(data));
    yield put(usersActions.getUserTeamsRequest());
  } catch (error) {
    yield put(usersActions.removeUserFromTeamFailure(error));
  }
}

function* fetchTeams() {
  try {
    const response = yield anamiService.fetchTeams();

    yield put(usersActions.fetchTeamsSuccess(response.data.teams));
  } catch (error) {
    yield put(usersActions.fetchTeamsFailure(error));
  }
}

function* createTeam({ data }) {
  try {
    const response = yield anamiService.createTeam(data);

    const team = response.data.team;

    yield apply(analytics, analytics.trackEvent, [
      events.CREATE_TEAM,
      {
        teamId: team.id,
        teamTitle: team.title,
      },
    ]);

    yield put(usersActions.createTeamSuccess(team));
  } catch (error) {
    yield put(usersActions.createTeamFailure(error));
  }
}

function* removeTeam({ data }) {
  try {
    yield anamiService.removeTeam(data);

    yield put(usersActions.removeTeamSuccess(data));
  } catch (error) {
    yield put(usersActions.removeTeamFailure(error));
  }
}

function* updateTeamSections({ data }) {
  try {
    const response = yield anamiService.updateTeamSections(data);

    yield put(usersActions.updateTeamSectionsSuccess(response.data.team));
  } catch (error) {
    yield put(usersActions.updateTeamSectionsFailure(error));
  }
}

function* updateTeamTitle({ data }) {
  try {
    const response = yield anamiService.updateTeamTitle(data);

    yield put(usersActions.updateTeamSectionsSuccess(response.data.team));
  } catch (error) {
    yield put(usersActions.updateTeamSectionsFailure(error));
  }
}

function* setProject({ data }) {
  try {
    const response = yield anamiService.setProject(data);

    yield put(usersActions.setProjectSuccess(response.data.team));
  } catch (error) {
    yield put(usersActions.setProjectFailure(error));
  }
}

function* unsetProject({ data }) {
  try {
    const response = yield anamiService.unsetProject(data);

    yield put(usersActions.unsetProjectSuccess(response.data.team));
  } catch (error) {
    yield put(usersActions.unsetProjectFailure(error));
  }
}

function* setLocation({ data }) {
  try {
    const response = yield anamiService.setLocation(data);

    yield put(usersActions.setLocationSuccess(response.data.team));
  } catch (error) {
    yield put(usersActions.setLocationFailure(error));
  }
}

function* unsetLocation({ data }) {
  try {
    const response = yield anamiService.unsetLocation(data);

    yield put(usersActions.unsetLocationSuccess(response.data.team));
  } catch (error) {
    yield put(usersActions.unsetLocationFailure(error));
  }
}

function* getUserTeams() {
  try {
    const response = yield anamiService.getUserTeams();
    yield put(usersActions.getUserTeamsSuccess(response.data.users));
  } catch (error) {
    yield put(usersActions.getUserTeamsFailure(error));
  }
}

function* inviteUserWithEmail({ payload }) {
  try {
    const response = yield anamiService.inviteUserWithEmail(payload);
    yield put(usersActions.inviteUserWithEmailSuccess(response.data?.isExist));

    if (payload.entity === 'company') {
      const selectedCompany = yield select(
        state => state.companies.selectedCompany
      );

      yield apply(analytics, analytics.trackEvent, [
        events.INVITE_USER_TO_COMPANY,
        {
          email: payload.email,
          companyId: selectedCompany,
        },
      ]);
    }

    if (payload?.entity === 'project') {
      yield put(projectActions.addInviteUserToProjectState(response.data));
    } else if (payload?.entity === 'business') {
      yield put(businessActions.addInviteUserToState(response.data));
    }
  } catch (error) {
    yield put(usersActions.inviteUserWithEmailFailure(error));
  }
}

function* getInviteUserLink({ payload }) {
  try {
    const response = yield anamiService.getInviteUserLink(payload);
    yield put(usersActions.getInviteUserLinkSuccess(response.data.link));
  } catch (error) {
    yield put(usersActions.getInviteUserLinkFailure(error));
  }
}

function* createPasswordForInviteUser({ payload }) {
  try {
    const response = yield anamiService.createPasswordForInviteUser(payload);

    if (response?.data?.token) {
      localStorage.clear();
      localStorage.setItem('id_token', response.data.token);
      yield put(authActions.checkAuthorization());
    }

    yield put(usersActions.createPasswordForInviteUserSuccess(response.data));
  } catch (error) {
    yield put(usersActions.createPasswordForInviteUserFailure(error));
  }
}

function* createLinkInviteUser({ payload }) {
  try {
    const response = yield anamiService.createLinkInviteUser(payload);
    if (response?.data?.token) {
      localStorage.clear();
      localStorage.setItem('id_token', response.data.token);
      yield put(authActions.checkAuthorization());
    }

    yield put(usersActions.createLinkInviteUserSuccess(response.data));
  } catch (error) {
    yield put(usersActions.createLinkInviteUserFailure(error));
  }
}

function* getInviteEmail({ payload }) {
  try {
    const response = yield anamiService.getInviteEmail(payload);
    yield put(usersActions.getInviteEmailSuccess(response.data));
  } catch (error) {
    yield put(usersActions.getInviteEmailFailure(error));
  }
}

function* checkInviteEmail({ payload }) {
  try {
    const response = yield anamiService.checkInvitedUser(payload);

    if (response?.data?.token) {
      localStorage.clear();
      localStorage.setItem('id_token', response.data.token);
      yield put(authActions.checkAuthorization());
    }

    yield put(usersActions.checkInviteEmailSuccess(response.data));
  } catch (error) {
    yield put(usersActions.checkInviteEmailFailure(error));
  }
}

export default function* usersSaga() {
  yield all([takeEvery(usersActions.GET_USER_LIST_REQUEST, getUserList)]);
  yield all([takeEvery(usersActions.GET_USER_REQUEST, getUser)]);
  yield all([takeEvery(usersActions.UPDATE_USER_REQUEST, userUpdate)]);
  yield all([takeEvery(usersActions.CREATE_USER_REQUEST, createUser)]);
  yield all([takeEvery(usersActions.REMOVE_USER_REQUEST, removeUser)]);
  yield all([takeEvery(usersActions.GET_USER_ROLES_REQUEST, getUserRoles)]);
  yield all([takeEvery(usersActions.SET_USER_ROLE_REQUEST, setUserRole)]);
  yield all([takeEvery(usersActions.UPDATE_SECTIONS_REQUEST, updateSections)]);
  yield all([takeEvery(usersActions.UNSET_USER_ROLE_REQUEST, unsetUserRole)]);
  yield all([takeEvery(usersActions.UPLOAD_AVATAR_REQUEST, uploadAvatar)]);
  yield takeEvery(usersActions.FETCH_TEAMS_REQUEST, fetchTeams);
  yield takeEvery(usersActions.CREATE_NEW_TEAM_REQUEST, createTeam);
  yield takeEvery(usersActions.REMOVE_TEAM_REQUEST, removeTeam);
  yield takeEvery(
    usersActions.UPDATE_TEAM_SECTIONS_REQUEST,
    updateTeamSections
  );
  yield takeEvery(usersActions.ADD_USER_TO_TEAM_REQUEST, addUserToTeam);
  yield takeEvery(usersActions.UPDATE_TEAM_TITLE_REQUEST, updateTeamTitle);
  yield takeEvery(usersActions.SET_PROJECT_TO_TEAM_REQUEST, setProject);
  yield takeEvery(usersActions.UNSET_PROJECT_FROM_TEAM_REQUEST, unsetProject);
  yield takeEvery(usersActions.SET_LOCATION_TO_TEAM_REQUEST, setLocation);
  yield takeEvery(usersActions.UNSET_LOCATION_FROM_TEAM_REQUEST, unsetLocation);
  yield takeEvery(usersActions.GET_USER_TEAMS_REQUEST, getUserTeams);
  yield takeEvery(usersActions.INVITE_USER_WITH_EMAIL, inviteUserWithEmail);
  yield takeEvery(usersActions.GET_INVITE_USER_LINK, getInviteUserLink);
  yield takeEvery(usersActions.CREATE_LINK_INVITE_USER, createLinkInviteUser);
  yield takeEvery(usersActions.GET_INVITE_EMAIL, getInviteEmail);
  yield takeEvery(usersActions.CHECK_INVITE_EMAIL, checkInviteEmail);
  yield takeEvery(
    usersActions.CREATE_PASSWORD_FOR_INVITE_USER,
    createPasswordForInviteUser
  );
  yield takeEvery(
    usersActions.REMOVE_USER_FROM_TEAM_REQUEST,
    removeUserFromTeam
  );
}
