const resourcesActions = {
  GET_RESOURCE_LIST_REQUEST: 'GET_RESOURCE_LIST_REQUEST',
  GET_RESOURCE_LIST_SUCCESS: 'GET_RESOURCE_LIST_SUCCESS',
  GET_RESOURCE_LIST_FAILURE: 'GET_RESOURCE_LIST_FAILURE',

  SET_NEW_RESOURCE: 'SET_NEW_RESOURCE',

  SELECT_RESOURCE: 'SELECT_RESOURCE',

  UPLOAD_NEW_RESOURCE: 'UPLOAD_NEW_RESOURCE',

  CREATE_FOLDER_REQUEST: 'CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE: 'CREATE_FOLDER_FAILURE',

  UPDATE_RESOURCE_REQUEST: 'UPDATE_RESOURCE_REQUEST',
  UPDATE_RESOURCE_SUCCESS: 'UPDATE_RESOURCE_SUCCESS',
  UPDATE_RESOURCE_FAILURE: 'UPDATE_RESOURCE_FAILURE',

  UPLOAD_RESOURCE_REQUEST: 'UPLOAD_RESOURCE_REQUEST',
  UPLOAD_RESOURCE_SUCCESS: 'UPLOAD_RESOURCE_SUCCESS',
  UPLOAD_RESOURCE_ERROR_RESET: 'UPLOAD_RESOURCE_ERROR_RESET',
  UPLOAD_RESOURCE_FAILURE: 'UPLOAD_RESOURCE_FAILURE',

  DOWNLOAD_RESOURCE_REQUEST: 'DOWNLOAD_RESOURCE_REQUEST',
  DOWNLOAD_RESOURCE_SUCCESS: 'DOWNLOAD_RESOURCE_SUCCESS',
  DOWNLOAD_RESOURCE_FAILURE: 'DOWNLOAD_RESOURCE_FAILURE',

  REMOVE_RESOURCE_REQUEST: 'REMOVE_RESOURCE_REQUEST',
  REMOVE_RESOURCE_SUCCESS: 'REMOVE_RESOURCE_SUCCESS',
  REMOVE_RESOURCE_FAILURE: 'REMOVE_RESOURCE_FAILURE',

  MOVE_RESOURCE_REQUEST: 'MOVE_RESOURCE_REQUEST',
  MOVE_RESOURCE_SUCCESS: 'MOVE_RESOURCE_SUCCESS',
  MOVE_RESOURCE_FAILURE: 'MOVE_RESOURCE_FAILURE',
  MOVE_RESOURCE_LOCAL: 'MOVE_RESOURCE_LOCAL',

  GET_FILE_USERS_REQUEST: 'GET_FILE_USERS_REQUEST',
  GET_FILE_USERS_SUCCESS: 'GET_FILE_USERS_SUCCESS',
  GET_FILE_USERS_FAILURE: 'GET_FILE_USERS_FAILURE',

  CONNECT_FILE_USER_REQUEST: 'CONNECT_FILE_USER_REQUEST',
  CONNECT_FILE_USER_SUCCESS: 'CONNECT_FILE_USER_SUCCESS',
  CONNECT_FILE_USER_FAILURE: 'CONNECT_FILE_USER_FAILURE',

  DISCONNECT_FILE_USER_REQUEST: 'DISCONNECT_FILE_USER_REQUEST',
  DISCONNECT_FILE_USER_SUCCESS: 'DISCONNECT_FILE_USER_SUCCESS',
  DISCONNECT_FILE_USER_FAILURE: 'DISCONNECT_FILE_USER_FAILURE',

  GET_RESOURCE_URL_REQUEST: 'GET_RESOURCE_URL_REQUEST',
  GET_RESOURCE_URL_SUCCESS: 'GET_RESOURCE_URL_SUCCESS',
  GET_RESOURCE_URL_FAILURE: 'GET_RESOURCE_URL_FAILURE',

  GET_RESOURCE_SUMMARY_REQUEST: 'GET_RESOURCE_SUMMARY_REQUEST',
  GET_RESOURCE_SUMMARY_SUCCESS: 'GET_RESOURCE_SUMMARY_SUCCESS',
  GET_RESOURCE_SUMMARY_FAILURE: 'GET_RESOURCE_SUMMARY_FAILURE',

  SELECT_BUSINESS_RESOURCE: 'SELECT_BUSINESS_RESOURCE',

  RESTRICT_ACCESS_TO_RESOURCE_REQUEST: 'RESTRICT_ACCESS_TO_RESOURCE_REQUEST',
  RESTRICT_ACCESS_TO_RESOURCE_SUCCESS: 'RESTRICT_ACCESS_TO_RESOURCE_SUCCESS',
  RESTRICT_ACCESS_TO_RESOURCE_FAILURE: 'RESTRICT_ACCESS_TO_RESOURCE_FAILURE',

  ALLOW_ACCESS_TO_RESOURCE_REQUEST: 'ALLOW_ACCESS_TO_RESOURCE_REQUEST',
  ALLOW_ACCESS_TO_RESOURCE_SUCCESS: 'ALLOW_ACCESS_TO_RESOURCE_SUCCESS',
  ALLOW_ACCESS_TO_RESOURCE_FAILURE: 'ALLOW_ACCESS_TO_RESOURCE_FAILURE',

  SHARE_RESOURCE_WITH_EXTERNAL_USER_REQUEST:
    'SHARE_RESOURCE_WITH_EXTERNAL_USER_REQUEST',
  SHARE_RESOURCE_WITH_EXTERNAL_USER_SUCCESS:
    'SHARE_RESOURCE_WITH_EXTERNAL_USER_SUCCESS',
  SHARE_RESOURCE_WITH_EXTERNAL_USER_FAILURE:
    'SHARE_RESOURCE_WITH_EXTERNAL_USER_FAILURE',

  DOWNLOAD_SHARED_RESOURCE_REQUEST: 'DOWNLOAD_SHARED_RESOURCE_REQUEST',
  DOWNLOAD_SHARED_RESOURCE_SUCCESS: 'DOWNLOAD_SHARED_RESOURCE_SUCCESS',
  DOWNLOAD_SHARED_RESOURCE_FAILURE: 'DOWNLOAD_SHARED_RESOURCE_FAILURE',

  SET_LOADER: 'SET_LOADER',

  ALLOW_TEAM_ACCESS_TO_RESOURCE_REQUEST:
    'ALLOW_TEAM_ACCESS_TO_RESOURCE_REQUEST',
  ALLOW_TEAM_ACCESS_TO_RESOURCE_SUCCESS:
    'ALLOW_TEAM_ACCESS_TO_RESOURCE_SUCCESS',
  ALLOW_TEAM_ACCESS_TO_RESOURCE_FAILURE:
    'ALLOW_TEAM_ACCESS_TO_RESOURCE_FAILURE',

  RESTRICT_TEAM_ACCESS_TO_RESOURCE_REQUEST:
    'RESTRICT_TEAM_ACCESS_TO_RESOURCE_REQUEST',
  RESTRICT_TEAM_ACCESS_TO_RESOURCE_SUCCESS:
    'RESTRICT_TEAM_ACCESS_TO_RESOURCE_SUCCESS',
  RESTRICT_TEAM_ACCESS_TO_RESOURCE_FAILURE:
    'RESTRICT_TEAM_ACCESS_TO_RESOURCE_FAILURE',

  SET_DEFAULT_RESOURCE_URL: 'SET_DEFAULT_RESOURCE_URL',

  SET_RESOURCE_DETAIL_VISIBILITY: 'SET_RESOURCE_DETAIL_VISIBILITY',

  GET_FILE_PREVIEW_IMAGES_REQUEST: 'GET_FILE_PREVIEW_IMAGES_REQUEST',
  SET_FILE_PREVIEW_IMAGES_SACCESS: 'SET_FILE_PREVIEW_IMAGES_SACCESS',
  SET_FILE_PREVIEW_IMAGES_FAILURE: 'SET_FILE_PREVIEW_IMAGES_FAILURE',
  CLEAR_PREVIEW_IMAGE_ERRORS: 'CLEAR_PREVIEW_IMAGE_ERRORS',

  UPLOAD_NEW_RESOURCE_WITH_CALLBACK_REQUEST:
    'UPLOAD_NEW_RESOURCE_WITH_CALLBACK_REQUEST',
  UPLOAD_NEW_RESOURCE_WITH_CALLBACK_SUCCESS:
    'UPLOAD_NEW_RESOURCE_WITH_CALLBACK_SUCCESS',
  UPLOAD_NEW_RESOURCE_WITH_CALLBACK_FAILURE:
    'UPLOAD_NEW_RESOURCE_WITH_CALLBACK_FAILURE',

  SET_UPLOADED_FILE_TO_DEFAULT: 'SET_UPLOADED_FILE_TO_DEFAULT',

  SET_RESOURCE_PERMISSION_REQUEST: 'SET_RESOURCE_PERMISSION_REQUEST',
  SET_RESOURCE_PERMISSION_SUCCESS: 'SET_RESOURCE_PERMISSION_SUCCESS',
  SET_RESOURCE_PERMISSION_FAILURE: 'SET_RESOURCE_PERMISSION_FAILURE',

  SHARE_RESOURCE_TO_USER_OR_TEAM_REQUEST:
    'SHARE_RESOURCE_TO_USER_OR_TEAM_REQUEST',
  SHARE_RESOURCE_TO_USER_OR_TEAM_SUCCESS:
    'SHARE_RESOURCE_TO_USER_OR_TEAM_SUCCESS',
  SHARE_RESOURCE_TO_USER_OR_TEAM_FAILURE:
    'SHARE_RESOURCE_TO_USER_OR_TEAM_FAILURE',

  RESTRICT_RESOURCE_TO_USER_OR_TEAM_REQUEST:
    'RESTRICT_RESOURCE_TO_USER_OR_TEAM_REQUEST',
  RESTRICT_RESOURCE_TO_USER_OR_TEAM_SUCCESS:
    'RESTRICT_RESOURCE_TO_USER_OR_TEAM_SUCCESS',
  RESTRICT_RESOURCE_TO_USER_OR_TEAM_FAILURE:
    'RESTRICT_RESOURCE_TO_USER_OR_TEAM_FAILURE',

  CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_REQUEST:
    'CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_REQUEST',
  CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_SUCCESS:
    'CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_SUCCESS',
  CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_FAILURE:
    'CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_FAILURE',

  SET_USER_PERMISSIONS_WARNING_DATA: 'SET_USER_PERMISSIONS_WARNING_DATA',

  getFilePreviewImagesRequest: payload => ({
    type: resourcesActions.GET_FILE_PREVIEW_IMAGES_REQUEST,
    payload,
  }),

  getFilePreviewImagesSaccess: payload => ({
    type: resourcesActions.SET_FILE_PREVIEW_IMAGES_SACCESS,
    payload,
  }),

  getFilePreviewImagesFailure: payload => ({
    type: resourcesActions.SET_FILE_PREVIEW_IMAGES_FAILURE,
    payload,
  }),

  clearPreviewImageErrors: () => ({
    type: resourcesActions.CLEAR_PREVIEW_IMAGE_ERRORS,
  }),

  setResourceDetailVisibility: isVisible => ({
    type: resourcesActions.SET_RESOURCE_DETAIL_VISIBILITY,
    payload: isVisible,
  }),

  getResourceListRequest: loading => ({
    type: resourcesActions.GET_RESOURCE_LIST_REQUEST,
    loading,
  }),
  getResourceListSuccess: resources => ({
    type: resourcesActions.GET_RESOURCE_LIST_SUCCESS,
    payload: resources,
  }),
  getResourceListFailure: error => ({
    type: resourcesActions.GET_RESOURCE_LIST_FAILURE,
    payload: error,
  }),

  createFolderRequest: data => ({
    type: resourcesActions.CREATE_FOLDER_REQUEST,
    data,
  }),
  createFolderSuccess: resource => ({
    type: resourcesActions.CREATE_FOLDER_SUCCESS,
    payload: resource,
  }),
  createFolderFailure: error => ({
    type: resourcesActions.CREATE_FOLDER_FAILURE,
    payload: error,
  }),

  updateResourceRequest: data => ({
    type: resourcesActions.UPDATE_RESOURCE_REQUEST,
    data,
  }),
  updateResourceSuccess: resource => ({
    type: resourcesActions.UPDATE_RESOURCE_SUCCESS,
    payload: resource,
  }),
  updateResourceFailure: error => ({
    type: resourcesActions.UPDATE_RESOURCE_FAILURE,
    payload: error,
  }),

  setNewResource: data => ({
    type: resourcesActions.SET_NEW_RESOURCE,
    payload: data,
  }),
  selectResource: resource => ({
    type: resourcesActions.SELECT_RESOURCE,
    payload: resource,
  }),
  selectBusinessResource: resource => ({
    type: resourcesActions.SELECT_BUSINESS_RESOURCE,
    payload: resource,
  }),
  uploadNewResource: state => ({
    type: resourcesActions.UPLOAD_NEW_RESOURCE,
    payload: state,
  }),

  uploadResourceRequest: (data, event) => ({
    type: resourcesActions.UPLOAD_RESOURCE_REQUEST,
    data,
    event,
  }),
  uploadResourceSuccess: resource => ({
    type: resourcesActions.UPLOAD_RESOURCE_SUCCESS,
    payload: resource,
  }),
  uploadResourceErrorReset: () => ({
    type: resourcesActions.UPLOAD_RESOURCE_ERROR_RESET,
  }),
  uploadResourceFailure: error => ({
    type: resourcesActions.UPLOAD_RESOURCE_FAILURE,
    payload: error,
  }),
  downloadResourceRequest: file => ({
    type: resourcesActions.DOWNLOAD_RESOURCE_REQUEST,
    file,
  }),
  downloadResourceSuccess: resource => ({
    type: resourcesActions.DOWNLOAD_RESOURCE_SUCCESS,
    payload: resource,
  }),
  downloadResourceFailure: error => ({
    type: resourcesActions.DOWNLOAD_RESOURCE_FAILURE,
    payload: error,
  }),

  removeResourceRequest: id => ({
    type: resourcesActions.REMOVE_RESOURCE_REQUEST,
    id,
  }),
  removeResourceSuccess: id => ({
    type: resourcesActions.REMOVE_RESOURCE_SUCCESS,
    payload: id,
  }),
  removeResourceFailure: error => ({
    type: resourcesActions.REMOVE_RESOURCE_FAILURE,
    payload: error,
  }),

  moveResourceRequest: data => ({
    type: resourcesActions.MOVE_RESOURCE_REQUEST,
    data,
  }),
  moveResourceSuccess: data => ({
    type: resourcesActions.MOVE_RESOURCE_SUCCESS,
    payload: data,
  }),
  moveResourceFailure: error => ({
    type: resourcesActions.MOVE_RESOURCE_FAILURE,
    payload: error,
  }),
  moveResourceLocal: data => ({
    type: resourcesActions.MOVE_RESOURCE_LOCAL,
    payload: data,
  }),

  getFileUsersRequest: id => ({
    type: resourcesActions.GET_FILE_USERS_REQUEST,
    id,
  }),
  getFileUsersSuccess: data => ({
    type: resourcesActions.GET_FILE_USERS_SUCCESS,
    payload: data,
  }),
  getFileUsersFailure: error => ({
    type: resourcesActions.GET_FILE_USERS_FAILURE,
    payload: error,
  }),

  connectFileUserRequest: data => ({
    type: resourcesActions.CONNECT_FILE_USER_REQUEST,
    data,
  }),
  connectFileUserSuccess: data => ({
    type: resourcesActions.CONNECT_FILE_USER_SUCCESS,
    payload: data,
  }),
  connectFileUserFailure: error => ({
    type: resourcesActions.CONNECT_FILE_USER_FAILURE,
    payload: error,
  }),

  disconnectFileUserRequest: data => ({
    type: resourcesActions.DISCONNECT_FILE_USER_REQUEST,
    data,
  }),
  disconnectFileUserSuccess: data => ({
    type: resourcesActions.DISCONNECT_FILE_USER_SUCCESS,
    payload: data,
  }),
  disconnectFileUserFailure: error => ({
    type: resourcesActions.DISCONNECT_FILE_USER_FAILURE,
    payload: error,
  }),

  getResourceUrlRequest: data => ({
    type: resourcesActions.GET_RESOURCE_URL_REQUEST,
    payload: data,
  }),
  getResourceUrlSuccess: data => ({
    type: resourcesActions.GET_RESOURCE_URL_SUCCESS,
    payload: data,
  }),
  getResourceUrlFailure: data => ({
    type: resourcesActions.GET_RESOURCE_URL_FAILURE,
    payload: data,
  }),
  getResourcesSummaryRequest: () => ({
    type: resourcesActions.GET_RESOURCE_SUMMARY_REQUEST,
  }),
  getResourcesSummarySuccess: count => ({
    type: resourcesActions.GET_RESOURCE_SUMMARY_SUCCESS,
    payload: count,
  }),
  getResourcesSummaryFailure: error => ({
    type: resourcesActions.GET_RESOURCE_SUMMARY_FAILURE,
    payload: error,
  }),

  restrictAccessResourceRequest: data => ({
    type: resourcesActions.RESTRICT_ACCESS_TO_RESOURCE_REQUEST,
    data,
  }),
  restrictAccessResourceSuccess: resource => ({
    type: resourcesActions.RESTRICT_ACCESS_TO_RESOURCE_SUCCESS,
    payload: resource,
  }),
  restrictAccessResourceFailure: error => ({
    type: resourcesActions.RESTRICT_ACCESS_TO_RESOURCE_FAILURE,
    payload: error,
  }),

  allowAccessResourceRequest: data => ({
    type: resourcesActions.ALLOW_ACCESS_TO_RESOURCE_REQUEST,
    data,
  }),
  allowAccessResourceSuccess: resource => ({
    type: resourcesActions.ALLOW_ACCESS_TO_RESOURCE_SUCCESS,
    payload: resource,
  }),
  allowAccessResourceFailure: error => ({
    type: resourcesActions.ALLOW_ACCESS_TO_RESOURCE_FAILURE,
    payload: error,
  }),

  shareWithExternalUserRequest: data => ({
    type: resourcesActions.SHARE_RESOURCE_WITH_EXTERNAL_USER_REQUEST,
    data,
  }),
  shareWithExternalUserSuccess: () => ({
    type: resourcesActions.SHARE_RESOURCE_WITH_EXTERNAL_USER_SUCCESS,
  }),
  shareWithExternalUserFailure: error => ({
    type: resourcesActions.SHARE_RESOURCE_WITH_EXTERNAL_USER_FAILURE,
    payload: error,
  }),

  downloadSharedResourceRequest: data => ({
    type: resourcesActions.DOWNLOAD_SHARED_RESOURCE_REQUEST,
    data,
  }),
  downloadSharedResourceSuccess: () => ({
    type: resourcesActions.DOWNLOAD_SHARED_RESOURCE_SUCCESS,
  }),
  downloadSharedResourceFailure: error => ({
    type: resourcesActions.DOWNLOAD_SHARED_RESOURCE_FAILURE,
    payload: error,
  }),

  setLoadingStatus: loading => ({
    type: resourcesActions.SET_LOADER,
    payload: loading,
  }),

  allowTeamAccessRequest: data => ({
    type: resourcesActions.ALLOW_TEAM_ACCESS_TO_RESOURCE_REQUEST,
    data,
  }),
  allowTeamAccessSuccess: data => ({
    type: resourcesActions.ALLOW_TEAM_ACCESS_TO_RESOURCE_SUCCESS,
    payload: data,
  }),
  allowTeamAccessFailure: error => ({
    type: resourcesActions.ALLOW_TEAM_ACCESS_TO_RESOURCE_FAILURE,
    payload: error,
  }),

  restrictTeamAccessRequest: data => ({
    type: resourcesActions.RESTRICT_TEAM_ACCESS_TO_RESOURCE_REQUEST,
    data,
  }),
  restrictTeamAccessSuccess: data => ({
    type: resourcesActions.RESTRICT_TEAM_ACCESS_TO_RESOURCE_SUCCESS,
    payload: data,
  }),
  restrictTeamAccessFailure: error => ({
    type: resourcesActions.RESTRICT_TEAM_ACCESS_TO_RESOURCE_FAILURE,
    payload: error,
  }),

  setDefaultResourceUrl: () => ({
    type: resourcesActions.SET_DEFAULT_RESOURCE_URL,
  }),

  uploadResourceWithCallback: data => ({
    type: resourcesActions.UPLOAD_NEW_RESOURCE_WITH_CALLBACK_REQUEST,
    data,
  }),
  uploadResourceWithCallbackSuccess: () => ({
    type: resourcesActions.UPLOAD_NEW_RESOURCE_WITH_CALLBACK_SUCCESS,
  }),
  uploadResourceWithCallbackFailure: error => ({
    type: resourcesActions.UPLOAD_NEW_RESOURCE_WITH_CALLBACK_FAILURE,
    payload: error,
  }),

  setUploadedFileToDefault: error => ({
    type: resourcesActions.SET_UPLOADED_FILE_TO_DEFAULT,
    payload: error,
  }),

  setResourcePermission: data => ({
    type: resourcesActions.SET_RESOURCE_PERMISSION_REQUEST,
    data,
  }),
  setResourcePermissionSuccess: data => ({
    type: resourcesActions.SET_RESOURCE_PERMISSION_SUCCESS,
    payload: data,
  }),
  setResourcePermissionFailure: error => ({
    type: resourcesActions.SET_RESOURCE_PERMISSION_FAILURE,
    payload: error,
  }),

  shareResourceToUserOrTeam: data => ({
    type: resourcesActions.SHARE_RESOURCE_TO_USER_OR_TEAM_REQUEST,
    data,
  }),
  shareResourceToUserOrTeamSuccess: data => ({
    type: resourcesActions.SHARE_RESOURCE_TO_USER_OR_TEAM_SUCCESS,
    payload: data,
  }),
  shareResourceToUserOrTeamFailure: error => ({
    type: resourcesActions.SHARE_RESOURCE_TO_USER_OR_TEAM_FAILURE,
    payload: error,
  }),

  restrictResourceToUserOrTeam: data => ({
    type: resourcesActions.RESTRICT_RESOURCE_TO_USER_OR_TEAM_REQUEST,
    data,
  }),
  restrictResourceToUserOrTeamSuccess: data => ({
    type: resourcesActions.RESTRICT_RESOURCE_TO_USER_OR_TEAM_SUCCESS,
    payload: data,
  }),
  restrictResourceToUserOrTeamFailure: error => ({
    type: resourcesActions.RESTRICT_RESOURCE_TO_USER_OR_TEAM_FAILURE,
    payload: error,
  }),

  checkUserHasHigherPermissionsToResource: data => ({
    type:
      resourcesActions.CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_REQUEST,
    data,
  }),
  checkUserHasHigherPermissionsToResourceSuccess: data => ({
    type:
      resourcesActions.CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_SUCCESS,
    payload: data,
  }),
  checkUserHasHigherPermissionsToResourceFailure: error => ({
    type:
      resourcesActions.CHECK_USER_HAS_HIGHER_PERMISSIONS_TO_RESOURCE_FAILURE,
    payload: error,
  }),

  setUserPermissionsWarningData: data => ({
    type: resourcesActions.SET_USER_PERMISSIONS_WARNING_DATA,
    payload: data,
  }),
};

export default resourcesActions;
