export default {
  FETCH_DATA: 'FETCH_PROJECTS_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_PROJECTS_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'FETCH_PROJECTS_DATA_FAILURE',
  SET_PROJECT_VIEWED: 'SET_PROJECT_VIEWED',
  GET_PROJECT_REQUEST: 'GET_PROJECT_REQUEST',
  GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
  GET_PROJECT_FAILURE: 'GET_PROJECT_FAILURE',
  GET_AUDIT_PROJECT_REQUEST: 'GET_AUDIT_PROJECT_REQUEST',
  GET_AUDIT_PROJECT_SUCCESS: 'GET_AUDIT_PROJECT_SUCCESS',
  GET_AUDIT_PROJECT_FAILURE: 'GET_AUDIT_PROJECT_FAILURE',
  GET_AUDIT_PROJECT_LIST_REQUEST: 'GET_AUDIT_PROJECT_LIST_REQUEST',
  GET_AUDIT_PROJECT_LIST_SUCCESS: 'GET_AUDIT_PROJECT_LIST_SUCCESS',
  GET_AUDIT_PROJECT_LIST_FAILURE: 'GET_AUDIT_PROJECT_LIST_FAILURE',
  CREATE_PROJECT_REQUEST: 'CREATE_PROJECT_REQUEST',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILURE: 'CREATE_PROJECT_FAILURE',
  RESET_TITLE_EXIST_ERROR: 'RESET_TITLE_EXIST_ERROR',
  UPDATE_PROJECT_REQUEST: 'UPDATE_PROJECT_REQUEST',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',
  REMOVE_PROJECT_REQUEST: 'REMOVE_PROJECT_REQUEST',
  REMOVE_PROJECT_SUCCESS: 'REMOVE_PROJECT_SUCCESS',
  REMOVE_PROJECT_FAILURE: 'REMOVE_PROJECT_FAILURE',
  PUBLISH_PROJECT_REQUEST: 'PUBLISH_PROJECT_REQUEST',
  PUBLISH_PROJECT_SUCCESS: 'PUBLISH_PROJECT_SUCCESS',
  PUBLISH_PROJECT_FAILURE: 'PUBLISH_PROJECT_FAILURE',
  CONVERT_TO_SOP_REQUEST: 'CONVERT_TO_SOP_REQUEST',
  CONVERT_TO_SOP_SUCCESS: 'CONVERT_TO_SOP_SUCCESS',
  CONVERT_TO_SOP_FAILURE: 'CONVERT_TO_SOP_FAILURE',
  GET_PROJECT_USERS_REQUEST: 'GET_PROJECT_USERS_REQUEST',
  GET_PROJECT_USERS_SUCCESS: 'GET_PROJECT_USERS_SUCCESS',
  GET_PROJECT_USERS_FAILURE: 'GET_PROJECT_USERS_FAILURE',

  GET_PROJECT_PARTS: 'GET_PROJECT_PARTS',
  GET_PROJECT_DETAILS_REQUEST: 'GET_PROJECT_DETAILS_REQUEST',
  GET_PROJECT_DETAILS_SUCCESS: 'GET_PROJECT_DETAILS_SUCCESS',
  GET_PROJECT_DETAILS_FAILURE: 'GET_PROJECT_DETAILS_FAILURE',
  GET_PROJECT_SECTION_REQUEST: 'GET_PROJECT_SECTION_REQUEST',
  GET_PROJECT_SECTION_SUCCESS: 'GET_PROJECT_SECTION_SUCCESS',
  GET_PROJECT_SECTION_FAILURE: 'GET_PROJECT_SECTION_FAILURE',
  GET_PROJECT_FILES_REQUEST: 'GET_PROJECT_FILES_REQUEST',
  GET_PROJECT_FILES_SUCCESS: 'GET_PROJECT_FILES_SUCCESS',
  GET_PROJECT_FILES_FAILURE: 'GET_PROJECT_FILES_FAILURE',
  GET_PROJECT_USERS_AND_TEAMS_REQUEST: 'GET_PROJECT_USERS_AND_TEAMS_REQUEST',
  GET_PROJECT_USERS_AND_TEAMS_SUCCESS: 'GET_PROJECT_USERS_AND_TEAMS_SUCCESS',
  GET_PROJECT_USERS_AND_TEAMS_FAILURE: 'GET_PROJECT_USERS_AND_TEAMS_FAILURE',

  GET_CHECKLISTS_REQUEST: 'GET_CHECKLISTS_REQUEST',
  GET_CHECKLISTS_SUCCESS: 'GET_CHECKLISTS_SUCCESS',
  GET_CHECKLISTS_FAILURE: 'GET_CHECKLISTS_FAILURE',
  CREATE_CHECKLIST_REQUEST: 'CREATE_CHECKLIST_REQUEST',
  CREATE_CHECKLIST_SUCCESS: 'CREATE_CHECKLIST_SUCCESS',
  CREATE_CHECKLIST_FAILURE: 'CREATE_CHECKLIST_FAILURE',
  GET_RECENT_VIEWS_REQUEST: 'GET_RECENT_VIEWS_REQUEST',
  GET_RECENT_VIEWS_SUCCESS: 'GET_RECENT_VIEWS_SUCCESS',
  GET_RECENT_VIEWS_FAILURE: 'GET_RECENT_VIEWS_FAILURE',
  UPLOAD_CHECKLIST_ICON_REQUEST: 'UPLOAD_CHECKLIST_ICON_REQUEST',
  UPLOAD_CHECKLIST_ICON_SUCCESS: 'UPLOAD_CHECKLIST_ICON_SUCCESS',
  UPLOAD_CHECKLIST_ICON_FAILURE: 'UPLOAD_CHECKLIST_ICON_FAILURE',
  REMOVE_CHECKLIST_ICON_REQUEST: 'REMOVE_CHECKLIST_ICON_REQUEST',
  REMOVE_CHECKLIST_ICON_SUCCESS: 'REMOVE_CHECKLIST_ICON_SUCCESS',
  REMOVE_CHECKLIST_ICON_FAILURE: 'REMOVE_CHECKLIST_ICON_FAILURE',
  MOVE_CHECKLIST_REQUEST: 'MOVE_CHECKLIST_REQUEST',
  MOVE_CHECKLIST_SUCCESS: 'MOVE_CHECKLIST_SUCCESS',
  MOVE_CHECKLIST_FAILURE: 'MOVE_CHECKLIST_FAILURE',

  MOVE_CHECKLIST_LOCAL: 'MOVE_CHECKLIST_LOCAL',

  GET_SOPS_REQUEST: 'GET_SOPS_REQUEST',
  GET_SOPS_SUCCESS: 'GET_SOPS_SUCCESS',
  GET_SOPS_FAILURE: 'GET_SOPS_FAILURE',
  CREATE_SOP_REQUEST: 'CREATE_SOP_REQUEST',
  CREATE_SOP_SUCCESS: 'CREATE_SOP_SUCCESS',
  CREATE_SOP_FAILURE: 'CREATE_SOP_FAILURE',
  ASSIGNEE_SOP_REQUEST: 'ASSIGNEE_SOP_REQUEST',
  ASSIGNEE_SOP_SUCCESS: 'ASSIGNEE_SOP_SUCCESS',
  ASSIGNEE_SOP_FAILURE: 'ASSIGNEE_SOP_FAILURE',
  SET_SOP_DUE_DATE_REQUEST: 'SET_SOP_DUE_DATE_REQUEST',
  SET_SOP_DUE_DATE_SUCCESS: 'SET_SOP_DUE_DATE_SUCCESS',
  SET_SOP_DUE_DATE_FAILURE: 'SET_SOP_DUE_DATE_FAILURE',
  SET_SOP_EFFECTIVE_DATE_REQUEST: 'SET_SOP_EFFECTIVE_DATE_REQUEST',
  SET_SOP_EFFECTIVE_DATE_SUCCESS: 'SET_SOP_EFFECTIVE_DATE_SUCCESS',
  SET_SOP_EFFECTIVE_DATE_FAILURE: 'SET_SOP_EFFECTIVE_DATE_FAILURE',

  GET_UPCOMING_TASKS_REQUEST: 'GET_UPCOMING_TASKS_REQUEST',
  GET_UPCOMING_TASKS_SUCCESS: 'GET_UPCOMING_TASKS_SUCCESS',
  GET_UPCOMING_TASKS_FAILURE: 'GET_UPCOMING_TASKS_FAILURE',

  GET_EXPIRED_TASKS_REQUEST: 'GET_EXPIRED_TASKS_REQUEST',
  GET_EXPIRED_TASKS_SUCCESS: 'GET_EXPIRED_TASKS_SUCCESS',
  GET_EXPIRED_TASKS_FAILURE: 'GET_EXPIRED_TASKS_FAILURE',

  GET_NOTIFICATION_TASKS_REQUEST: 'GET_NOTIFICATION_TASKS_REQUEST',
  GET_NOTIFICATION_TASKS_SUCCESS: 'GET_NOTIFICATION_TASKS_SUCCESS',
  GET_NOTIFICATION_TASKS_FAILURE: 'GET_NOTIFICATION_TASKS_FAILURE',
  GET_EXPIRED_PROJECT_TASKS: 'GET_EXPIRED_PROJECT_TASKS',
  GET_UPCOMING_PROJECT_TASKS: 'GET_UPCOMING_PROJECT_TASKS',
  GET_NOTIFICATION_PROJECT_TASKS: 'GET_NOTIFICATION_PROJECT_TASKS',

  GET_CONNECTED_USERS_REQUEST: 'GET_CONNECTED_USERS_REQUEST',
  GET_CONNECTED_USERS_SUCCESS: 'GET_CONNECTED_USERS_SUCCESS',
  GET_CONNECTED_USERS_FAILURE: 'GET_CONNECTED_USERS_FAILURE',
  CONNECT_USER_REQUEST: 'CONNECT_USER_REQUEST',
  CONNECT_USER_SUCCESS: 'CONNECT_USER_SUCCESS',
  CONNECT_USER_FAILURE: 'CONNECT_USER_FAILURE',
  DISCONNECT_USER_REQUEST: 'DISCONNECT_USER_REQUEST',
  DISCONNECT_USER_SUCCESS: 'DISCONNECT_USER_SUCCESS',
  DISCONNECT_USER_FAILURE: 'DISCONNECT_USER_FAILURE',

  CREATE_SECTION_REQUEST: 'CREATE_SECTION_REQUEST',
  CREATE_SECTION_SUCCESS: 'CREATE_SECTION_SUCCESS',
  CREATE_SECTION_FAILURE: 'CREATE_SECTION_FAILURE',
  UPDATE_SECTION_REQUEST: 'UPDATE_SECTION_REQUEST',
  UPDATE_SECTION_SUCCESS: 'UPDATE_SECTION_SUCCESS',
  UPDATE_SECTION_FAILURE: 'UPDATE_SECTION_FAILURE',
  REMOVE_SECTION_REQUEST: 'REMOVE_SECTION_REQUEST',
  REMOVE_SECTION_SUCCESS: 'REMOVE_SECTION_SUCCESS',
  REMOVE_SECTION_FAILURE: 'REMOVE_SECTION_FAILURE',

  CREATE_TASK_REQUEST: 'CREATE_TASK_REQUEST',
  CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
  CREATE_TASK_FAILURE: 'CREATE_TASK_FAILURE',
  UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',
  UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_FAILURE: 'UPDATE_TASK_FAILURE',
  REMOVE_TASK_REQUEST: 'REMOVE_TASK_REQUEST',
  REMOVE_TASK_SUCCESS: 'REMOVE_TASK_SUCCESS',
  REMOVE_TASK_FAILURE: 'REMOVE_TASK_FAILURE',

  SELECT_TASK: 'SELECT_TASK',
  SELECT_TASK_SUCCESS: 'SELECT_TASK_SUCCESS',

  CREATE_ATTACHMENT_REQUEST: 'CREATE_ATTACHMENT_REQUEST',
  CREATE_ATTACHMENT_SUCCESS: 'CREATE_ATTACHMENT_SUCCESS',
  CREATE_ATTACHMENT_FAILURE: 'CREATE_ATTACHMENT_FAILURE',

  DELETE_ATTACHMENT_REQUEST: 'DELETE_ATTACHMENT_REQUEST',
  DELETE_ATTACHMENT_SUCCESS: 'DELETE_ATTACHMENT_SUCCESS',
  DELETE_ATTACHMENT_FAILURE: 'DELETE_ATTACHMENT_FAILURE',

  GET_SPECIFICATIONS_REQUEST: 'GET_SPECIFICATIONS_REQUEST',
  GET_SPECIFICATIONS_SUCCESS: 'GET_SPECIFICATIONS_SUCCESS',
  GET_SPECIFICATIONS_FAILURE: 'GET_SPECIFICATIONS_FAILURE',

  CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
  CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
  CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',
  UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
  UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
  UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',
  REMOVE_COMMENT_REQUEST: 'REMOVE_COMMENT_REQUEST',
  REMOVE_COMMENT_SUCCESS: 'REMOVE_COMMENT_SUCCESS',
  REMOVE_COMMENT_FAILURE: 'REMOVE_COMMENT_FAILURE',

  GET_PROJECT_LABELS_REQUEST: 'GET_PROJECT_LABELS_REQUEST',
  GET_PROJECT_LABELS_SUCCESS: 'GET_PROJECT_LABELS_SUCCESS',
  GET_PROJECT_LABELS_FAILURE: 'GET_PROJECT_LABELS_FAILURE',

  CREATE_PROJECT_LABEL_REQUEST: 'CREATE_PROJECT_LABEL_REQUEST',
  CREATE_PROJECT_LABEL_SUCCESS: 'CREATE_PROJECT_LABEL_SUCCESS',
  CREATE_PROJECT_LABEL_FAILURE: 'CREATE_PROJECT_LABEL_FAILURE',
  UPDATE_PROJECT_LABEL_REQUEST: 'UPDATE_PROJECT_LABEL_REQUEST',
  UPDATE_PROJECT_LABEL_SUCCESS: 'UPDATE_PROJECT_LABEL_SUCCESS',
  UPDATE_PROJECT_LABEL_FAILURE: 'UPDATE_PROJECT_LABEL_FAILURE',
  REMOVE_PROJECT_LABEL_REQUEST: 'REMOVE_PROJECT_LABEL_REQUEST',
  REMOVE_PROJECT_LABEL_SUCCESS: 'REMOVE_PROJECT_LABEL_SUCCESS',
  REMOVE_PROJECT_LABEL_FAILURE: 'REMOVE_PROJECT_LABEL_FAILURE',

  CREATE_PROJECT_USE_TEMPLATE: 'CREATE_PROJECT_USE_TEMPLATE',

  CREATE_TASK_LABEL_REQUEST: 'CREATE_TASK_LABEL_REQUEST',
  CREATE_TASK_LABEL_SUCCESS: 'CREATE_TASK_LABEL_SUCCESS',
  CREATE_TASK_LABEL_FAILURE: 'CREATE_TASK_LABEL_FAILURE',
  REMOVE_TASK_LABEL_REQUEST: 'REMOVE_TASK_LABEL_REQUEST',
  REMOVE_TASK_LABEL_SUCCESS: 'REMOVE_TASK_LABEL_SUCCESS',
  REMOVE_TASK_LABEL_FAILURE: 'REMOVE_TASK_LABEL_FAILURE',

  GET_TASK_REQUEST: 'GET_TASK_REQUEST',
  GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
  GET_TASK_FAILURE: 'GET_TASK_FAILURE',

  SET_TASK_ASSIGNEE_REQUEST: 'SET_TASK_ASSIGNEE_REQUEST',
  SET_TASK_ASSIGNEE_SUCCESS: 'SET_TASK_ASSIGNEE_SUCCESS',
  SET_TASK_ASSIGNEE_FAILURE: 'SET_TASK_ASSIGNEE_FAILURE',

  COPY_PROJECT_REQUEST: 'COPY_PROJECT_REQUEST',
  COPY_PROJECT_SUCCESS: 'COPY_PROJECT_SUCCESS',
  COPY_PROJECT_FAILURE: 'COPY_PROJECT_FAILURE',

  COPY_TASK_REQUEST: 'COPY_TASK_REQUEST',
  COPY_TASK_SUCCESS: 'COPY_TASK_SUCCESS',
  COPY_TASK_FAILURE: 'COPY_TASK_FAILURE',

  CHANGE_BUSINESS_REQUEST: 'CHANGE_BUSINESS_REQUEST',
  CHANGE_BUSINESS_SUCCESS: 'CHANGE_BUSINESS_SUCCESS',
  CHANGE_BUSINESS_FAILURE: 'CHANGE_BUSINESS_FAILURE',

  MOVE_TASK_REQUEST: 'MOVE_TASK_REQUEST',
  MOVE_TASK_SUCCESS: 'MOVE_TASK_SUCCESS',
  MOVE_TASK_FAILURE: 'MOVE_TASK_FAILURE',

  MOVE_TASK_LOCAL: 'MOVE_TASK_LOCAL',

  SET_TASK_REPEAT_REQUEST: 'SET_TASK_REPEAT_REQUEST',
  SET_TASK_REPEAT_SUCCESS: 'SET_TASK_REPEAT_SUCCESS',
  SET_TASK_REPEAT_FAILURE: 'SET_TASK_REPEAT_FAILURE',

  GET_AUDIT_LOGS_REQUEST: 'GET_AUDIT_LOGS_REQUEST',
  GET_AUDIT_LOGS_SUCCESS: 'GET_AUDIT_LOGS_SUCCESS',
  GET_AUDIT_LOGS_FAILURE: 'GET_AUDIT_LOGS_FAILURE',

  GET_ASSIGNED_TASKS_REQUEST: 'GET_ASSIGNED_TASKS_REQUEST',
  GET_ASSIGNED_TASKS_SUCCESS: 'GET_ASSIGNED_TASKS_SUCCESS',
  GET_ASSIGNED_TASKS_FAILURE: 'GET_ASSIGNED_TASKS_FAILURE',

  RENAME_CHECKLIST_REQUEST: 'RENAME_CHECKLIST_REQUEST',
  RENAME_CHECKLIST_SUCCESS: 'RENAME_CHECKLIST_SUCCESS',
  RENAME_CHECKLIST_FAILURE: 'RENAME_CHECKLIST_FAILURE',

  SET_DEFAULT_TASK_TYPE: 'SET_DEFAULT_TASK_TYPE',

  GET_UPCOMING_TASKS_TRACKER_REQUEST: 'GET_UPCOMING_TASKS_TRACKER_REQUEST',
  GET_UPCOMING_TASKS_TRACKER_SUCCESS: 'GET_UPCOMING_TASKS_TRACKER_SUCCESS',
  GET_UPCOMING_TASKS_TRACKER_FAILURE: 'GET_UPCOMING_TASKS_TRACKER_FAILURE',

  GET_EXPIRED_TASKS_TRACKER_REQUEST: 'GET_EXPIRED_TASKS_TRACKER_REQUEST',
  GET_EXPIRED_TASKS_TRACKER_SUCCESS: 'GET_EXPIRED_TASKS_TRACKER_SUCCESS',
  GET_EXPIRED_TASKS_TRACKER_FAILURE: 'GET_EXPIRED_TASKS_TRACKER_FAILURE',

  GET_ASSIGNED_TASKS_TRACKER_REQUEST: 'GET_ASSIGNED_TASKS_TRACKER_REQUEST',
  GET_ASSIGNED_TASKS_TRACKER_SUCCESS: 'GET_ASSIGNED_TASKS_TRACKER_SUCCESS',
  GET_ASSIGNED_TASKS_TRACKER_FAILURE: 'GET_ASSIGNED_TASKS_TRACKER_FAILURE',

  MOVE_SECTION_REQUEST: 'MOVE_SECTION_REQUEST',
  MOVE_SECTION_SUCCESS: 'MOVE_SECTION_SUCCESS',
  MOVE_SECTION_FAILURE: 'MOVE_SECTION_FAILURE',
  MOVE_SECTION_LOCAL: 'MOVE_SECTION_LOCAL',

  HIDE_COLUMNS: 'HIDE_COLUMNS',
  HIDE_COLUMNS_FAILURE: 'HIDE_COLUMNS_FAILURE',

  HIDE_SECTION: 'HIDE_SECTION',
  HIDE_SECTION_FAILURE: 'HIDE_SECTION_FAILURE',

  GET_DASHBOARD_ASSIGNED_USERS_REQUEST: 'GET_DASHBOARD_ASSIGNED_USERS_REQUEST',
  GET_DASHBOARD_ASSIGNED_USERS_SUCCESS: 'GET_DASHBOARD_ASSIGNED_USERS_SUCCESS',
  GET_DASHBOARD_ASSIGNED_USERS_FAILURE: 'GET_DASHBOARD_ASSIGNED_USERS_FAILURE',

  FETCH_PROJECTS_HOME_VIEW_REQUEST: 'FETCH_PROJECTS_HOME_VIEW_REQUEST',
  FETCH_PROJECTS_HOME_VIEW_SUCCESS: 'FETCH_PROJECTS_HOME_VIEW_SUCCESS',
  FETCH_PROJECTS_HOME_VIEW_FAILURE: 'FETCH_PROJECTS_HOME_VIEW_FAILURE',

  FETCH_CHECKLISTS_HOME_VIEW_REQUEST: 'FETCH_CHECKLISTS_HOME_VIEW_REQUEST',
  FETCH_CHECKLISTS_HOME_VIEW_SUCCESS: 'FETCH_CHECKLISTS_HOME_VIEW_SUCCESS',
  FETCH_CHECKLISTS_HOME_VIEW_FAILURE: 'FETCH_CHECKLISTS_HOME_VIEW_FAILURE',

  FETCH_ARCHIVED_PROJECTS_HOME_VIEW_REQUEST:
    'FETCH_ARCHIVED_PROJECTS_HOME_VIEW_REQUEST',
  FETCH_ARCHIVED_PROJECTS_HOME_VIEW_SUCCESS:
    'FETCH_ARCHIVED_PROJECTS_HOME_VIEW_SUCCESS',
  FETCH_ARCHIVED_PROJECTS_HOME_VIEW_FAILURE:
    'FETCH_ARCHIVED_PROJECTS_HOME_VIEW_FAILURE',

  UPDATE_PROJECT_STARRED_REQUEST: 'UPDATE_PROJECT_STARRED_REQUEST',
  UPDATE_PROJECT_STARRED_SUCCESS: 'UPDATE_PROJECT_STARRED_SUCCESS',
  UPDATE_PROJECT_STARRED_FAILURE: 'UPDATE_PROJECT_STARRED_FAILURE',

  CREATE_PROJECT_IMAGE_REQUEST: 'CREATE_PROJECT_IMAGE_REQUEST',
  CREATE_PROJECT_IMAGE_SUCCESS: 'CREATE_PROJECT_IMAGE_SUCCESS',
  CREATE_PROJECT_IMAGE_FAILURE: 'CREATE_PROJECT_IMAGE_FAILURE',

  DELETE_PROJECT_IMAGE_REQUEST: 'DELETE_PROJECT_IMAGE_REQUEST',
  DELETE_PROJECT_IMAGE_SUCCESS: 'DELETE_PROJECT_IMAGE_SUCCESS',
  DELETE_PROJECT_IMAGE_FAILURE: 'DELETE_PROJECT_IMAGE_FAILURE',

  UPDATE_PROJECT_PERMISSION_REQUEST: 'UPDATE_PROJECT_PERMISSION_REQUEST',
  UPDATE_PROJECT_PERMISSION_SUCCESS: 'UPDATE_PROJECT_PERMISSION_SUCCESS',
  UPDATE_PROJECT_PERMISSION_FAILURE: 'UPDATE_PROJECT_PERMISSION_FAILURE',

  ARCHIVE_PROJECT_REQUEST: 'ARCHIVE_PROJECT_REQUEST',
  ARCHIVE_PROJECT_SUCCESS: 'ARCHIVE_PROJECT_SUCCESS',
  ARCHIVE_PROJECT_FAILURE: 'ARCHIVE_PROJECT_FAILURE',

  UNZIP_PROJECT_REQUEST: 'UNZIP_PROJECT_REQUEST',
  UNZIP_PROJECT_SUCCESS: 'UNZIP_PROJECT_SUCCESS',
  UNZIP_PROJECT_FAILURE: 'UNZIP_PROJECT_FAILURE',

  REMOVE_HOME_VIEW_PROJECT_REQUEST: 'REMOVE_HOME_VIEW_PROJECT_REQUEST',
  REMOVE_HOME_VIEW_PROJECT_SUCCESS: 'REMOVE_HOME_VIEW_PROJECT_SUCCESS',
  REMOVE_HOME_VIEW_PROJECT_FAILURE: 'REMOVE_HOME_VIEW_PROJECT_FAILURE',

  SET_FILTER_STATE: 'SET_FILTER_STATE',

  FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_REQUEST:
    'FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_REQUEST',
  FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_SUCCESS:
    'FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_SUCCESS',
  FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_FAILURE:
    'FETCH_HOME_VIEW_PROJECT_ACTIVE_ITEMS_FAILURE',

  UPDATE_PROJECT_ADMIN_TYPE_REQUEST: 'UPDATE_PROJECT_ADMIN_TYPE_REQUEST',
  UPDATE_PROJECT_ADMIN_TYPE_SUCCESS: 'UPDATE_PROJECT_ADMIN_TYPE_SUCCESS',
  UPDATE_PROJECT_ADMIN_TYPE_FAILURE: 'UPDATE_PROJECT_ADMIN_TYPE_FAILURE',

  FETCH_PROJECTS_BY_NAME_REQUEST: 'FETCH_PROJECTS_BY_NAME_REQUEST',
  FETCH_PROJECTS_BY_NAME_SUCCESS: 'FETCH_PROJECTS_BY_NAME_SUCCESS',
  FETCH_PROJECTS_BY_NAME_FAILURE: 'FETCH_PROJECTS_BY_NAME_FAILURE',

  FETCH_PROJECTS_BY_EXTRA_ID_AND_NAME_REQUEST:
    'FETCH_PROJECTS_BY_EXTRA_ID_AND_NAME_REQUEST',
  FETCH_PROJECTS_BY_EXTRA_ID_AND_NAME_SUCCESS:
    'FETCH_PROJECTS_BY_EXTRA_ID_AND_NAME_SUCCESS',
  FETCH_PROJECTS_BY_EXTRA_ID_AND_NAME_FAILURE:
    'FETCH_PROJECTS_BY_EXTRA_ID_AND_NAME_FAILURE',

  CLEAR_PROJECT_USERS: 'CLEAR_PROJECT_USERS',

  CONNECT_USER_PROJECTS_HOME_REQUEST: 'CONNECT_USER_PROJECTS_HOME_REQUEST',
  CONNECT_USER_PROJECTS_HOME_SUCCESS: 'CONNECT_USER_PROJECTS_HOME_SUCCESS',
  CONNECT_USER_PROJECTS_HOME_FAILURE: 'CONNECT_USER_PROJECTS_HOME_FAILURE',

  DISCONNECT_USER_PROJECTS_HOME_REQUEST:
    'DISCONNECT_USER_PROJECTS_HOME_REQUEST',
  DISCONNECT_USER_PROJECTS_HOME_SUCCESS:
    'DISCONNECT_USER_PROJECTS_HOME_SUCCESS',
  DISCONNECT_USER_PROJECTS_HOME_FAILURE:
    'DISCONNECT_USER_PROJECTS_HOME_FAILURE',

  FETCH_BUSINESS_PROJECTS_REQUEST: 'FETCH_BUSINESS_PROJECTS_REQUEST',
  FETCH_BUSINESS_PROJECTS_SUCCESS: 'FETCH_BUSINESS_PROJECTS_SUCCESS',
  FETCH_BUSINESS_PROJECTS_FAILURE: 'FETCH_BUSINESS_PROJECTS_FAILURE',

  CLEAR_BUSINESS_PROJECTS: 'CLEAR_BUSINESS_PROJECTS',

  SET_CHECKLIST_SOP_EDIT_AVAILABLE: 'SET_CHECKLIST_SOP_EDIT_AVAILABLE',

  FETCH_PROJECTS_LIST_DATA_REQUEST: 'FETCH_PROJECTS_LIST_DATA_REQUEST',
  FETCH_PROJECTS_LIST_DATA_SUCCESS: 'FETCH_PROJECTS_LIST_DATA_SUCCESS',
  FETCH_PROJECTS_LIST_DATA_FAILURE: 'FETCH_PROJECTS_LIST_DATA_FAILURE',

  REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_REQUEST:
    'REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_REQUEST',
  REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_SUCCESS:
    'REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_SUCCESS',
  REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_FAILURE:
    'REMOVE_ASSIGNED_PROJECT_FROM_BUSINESS_FAILURE',

  ASSIGN_NEW_USER_TO_PROJECT_TASK_REQUEST:
    'ASSIGN_NEW_USER_TO_PROJECT_TASK_REQUEST',
  ASSIGN_NEW_USER_TO_PROJECT_TASK_SUCCESS:
    'ASSIGN_NEW_USER_TO_PROJECT_TASK_SUCCESS',
  ASSIGN_NEW_USER_TO_PROJECT_TASK_FAILURE:
    'ASSIGN_NEW_USER_TO_PROJECT_TASK_FAILURE',

  SET_ASSIGNED_USER_TO_NULL: 'SET_ASSIGNED_USER_TO_NULL',

  FETCH_TASK_FILE_REQUEST: 'FETCH_TASK_FILE_REQUEST',
  FETCH_TASK_FILE_SUCCESS: 'FETCH_TASK_FILE_SUCCESS',
  FETCH_TASK_FILE_FAILURE: 'FETCH_TASK_FILE_FAILURE',

  SHOW_CHANGING_BUSINESS_PROMPT: 'SHOW_CHANGING_BUSINESS_PROMPT',

  FETCH_PROJECT_TAGS_REQUEST: 'FETCH_PROJECT_TAGS_REQUEST',
  FETCH_PROJECT_TAGS_SUCCESS: 'FETCH_PROJECT_TAGS_SUCCESS',
  FETCH_PROJECT_TAGS_FAILURE: 'FETCH_PROJECT_TAGS_FAILURE',

  CREATE_PROJECT_TAG_REQUEST: 'CREATE_PROJECT_TAG_REQUEST',
  CREATE_PROJECT_TAG_SUCCESS: 'CREATE_PROJECT_TAG_SUCCESS',
  CREATE_PROJECT_TAG_FAILURE: 'CREATE_PROJECT_TAG_FAILURE',

  UPDATE_PROJECT_TAG_REQUEST: 'UPDATE_PROJECT_TAG_REQUEST',
  UPDATE_PROJECT_TAG_SUCCESS: 'UPDATE_PROJECT_TAG_SUCCESS',
  UPDATE_PROJECT_TAG_FAILURE: 'UPDATE_PROJECT_TAG_FAILURE',

  DELETE_PROJECT_TAG_REQUEST: 'DELETE_PROJECT_TAG_REQUEST',
  DELETE_PROJECT_TAG_FROM_LIST_REQUEST: 'DELETE_PROJECT_TAG_FROM_LIST_REQUEST',
  DELETE_PROJECT_TAG_FROM_LIST_REQUEST_SUCCESS:
    'DELETE_PROJECT_TAG_FROM_LIST_REQUEST_SUCCESS',
  DELETE_PROJECT_TAG_SUCCESS: 'DELETE_PROJECT_TAG_SUCCESS',
  DELETE_PROJECT_TAG_FAILURE: 'DELETE_PROJECT_TAG_FAILURE',

  SELECT_AVAILABLE_PROJECT_TAG_REQUEST: ' SELECT_AVAILABLE_PROJECT_TAG_REQUEST',
  SELECT_AVAILABLE_PROJECT_TAG_SUCCESS: 'SELECT_AVAILABLE_PROJECT_TAG_SUCCESS',
  SELECT_AVAILABLE_PROJECT_TAG_FAILURE: ' SELECT_AVAILABLE_PROJECT_TAG_FAILURE',

  SET_TASK_DATE_REQUEST: 'SET_TASK_DATE_REQUEST',
  SET_TASK_DATE_SUCCESS: 'SET_TASK_DATE_SUCCESS',
  SET_TASK_DATE_FAILURE: 'SET_TASK_DATE_FAILURE',

  UPDATE_FILTERS_SETTINGS: 'UPDATE_FILTERS_SETTINGS',
  REMOVE_FILTER_SETTINGS_ITEM: 'REMOVE_FILTER_SETTINGS_ITEM',
  SET_FILTERS_SETTINGS_BY_DEFAULT: 'SET_FILTERS_SETTINGS_BY_DEFAULT',
  SET_SAVED_FILTER_SETTINGS: 'SET_SAVED_FILTER_SETTINGS',

  UPDATE_PROJECT_DATA_AND_PUBLISH_REQUEST:
    'UPDATE_PROJECT_DATA_AND_PUBLISH_REQUEST',
  UPDATE_PROJECT_DATA_AND_PUBLISH_SUCCESS:
    'UPDATE_PROJECT_DATA_AND_PUBLISH_SUCCESS',
  UPDATE_PROJECT_DATA_AND_PUBLISH_FAILURE:
    'UPDATE_PROJECT_DATA_AND_PUBLISH_FAILURE',

  FETCH_TEMPLATE_LIST_REQUEST: 'FETCH_TEMPLATE_LIST_REQUEST',
  FETCH_TEMPLATE_LIST_SUCCESS: 'FETCH_TEMPLATE_LIST_SUCCESS',
  FETCH_TEMPLATE_LIST_FAILURE: 'FETCH_TEMPLATE_LIST_FAILURE',

  FETCH_TEMPLATES_BY_NAME_REQUEST: 'FETCH_TEMPLATES_BY_NAME_REQUEST',
  FETCH_TEMPLATES_BY_NAME_SUCCESS: 'FETCH_TEMPLATES_BY_NAME_SUCCESS',
  FETCH_TEMPLATES_BY_NAME_FAILURE: 'FETCH_TEMPLATES_BY_NAME_FAILURE',

  FETCH_SELECTED_PROJECT_REQUEST: 'FETCH_SELECTED_PROJECT_REQUEST',
  FETCH_SELECTED_PROJECT_SUCCESS: 'FETCH_SELECTED_PROJECT_SUCCESS',
  FETCH_SELECTED_PROJECT_FAILURE: 'FETCH_SELECTED_PROJECT_FAILURE',

  CREATE_PROJECT_AVATAR_REQUEST: 'CREATE_PROJECT_AVATAR_REQUEST',
  CREATE_PROJECT_AVATAR_SUCCESS: 'CREATE_PROJECT_AVATAR_SUCCESS',
  CREATE_PROJECT_AVATAR_FAILURE: 'CREATE_PROJECT_AVATAR_FAILURE',

  FETCH_PROJECT_AVATAR_URL_REQUEST: 'FETCH_PROJECT_AVATAR_URL_REQUEST',
  FETCH_PROJECT_AVATAR_URL_SUCCESS: 'FETCH_PROJECT_AVATAR_URL_SUCCESS',
  FETCH_PROJECT_AVATAR_URL_FAILURE: 'FETCH_PROJECT_AVATAR_URL_FAILURE',

  REMOVE_PROJECT_AVATAR_REQUEST: 'REMOVE_PROJECT_AVATAR_REQUEST',
  REMOVE_PROJECT_AVATAR_SUCCESS: 'REMOVE_PROJECT_AVATAR_SUCCESS',
  REMOVE_PROJECT_AVATAR_FAILURE: 'REMOVE_PROJECT_AVATAR_FAILURE',

  UPDATE_PROJECT_COMPLETING: 'UPDATE_PROJECT_COMPLETING',

  CREATE_PROJECT_AVATAR_REQUEST: 'CREATE_PROJECT_AVATAR_REQUEST',
  CREATE_PROJECT_AVATAR_SUCCESS: 'CREATE_PROJECT_AVATAR_SUCCESS',
  CREATE_PROJECT_AVATAR_FAILURE: 'CREATE_PROJECT_AVATAR_FAILURE',

  FETCH_PROJECT_AVATAR_URL_REQUEST: 'FETCH_PROJECT_AVATAR_URL_REQUEST',
  FETCH_PROJECT_AVATAR_URL_SUCCESS: 'FETCH_PROJECT_AVATAR_URL_SUCCESS',
  FETCH_PROJECT_AVATAR_URL_FAILURE: 'FETCH_PROJECT_AVATAR_URL_FAILURE',

  REMOVE_PROJECT_AVATAR_REQUEST: 'REMOVE_PROJECT_AVATAR_REQUEST',
  REMOVE_PROJECT_AVATAR_SUCCESS: 'REMOVE_PROJECT_AVATAR_SUCCESS',
  REMOVE_PROJECT_AVATAR_FAILURE: 'REMOVE_PROJECT_AVATAR_FAILURE',

  CREATE_RELATED_TASKS_REQUEST: 'CREATE_RELATED_TASKS_REQUEST',
  CREATE_RELATED_TASKS_SUCCESS: 'CREATE_RELATED_TASKS_SUCCESS',
  CREATE_RELATED_TASKS_FAILURE: 'CREATE_RELATED_TASKS_FAILURE',

  REMOVE_RELATED_TASKS_REQUEST: 'REMOVE_RELATED_TASKS_REQUEST',
  REMOVE_RELATED_TASKS_SUCCESS: 'REMOVE_RELATED_TASKS_SUCCESS',
  REMOVE_RELATED_TASKS_FAILURE: 'REMOVE_RELATED_TASKS_FAILURE',

  CHANGE_TEMPLATE_TYPE_REQUEST: 'CHANGE_TEMPLATE_TYPE_REQUEST',
  CHANGE_TEMPLATE_TYPE_SUCCESS: 'CHANGE_TEMPLATE_TYPE_SUCCESS',
  CHANGE_TEMPLATE_TYPE_FAILURE: 'CHANGE_TEMPLATE_TYPE_FAILURE',

  ASSIGN_TEAM_TO_PROJECT_REQUEST: 'ASSIGN_TEAM_TO_PROJECT_REQUEST',
  ASSIGN_TEAM_TO_PROJECT_SUCCESS: 'ASSIGN_TEAM_TO_PROJECT_SUCCESS',
  ASSIGN_TEAM_TO_PROJECT_FAILURE: 'ASSIGN_TEAM_TO_PROJECT_FAILURE',

  DISMISS_TEAM_FROM_PROJECT_REQUEST: 'DISMISS_TEAM_FROM_PROJECT_REQUEST',
  DISMISS_TEAM_FROM_PROJECT_SUCCESS: 'DISMISS_TEAM_FROM_PROJECT_SUCCESS',
  DISMISS_TEAM_FROM_PROJECT_FAILURE: 'DISMISS_TEAM_FROM_PROJECT_FAILURE',

  UPDATE_PROJECT_SECTION_TASK_DATES: 'UPDATE_PROJECT_SECTION_TASK_DATES',

  GET_SIMPLE_PROJECTS_DATA_REQUEST: 'GET_SIMPLE_PROJECTS_DATA_REQUEST',
  GET_SIMPLE_PROJECTS_DATA_SUCCESS: 'GET_SIMPLE_PROJECTS_DATA_SUCCESS',
  GET_SIMPLE_PROJECTS_DATA_FAILURE: 'GET_SIMPLE_PROJECTS_DATA_FAILURE',

  ADD_INVITE_USER_TO_PROJECT_STATE: 'ADD_INVITE_USER_TO_PROJECT_STATE',

  FETCH_TASK_SUBTASKS_REQUEST: 'FETCH_TASK_SUBTASKS_REQUEST',
  FETCH_TASK_SUBTASKS_SUCCESS: 'FETCH_TASK_SUBTASKS_SUCCESS',
  FETCH_TASK_SUBTASKS_FAILURE: 'FETCH_TASK_SUBTASKS_FAILURE',

  REMOVE_TASK_SUBTASKS: 'REMOVE_TASK_SUBTASKS',

  FETCH_PROJECT_SUBTASKS_REQUEST: 'FETCH_PROJECT_SUBTASKS_REQUEST',
  FETCH_PROJECT_SUBTASKS_SUCCESS: 'FETCH_PROJECT_SUBTASKS_SUCCESS',
  FETCH_PROJECT_SUBTASKS_FAILURE: 'FETCH_PROJECT_SUBTASKS_FAILURE',

  REMOVE_PROJECT_SUBTASKS: 'REMOVE_PROJECT_SUBTASKS',

  CREATE_SUBTASK_SUCCESS: 'CREATE_SUBTASK_SUCCESS',

  MOVE_SUBTASK_REQUEST: 'MOVE_SUBTASK_REQUEST',
  MOVE_SUBTASK_SUCCESS: 'MOVE_SUBTASK_SUCCESS',
  MOVE_SUBTASK_FAILURE: 'MOVE_SUBTASK_FAILURE',
  MOVE_SUBTASK_LOCAL: 'MOVE_SUBTASK_LOCAL',

  CHECK_EXISTENCE_OF_PROJECT_REQUEST: 'CHECK_EXISTENCE_OF_PROJECT_REQUEST',
  CHECK_EXISTENCE_OF_PROJECT_SUCCESS: 'CHECK_EXISTENCE_OF_PROJECT_SUCCESS',
  CHECK_EXISTENCE_OF_PROJECT_FAILURE: 'CHECK_EXISTENCE_OF_PROJECT_FAILURE',
  RESET_EXISTENCE_OF_PROJECT_DATE: 'RESET_EXISTENCE_OF_PROJECT_DATE',

  GET_PROJECTS_TAGS_REQUEST: 'GET_PROJECTS_TAGS_REQUEST',
  GET_PROJECTS_TAGS_SUCCESS: 'GET_PROJECTS_TAGS_SUCCESS',
  GET_PROJECTS_TAGS_FAILURE: 'GET_PROJECTS_TAGS_FAILURE',

  GET_TEMPLATES_TAGS_REQUEST: 'GET_TEMPLATES_TAGS_REQUEST',
  GET_TEMPLATES_TAGS_SUCCESS: 'GET_TEMPLATES_TAGS_SUCCESS',
  GET_TEMPLATES_TAGS_FAILURE: 'GET_TEMPLATES_TAGS_FAILURE',

  GET_ARCHIVED_PROJECTS_TAGS_REQUEST: 'GET_ARCHIVED_PROJECTS_TAGS_REQUEST',
  GET_ARCHIVED_PROJECTS_TAGS_SUCCESS: 'GET_ARCHIVED_PROJECTS_TAGS_SUCCESS',
  GET_ARCHIVED_PROJECTS_TAGS_FAILURE: 'GET_ARCHIVED_PROJECTS_TAGS_FAILURE',

  PROJECT_NAME_IS_TAKEN_ERROR: 'PROJECT_NAME_IS_TAKEN_ERROR',
};
