import { all, takeEvery, put } from 'redux-saga/effects';
import _ from 'lodash';

import meActions from './actions';
import anamiService from '../../services/anamiService';

import tableColumns from './me-business-table-columns';

function* getMe() {
  try {
    const response = yield anamiService.getMe();

    yield put(meActions.getMeSuccess(response.data));
  } catch (error) {
    yield put(meActions.getMeFailure(error));
  }
}

function* meUpdate({ data }) {
  try {
    const response = yield anamiService.updateMe(data);

    yield put(meActions.updateMeSuccess(response.data.user));
  } catch (error) {
    yield put(meActions.updateMeFailure(error));
  }
}

function* uploadAvatar({ data }) {
  try {
    const response = yield anamiService.uploadMeAvatar(data);
    const { avatar } = response.data;

    yield put(meActions.uploadAvatarSuccess(avatar));
  } catch (error) {
    yield put(meActions.uploadAvatarFailure(error));
  }
}

function* getAgreement({ data }) {
  try {
    const response = yield anamiService.getAgreement(data);

    yield put(meActions.getAgreementSuccess(response.data.agreement));
  } catch (error) {
    yield put(meActions.getAgreementFailure(error));
  }
}

function* getMeFilters() {
  try {
    const response = yield anamiService.getMeFilters();
    const filters = response.data.filters || {};
    let isFiltersEqual = false;

    if (_.has(filters, 'businessTableFields')) {
      isFiltersEqual =
        Object.keys(filters.businessTableFields).length ===
          Object.keys(tableColumns).length &&
        Object.values(filters.businessTableFields).every(
          key =>
            !!Object.values(tableColumns).find(item => item.name === key.name)
        );
    }

    yield put(
      meActions.getMeFiltersSuccess({
        ...filters,
        ...(!_.has(
          filters,
          'businessTableFields'
        ) /*  || !isFiltersEqual */ && {
          businessTableFields: tableColumns,
        }),
      })
    );
  } catch (error) {
    yield put(meActions.getMeFiltersFailure(error));
  }
}

function* updateMeFilters({ data }) {
  try {
    const response = yield anamiService.updateMeFilters(data);

    yield put(meActions.updateMeFiltersSuccess(response.data.filters));
  } catch (error) {
    yield put(meActions.updateMeFiltersFailure(error));
  }
}

function* setUploadFileNotification() {
  try {
    yield anamiService.setUploadFileNotification({
      entity: 'uploadFile',
      enable: false,
    });

    yield put(meActions.setUploadFileNotificationSuccess());
  } catch (error) {
    yield put(meActions.setUploadFileNotificationFailure(error));
  }
}

export default function* mesSaga() {
  yield all([takeEvery(meActions.GET_ME_REQUEST, getMe)]);
  yield all([takeEvery(meActions.UPDATE_ME_REQUEST, meUpdate)]);
  yield all([takeEvery(meActions.GET_AGREEMENT_REQUEST, getAgreement)]);
  yield all([takeEvery(meActions.UPLOAD_ME_AVATAR_REQUEST, uploadAvatar)]);
  yield takeEvery(meActions.GET_ME_FILTERS_REQUEST, getMeFilters);
  yield takeEvery(meActions.UPDATE_ME_FILTERS_REQUEST, updateMeFilters);
  yield takeEvery(
    meActions.SET_UPLOAD_FILE_NOTIFICATION_REQUEST,
    setUploadFileNotification
  );
}
