import { createSelector } from 'reselect';

const appSelector = state => state.App;

export const selectIsTabDuplicate = createSelector(
  appSelector,
  ({ isTabDuplicate }) => isTabDuplicate
);

export const selectIsViewMobile = createSelector(
  appSelector,
  ({ view }) => view === 'MobileView'
);
