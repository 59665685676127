const sortLicenses = licenses => {
  try {
    const splitIndex = licenses.findIndex((license, idx, arr) => {
      if (arr[idx + 1] && license.position === arr[idx + 1].position) {
        return true;
      }

      return false;
    });

    let licensesForSort = [...licenses];
    let restLicenses = [];

    if (splitIndex > -1) {
      licensesForSort = licenses.slice(0, splitIndex + 1);
      restLicenses = licenses.slice(splitIndex + 1);
    }

    licensesForSort = licensesForSort.sort((a, b) =>
      a.position < b.position ? -1 : a.position > b.position ? 1 : 0
    );

    return [...licensesForSort, ...restLicenses];
  } catch (error) {
    console.log('error', error);
  }
};

export default (state, action) => ({
  sortLicenseByPosition: () => {
    return {
      ...state,
      listLoading: false,
      licenses: sortLicenses(action.payload),
    };
  },
});
